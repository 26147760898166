export const MAIN = 0
export const TOP = 1
export const SIDE = 2
export const REAR = 3

export const MATERIAL_COLORS = {
  PCD: {
    H: 0.341,
    S: 0.38,
    L: 0.57
  },
  HIGHLIGHT: 0xffffff,
  FAIL: '#B03A2E',
  HELPER: '#8E44AD',
  CAR: '#3498DB',
  PEDESTRIAN: '#148F77',
  CYCLIST: '#D4AC0D'
}

export const TYPE = {
  CAR: 'car',
  PEDESTRIAN: 'pedestrian',
  CYCLIST: 'cyclist'
}

export const PCD_COLOR = [104 / 255, 187 / 255, 108 / 255]

export const PCD_INBOX_COLOR = [255 / 255, 255 / 255, 255 / 255]

export const COLOR = {
  CAR: '#3498DB',
  PEDESTRIAN: '#148F77',
  CYCLIST: '#D4AC0D'
}

export const DEFAULT_DIMENSION = { x: 4, y: 2, z: 1.5 }

export const PLANE_DISTANCE = 1
export const FIGURE_PRECISION = 3
export const DEFAULT_POINT_SIZE = 1

export const SCALE_FACTOR = 100
export const SCALE_FACTORS = [SCALE_FACTOR, SCALE_FACTOR, SCALE_FACTOR]
export const HALF_WIDTH = 8

export const OPEN_BASE_URL = 's3://allride.ai-cardata/car/label'

export const CAM_WIDTH = 400
