import { axios } from '../_helpers'

export const annotationServices = {
  getAllAnnotations,
  getAnnotation,
  createAnnotation,
  updateAnnotation,
  updateAllAnnotations,
  assignAnnotation,
  getAllFrames,
  getFrame,
  createFrame,
  resetFrame,
  getObject,
  getAllObjects,
  createObject,
  updateObject,
  getAllTags,
  createTag,
  updateTag,
  getSome,
  getStat,
  getStatSum,
  calcuStats
}

/** Annotation Services */
function getAllAnnotations(params) {
  return axios
    .get('/annotationservice/getAll', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function getAnnotation(params) {
  return axios
    .get('/annotationservice/get', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function createAnnotation(data) {
  return axios.post('/annotationservice/create', data).then(function (response) {
    console.log(response)
  })
}

function updateAnnotation(data) {
  return axios.post('/annotationservice/update', data).then(function (response) {

    return response.data
  })
}

function updateAllAnnotations(data) {
  return axios
    .post('/annotationservice/updateAll', data)
    .then(function (response) {
      return response.data
    })
}

function assignAnnotation(data) {
  return axios.post('/annotationservice/assign', data).then(function (response) {
    return response.data
  })
}

/** Annotation Services */
function getAllFrames(data) {
  return axios
    .post('/frameservice/getAllByTimestamp', data)
    .then(function (response) {
      return response.data
    })
}

function getFrame(params) {
  return axios
    .get('/frameservice/get', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function createFrame(data) {
  return axios.post('/frameservice/create', data).then(function (response) {
    return response.data
  })
}

function resetFrame(data) {
  return axios.post('/frameservice/reset', data).then(function (response) {
    return response.data
  })
}

/** Object Services */
function getObject(params) {
  return axios
    .get('/annotationobjectservice/get', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function getAllObjects(params) {
  return axios
    .get('/annotationobjectservice/getAll', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function createObject(data) {
  return axios
    .post('/annotationobjectservice/create', data)
    .then(function (response) {
      return response.data
    })
}

function updateObject(data) {
  return axios
    .post('/annotationobjectservice/update', data)
    .then(function (response) {
      return response.data
    })
}

/** Tag Services */
function getAllTags(params) {
  return axios
    .get('/annotationservice/getTag', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function createTag(data) {
  return axios
    .post('/annotationservice/createTag', data)
    .then(function (response) {
      return response.data
    })
}

function updateTag(data) {
  return axios
    .post('/annotationservice/update', data)
    .then(function (response) {
      return response.data
    })
}

function getSome(params) {
  return axios
    .get('/annotationservice/getSome', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function getStat(data) {
  return axios
    .post('/annotationservice/getStat', data)
    .then(function (response) {
      return response.data
    })
}

function getStatSum(params) {
  return axios
    .get('/annotationservice/getStatSum', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function calcuStats(data) {
  return axios
    .post('/annotationservice/calcuStats', data)
    .then(function (response) {
      return response.data
    })
}