export const MAIN = 0
export const TOP = 1
export const SIDE = 2
export const REAR = 3
export const MATERIAL_COLORS = {
  PCD: {
    H: 0.341,
    S: 0.38,
    L: 0.57
  },
  HIGHLIGHT: 0xffffff,
  FAIL: '#B03A2E',
  HELPER: '#8E44AD',
  CAR: '#3498DB',
  PEDESTRIAN: '#148F77',
  CYCLIST: '#D4AC0D',
  TRUCK: '#FF1493',
  BICYCLE: '#fd8d02',
  TRICYCLE: '#FFF68F',
  CONE: '#02fde3',
  TROLLEY: '#02fdc3',
  MOVABLE: '#FFFFFF',
  STATIC: '#FFFFFF',
}
export const MATERIAL_RECT_COLORS = {
  CAR: '#3498DB',
  PEDESTRIAN: '#148F77',
  CONE: '#02fde3',
  CYCLIST: '#D4AC0D',
  TRUCK: '#FF1493',
  BUS:'#FB9322',
  VAN: '#A882FF',
  OTHER: '#AAAAAA',
  BARRIER: '#FFFFFF',
  TROLLEY: '#02fdc3',
}
export const MATERIAL_COLORS_OPACITY = {
  CAR: 'rgba(52, 152, 219, 0.1)',
  PEDESTRIAN: 'rgba(20, 143, 119, 0.1)',
  CYCLIST: 'rgba(212, 172, 13, 0.1)',
  TRUCK: 'rgba(255, 20, 147, 0.1)',
  BICYCLE: 'rgba(253, 141, 2, 0.1)',
  TRICYCLE: 'rgba(255, 246, 143, 0.1)',
  CONE: 'rgba(2, 253, 227, 0.1)',
  TROLLEY: 'rgba(2, 253, 195, 0.1)',
  MOVABLE: 'rgba(255, 255, 255, 0.1)',
  STATIC: 'rgba(255, 255, 255, 0.1)',
}
export const MATERIAL_NUM = {
  Digit1:'car',
  Digit2:'cone',
  Digit3:'pedestrian',
  Digit4:'truck',
  Digit5:'bicycle',
  Digit6:'cyclist',
}
export const MATERIAL_LIST = [
  {type:'tricycle'},
  {type:'trolley'},
  {type:'movable'},
  {type:'static'},
]

export const MATERIAL_DIMENSION = {
  FAIL: { x: 4.8, y: 2, z: 1.6 },
  HELPER: { x: 4.8, y: 2, z: 1.6 },
  CAR: { x: 4.8, y: 2.0, z: 1.6 },
  PEDESTRIAN: { x:0.7, y: 0.7, z: 1.8 },
  CYCLIST: { x: 1.8, y: 0.9, z: 1.6},
  TRUCK: { x: 9.1, y: 2.9, z: 3.3 },
  BICYCLE: { x: 1.8, y: 0.7, z: 1.3 },
  TRICYCLE: { x: 3.1, y: 1.4, z: 1.8 },
  CONE: { x: 0.4, y: 0.4, z: 0.8 },
  TROLLEY: { x: 1.2, y: 0.9, z: 0.9 },
  MOVABLE: { x: 0.8, y: 0.6, z: 0.6 },
  STATIC: { x: 0.5, y: 0.5, z: 0.8 }
}

export const PCD_COLOR = [104 / 255, 187 / 255, 108 / 255]

// export const PCD_INBOX_COLOR = [231 / 255, 76 / 255, 60 / 255]
export const PCD_INBOX_COLOR = [255 / 255, 255 / 255, 255 / 255]

export const TYPE = {
  TRUCK: 'truck',
  CAR: 'car',
  PEDESTRIAN: 'pedestrian',
  CYCLIST: 'cyclist',
  BICYCLE: 'bicycle',
  TRICYCLE: 'tricycle',
  CONE: 'cone',
  TROLLEY: 'trolley',
  MOVABLE: 'movable',
  STATIC: 'static',
}

export const COLOR = {
  CAR: '#3498DB',
  PEDESTRIAN: '#148F77',
  TRUCK: '#E74C3C',
  CYCLIST: '#D4AC0D',
  BICYCLE: '#fd8d02',
  TRICYCLE: '#FFF68F',
  CONE: '#02fde3',
  TROLLEY: '#02fdc3',
  MOVABLE: '#FFFFFF',
  STATIC: '#FFFFFF',
}

export const DEFAULT_DIMENSION = { x: 4.8, y: 2, z: 1.6 }

export const PLANE_DISTANCE = 1.8
export const FIGURE_PRECISION = 3
export const DEFAULT_POINT_SIZE = 1

export const SCALE_FACTOR = 100
export const SCALE_FACTORS = [SCALE_FACTOR, SCALE_FACTOR, SCALE_FACTOR]
export const HALF_WIDTH = 8

export const OPEN_BASE_URL = 's3://allride.ai-cardata/car/label'

export const CAM_WIDTH = 600
export const DEFAULT = {
  ZOOM_RATIO_PP: 100,
  IMAGE_OPACITY: 0.8,
  TYPE: TYPE.CAR,
  ALPHA: 0.2,
  STROKE_WIDTH: 1.5,
  STROKE_TRACKING_WIDTH: 1
}
export const PAD = 20
export const ZOOM = {
  MIN: 100,
  MAX: 300,
  PRECISION: 0
}