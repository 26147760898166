import {
  annotationConstants,
  frameConstants,
  objectConstants
} from '../_constants'
import moment from 'moment'

const initState = {
  annotations: [],
  frames: [],
  objects: [],
  objId: '',
  allObjects: [],
  total: 0,
  tag: '',
}

export function annotation(state = initState, action) {
  switch (action.type) {
    // Annotation
    case annotationConstants.GET_ALL_REQUEST:
      return {
        ...state,
        gettingAnnotations: true
      }
    case annotationConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        annotations: action.annotations || [],
        total: action.total,
        gettingAnnotations: false
      }
    case annotationConstants.GET_ALL_FAILURE:
      return state
    case annotationConstants.CREATE_REQUEST:
      return state
    case annotationConstants.CREATE_SUCCESS:
      return state
    case annotationConstants.CREATE_FAILURE:
      return state
    case annotationConstants.UPDATE_REQUEST:
      return {
        ...state,
        updatingAnnotation: true
      }
    case annotationConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updatingAnnotation: false
      }
    case annotationConstants.UPDATE_FAILURE:
      return {
        ...state,
        updatingAnnotation: false
      }
    case annotationConstants.UPDATE_ALL_REQUEST:
      return {
        ...state,
        updatingAnnotations: true
      }
    case annotationConstants.UPDATE_ALL_SUCCESS:
      return {
        ...state,
        updatingAnnotations: false
      }
    case annotationConstants.UPDATE_ALL_FAILURE:
      return {
        ...state,
        updatingAnnotations: false
      }
    case annotationConstants.ASSIGN_REQUEST:
      return state
    case annotationConstants.ASSIGN_SUCCESS:
      return state
    case annotationConstants.ASSIGN_FAILURE:
      return state
    // Frame
    case frameConstants.GET_ALL_REQUEST:
      return {
        ...state,
        gettingFrames: true
      }
    case frameConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        frames: action.frames || [],
        objects: [],
        gettingFrames: false
      }
    case frameConstants.GET_ALL_FAILURE:
      return {
        ...state,
        gettingFrames: false
      }
    case frameConstants.CREATE_REQUEST:
      return state
    case frameConstants.CREATE_SUCCESS:
      return state
    case frameConstants.CREATE_FAILURE:
      return state
    case frameConstants.RESET_REQUEST:
      return state
    case frameConstants.RESET_SUCCESS:
      return state
    case frameConstants.RESET_FAILURE:
      return state
    // Object
    case objectConstants.GET_REQUEST:
      return state
    case objectConstants.GET_SUCCESS:
      return {
        ...state,
        objects: action.objects || []
      }
    case objectConstants.GET_ID_SUCCESS:
      return {
        ...state,
        objId: action.objId || []
      }
    case objectConstants.GET_FAILURE:
      return state
    case objectConstants.GET_ALL_REQUEST:
      return state
    case objectConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        allObjects: action.allObjects || []
      }
    case objectConstants.GET_ALL_FAILURE:
      return state
    case objectConstants.CREATE_REQUEST:
      return state
    case objectConstants.CREATE_SUCCESS:
      return state
    case objectConstants.CREATE_FAILURE:
      return state
    case objectConstants.UPDATE_REQUEST:
      return state
    case objectConstants.UPDATE_SUCCESS:
      return state
    case objectConstants.UPDATE_FAILURE:
      return state
    case annotationConstants.GET_STAT_REQUEST:
      return state
    case annotationConstants.GET_STAT_SUCCESS:
      return {
        ...state,
        stats: action.data.stats?action.data.stats.map(item => {
          return {
            id: item.annotationId||0,
            type: item.type,
            time: moment.unix(parseInt(item.finishdate)).format('YYYY-MM-DD kk:mm:ss') ,
            iNum: item.i||0,
            tNum: item.t||0,
            oNum: item.o||0,
            fNum: item.frames||0,
            annotatorName: item.annotatorName ? item.annotatorName : '无',
            annotatorGroup: item.annotatorGroup ? item.annotatorGroup : '无',
            examinerName: item.examinerName ? item.examinerName : '无',
            examinerGroup: item.examinerGroup ? item.examinerGroup : '无',
            annotatorBuild: item.annotatorBuildCount||0,
            examinerBuild: item.examinerBuildCount||0,
            annotatorworkload: item.annotatorWork ? item.annotatorWork : 0,
            examinerworkload: item.examinerWork ? item.examinerWork : 0,
            annotatorAccuracy: (Number(item.annotatorAccuracy * 100).toFixed(2)+'%')||0,
            examinerReviewFrame: item.examinerExamCount||0,
          }
        }):[],
        statSum: action.data.stat||{},
        statDays: action.data.statdays||[],
        totalCount:action.data.totalCount
      }
    case annotationConstants.GET_STAT_FAILURE:
      return state
    // case annotationConstants.GET_STATSUM_REQUEST:
    //   return state
    // case annotationConstants.GET_STATSUM_SUCCESS:
    //   return {
    //     ...state,
    //     statSum: action.data.stat
    //   }
    // case annotationConstants.GET_STATSUM_FAILURE:
    //   return state
    case annotationConstants.GET_CALCUSTAT_REQUEST:
      return state
    case annotationConstants.GET_CALCUSTAT_SUCCESS:
      return {
        ...state,
        type:action.data.type
      }
    case annotationConstants.GET_CALCUSTAT_FAILURE:
      return state
    default:
      return state
  }
}
