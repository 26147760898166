import React from 'react'
import { CopyrightOutlined } from '@ant-design/icons'
import './Footer.scss'

const Footer = () => (
  <footer className="footer">
    <span className="footer-font">
      <CopyrightOutlined />
      allride.ai All Rights Reserved
    </span>
    <span>    </span>
    <a className="footer-font" href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">
      京公网安备32011302320725号
    </a>
  </footer>
)

export default Footer