import React, { Component } from 'react'
import './Bags.scss'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { alertActions, bagActions, groupActions } from '../_actions'
import { dataToFields, fieldsToData } from '../_utils'

import { FolderOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import {
  Table,
  ConfigProvider,
  message,
  Input,
  Button,
  Modal,
  Popconfirm,
  Select,
  InputNumber,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
const { Column } = Table
const { Option } = Select
const PageSize = 10

const modalBodyStyle = {
  backgroundColor: '#202020',
  padding: '1.5rem 1.5rem 0.5rem'
}

const formItemLayout = {
  labelAlign: 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const tailFormItemLayout = {
  wrapperCol: {
    span: 18,
    offset: 6
  }
}

const BagEditForm = Form.create({
  //
  name: 'BagEdit',
  //
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields)
  },
  //
  mapPropsToFields(props) {
    return {
      description: Form.createFormField({
        ...props.description,
        value: props.description ? props.description.value : ''
      }),
      groupId: Form.createFormField({
        ...props.groupId,
        value: props.groupId ? props.groupId.value : ''
      }),
      priority: Form.createFormField({
        ...props.priority,
        value: props.priority ? props.priority.value : null
      })
    }
  },
  //
  onValuesChange(_, values) { }
})(({ form, onSubmit, onDelete, updating, t, groups }) => {
  const { getFieldDecorator } = form

  const GroupRender = () => {
    return (
      <Form.Item label={t('group')}>
        {getFieldDecorator('groupId')(
          <Select>
            {groups &&
              groups.map(group => <Option key={group.id}>{group.name}</Option>)}
          </Select>
        )}
      </Form.Item>
    )
  }

  return (
    <Form {...formItemLayout}>
      <Form.Item label={t('description')}>
        {getFieldDecorator('description')(<TextArea />)}
      </Form.Item>
      <GroupRender />
      <Form.Item label={t('priority')}>
        {getFieldDecorator('priority')(<InputNumber min={0} />)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" onClick={onSubmit} loading={updating}>
          {t('btns.update')}
        </Button>
        <Popconfirm
          title={t('pop.deleteBag')}
          onConfirm={onDelete}
          okText={t('pop.okText')}
          cancelText={t('pop.cancelText')}
        >
          <span className="bags-delete-button">{t('btns.delete')}</span>
        </Popconfirm>
      </Form.Item>
    </Form>
  )
})

const InitPagination = {
  current: 1,
  pageSize: PageSize,
  total: 0,
  showQuickJumper: true
}

class Bags extends Component {
  state = {
    fields: {},
    bagSearch: '',
    isSelectGroupVisible: false,
    isEditVisible: false,
    pagination: InitPagination
  }

  componentDidMount() {
    const { dispatch, groups } = this.props

    this.getCurrentBags()
    groups.length || dispatch(groupActions.getAll())
  }

  componentDidUpdate(prevProps) {
    const { dispatch, message: alertMessage } = this.props

    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ pagination }, () => this.getCurrentBags())
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }))
  }

  getCurrentBags = (value, confirm) => {
    let { pagination, bagSearch } = this.state
    const { dispatch } = this.props

    if (value) {
      pagination.current = 1
    }

    dispatch(bagActions.getByName(pagination.current, pagination.pageSize, bagSearch)).then(
      ({ total }) => {
        pagination.total = total

        this.setState({ pagination })
      }
    )
  }

  handleOpenEditModal = record => {
    this.setState({
      fields: dataToFields(record),
      isEditVisible: true
    })
  }

  handleCloseEditModal = () => {
    this.setState({
      fields: {},
      isEditVisible: false
    })
  }

  handleUpdateBag = () => {
    const { fields } = this.state
    const { dispatch, t } = this.props

    const data = fieldsToData(fields, {})

    dispatch(bagActions.update(data))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alert.updateSuccess')
          })
        )
        this.getCurrentBags()
      })
      .catch(e => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alert.updateFailure')
          })
        )
      })
  }

  handleDeleteBag = () => {
    const { fields } = this.state
    const { dispatch, t } = this.props

    const data = {
      id: fields.id.value
    }

    dispatch(bagActions.delete(data))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alert.deleteSuccess')
          })
        )
        this.handleCloseEditModal()
        this.getCurrentBags()
      })
      .catch(e => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alert.deleteFailure')
          })
        )
      })
  }

  handleSearch = (value) => {
    this.setState({ bagSearch: value })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ bagSearch: '' })
  }

  getTableEditableCell = (content, handleEdit) => {
    return <>
      <span className="bags-table-cell-icon" onClick={handleEdit}>
        <FormOutlined style={{ color: '#138D75' }} />
      </span>
      {content}
    </>
  }

  getColumnSearchProps = dataIndex => {
    const { t } = this.props

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: '0.5rem' }}>
          <Input
            ref={node => {
              this.searchInput = node
            }}
            placeholder={t('searchPlhd')}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : []) || this.handleSearch(e.target.value)
            }
            onPressEnter={(e) => this.getCurrentBags(selectedKeys[0], confirm)}
            style={{ width: 188, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t('btns.reset')}
          </Button>
          <Button
            type="primary"
            onClick={(e) => this.getCurrentBags(selectedKeys[0], confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {t('btns.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined
          }} />
      ),
      // onFilter: (value, record) =>
      //   record[dataIndex] &&
      //   record[dataIndex]
      //     .toString()
      //     .toLowerCase()
      //     .includes(value.toLowerCase()),
      // onFilterDropdownVisibleChange: visible => {
      //   if (visible) {
      //     setTimeout(() => this.searchInput.select())
      //   }
      // },
      render: text => (text ? text.toString() : '')
    }
  }

  render() {
    const { fields, isEditVisible, pagination } = this.state
    const { bags, groups, gettingBags, updatingBag, t } = this.props
    console.log('测试标注')
    const customizeRenderBagsEmpty = () => (
      <div className="bags-empty">
        <FolderOutlined style={{ fontSize: '3rem', color: '#797979' }} />
        <p>{t('noBags')}</p>
      </div>
    )

    return (
      <div className="bags">
        <div className="bags-table-panel">
          <ConfigProvider renderEmpty={customizeRenderBagsEmpty}>
            <Table
              dataSource={bags}
              rowKey="id"
              loading={gettingBags}
              pagination={pagination}
              onChange={this.handleTableChange}
            >
              <Column title="ID" dataIndex="id" key="id" />
              <Column
                title={t('name')}
                dataIndex="name"
                key="name"
                {...this.getColumnSearchProps('name')}
              />
              <Column
                title={t('startTimestamp')}
                dataIndex="startTimestamp"
                key="startTimestamp"
              />
              <Column
                title={t('endTimestamp')}
                dataIndex="endTimestamp"
                key="endTimestamp"
              />
              <Column
                title={t('description')}
                dataIndex="description"
                key="description"
              />
              <Column
                title={t('group')}
                dataIndex="groupId"
                key="group"
                render={text => {
                  const group = groups.find(group => group.id === text)
                  return group ? group.name : text ? text : ''
                }}
              />
              <Column
                title={t('priority')}
                dataIndex="priority"
                key="priority"
                sorter={(a, b) => {
                  return +a.priority || 0 - +b.priority || 0
                }}
                sortDirections={['descend', 'ascend']}
              />
              <Column title={t('status')} dataIndex="status" key="status" />
              <Column
                title={null}
                key="actions"
                render={(text, record) => (
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => this.handleOpenEditModal(record)}
                    ghost
                  >
                    {t('btns.edit')}
                  </Button>
                )}
              />
            </Table>
          </ConfigProvider>
          <Modal
            title={t('editBagTitle')}
            style={{ top: '15vh' }}
            bodyStyle={modalBodyStyle}
            footer={null}
            visible={isEditVisible}
            onCancel={this.handleCloseEditModal}
            destroyOnClose
          >
            <BagEditForm
              {...fields}
              onChange={this.handleFormChange}
              onSubmit={this.handleUpdateBag}
              onDelete={this.handleDeleteBag}
              updating={updatingBag}
              t={t}
              groups={groups}
            />
          </Modal>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    bags: state.bag.bags,
    groups: state.group.groups,
    gettingBags: state.bag.gettingBags,
    gettingGroups: state.group.gettingGroups,
    updatingBag: state.bag.updatingBag,
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(
  withTranslation(['bags', 'common'])(Bags)
)
