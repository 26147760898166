// Pages
import { Login } from '../Login'
import { AdminHome } from '../AdminHome'
import { AnnotHome } from '../AnnotHome'
import { GAdminHome } from '../GAdminHome'
import { Annotations } from '../Annotations'
import { ExamHome } from '../ExamHome'
import { DevHome } from '../DevHome'
import { Users } from '../Users'
import { Bags } from '../Bags'
import { ObjectTypes } from '../ObjectTypes'
import { TaskTypes } from '../TaskTypes'
import { Groups } from '../Groups'
import { Statistics } from '../Statistics'
import { Views } from '../Views'
import { ViewerFusion } from '../ViewsFusion'
import { Viewer2D } from '../Viewer2D'
import { Viewer2Dtracking } from '../Viewer2Dtracking'
import { ViewerCityscape } from '../ViewerCityscape'
import { ViewerSignal } from '../ViewerSignal'
import { Viewer3Dtracking } from '../Viewer3Dtracking'
import { Viewer2D3Drelation } from '../Viewer2D3Drelation'
import { Test } from '../Test'

// import { Viewer3D } from '../Viewer3D'

// Roles
import { role } from './role'

export const routes = [
  {
    path: '/',
    component: {
      [role.admin]: AdminHome,
      [role.groupAdmin]: GAdminHome,
      [role.globalAnnotator]: AnnotHome,
      [role.groupAnnotator]: Annotations,
      [role.globalExaminer]: ExamHome,
      [role.groupExaminer]: ExamHome,
      [role.developer]: DevHome
    },
    auth: [],
    exact: true,
    title:'首页'
  },
  {
    path: '/users',
    component: Users,
    auth: [role.admin, role.groupAdmin],
    exact: true,
    title:'用户列表'
  },
  {
    path: '/groups',
    component: Groups,
    auth: [role.admin, role.groupAdmin],
    exact: true,
    title:'用户组列表'
  },
  {
    path: '/statistics',
    component: Statistics,
    auth: [role.admin, role.groupAdmin],
    exact: true,
    title:'标注统计'
  },
  {
    path: '/annotations',
    component: Annotations,
    auth: [],
    exact: true,
    title:'标注列表'
  },
  {
    path: '/annotations/viewer-2d',
    component: Viewer2D,
    auth: [],
    exact: true,
    title:'2D标注视图'
  },
  {
    path: '/annotations/viewer-2D_tracking',
    component: Viewer2Dtracking,
    auth: [],
    exact: true,
    title:'2D跟踪标注视图'
  },
  {
    path: '/annotations/viewer-3d_tracking',
    component: Viewer3Dtracking,
    auth: [],
    exact: true,
    title:'3D跟踪标注视图'
  },
  {
    path: '/annotations/viewer-link',
    component: Viewer2D3Drelation,
    auth: [],
    exact: true,
    title:'关联标注视图'
  },
  {
    path: '/annotations/viewer-cityscape',
    component: ViewerCityscape,
    auth: [],
    exact: true,
  },
  {
    path: '/annotations/viewer-signal',
    component: ViewerSignal,
    auth: [],
    exact: true
  },
  {
    path: '/annotations/viewer-3d',
    component: Views,
    auth: [],
    exact: true,
    title:'3D标注视图'
  },
  {
    path: '/annotations/viewer-fusion',
    component: ViewerFusion,
    auth: [],
    exact: true
  },
  {
    path: '/bags',
    component: Bags,
    auth: [role.admin, role.groupAdmin],
    exact: true,
    title:'Bags'
  },
  {
    path: '/tasktypes',
    component: TaskTypes,
    auth: [role.admin, role.groupAdmin],
    exact: true,
    title:'任务类别'
  },
  {
    path: '/objecttypes',
    component: ObjectTypes,
    auth: [role.admin, role.groupAdmin],
    exact: true,
    title:'自定义标签组'
  },
  {
    path: '/test',
    component: Test,
    auth: [role.admin],
    exact: true
  },
  {
    path: '/login',
    component: Login,
    exact: true,
    title:'登录'
  }
]
