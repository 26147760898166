// Constants
export const PAD = 20

export const TOOLTIP_DELAY = 0.3

export const TOOL = {
  NULL: 0,
  RECTANGLE: 1,
  POLYGON: 2,
  SQUARE: 3,
}

export const CURSOR = {
  GRAB: 'grab',
  DEFAULT: 'default',
  MOVE: 'move',
  CROSSHAIR: 'crosshair'
}

export const ZOOM = {
  MIN: 50,
  MAX: 500,
  PRECISION: 0
}

export const OPACITY = {
  MIN: 0.3,
  MAX: 1,
  PRECISION: 2
}

export const TYPE = {
  RED: 'red',
  RED_TIME: 'red_time',
  GREEN: 'green',
  GREEN_TIME: 'green_time',
  YELLOW: 'yellow',
  YELLOW_TIME: 'yellow_time',
  BLACK: 'black',
  BLACK_TIME: 'black_time',
}

export const DIRECTION = {
  UP: 'up',
  DOWN: 'down',
  UP_DOWN: 'up_down',
  LEFT: 'left',
  RIGHT: 'right',
  LEFT_RIGHT: 'left_right',
  SINGLE: 'single',
}

export const COLOR = {
  RED: '#C0392B',
  RED_TIME: '#C0392B',
  GREEN: '#148F77',
  GREEN_TIME: '#148F77',
  YELLOW: '#D4AC0D',
  YELLOW_TIME: '#D4AC0D',
  BLACK: '#ccc',
  BLACK_TIME: '#ccc',
}

export const REC_THRESHOLD = 3
export const DEFAULT = {
  ZOOM_RATIO_PP: 100,
  IMAGE_OPACITY: 0.8,
  TYPE: TYPE.RED,
  ALPHA: 0.2,
  STROKE_WIDTH: 1.5,
}

export const OPEN_BASE_URL = 's3://allride.ai-cardata/car/label'
