import { getSomeConstants } from "../_constants"

const initState = {
    startTimestamp: '',
    endTimestamp: '',
    bagId: '',
    bagName: '',
    status: '',
    groupName: ''
}

export function getSome(state = initState, action) {
    switch (action.type) {
        case getSomeConstants.GET_ALL_REQUEST:
            return {
                ...state,
                gettingGetSome: true
            }
        case getSomeConstants.GET_ALL_SUCCESS:
            // console.log('su', action)
            return {
                ...state,
                startTimestamp: action.data.startTimestamp,
                endTimestamp: action.data.endTimestamp,
                bagId: action.data.bagId,
                bagName: action.data.bagName.bagName,
                status: action.data.status,
                groupName: action.data.groupName,
                isUrl: true,
                gettingGetSome: false
            }
        case getSomeConstants.GET_ALL_FAILURE:
            console.log('fail')
            return {
                ...state,
                isUrl: false,
                gettingGetSome: false
            }
        default:
            return state
    }
}