import React, { Component } from 'react'
import './Users.scss'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { userActions, groupActions, alertActions } from '../_actions'
import { UserForm } from '../common'
import { dataToFields, fieldsToData } from '../_utils'
import { role } from '../_helpers'

import { FolderOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import {
  Table,
  ConfigProvider,
  Button,
  Input,
  Checkbox,
  Modal,
  message,
  Divider,
  Tag,
  Select,
  Switch,
  Popconfirm,
  Tooltip,
} from 'antd'

const { Option, OptGroup } = Select
const { Column } = Table
const CheckboxGroup = Checkbox.Group
const PageSize = 10

const modalBodyStyle = {
  backgroundColor: '#202020',
  padding: '1.5rem 1.5rem 0.5rem'
}

const formItemLayout = {
  labelAlign: 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const tailFormItemLayout = {
  wrapperCol: {
    span: 18,
    offset: 6
  }
}

const USER_STATUS_COLOR = {
  ACTIVE: '#1A5276',
  INACTIVE: '#404040'
}

const DEFAULT_VALUE = '---'

const modalWidth = 750

const UserEditForm = Form.create({
  //
  name: 'userEdit',
  //
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields)
  },
  //
  mapPropsToFields(props) {
    return {
      username: Form.createFormField({
        ...props.username,
        value: props.username ? props.username.value : ''
      }),
      groupId: Form.createFormField({
        ...props.groupId,
        value: props.groupId ? props.groupId.value : ''
      }),
      role: Form.createFormField({
        ...props.role,
        value: props.role ? props.role.value : ''
      }),
      email: Form.createFormField({
        ...props.email,
        value: props.email ? props.email.value : ''
      }),
      phonenumber: Form.createFormField({
        ...props.phonenumber,
        value: props.phonenumber ? props.phonenumber.value : ''
      }),
      description: Form.createFormField({
        ...props.description,
        value: props.description ? props.description.value : ''
      }),
      password: Form.createFormField({
        ...props.password,
        value: props.password ? props.password.value : ''
      }),
      isActive: Form.createFormField({
        ...props.isActive,
        value: props.isActive ? props.isActive.value : false
      })
    }
  },
  //
  onValuesChange(_, values) { }
})(({ form, onSubmit, onDelete, loading, t, groups, user }) => {
  const { getFieldDecorator, getFieldValue } = form
  const curRole = user.role
  const newRole = getFieldValue('role')

  const GroupRender = () => {
    if (
      curRole != role.admin ||
      newRole === role.admin ||
      newRole === role.globalAnnotator ||
      newRole === role.globalExaminer ||
      newRole === role.developer
    )
      return null

    return (
      <Form.Item label={t('group')}>
        {getFieldDecorator('groupId')(
          <Select>
            {groups &&
              groups.map(group => <Option key={group.id}>{group.name}</Option>)}
          </Select>
        )}
      </Form.Item>
    )
  }

  const RolesRender = () => {
    return (
      <Form.Item label={t('role')}>
        {getFieldDecorator('role')(
          <Select>
            {curRole === role.admin ? (
              <OptGroup label={t('globalUser')}>
                <Option value="admin">{t('common:role.admin')}</Option>
                <Option value="globalAnnotator">
                  {t('common:role.globalAnnotator')}
                </Option>
                <Option value="globalExaminer">
                  {t('common:role.globalExaminer')}
                </Option>
                <Option value="developer">{t('common:role.developer')}</Option>
              </OptGroup>
            ) : null}
            <OptGroup label={t('groupUser')}>
              <Option value="groupAdmin">{t('common:role.groupAdmin')}</Option>
              <Option value="groupAnnotator">
                {t('common:role.groupAnnotator')}
              </Option>
              <Option value="groupExaminer">
                {t('common:role.groupExaminer')}
              </Option>
            </OptGroup>
          </Select>
        )}
      </Form.Item>
    )
  }

  return (
    <Form {...formItemLayout}>
      <Form.Item label={t('username')}>
        {getFieldDecorator('username')(<Input />)}
      </Form.Item>
      <Form.Item label={t('email')}>
        {getFieldDecorator('email', {
          rules: [{ type: 'email', message: 'Invalid email address' }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('phone')}>
        {getFieldDecorator('phonenumber')(<Input />)}
      </Form.Item>
      <Form.Item label={t('description')}>
        {getFieldDecorator('description')(
          <Input.TextArea autosize={{ minRows: 1, maxRows: 4 }} />
        )}
      </Form.Item>
      <Divider />
      <Form.Item label={t('password')}>
        {getFieldDecorator('password')(<Input.Password />)}
      </Form.Item>
      <Divider />
      <RolesRender />
      <GroupRender />
      <Form.Item label={t('activeStatus')}>
        {getFieldDecorator('isActive', { valuePropName: 'checked' })(
          <Switch />
        )}
      </Form.Item>
      <Divider />
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" onClick={onSubmit} loading={loading}>
          {t('btns.update')}
        </Button>
        <Popconfirm
          title={t('pop.deleteUser')}
          onConfirm={onDelete}
          okText={t('pop.okText')}
          cancelText={t('pop.cancelText')}
        >
          <span className="users-delete-button">{t('btns.delete')}</span>
        </Popconfirm>
      </Form.Item>
    </Form>
  )
})

class Users extends Component {
  state = {
    fields: {},
    userSearch: '',
    searchText: '',
    isEditVisible: false,
    isCreateVisible: false,
    pagination: {
      current: 1,
      pageSize: PageSize,
      total: 0
    }
  }

  componentDidMount() {
    const { dispatch, user, groups } = this.props

    this.getCurrentUsers()
    if (user.role === role.admin) {
      groups.length || dispatch(groupActions.getAll())
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, message: alertMessage } = this.props

    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { userSearch } = this.state
    // console.log(userSearch)
    // if (userSearch) {
    //   this.setState({ userSearch: '' })
    //   return
    // } else {
    this.setState({ pagination }, () => this.getCurrentUsers())
    // }
  }

  getCurrentUsers = (value, confirm) => {
    let { pagination, userSearch } = this.state
    const { dispatch } = this.props
    if (value) {
      pagination.current = 1
      // this.setState({ userSearch: true })
      // this.setState({userSearch:value})
      // dispatch(userActions.getAll(pagination.current, pagination.pageSize, null, value)).then(
      //   ({ total }) => {
      //     pagination.total = total
      //     this.setState({ pagination, userSearch: true })
      //   }
      // )
      // return
    }

    dispatch(userActions.getAll(pagination.current, pagination.pageSize, null, userSearch)).then(
      ({ total }) => {
        pagination.total = total

        this.setState({ pagination })
      }
    )
  }

  handleOpenEditModal = record => {
    this.isActive = !!record.isActive

    this.setState({
      fields: dataToFields(record),
      isEditVisible: true
    })
  }

  handleCloseEditModal = () => {
    this.setState({
      fields: {},
      isEditVisible: false
    })
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }))
  }

  handleToggleCreateModal = () => {
    const { isCreateVisible } = this.state

    this.setState({
      isCreateVisible: !isCreateVisible
    })
  }

  handleCreateUser = values => {
    const { dispatch, user, t } = this.props

    // User default is active
    values.isActive = true

    dispatch(userActions.create(this.processUserGroupData(values, user)))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.createSuccess')
          })
        )
        this.getCurrentUsers()
        this.handleToggleCreateModal()
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: this.translateErrorToMessage(error.detail)
          })
        )
      })
  }

  handleUpdateUser = () => {
    const { fields } = this.state
    const { user, dispatch, t } = this.props

    const data = fieldsToData(fields, {
      isActive: this.isActive
    })

    dispatch(userActions.update(this.processUserGroupData(data, user)))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.updateSuccess')
          })
        )
        this.getCurrentUsers()
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alerts.updateFailure')
          })
        )
      })
  }

  handleDeleteUser = () => {
    const { fields } = this.state
    const { dispatch, t } = this.props

    dispatch(userActions.deleteUser(fields.id.value))
      .then(() => {
        this.handleCloseEditModal()

        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.deleteSuccess')
          })
        )
        this.getCurrentUsers()
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alerts.deleteFailure')
          })
        )
      })
  }

  handleSearch = (value) => {

    this.setState({ userSearch: value })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ userSearch: '' })
  }

  getColumnSearchProps = dataIndex => {
    const { t } = this.props

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: '0.5rem' }}>
          <Input
            ref={node => {
              this.searchInput = node
            }}
            placeholder={t('searchPlhd')}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : []) || this.handleSearch(e.target.value)

            }
            onPressEnter={(e) => this.getCurrentUsers(selectedKeys[0], confirm)}
            style={{ width: 188, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t('btns.reset')}
          </Button>
          <Button
            type="primary"
            onClick={(e) => this.getCurrentUsers(selectedKeys[0], confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {t('btns.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined
          }} />
      ),
      // onFilter: (value, record) =>
      //   record[dataIndex]
      //     .toString()
      //     .toLowerCase()
      //     .includes(value.toLowerCase()),
      // onFilterDropdownVisibleChange: visible => {
      //   if (visible) {
      //     setTimeout(() => this.searchInput.select())
      //   }
      // },
      render: text => text.toString()
    }
  }

  getColumnStatusProps = dataIndex => {
    const { t } = this.props

    return {
      filters: [
        {
          text: 'active',
          value: true
        },
        {
          text: 'inactive',
          value: false
        }
      ],
      // '!!': Convert 'undefined' to 'false'
      onFilter: (value, record) => !!record[dataIndex] === value,
      filterDropdown: ({
        filters,
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        const checkboxes = filters.map(filter => (
          <div className="users-checkboxes-row" key={filter.value.toString()}>
            <Checkbox value={filter.value}>{t(filter.text)}</Checkbox>
          </div>
        ))

        return (
          <div style={{ padding: '0.5rem' }}>
            <div className="users-checkboxes">
              <CheckboxGroup
                value={selectedKeys}
                onChange={checkedValues => setSelectedKeys(checkedValues)}
              >
                {checkboxes}
              </CheckboxGroup>
            </div>
            <Button
              onClick={clearFilters}
              size="small"
              style={{ marginRight: '0.5rem' }}
            >
              {t('btns.reset')}
            </Button>
            <Button type="primary" onClick={confirm} size="small">
              {t('btns.filter')}
            </Button>
          </div>
        )
      }
    }
  }

  processUserGroupData = (data, user) => {
    if (
      [
        role.admin,
        role.globalAnnotator,
        role.globalExaminer,
        role.developer
      ].includes(data.role)
    ) {
      data.groupId = null
    } else {
      // User created by group admin is assigned the same group as group admin
      data.groupId = user.role === role.groupAdmin ? user.groupId : data.groupId
    }

    return data
  }

  translateErrorToMessage = detail => {
    const { t } = this.props

    const MESSAGES = [
      t('alerts.unkown') + ': ' + detail,
      t('alerts.usernameExists'),
      t('alerts.emailExists')
    ]
    const ERRORS = ['username exists', 'email exists']

    const index = ERRORS.reduce(
      (prevValue, ERROR, index) =>
        detail.includes(ERROR) ? index + 1 : prevValue,
      0
    )

    return MESSAGES[index]
  }

  render() {
    const { isEditVisible, isCreateVisible, fields, pagination } = this.state
    const {
      users,
      user: self,
      groups,
      gettingUsers,
      updatingUser,
      creatingUser,
      t
    } = this.props

    const customizeRenderEmpty = () => (
      <div className="users-empty">
        <FolderOutlined style={{ fontSize: '3rem', color: '#797979' }} />
        <p>{t('noUsers')}</p>
      </div>
    )
    return (
      <div className="users">
        <div className="users-table-panel">
          <div className="users-table-wrapper">
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
              <Table
                dataSource={users}
                rowKey="id"
                loading={gettingUsers}
                pagination={pagination}
                onChange={this.handleTableChange}
              >
                <Column title="ID" dataIndex="id" key="id" />
                <Column
                  title={t('username')}
                  dataIndex="username"
                  key="username"
                  {...this.getColumnSearchProps('username')}
                />
                {self.role === role.admin ? (
                  <Column
                    title={t('group')}
                    dataIndex="groupId"
                    key="groupId"
                    render={text => {
                      const group = groups.find(group => group.id === text)
                      return group ? group.name : text ? text : DEFAULT_VALUE
                    }}
                  />
                ) : null}
                <Column
                  title={t('role')}
                  dataIndex="role"
                  key="role"
                  render={text => t(`common:role.${text}`)}
                />
                <Column title={t('email')} dataIndex="email" key="email" />
                <Column
                  title={t('phone')}
                  dataIndex="phonenumber"
                  key="phonenumber"
                />
                <Column
                  title={t('description')}
                  dataIndex="description"
                  key="description"
                />
                <Column
                  title={t('activeStatus')}
                  dataIndex="isActive"
                  key="activeStatus"
                  render={(text, record) => {
                    return (
                      <span>
                        {text ? (
                          <Tag color={USER_STATUS_COLOR.ACTIVE}>
                            {t('active')}
                          </Tag>
                        ) : (
                          <Tag color={USER_STATUS_COLOR.INACTIVE}>
                            {t('inactive')}
                          </Tag>
                        )}
                      </span>
                    )
                  }}
                  {...this.getColumnStatusProps('isActive')}
                />
                <Column
                  title={
                    <Tooltip title={t('tooltip.create')}>
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        ghost
                        onClick={this.handleToggleCreateModal}
                      />
                    </Tooltip>
                  }
                  key="action"
                  render={(text, record) => {
                    // The reason == here but not === is that user id from auth api is number which is parsed from token
                    const disabled = record.id == self.id

                    return (
                      <Button
                        type="primary"
                        size="small"
                        ghost
                        onClick={() => this.handleOpenEditModal(record)}
                        disabled={disabled}
                      >
                        {t('btns.edit')}
                      </Button>
                    )
                  }}
                />
              </Table>
              <Modal
                title={t('editUserTitle')}
                style={{ top: '5vh' }}
                bodyStyle={modalBodyStyle}
                width={modalWidth}
                footer={null}
                visible={isEditVisible}
                onCancel={this.handleCloseEditModal}
                destroyOnClose
              >
                <UserEditForm
                  {...fields}
                  onChange={this.handleFormChange}
                  onSubmit={this.handleUpdateUser}
                  onDelete={this.handleDeleteUser}
                  updating={updatingUser}
                  t={t}
                  user={self}
                  groups={groups}
                />
              </Modal>
              <Modal
                title={t('createUserTitle')}
                bodyStyle={modalBodyStyle}
                width={modalWidth}
                footer={null}
                visible={isCreateVisible}
                onCancel={this.handleToggleCreateModal}
                destroyOnClose
              >
                <UserForm
                  formItemLayout={formItemLayout}
                  tailFormItemLayout={tailFormItemLayout}
                  onSubmit={this.handleCreateUser}
                  loading={creatingUser}
                  groups={groups}
                  curRole={self.role}
                  t={t}
                />
              </Modal>
            </ConfigProvider>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    users: state.authentication.users,
    groups: state.group.groups,
    gettingUsers: state.authentication.gettingUsers,
    updatingUser: state.authentication.updatingUser,
    creatingUser: state.authentication.creatingUser,
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(
  withTranslation(['users', 'common'])(Users)
)
