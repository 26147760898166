import { objectTypeConstants } from '../_constants'

const initState = {
  objectTypes: [],
  objectTypesgroups: [],
}

export function objectType(state = initState, action) {
  switch (action.type) {
    case objectTypeConstants.GET_ALL_REQUEST:
      return {
        ...state,
        gettingObjectTypes: true
      }
    case objectTypeConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        objectTypes: action.objectTypes,
        gettingObjectTypes: false
      }
    case objectTypeConstants.GET_ALL_FAILURE:
      return {
        ...state,
        gettingObjectTypes: false
      }
    case objectTypeConstants.CREATE_REQUEST:
      return {
        ...state,
        creatingObjectType: true
      }
    case objectTypeConstants.CREATE_SUCCESS:
      return {
        ...state,
        creatingObjectType: false
      }
    case objectTypeConstants.CREATE_FAILURE:
      return {
        ...state,
        creatingObjectType: false
      }
    case objectTypeConstants.UPDATE_REQUEST:
      return {
        ...state,
        updatingObjectType: true
      }
    case objectTypeConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updatingObjectType: false
      }
    case objectTypeConstants.UPDATE_FAILURE:
      return {
        ...state,
        updatingObjectType: false
      }
    case objectTypeConstants.DELETE_REQUEST:
      return {
        ...state,
        deletingObjectType: true
      }
    case objectTypeConstants.DELETE_SUCCESS:
      return {
        ...state,
        deletingObjectType: false
      }
    case objectTypeConstants.DELETE_FAILURE:
      return {
        ...state,
        deletingObjectType: false
      }
    case objectTypeConstants.GETGROUP_REQUEST:
      return {
        ...state,
        getGroup: true
      }
    case objectTypeConstants.GETGROUP_SUCCESS:
      return {
        ...state,
        objectTypesgroups: action.data.Groups || [],
        getGroup: false
      }
    case objectTypeConstants.GETGROUP_FAILURE:
      return {
        ...state,
        getGroup: false
      }
    case objectTypeConstants.UPDATEGROUP_REQUEST:
      return {
        ...state,
        updateGroup: true
      }
    case objectTypeConstants.UPDATEGROUP_SUCCESS:
      return {
        ...state,
        updateGroup: false
      }
    case objectTypeConstants.UPDATEGROUP_FAILURE:
      return {
        ...state,
        updateGroup: false
      }
    case objectTypeConstants.DELETEGROUP_REQUEST:
      return {
        ...state,
        deleteGroup: true
      }
    case objectTypeConstants.DELETEGROUP_SUCCESS:
      return {
        ...state,
        deleteGroup: false
      }
    case objectTypeConstants.DELETEGROUP_FAILURE:
      return {
        ...state,
        deleteGroup: false
      }
    case objectTypeConstants.GETBYGROUP_REQUEST:
      return {
        ...state,
        getByGroup: true
      }
    case objectTypeConstants.GETBYGROUP_SUCCESS:
      return {
        ...state,
        objectTypes: action.data.objectTypes||[],
        getByGroup: false
      }
    case objectTypeConstants.GETBYGROUP_FAILURE:
      return {
        ...state,
        objectTypes:[],
        getByGroup: false
      }
    case objectTypeConstants.REFRESH:
      return {
        ...state,
        objectTypes:[],
        refresh:true,
      }
    default:
      return state
  }
}
