// Constants
export const PAD = 20

export const TOOLTIP_DELAY = 0.3

export const TOOL = {
  NULL: 0,
  RECTANGLE: 1,
  POLYGON: 2
}

export const CURSOR = {
  GRAB: 'grab',
  DEFAULT: 'default',
  MOVE: 'move',
  CROSSHAIR: 'crosshair'
}

export const ZOOM = {
  MIN: 50,
  MAX: 500,
  PRECISION: 0
}

export const OPACITY = {
  MIN: 0.3,
  MAX: 1,
  PRECISION: 2
}

export const TYPE = {
  CAR: 'car',
  PEDESTRIAN: 'pedestrian',
  TRUCK: 'truck',
  CYCLIST: 'cyclist',
  BUS: 'bus',
  VAN: 'van',
  TROLLEY: 'trolley',
  CONE: 'cone',
  BARRIER: 'barrier',
  RED: 'red',
  RED_TIME: 'red_time',
  GREEN: 'green',
  GREEN_TIME: 'green_time',
  YELLOW: 'yellow',
  YELLOW_TIME: 'yellow_time',
  BLACK: 'black',
  BLACK_TIME: 'black_time',
  OTHER: 'other',
}

export const COLOR = {
  RED: '#C0392B',
  RED_TIME: '#C0392B',
  GREEN: '#148F77',
  GREEN_TIME: '#148F77',
  YELLOW: '#D4AC0D',
  YELLOW_TIME: '#D4AC0D',
  BLACK: '#ccc',
  BLACK_TIME: '#ccc',
  CAR: '#2874A6',
  PEDESTRIAN: '#8E44AD',
  TRUCK: '#EB984E',
  VAN: '#00BCD4',
  BUS: '#16A085',
  CYCLIST: '#E67E22',
  CONE: '#F5EC51',
  BARRIER: '#FFF68F',
  TROLLEY: '#D4AC0D',
  OTHER: '#FFFFFF',
}

export const REC_THRESHOLD = 3
export const DEFAULT = {
  ZOOM_RATIO_PP: 100,
  IMAGE_OPACITY: 0.8,
  TYPE: TYPE.RED,
  ALPHA: 0.2,
  STROKE_WIDTH: 1.5
}

export const OPEN_BASE_URL = 's3://allride.ai-cardata/car/label'
