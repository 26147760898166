// Constants
export const PAD = 20

export const TOOLTIP_DELAY = 0.3

export const MATERIAL_LIST = [
  {type:'van'},
  {type:'barrier'},
  {type:'trolley'},
  {type:'other'}
]

export const MATERIAL_COLORS = {
  CAR: '#3498DB',
  PEDESTRIAN: '#148F77',
  CONE: '#02fde3',
  CYCLIST: '#D4AC0D',
  TRUCK: '#FF1493',
  BUS:'#FB9322',
  VAN: '#A882FF',
  OTHER: '#AAAAAA',
  BARRIER: '#FFFFFF',
  TROLLEY: '#02fdc3',
}
export const MATERIAL_NUM = {
  Digit1:'car',
  Digit2:'pedestrian',
  Digit3:'cone',
  Digit4:'cyclist',
  Digit5:'truck',
  Digit6:'bus',
}
export const TYPE = {
  CAR: 'car',
  PEDESTRIAN: 'pedestrian',
  CONE: 'cone',
  CYCLIST: 'cyclist',
  TRUCK: 'truck',
  BUS: 'bus',
  VAN: 'van',
  TROLLEY: 'trolley',
  BARRIER: 'barrier',
  OTHER: 'other',
}

export const TOOL = {
  NULL: 0,
  RECTANGLE: 1,
  POLYGON: 2
}

export const CURSOR = {
  GRAB: 'grab',
  DEFAULT: 'default',
  MOVE: 'move',
  CROSSHAIR: 'crosshair'
}

export const ZOOM = {
  MIN: 50,
  MAX: 500,
  PRECISION: 0
}

export const OPACITY = {
  MIN: 0.3,
  MAX: 1,
  PRECISION: 2
}

export const COLOR = {
  RED: '#C0392B',
  RED_TIME: '#C0392B',
  GREEN: '#148F77',
  GREEN_TIME: '#148F77',
  YELLOW: '#D4AC0D',
  YELLOW_TIME: '#D4AC0D',
  BLACK: '#ccc',
  BLACK_TIME: '#ccc',
  CAR: '#2874A6',
  PEDESTRIAN: '#8E44AD',
  TRUCK: '#EB984E',
  VAN: '#00BCD4',
  BUS: '#16A085',
  CYCLIST: '#E67E22',
  CONE: '#F5EC51',
  BARRIER: '#FFF68F',
  TROLLEY: '#D4AC0D',
  OTHER: '#FFFFFF',
}

export const REC_THRESHOLD = 3
export const DEFAULT = {
  ZOOM_RATIO_PP: 100,
  IMAGE_OPACITY: 0.8,
  TYPE: TYPE.CAR,
  ALPHA: 0.2,
  STROKE_WIDTH: 1.5,
  STROKE_TRACKING_WIDTH: 1
}

export const OPEN_BASE_URL = 's3://allride.ai-cardata/car/label'
