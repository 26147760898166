export const tagConstants = {
  GET_ALL_REQUEST: 'TAG_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'TAG_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'TAG_GET_ALL_FAILURE',
  CREATE_REQUEST: 'TAG_CREATE_REQUEST',
  CREATE_SUCCESS: 'TAG_CREATE_SUCCESS',
  CREATE_FAILURE: 'TAG_CREATE_FAILURE',
  UPDATE_REQUEST: 'TAG_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TAG_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TAG_UPDATE_FAILURE',
}