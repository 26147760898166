import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from '../_locale/en'
import zh from '../_locale/zh'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      zh
    },
    fallbackLng: 'en',
    debug: false,
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
