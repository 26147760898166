import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Input, Button, InputNumber, Select, Row, Col } from 'antd'
import Icon from '@ant-design/icons'
import { ReactComponent as Car } from '../../_assets/car copy.svg'
import { ReactComponent as Cone } from '../../_assets/cone copy.svg'
import { ReactComponent as Pedestrian } from '../../_assets/pedestrian copy.svg'
import { ReactComponent as Truck } from '../../_assets/truck copy.svg'
import { ReactComponent as Bicycle } from '../../_assets/bicycle copy.svg'
import { ReactComponent as Cyclist } from '../../_assets/cyclist copy.svg'
import { ReactComponent as More } from '../../_assets/more copy.svg'

import { SketchPicker } from 'react-color'

const ObjectTypeForm = ({
  form,
  onSubmit,
  formItemLayout,
  tailFormItemLayout,
  loading,
  t,
  objectTypesgroups,
  objectTypeRecord,
  isAltObjectType
}) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = form

  const iconArr = [
    { name: 'Car', component: Car },
    { name: 'Cone', component: Cone },
    { name: 'Pedestrian', component: Pedestrian },
    { name: 'Truck', component: Truck },
    { name: 'Bicycle', component: Bicycle },
    { name: 'Cyclist', component: Cyclist },
    // {name:'More',component:More},
  ]

  const sortNumbers = ['无', '1', '2', '3', '4', '5', '6']

  const [realColor, setRealColor] = useState('#ff0000')
  const [displayColorPicker, setPicker] = useState(false)
  // let state = {
  //   displayColorPicker: false,
  // };

  // handleClick = () => {
  //   this.setState({ displayColorPicker: !this.state.displayColorPicker })
  // };

  // handleClose = () => {
  //   this.setState({ displayColorPicker: false })
  // };


  const handleClick = () => {
    setPicker(true)
  }
  const handleClose = () => {
    setPicker(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    validateFields((error, values) => {
      if (!error) {
        onSubmit(values)
      }
    })
  }

  const popover = {
    position: 'absolute',
    zIndex: '200',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={t('showName')}>
        {getFieldDecorator('showName', {
          rules: [{ required: true, message: 'showName can not be empty' }],
          initialValue: objectTypeRecord.showName
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('name')}>
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Name can not be empty' }],
          initialValue: objectTypeRecord.name
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('shortName')}>
        {getFieldDecorator('shortName', {
          rules: [{ required: true, message: 'shortName can not be empty' }],
          initialValue: objectTypeRecord.shortName
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('groupName')}>
        {getFieldDecorator('groupName', {
          rules: [{ required: true, message: 'groupName can not be empty' }],
          initialValue: objectTypeRecord.groupName
        })
          (<Select
            style={{ minWidth: '10rem' }}>
            {(objectTypesgroups ? objectTypesgroups : []).map(group => (
              <Select.Option key={group.groupName}>{t(group.groupName)}</Select.Option>
            ))}
          </Select>)}
      </Form.Item>
      <Form.Item label={t('color')}>
        {getFieldDecorator('color', {
          rules: [{ required: true, message: 'color can not be empty' }],
          initialValue: objectTypeRecord.color
        })(
          <Input />
        )}
        <div>
          <Button onClick={handleClick}>选择颜色</Button>
          {displayColorPicker ? <div style={popover}>
            <div style={cover} onClick={handleClose} />
            <SketchPicker
              color={realColor}
              onChangeComplete={realColor => {
                setRealColor(realColor.hex)
                setFieldsValue({ color: realColor.hex })
              }}
              disableAlpha={true} />
          </div> : null}

        </div>
      </Form.Item>
      <Form.Item label={t('defaultSize')} required style={{marginBottom:'0px'}}>
        <Row>
          <Col span={8}>
            <Form.Item label={t('size.x')}>
              {getFieldDecorator('size.x', {
                rules: [{ required: true, message: 'Size.X can not be empty' }],
                initialValue: objectTypeRecord.x || 0
              })(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('size.y')} >
              {getFieldDecorator('size.y', {
                rules: [{ required: true, message: 'Size.Y can not be empty' }],
                initialValue: objectTypeRecord.y || 0,
              })(<InputNumber />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('size.z')}>
              {getFieldDecorator('size.z', {
                rules: [{ required: true, message: 'Size.Z can not be empty' }],
                initialValue: objectTypeRecord.z || 0
              })(<InputNumber />)}
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label={t('icon')}>
        {getFieldDecorator('icon', {
          initialValue: objectTypeRecord.icon
        })(<Select >
          {iconArr.map(item => (
            <Select.Option key={item.name} value={item.name} label={item.name}>
              <div>
                <Icon component={item.component} /></div>
            </Select.Option>
          ))}
        </Select>
        )}
      </Form.Item>
      <Form.Item label={t('sortNumber')}>
        {getFieldDecorator('sortNumber', {
          initialValue: objectTypeRecord.sortNumber || '无'
        })(<Select
        >
          {Object.values(sortNumbers).map(number => (
            <Select.Option key={number}>{number}</Select.Option>
          ))}
        </Select>)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={loading}>
          {isAltObjectType ? t('pop.okText') : t('btns.create')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default withTranslation(['objectTypes', 'common'])(
  Form.create({ name: 'objectType form' })(ObjectTypeForm)
)
