import React, { Component } from 'react'
import './Viewer3Dtracking.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { fabric } from 'fabric'
import { createPointsKDTree, changePointsColorInBox, dyePCD, uuid } from '../_utils'
fabric.Object.prototype.noScaleCache = false

import { DeleteOutlined, InboxOutlined, CopyOutlined, EyeOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'

import { Button, Tabs, Slider, InputNumber, Divider, message, Tooltip, Checkbox } from 'antd'
import { IconButton, HoverPanel, LabelType, LoadingCover } from '../common'
import {
  AttributesTable,
  Shortcuts,
  FrameInfo,
  LabelsStats,
  Addlabel,
  AddlabeLlist,
  CameraCanvas
} from './components'
import { bagActions, annotationActions, alertActions, objectTypeActions } from '../_actions'
import { dataServices, objectTypeServices } from '../_services'
import { db } from '../_db'
import DataWorker from '../_workers/data.worker'

import {
  MATERIAL_COLORS,
  MATERIAL_NUM,
  MATERIAL_DIMENSION,
  PLANE_DISTANCE,
  FIGURE_PRECISION,
  DEFAULT_POINT_SIZE,
  HALF_WIDTH,
  DEFAULT_DIMENSION,
  OPEN_BASE_URL,
  PCD_COLOR,
  PCD_INBOX_COLOR,
  SIMPLETYPE
} from './constants'
import { MAIN, TOP, SIDE, CAM_WIDTH } from './constants'
import {
  toPrecision,
  extractObject3D,
  extractObjectFusion,
  base64ToArrayBuffer,
  recreateDB,
} from '../_utils'
import { history, role } from '../_helpers'

// Three libs
import 'three/examples/js/loaders/PCDLoader'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls'
import '../_lib/DragControls'
import 'three/examples/js/renderers/CSS2DRenderer'
import { Components } from 'antd/lib/date-picker/generatePicker'
import QueueManager from './queueManager'
const TabPane = Tabs.TabPane
const sliderLabelStyle = {
  color: '#fff',
  fontSize: '0.8rem',
  userSelect: 'none'
}

function applyPrecisionToLabel(object) {
  const props = ['position', 'scale', 'rotation']
  const subProps = ['x', 'y', 'z']
  props.forEach(prop => {
    subProps.forEach(subProp => {
      object[prop][subProp] = toPrecision(
        object[prop][subProp],
        FIGURE_PRECISION
      )
    })
  })

  return object
}
class Viewer3Dtracking extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initLabel: {
        type: '',
        dimension: []
      },
      changedTypes: [],
      hideLabels: [],
      fromURL: {
        startTimestamp: '',
        endTimestamp: '',
        bagId: '',
        bagName: '',
        id: '',
        status: '',
        firstFrameIndex: 0,
        groupName: '',
        isUrl: false
      },
      ls: {
        startTimestamp: "1632131304581",
        endTimestamp: "1632131310401",
        bagId: "1400",
        bagName: "rideware-20210920174800",
        id: "563",
        status: "annotating"
      },
      currentFrame: 1,
      totalFrames: 1,
      labels: [],
      labelsCounter: {},
      checkedLabels: [],
      editingLabel: null,
      labelAdding: false,
      helpersShowing: true,
      auxShowing: true,
      isInfoVisible: true,
      isStatsVisible: true,
      firstLoading: true,
      isEdited: false,
      noLabels: false,
      pointSize: DEFAULT_POINT_SIZE,
      pointHue: MATERIAL_COLORS.PCD.H,
      pointSaturation: MATERIAL_COLORS.PCD.S,
      pointLightness: MATERIAL_COLORS.PCD.L,
      activeList: '',
      isAddListVisible: false,
      projectionMatrixs: [],
      cameraProp: [],
      objectClientId: null,
      objectServiceId: null,
      cameraShowing: true,
    }

    this.views = [
      {
        name: 'main',
        container: null,
        canvas: null,
        camera: null,
        context: null,
        dimension: {},
        // eye: [-40, 0, 40],
        // up: [0, 0, 1],
        eye: [0, -40, 40],
        up: [0, 0, 1],
        fov: 45,
        mapControl: null,
        render: () => { }
      },
      {
        name: 'top',
        container: null,
        canvas: null,
        camera: null,
        context: null,
        dimension: {},
        eye: [0, 0, 10],
        up: [0, 0, 1],
        mapControl: null,
        dragControl: null,
        render: () => { }
      },
      {
        name: 'side',
        container: null,
        canvas: null,
        camera: null,
        context: null,
        dimension: {},
        eye: [0, -200, 0],
        up: [0, -1, 0],
        mapControl: null,
        dragControl: null,
        render: () => { }
      }
    ]
    this.isRedoOrUndoIng = false,
    this.queueManager = new QueueManager(),
    this.scene, this.rendererMain, this.rendererAux
    this.plane, this.raycaster, this.mouse, this.loader
    this.helpers = []
    this.defaultBoxGeometry, this.defaultBoxMaterial
    this.defaultEdgesGeometry, this.defaultLineMaterial
    this.initBoxGeometry, this.initEdgesGeometry
    this.defaultConeGeometry
    this.dataWorker
    this.currentPCD
    this.buffer = {}
    this.bufferIndex = 0

    this.unlabeledIndices = []
    this.pointsKDTree

    // console.log('search', location.search)
    //不能在didmount中使用，因为setstate是异步的，在didmount渲染周期结束后才会更新state，而实际上我们需要在didmount中就使用更新后的state
    if (location.search) {
      let locationId, locationFrame
      if (location.search.split('?')[1].split('').indexOf('&') > -1) {
        if (location.search.split('?')[1].split('&')[1]) {
          locationId = location.search.split('?')[1].split('&')[0].split('=')[1]
          locationFrame = location.search.split('?')[1].split('&')[1].split('=')[1]
        } else {
          locationId = location.search.split('?')[1].split('=')[1]
          locationFrame = 0
        }
      } else {
        locationId = location.search.split('?')[1].split('=')[1]
        locationFrame = 0
      }

      // console.log(locationId, locationFrame)

      this.state.fromURL = { ...this.state.fromURL, id: locationId, firstFrameIndex: parseInt(locationFrame) }

      //后端通过id获取fromurl的其他信息
      //
      // const {dispatch} = this.props
      // dispatch(annotationActions.getSome(this.state.fromURL.id))
      // .then(()=>{
      //   console.log('then')
      //   const getsome = this.props.getSome
      //   this.setState({
      //     fromURL:{
      //       ...this.state.fromURL,
      //       getsome
      //     }
      //   })
      // })
      // .catch(()=>{
      //   console.log('ca')
      //   const getsome = this.props.getSome
      //   this.state.fromURL = {
      //     fromURL:{
      //       ...this.state.fromURL,
      //       ...getsome
      //     }
      //   }
      // })

      // if(this.state.fromURL.firstFrameIndex!==0){
      //   const {dispatch} = this.props
      //   // dispatch(annotationActions.getSome(this.state.fromURL.id))
      //   //   .then((res)=>{
      //   //     console.log(res)
      //   //   })
      //   this.state.fromURL = {
      //   ...this.state.fromURL,
      //   startTimestamp: "1632131304501",
      //   endTimestamp: "1632131310401",
      //   bagId: "1400",
      //   bagName: "rideware-20210920174800",
      //   status: "annotating",
      //   isUrl:true
      // }
      // }
    }

  }



  async componentDidMount() {
    const { dispatch, location } = this.props
    const getsome = await dispatch(annotationActions.getSome({ id: this.state.fromURL.id }))
    let forGroup = {}
    if (this.props.getSome.groupName == 'none') {
      forGroup = {
        ...this.props.getSome,
        groupName: null
      }
    } else {
      forGroup = {
        ...this.props.getSome,
      }
    }
    this.setState({
      fromURL: {
        ...this.state.fromURL,
        ...forGroup

      }
    })
    const { fromURL } = this.state
    if (fromURL.groupName && fromURL.groupName !== '') {
      await dispatch(objectTypeActions.getByGroup({ groupName: fromURL.groupName }))
    } else {
      await dispatch(objectTypeActions.refresh())
    }
    // console.log('state', this.state)
    // console.log('props', this.props)
    // console.log('location.state', location.state)
    // if (location.state) {
    //   // console.log('locationget')
    //   const { bagId, startTimestamp, endTimestamp } = location.state.record
    //   // Get frames when component did mount
    //   if (bagId && startTimestamp && endTimestamp) {
    //     dispatch(
    //       bagActions.get(bagId)
    //     ).then((res) => {
    //       const bag = res.bag
    //       //setup matrix
    //       if (bag && bag.matrix && bag.matrix !== '{}') {
    //         const matrixObj = JSON.parse(bag.matrix)
    //         const matrixs = []
    //         Object.values(matrixObj).forEach((item) => {
    //           let projectionMatrix = item.split(',')
    //             .map(float => parseFloat(float)) || null
    //           matrixs.push(new THREE.Matrix4().set(...projectionMatrix))
    //         })
    //         this.setState({
    //           cameraProp: Object.keys(matrixObj),
    //           projectionMatrixs: matrixs
    //         }, () => {
    //           this.SubCanvas.initCanvas(this.state.cameraProp)
    //         })
    //       }

    //       // 后边的请求依赖matrix接口结果
    //       dispatch(
    //         annotationActions.getAllFrames(bagId, startTimestamp, endTimestamp, [
    //           'ready',
    //           'finish'
    //         ])
    //       )
    //     })

    //     // Initialize views
    //     setTimeout(this.init, 0)

    //   }
    // } else
    if (fromURL.isUrl) {
      // console.log('urlget')
      const { bagId, startTimestamp, endTimestamp } = this.state.fromURL


      if (bagId && startTimestamp && endTimestamp) {
        dispatch(
          bagActions.get(bagId)
        ).then((res) => {
          const bag = res.bag
          //setup matrix
          if (bag && bag.matrix && bag.matrix !== '{}') {
            const matrixObj = JSON.parse(bag.matrix)
            const matrixs = []
            Object.values(matrixObj).forEach((item) => {
              let projectionMatrix = item.split(',')
                .map(float => parseFloat(float)) || null
              matrixs.push(new THREE.Matrix4().set(...projectionMatrix))
            })
            this.setState({
              cameraProp: Object.keys(matrixObj),
              projectionMatrixs: matrixs
            }, () => {
              this.SubCanvas.initCanvas(this.state.cameraProp)
            })
          }

          // 后边的请求依赖matrix接口结果
          dispatch(
            annotationActions.getAllFrames(bagId, startTimestamp, endTimestamp, [
              'ready',
              'finish'
            ])
          )
        })

        // Initialize views
        setTimeout(this.init, 0)

      }
    }
    else {
      history.replace('/annotations')
    }

    // // Initialize data worker
    this.dataWorker = new DataWorker()
    // console.log('dw1', this.dataWorker)

  }

  componentDidUpdate(prevProps) {
    const { bag, dispatch, frames, objects, objId, message: alertMessage } = this.props


    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 1, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 1, () =>
          dispatch(alertActions.clear())
        )
      }
    }
    // Get objects when: do have frames && not other props changes
    if (frames.length > 0 && frames !== prevProps.frames) {
      const lastFrameIndex = frames.length - 1
      let firstFrameIndex = lastFrameIndex
      if (this.state.fromURL.firstFrameIndex !== 0) {
        this.unlabeledIndices.push(this.state.fromURL.firstFrameIndex - 1)
        firstFrameIndex = this.state.fromURL.firstFrameIndex - 1
      } else {
        frames.forEach((frame, index) => {
          if (!frame.annotationobjectsId || frame.annotationobjectsId < 0) {
            this.unlabeledIndices.push(index)
            // this.unlabeledIndices.push(3)
            firstFrameIndex === lastFrameIndex && (firstFrameIndex = index)
            // firstFrameIndex === lastFrameIndex && (firstFrameIndex = 3)
          }
        })
      }


      var allLabeled = false
      if (this.unlabeledIndices.length === 0) {
        firstFrameIndex = 0
        allLabeled = true
      }
      this.setState(
        {
          currentFrame: firstFrameIndex + 1,
          totalFrames: frames.length
        },
        () => {
          this.loadData(() => {
            if (frames[firstFrameIndex].annotationobjectsId > 0) {
              this.setState({
                objectClientId: frames[firstFrameIndex].annotationobjectsId
              })
              dispatch(
                annotationActions.getObject(
                  frames[firstFrameIndex].annotationobjectsId
                )
              )
            }
          })
        }
      )
    }

    // Put objects into the scene
    // if (objects.length > 0 && objects !== prevProps.objects) {
    if (objects !== prevProps.objects) {
      // When no objects, reuse objects in the previous frame
      if (objId === this.state.objectClientId) {
        this.handleUpdateLabels(objects)
      }
      if (allLabeled) {
        // When no objects, set noLabels
        this.setState({ noLabels: !objects.length })
      }
    }
  }

  componentWillUnmount() {
    // Remove global listeners before leaving
    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('keydown', this.onKeyDown)

    this.setState = () => false

    // console.log('dw', this.dataworker)
    // Kill worker before leaving

    this.dataWorker.terminate()

    // Clear db before leaving
    recreateDB(db)
    this.setState = (state, callback) => {
      return
    }
  }

  /** THREE loading trigger */
  init = () => {
    this.createCanvases()
    this.createScene()
    this.createAccessories()
    this.createDefaultBox()
    this.createHelpers()
    this.createRenderers()
    this.createViews()

    this.animate()
  }

  createCanvases = () => {
    this.views.forEach(view => {
      const canvas = document.createElement('canvas')
      view.canvas = canvas
      view.container.append(view.canvas)
    })

    this.bindEventListeners()
  }

  bindEventListeners = () => {
    const main = this.views[MAIN]
    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('keydown', this.onKeyDown, false)
    main.canvas.addEventListener('mousedown', this.onMouseDown)
  }

  /** Listeners */
  onWindowResize = () => {
    const main = this.views[MAIN]
    const top = this.views[TOP]

    this.views.forEach(({ name, canvas, camera, dimension }) => {
      dimension.width = canvas.clientWidth
      dimension.height = canvas.clientHeight
      dimension.aspect = dimension.width / dimension.height

      canvas.width = dimension.width * window.devicePixelRatio
      canvas.height = dimension.height * window.devicePixelRatio

      if (camera) {
        switch (name) {
          case 'main':
            camera.aspect = dimension.aspect
            break
          case 'top':
            camera.left = -HALF_WIDTH
            camera.right = HALF_WIDTH
            camera.top = HALF_WIDTH / dimension.aspect
            camera.bottom = -HALF_WIDTH / dimension.aspect
            break
          case 'side':
            camera.left = HALF_WIDTH
            camera.right = -HALF_WIDTH
            camera.top = -HALF_WIDTH / dimension.aspect + PLANE_DISTANCE
            camera.bottom = HALF_WIDTH / dimension.aspect + PLANE_DISTANCE
            break
        }

        camera.updateProjectionMatrix()
      }
    })

    this.rendererMain.setSize(main.canvas.clientWidth, main.canvas.clientHeight)
    this.rendererAux.setSize(top.canvas.clientWidth, top.canvas.clientHeight)
    this.labelRenderer.setSize(
      main.canvas.clientWidth,
      main.canvas.clientHeight
    )
  }

  onFocusHandler = (e) => {
    window.removeEventListener('keydown', this.onKeyDown)
  }

  onBlurHandler = (e) => {
    window.addEventListener('keydown', this.onKeyDown, false)
  }

  onKeyDown = e => {
    // DIFF
    e.stopPropagation()
    const { currentFrame, editingLabel, firstLoading, totalFrames, checkedLabels, fromURL } = this.state
    const { user, objectTypes } = this.props
    // Disable all keyboard events when first loading
    if (firstLoading) return
    switch (e.code) {
      case 'Backspace':
        this.handleRemoveLabel()
        break
      case 'Escape':
        this.handleEscapeLabel()
        break
      case 'Backquote':
        this.handleResetCamera(MAIN)
        break
    }
    if (e.altKey || e.ctrlKey) {
      switch (e.code) {
        case 'Digit1':
          this.handleToggleHelpers()
          break
        case 'Digit2':
          this.handleToggleAuxViews()
          break
        case 'Digit3':
          this.handleToggleStatsInfo()
          break
        case 'KeyS':
          this.handleSave()
          break
        case 'KeyC':
          this.handleInputFrame(1)
          break
        case 'KeyV':
          this.handleInputFrame(totalFrames)
          break
        case 'KeyA':
          this.handleAllLabel()
          break
      }
    } else if (e.shiftKey) {
      switch (e.code) {
        case 'KeyC':
          this.handleInputFrame(currentFrame - 5)
          break
        case 'KeyV':
          this.handleInputFrame(currentFrame + 5)
          break
      }
    } else {
      let sort = parseInt(e.code.split('Digit').join(''))
      switch (e.code) {
        case 'Digit1': case 'Digit2': case 'Digit3': case 'Digit4': case 'Digit5': case 'Digit6':
          // this.handleClickAddLabel(MATERIAL_NUM[e.code])1
          this.handleClickAddLabel(fromURL.groupName && objectTypes.length >= sort ? objectTypes[sort - 1].name : (fromURL.groupName && objectTypes.length < sort && objectTypes.length > 0 ? objectTypes[0].name : MATERIAL_NUM[e.code]))
          break
        case 'KeyC':
          this.handleInputFrame(currentFrame - 1)
          break
        case 'KeyV':
          this.handleInputFrame(currentFrame + 1)
          break
        case 'KeyH':
          this.handleToggleLabelVisibility(e, editingLabel)
          break
      }
    }
    if (checkedLabels.length > 0) {
      if (e.altKey || e.ctrlKey) {
        switch (e.code) {
          case 'KeyQ':
            this.handleInputFrame(currentFrame - 1, true)
            break
        }
        return
      }
      switch (e.code) {
        case 'KeyQ':
          this.handleInputFrame(currentFrame + 1, true)
          break
      }
    }
    if (editingLabel) {
      switch (e.code) {
        case 'KeyW':
        case 'ArrowUp':
          this.handleInputLabel(
            editingLabel.position.y + (e.shiftKey ? 0.01 : 0.1),
            'position',
            'y'
          )
          break
        case 'KeyS':
        case 'ArrowDown':
          this.handleInputLabel(
            editingLabel.position.y - (e.shiftKey ? 0.01 : 0.1),
            'position',
            'y'
          )
          break
        case 'KeyA':
        case 'ArrowLeft':
          this.handleInputLabel(
            editingLabel.position.x - (e.shiftKey ? 0.01 : 0.1),
            'position',
            'x'
          )
          break
        case 'KeyD':
        case 'ArrowRight':
          this.handleInputLabel(
            editingLabel.position.x + (e.shiftKey ? 0.01 : 0.1),
            'position',
            'x'
          )
          break
        case 'KeyE':
          this.handleInputLabel(
            editingLabel.position.z + (e.shiftKey ? 0.01 : 0.1),
            'position',
            'z'
          )
          break
        case 'KeyR':
          this.handleInputLabel(
            editingLabel.position.z - (e.shiftKey ? 0.01 : 0.1),
            'position',
            'z'
          )
          break
        case 'KeyX':
          this.handleInputLabel(
            editingLabel.rotation.z - (e.shiftKey ? 0.01 : 0.1),
            'rotation',
            'z'
          )
          break
        case 'KeyZ':
          this.handleInputLabel(
            editingLabel.rotation.z + (e.shiftKey ? 0.01 : 0.1),
            'rotation',
            'z'
          )
          break
        case 'KeyF':
          this.handleInputLabel(
            editingLabel.rotation.z + 3.14 < 6.28
              ? editingLabel.rotation.z + 3.14
              : editingLabel.rotation.z - 3.14,
            'rotation',
            'z'
          )
          break
        case 'KeyO':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.x
              : editingLabel.userData.dimension.x) + (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'x'
          )
          break
        case 'KeyL':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.x
              : editingLabel.userData.dimension.x) - (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'x'
          )
          break
        case 'Semicolon':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.y
              : editingLabel.userData.dimension.y) + (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'y'
          )
          break
        case 'KeyK':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.y
              : editingLabel.userData.dimension.y) - (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'y'
          )
          break
        case 'KeyU':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.z
              : editingLabel.userData.dimension.z) + (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'z'
          )
          break
        case 'KeyI':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.z
              : editingLabel.userData.dimension.z) - (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'z'
          )
          break
        case 'KeyQ':
          ;[role.globalExaminer, role.groupExaminer].includes(user.role) &&
            this.handleInputLabel(!editingLabel.userData.fail, 'fail')
          break
      }
    }
  }

  onMouseDown = e => {
    const main = this.views[MAIN]
    const {
      labels,
      labelAdding,
      isEdited,
      activeList,
      checkedLabels,
      projectionMatrixs
    } = this.state
    e.preventDefault()
    this.mouse.x = (e.offsetX / e.target.clientWidth) * 2 - 1
    this.mouse.y = -(e.offsetY / e.target.clientHeight) * 2 + 1
    this.raycaster.setFromCamera(this.mouse, main.camera)
    const intersects = this.raycaster.intersectObjects(labels)
    if (e.button === 0) {
      if (activeList !== '') {
        main.mapControl.enabled = false
        main.mapControl.enableRotate = false
      } else {
        main.mapControl.enabled = true
        main.mapControl.enableRotate = true
      }
      this.setState({
        isAddListVisible: false
      })
      if (labelAdding) {
        // 清除多选数据样式
        checkedLabels.forEach((item, index) => {
          this.checkedsunlightLabel(item.object)
        })
        checkedLabels.length = 0
        isEdited || this.setState({ isEdited: true })
        const intersect = new THREE.Vector3()
        this.raycaster.ray.intersectPlane(this.plane, intersect) // 'target'(para 2) is required
        this.addLabel(intersect)
        this.setState({ activeList: '', labelAdding: false, })
        return
      }
      if (intersects.length > 0) {
        // 批量多选
        if (e.shiftKey) {
          if (checkedLabels.length > 0) {
            const exitLabel = checkedLabels.some((item) => {
              return item.object.name === intersects[0].object.name
            })
            if (exitLabel) {
              checkedLabels.forEach((item, index) => {
                if (item.object.name === intersects[0].object.name) {
                  this.checkedsunlightLabel(item.object)
                  checkedLabels.splice(index, 1)
                  if (projectionMatrixs.length > 0) {
                    this.SubCanvas.deleteRect()
                  }
                  this.setState({
                    editingLabel: null,
                  })
                }
              })
            } else {
              checkedLabels.push(intersects[0])
            }
          } else {
            checkedLabels.push(intersects[0])
            this.handleIntersects(intersects[0].object)
          }
          // 给多选的数据添加样式
          checkedLabels.forEach((item) => {
            this.checkedslightLabel(item.object)
          })
        } else {
          // 清除多选数据样式
          checkedLabels.forEach((item, index) => {
            this.checkedsunlightLabel(item.object)
          })
          checkedLabels.length = 0
          checkedLabels.push(intersects[0])  // editingLabel增加数据，给checkedLabels加数据
          this.highlightLabel(intersects[0].object)  // 使标注高亮显示
          this.handleIntersects(intersects[0].object)
          this.pointCameraAtLabel(intersects[0].object, this.views[TOP])
          this.pointCameraAtLabel(intersects[0].object, this.views[SIDE])
        }
      }
    }
  }
  handleIntersects = label => {
    const { projectionMatrixs } = this.state
    this.setState({
      editingLabel: label,
    })
    if (projectionMatrixs.length > 0) {
      this.SubCanvas.createRectFromLabel(label)
    }
  }
  checkedslightLabel = label => {
    const { labels } = this.state
    labels.forEach((item) => {
      if (item.name === label.name) {
        item.children[0].material.color.set(MATERIAL_COLORS.HIGHLIGHT)
        item.children[2].element.style.backgroundColor = label.color
        // MATERIAL_COLORS[label.userData.type.toUpperCase()]
      }
    })
  }

  checkedsunlightLabel = label => {
    const { labels } = this.state
    labels.forEach((item) => {
      if (item.name === label.name) {
        // item.children[0].material.color.set(MATERIAL_COLORS[label.userData.type.toUpperCase()])
        item.children[0].material.color.set(label.color)
        item.children[2].element.style.backgroundColor = ''
      }
    })
  }

  createRenderers = () => {
    this.rendererMain = new THREE.WebGLRenderer({ antialias: true })
    this.rendererMain.setPixelRatio(window.devicePixelRatio)

    this.rendererAux = new THREE.WebGLRenderer({ antialias: true })
    this.rendererAux.setPixelRatio(window.devicePixelRatio)

    this.labelRenderer = new THREE.CSS2DRenderer()
    this.labelRenderer.domElement.style.position = 'absolute'
    this.labelRenderer.domElement.style.top = 0
    this.labelRenderer.domElement.style.pointerEvents = 'none'
    this.labelRenderer.domElement.style.zIndex = 0
    this.views[0].container.appendChild(this.labelRenderer.domElement)

    this.onWindowResize() // Get canvases' sizes and set renderers' sizes
  }

  createScene = () => {
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0x151515)
  }

  createAccessories = () => {
    this.plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), PLANE_DISTANCE)
    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()
    this.loader = new THREE.PCDLoader()
  }

  createViews = () => {
    this.views.forEach(view => {
      let camera
      const HALF_WIDTH = 8

      switch (view.name) {
        case 'main':
          camera = new THREE.PerspectiveCamera(
            view.fov,
            view.canvas.width / view.canvas.height,
            0.1,
            400
          )
          break
        case 'top':
          camera = new THREE.OrthographicCamera(
            -HALF_WIDTH,
            HALF_WIDTH,
            HALF_WIDTH / view.dimension.aspect,
            -HALF_WIDTH / view.dimension.aspect,
            0,
            400
          )
          break
        case 'side':
          camera = new THREE.OrthographicCamera(
            HALF_WIDTH,
            -HALF_WIDTH,
            -HALF_WIDTH / view.dimension.aspect + PLANE_DISTANCE, // Add an vertical offset equal to PLANE_DISTANCE
            HALF_WIDTH / view.dimension.aspect + PLANE_DISTANCE,
            0,
            400
          )
          break
      }

      camera.position.fromArray(view.eye)
      camera.up.fromArray(view.up)

      view.camera = camera
      view.mapControl = this.createMapControls(view.camera, view.canvas)

      if (view.name !== 'main') {
        // No dragging in the main view
        view.dragControl = this.createDragControls(
          view.camera,
          view.canvas,
          view.mapControl
        )

        // No rotate in other views
        view.mapControl.enableRotate = false
      }

      // Main render for main view, auxiliary render for top/side/rear views
      view.context = view.canvas.getContext('2d')
      view.render =
        view.name === 'main'
          ? () => {
            this.rendererMain.render(this.scene, view.camera)
            view.context.drawImage(this.rendererMain.domElement, 0, 0)

            this.labelRenderer.render(this.scene, view.camera)
          }
          : () => {
            this.rendererAux.render(this.scene, view.camera)
            view.context.drawImage(this.rendererAux.domElement, 0, 0)
          }
    })
  }

  createHelpers = () => {
    const { fromURL } = this.state
    const { objectTypes } = this.props
    const defaultBoxMaterial = this.defaultBoxMaterial.clone()
    // Car helper
    const mesh = new THREE.Mesh(this.initBoxGeometry, defaultBoxMaterial)
    // const mesh = new THREE.Mesh(this.defaultBoxGeometry, defaultBoxMaterial)
    mesh.material.color.set(MATERIAL_COLORS.HELPER)

    const line = new THREE.LineSegments(
      this.initEdgesGeometry,
      this.defaultLineMaterial.clone()
    )
    line.material.color.set(MATERIAL_COLORS.HELPER)
    mesh.add(line)

    const arrow = new THREE.Mesh(this.defaultConeGeometry, defaultBoxMaterial)
    arrow.position.fromArray([2.8, 0, 0])
    arrow.rotation.fromArray([0, 0, -1.57])

    mesh.add(arrow)

    mesh.position.set(0, 0, -PLANE_DISTANCE)
    //mesh.rotateZ(-Math.PI / 2)
    this.helpers.push(mesh)
    this.scene.add(mesh)

    // Axes helper
    const axesHelper = new THREE.AxesHelper(60)
    this.helpers.push(axesHelper)
    this.scene.add(axesHelper)

    const gridHelper = new THREE.GridHelper(200, 20)
    gridHelper.rotateX(Math.PI / 2)
    this.helpers.push(gridHelper)
    this.scene.add(gridHelper)
  }

  createDefaultBox = () => {
    const { fromURL } = this.state
    const { objectTypes } = this.props
    this.initBoxGeometry = new THREE.BoxBufferGeometry(
      ...Object.values(DEFAULT_DIMENSION)
    )
    this.initEdgesGeometry = new THREE.EdgesGeometry(this.initBoxGeometry)
    if (fromURL.groupName && objectTypes.length > 0) {
      this.defaultBoxGeometry = new THREE.BoxBufferGeometry(
        ...Object.values(JSON.parse(objectTypes[0].size))
      )
      this.defaultBoxMaterial = new THREE.MeshBasicMaterial({
        color: objectTypes[0].color,
        opacity: 0.2,
        transparent: true
      })
      this.defaultEdgesGeometry = new THREE.EdgesGeometry(this.defaultBoxGeometry)
      this.defaultLineMaterial = new THREE.LineBasicMaterial({
        color: objectTypes[0].color,
      })
    } else {
      // Create default Box
      this.defaultBoxGeometry = new THREE.BoxBufferGeometry(
        ...Object.values(DEFAULT_DIMENSION)
      ) // Deafult box geometry
      this.defaultBoxMaterial = new THREE.MeshBasicMaterial({
        opacity: 0.2,
        transparent: true
      })

      // Create default Edges
      this.defaultEdgesGeometry = new THREE.EdgesGeometry(this.defaultBoxGeometry)
      this.defaultLineMaterial = new THREE.LineBasicMaterial({
        color: MATERIAL_COLORS.CAR
      })
    }


    // Create default arrow cone
    this.defaultConeGeometry = new THREE.ConeBufferGeometry(
      0.5,
      0.8,
      50,
      1,
      true
    )
  }

  createMapControls = (camera, canvas) => {
    const mapControl = new MapControls(camera, canvas)
    mapControl.enableDamping = true
    mapControl.dampingFactor = 0.3
    mapControl.enableKeys = false
    mapControl.screenSpacePanning = false
    mapControl.minDistance = 1
    mapControl.maxDistance = 300
    mapControl.maxPolarAngle = Math.PI / 2

    return mapControl
  }

  createDragControls = (camera, canvas, mapControl) => {
    const { labels, isEdited } = this.state
    const { user } = this.props
    const dragControl = new THREE.DragControls(labels, camera, canvas)
    dragControl.addEventListener('dragstart', e => {
      // 每次drag的变化保存到撤销栈中
      if (!this.isRedoOrUndoIng) {
        this.queueManager.saveSnap(this.state)
      }
      mapControl.enabled = false
      if (e.object.type === 'LineSegments') return
      this.highlightLabel(e.object)
      this.setState({
        editingLabel: applyPrecisionToLabel(e.object),
        // checkedLabels: [{ object: (applyPrecisionToLabel(e.object)) }]
      })
    })

    dragControl.addEventListener('drag', e => {
      if (e.object.type === 'LineSegments') return

      const count = changePointsColorInBox(
        e.object,
        this.currentPCD,
        this.pointsKDTree,
        PCD_INBOX_COLOR
      )

      e.object.userData.pointsCount = count

      // Update rects
      if (this.state.projectionMatrixs.length > 0) {
        this.SubCanvas.updateRect(e.object)
      }
      this.setState({
        editingLabel: applyPrecisionToLabel(e.object)
      })

      if ([role.globalExaminer, role.groupExaminer].includes(user.role)) {
        this.setFail(e.object, true)
      }
      isEdited || this.setState({ isEdited: true })
    })

    dragControl.addEventListener('dragend', e => {
      mapControl.enabled = true
      this.pointCameraAtLabel(e.object, this.views[TOP], true)
      this.pointCameraAtLabel(e.object, this.views[SIDE], true)
    })


    dragControl.addEventListener('dragBefore', e => {
      changePointsColorInBox(
        this.state.editingLabel,
        this.currentPCD,
        this.pointsKDTree,
        PCD_COLOR
      )
    })

    return dragControl
  }

  createLabel = ({
    id,
    name,
    shortName,
    type,
    color,
    showName,
    position,
    scale,
    dimension,
    rotation,
    fail,
  }) => {
    const { objectTypes, t } = this.props
    const { fromURL, hideLabels } = this.state
    const { currentFrame } = this.state
    // Create box
    const defaultBoxMaterial = this.defaultBoxMaterial.clone()
    // let mesh = new THREE.Mesh(this.defaultBoxGeometry, defaultBoxMaterial)
    let mesh

    if (!name) {
      mesh = new THREE.Mesh(this.defaultBoxGeometry, defaultBoxMaterial)
      // Create line
      const defaultLineMaterial = this.defaultLineMaterial.clone()
      const line = new THREE.LineSegments(
        this.defaultEdgesGeometry,
        defaultLineMaterial
      )
      mesh.add(line)
    } else {
      mesh = new THREE.Mesh(this.initBoxGeometry, defaultBoxMaterial)

      // Create line
      const defaultLineMaterial = this.defaultLineMaterial.clone()
      const line = new THREE.LineSegments(
        this.initEdgesGeometry,
        defaultLineMaterial
      )
      mesh.add(line)
    }
    console.log('mesh===', mesh)
    // 修改朝向的圆锥形状大小
    // Create arrow, using the same material with box to change color synchronized
    const arrow = new THREE.Mesh(this.defaultConeGeometry, defaultBoxMaterial)
    console.log(arrow.geometry)
    arrow.position.fromArray([2.8, 0, 0])
    arrow.rotation.fromArray([0, 0, -1.57])
    const newMaterial = arrow.material.clone();
    newMaterial.opacity = 0.8;
    arrow.material = newMaterial
    mesh.add(arrow)
    // Label customization
    let nameArr1 = name
    nameArr1 ? (mesh.forName = Number(nameArr1.split('-')[2] || 1)) : this.assignForName(mesh)
    let nameArr2 = name
    let newShortName
    name ? (newShortName = nameArr2.split('-')[0]) : (fromURL.groupName && objectTypes.length > 0 ? (newShortName = objectTypes.find(item => item.name == type).shortName) : (newShortName = SIMPLETYPE[type.toUpperCase()]))
    // ID
    id ? (mesh.userData.id = id) : this.assignID(mesh)
    // Type
    mesh.userData.type = type
    let newColor, newShowName
    if (showName) {
      newColor = color
      newShowName = showName
    } else {
      newColor = fromURL.groupName && objectTypes.length > 0 ? objectTypes.find(item => item.name == type).color : MATERIAL_COLORS[type.toUpperCase()]
      newShowName = fromURL.groupName && objectTypes.length > 0 ? objectTypes.find(item => item.name == type).showName : t(`type.${mesh.userData.type}`)
    }

    mesh.material.color.set(newColor)
    mesh.children[0].material.color.set(newColor)
    mesh.children[1].material.color.set(newColor)
    mesh.color = newColor
    mesh.showName = newShowName
    // const color = MATERIAL_COLORS[type.toUpperCase()]
    // mesh.userData.type = type
    // mesh.material.color.set(color)
    // mesh.children[0].material.color.set(color)
    // Name
    if (name) {
      mesh.name = name
    } else {
      // if (!mesh.userData.id) mesh.userData.id = 1
      // mesh.name = fromURL.groupName && objectTypes.length > 0 ? `${objectTypes.find(item => item.name == type).shortName}-${currentFrame}-${mesh.forName}` : `${SIMPLETYPE[type.toUpperCase()]}-${currentFrame}-${mesh.forName}`
      mesh.name = `${newShortName}-${currentFrame}-${mesh.forName}`
    }


    // Position
    position &&
      (position instanceof THREE.Vector3
        ? mesh.position.copy(position)
        : mesh.position.fromArray(position))

    // Scale
    dimension = Array.isArray(dimension) ? dimension : Object.values(dimension)
    if (dimension) {
      const newScale = [
        dimension[0] / DEFAULT_DIMENSION.x,
        dimension[1] / DEFAULT_DIMENSION.y,
        dimension[2] / DEFAULT_DIMENSION.z
      ]
      mesh.scale.fromArray(newScale)
    }

    // Dimension
    mesh.userData.dimension = new THREE.Vector3(
      ...Object.values(dimension ? dimension : (fromURL.groupName && objectTypes.length > 0 ? JSON.parse(objectTypes[0].size) : DEFAULT_DIMENSION))
    )
    // Rotation
    rotation && mesh.rotation.fromArray(rotation)

    // Add label tag
    const labelTagDiv = document.createElement('div')
    labelTagDiv.className = 'views-label'
    labelTagDiv.textContent = mesh.name
    // labelTagDiv.style.pointerEvents = 'auto'
    const labelTag = new THREE.CSS2DObject(labelTagDiv)
    labelTag.position.set(0, 0, 3)

    mesh.add(labelTag)

    if (fail) {
      mesh.children[0].material.color.set(MATERIAL_COLORS.FAIL)
      labelTagDiv.style.color = MATERIAL_COLORS.FAIL

      mesh.userData.fail = fail
    }

    // Apply precision
    mesh = applyPrecisionToLabel(mesh)

    const count = changePointsColorInBox(
      mesh,
      this.currentPCD,
      this.pointsKDTree,
      PCD_INBOX_COLOR
    )
    mesh.userData.pointsCount = count

    if (hideLabels.indexOf(mesh.name) > -1) {
      mesh.visible = false
      mesh.children[2].visible = false
    }
    return mesh
  }

  pointCameraAtLabel = (label, view, noReset) => {
    //noReset || view.mapControl.reset()

    switch (view.name) {
      case 'top':
        view.camera.left = -HALF_WIDTH + label.position.x
        view.camera.right = HALF_WIDTH + label.position.x
        view.camera.top = HALF_WIDTH / view.dimension.aspect + label.position.y
        view.camera.bottom =
          -HALF_WIDTH / view.dimension.aspect + label.position.y
        break
      case 'side':
        view.camera.left = HALF_WIDTH - label.position.x
        view.camera.right = -HALF_WIDTH - label.position.x
        view.camera.top = -HALF_WIDTH / view.dimension.aspect + PLANE_DISTANCE
        view.camera.bottom = HALF_WIDTH / view.dimension.aspect + PLANE_DISTANCE
        break
    }

    view.camera.updateProjectionMatrix()
  }

  animate = () => {
    this.views.forEach(view => {
      view.render()
    })
    requestAnimationFrame(this.animate)
  }

  handleRemoveLabel = () => {
    const { editingLabel, checkedLabels } = this.state
    console.log(checkedLabels.indexOf(editingLabel))
    this.unhighlightLabel()
    if (checkedLabels.length) {
      this.removeLabels(checkedLabels.map((item) => item.object))
    }
    this.setState({
      editingLabel: null,
      checkedLabels: []
    })
  }

  handleToggleHelpers = () => {
    const { helpersShowing } = this.state

    this.helpers.forEach(helper =>
      helpersShowing ? this.scene.remove(helper) : this.scene.add(helper)
    )

    this.setState({ helpersShowing: !helpersShowing })
  }

  handleResetCamera = viewName => {
    this.views[viewName].mapControl.reset()
  }

  handleInputLabel = (value, prop, subProp) => {
    // 每次属性的变化保存到撤销栈中
    if (!this.isRedoOrUndoIng) {
      let isSaveState = false
      switch (prop) {
        case 'name':
          if (value !== this.state.editingLabel.name) {
            isSaveState = true
          }
        case 'fail':
          if (value !== this.state.editingLabel.userData.fail) {
            isSaveState = true
          }
          break
        case 'type':
          if (value !== this.state.editingLabel.userData.type) {
            isSaveState = true
          }
          break
        case 'position':
          if (value !== this.state.editingLabel.position[subProp]) {
            isSaveState = true
          }
          break
        case 'rotation':
          if (value !== this.state.editingLabel.rotation[subProp]) {
            isSaveState = true
          }
          break
        case 'scale':
          if (value !== this.state.editingLabel.scale[subProp]) {
            isSaveState = true
          }
          break
        case 'dimension':
          if (value !== this.state.editingLabel.userData.dimension[subProp]) {
            isSaveState = true
          }
          break
      }
      isSaveState && this.queueManager.saveSnap(this.state)
    }
    console.log('改变', value, prop, subProp)
    const { editingLabel, isEdited, fromURL, currentFrame } = this.state
    const { user, objectTypes, t } = this.props
    // Change label tag content
    if (prop === 'name') {
      this.setState({ labelEditing: true, isEdited: true }, () => {
        editingLabel.children[2].element.textContent = value
        editingLabel.name = value
        this.setState({ labelEditing: false })
      })
      return
    }

    value === +value && (value = toPrecision(value, FIGURE_PRECISION))

    // This is verbose, the better way is setting Proxy or getter/setter to labels
    if (editingLabel) {
      const count = changePointsColorInBox(
        editingLabel,
        this.currentPCD,
        this.pointsKDTree,
        PCD_COLOR
      )
      editingLabel.userData.pointsCount = count

      switch (prop) {
        case 'name':
        case 'fail':
          editingLabel.userData[prop] = value
          break
        case 'type':
          // Update counter
          this.updateLabelsCounter({
            type: 'update',
            payload: { old: editingLabel.userData.type, new: value }
          })
          let newShortName
          fromURL.groupName && objectTypes.length > 0 ? (newShortName = objectTypes.find(item => item.name == value).shortName) : (newShortName = SIMPLETYPE[value.toUpperCase()])
          editingLabel.userData[prop] = value
          // editingLabel.children[2].element.textContent = `${newShortName}-${currentFrame}-${editingLabel.forName} `
          // editingLabel.name = `${newShortName}-${currentFrame}-${editingLabel.forName} `
          break
        case 'position':
        case 'rotation':
          editingLabel[prop][subProp] = value
          break
        case 'scale':
          editingLabel[prop][subProp] = value
          editingLabel.userData.dimension[subProp] =
            DEFAULT_DIMENSION[subProp] * value
          break
        case 'dimension':
          const dis = value - editingLabel.userData[prop][subProp]

          editingLabel.userData[prop][subProp] = value
          editingLabel.scale[subProp] = toPrecision(
            value / DEFAULT_DIMENSION[subProp],
            FIGURE_PRECISION
          )

          switch (subProp) {
            case 'x':
              editingLabel.position.x +=
                (dis * Math.cos(editingLabel.rotation.z)) / 2
              editingLabel.position.y +=
                (dis * Math.sin(editingLabel.rotation.z)) / 2
              break
            case 'y':
              editingLabel.position.x +=
                (dis * Math.cos(editingLabel.rotation.z - Math.PI / 2)) / 2
              editingLabel.position.y +=
                (dis * Math.sin(editingLabel.rotation.z - Math.PI / 2)) / 2
              break
            case 'z':
              editingLabel.position.z += dis / 2
              break
          }

          editingLabel.position.x = toPrecision(
            editingLabel.position.x,
            FIGURE_PRECISION
          )
          editingLabel.position.y = toPrecision(
            editingLabel.position.y,
            FIGURE_PRECISION
          )
          editingLabel.position.z = toPrecision(
            editingLabel.position.z,
            FIGURE_PRECISION
          )
          break
      }

      // Change color
      let color = fromURL.groupName && objectTypes.length > 0 ? objectTypes.find(type => type.name == editingLabel.userData.type).color :
        MATERIAL_COLORS[editingLabel.userData.type.toUpperCase()]
      let showName = (fromURL.groupName && objectTypes.length > 0 && objectTypes.find(type => type.name == editingLabel.userData.type && type.color == color)) ? objectTypes.length > 0 && objectTypes.find(type => type.name == editingLabel.userData.type && type.color == color).showName : t(`type.${editingLabel.userData.type}`)
      if (prop === 'type') {
        editingLabel.material.color.set(
          color
        )
        editingLabel.showName = showName
        editingLabel.color = color
        editingLabel.children[2].element.style.backgroundColor = color
        // MATERIAL_COLORS[editingLabel.userData.type.toUpperCase()]
      }

      // Mark fail
      if (prop === 'fail') {
        this.setFail(editingLabel, value)
      } else if (
        [role.globalExaminer, role.groupExaminer].includes(user.role)
      ) {
        this.setFail(editingLabel, true)
      }

      changePointsColorInBox(
        editingLabel,
        this.currentPCD,
        this.pointsKDTree,
        PCD_INBOX_COLOR
      )

      this.setState({
        editingLabel
      })

      if (['position', 'rotation', 'dimension', 'scale', 'type'].includes(prop)) {
        if (this.state.projectionMatrixs.length > 0) {
          this.SubCanvas.updateRect(editingLabel)
        }
      }

      isEdited || this.setState({ isEdited: true })
    }
  }

  handleInputFrame = (value, keyup) => {
    const { totalFrames, labelsCounter, labels, checkedLabels, projectionMatrixs, editingLabel } = this.state
    const { dispatch, frames } = this.props
    if (value > 0 && value <= totalFrames) {
      // 2D Rect
      if (projectionMatrixs.length > 0) {
        this.SubCanvas.deleteRect()
      }
      const objectId = frames[value - 1].annotationobjectsId
      this.handleSave()
      this.setState(
        {
          currentFrame: value,
          noLabels: false
        },
        () => {
          this.setState({ isEdited: false, noLabels: false })
          // 复制数据方法
          const copyLabelAdd = (label) => {
            this.scene.add(label)
            labels.push(label)
            this.updateLabelsCounter({
              type: 'create',
              payload: label.userData.type
            })
            this.checkedslightLabel(label)
            this.setState({ isEdited: true })
          }
          const copyData = () => {
            if (labels.length > 0) {
              checkedLabels.forEach((item) => {
                const exitFlag = labels.some(currItem => currItem.name === item.object.name)
                if (keyup) {
                  if (exitFlag) {
                    labels.forEach((labelItem) => {
                      if (labelItem.name === item.object.name) {
                        this.checkedslightLabel(labelItem)
                      }
                    })
                  } else {
                    copyLabelAdd(item.object)
                  }
                } else {
                  if (exitFlag) {
                    labels.forEach((labelItem) => {
                      if (labelItem.name === item.object.name) {
                        this.checkedslightLabel(labelItem)
                      }
                    })
                  }
                }
              })
            } else {
              if (keyup) {
                checkedLabels.forEach((item) => {
                  copyLabelAdd(item.object)
                })
              }
            }
            if (editingLabel) {
              labels.forEach((labelItem) => {
                if (labelItem.name === editingLabel.name) {
                  this.setState({
                    editingLabel: labelItem
                  })
                  if (projectionMatrixs.length > 0) {
                    this.SubCanvas.createRectFromLabel(labelItem)
                  }
                  this.pointCameraAtLabel(labelItem, this.views[TOP])
                  this.pointCameraAtLabel(labelItem, this.views[SIDE])
                }
              })
            }
          }
          this.loadData(() => {
            if (objectId > 0) {
              this.setState({
                objectClientId: objectId
              })
              dispatch(annotationActions.getObject(objectId)).then((res) => {
                copyData()
              })
            } else {
              this.handleUpdateLabels([])
              checkedLabels.forEach((item) => {
                // this.setState({})
                labelsCounter[item.object.userData.type] = 0
              })
              this.setState({ labelsCounter: {} }, () => {
                copyData()
              })
              console.log('不加载接口数据')
            }
          })
        }
      )
    }
  }

  handleSelectLabel = (label, e) => {
    // 切换新的 editingLabel 时保存其状态
    if (!this.isRedoOrUndoIng) {
      this.queueManager.saveSnap(this.state)
    }
    const { editingLabel, projectionMatrixs, checkedLabels } = this.state
    if (label !== editingLabel) {
      this.highlightLabel(label)
      this.setState({
        editingLabel: label
      })
      checkedLabels.forEach((item, index) => {
        this.checkedsunlightLabel(item.object)
      })
      checkedLabels.length = 0
      checkedLabels.push({ object: label })
      if (projectionMatrixs.length > 0) {
        this.SubCanvas.createRectFromLabel(label)
      }
      this.pointCameraAtLabel(label, this.views[TOP])
      this.pointCameraAtLabel(label, this.views[SIDE])
    } else {
      this.unhighlightLabel(label)
      checkedLabels.length = 0
      if (projectionMatrixs.length > 0) {
        this.SubCanvas.deleteRect()
      }
      this.setState({
        editingLabel: null
      })
    }
  }

  handleToggleLabelVisibility = (e, label) => {
    const { editingLabel, hideLabels } = this.state

    e.stopPropagation()

    if (!label && !editingLabel) return

    let hidelabel = label ? label.name : editingLabel.name
    const target = label ? label : editingLabel
    target.visible = !target.visible
    if (target.visible == false) {
      hideLabels.push(hidelabel)
    } else {
      hideLabels.splice(hideLabels.indexOf(hidelabel), 1)
    }
    const labelDiv = label ? label.children[2] : editingLabel.children[2]
    labelDiv.visible = !labelDiv.visible
    this.setState({
      editingLabel,
      labels: this.state.labels,
      hideLabels
    })
    // this.fcanvas.requestRenderAll()

    // Clear seletedLabel and rigger setState btw
    // this.clearLabel()
  }

  handleSave = () => {
    const { labels, currentFrame, isEdited, fromURL } = this.state
    const { dispatch, frames, location, t, user } = this.props
    const { id } = (fromURL.isUrl) ? fromURL : location.state.record


    // const { id } = this.state.ls

    if (!isEdited) return

    const objectId = frames[currentFrame - 1].annotationobjectsId
    var objects = null
    objects = labels.map(label => extractObject3D(label))
    const userId = parseInt(user.id)
    const annotationId = parseInt(fromURL.id)


    dispatch(
      objectId > 0
        ? annotationActions.updateObject(objects, objectId, objects.length, userId, annotationId)
        : annotationActions.createObject(
          objects,
          frames[currentFrame - 1].id,
          objects.length,
          userId, annotationId
        )
    )
      .then(object => {
        // When created objects, remove that frame from indices
        if (!(objectId > 0)) {
          frames[currentFrame - 1].annotationobjectsId = object.id

          this.unlabeledIndices.splice(
            this.unlabeledIndices.findIndex(
              value => value === currentFrame - 1
            ),
            1
          )
        }

        // Update this annotation detail and progress
        dispatch(
          annotationActions.updateAnnotation({
            id,
            progress: `${frames.length - this.unlabeledIndices.length} / ${frames.length
              }`
          })
        )

        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.saveSuccess')
          })
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alerts.saveFailure')
          })
        )
      })
  }

  handleToggleAuxViews = () => {
    const { auxShowing } = this.state
    this.setState({ auxShowing: !auxShowing })
  }

  handleEscapeLabel = () => {
    const { editingLabel, checkedLabels, projectionMatrixs } = this.state
    const main = this.views[MAIN]
    this.setState({
      activeList: '',
      labelAdding: false
    })
    // 清除多选数据样式
    checkedLabels.forEach((item, index) => {
      this.checkedsunlightLabel(item.object)
    })
    checkedLabels.length = 0
    if (editingLabel) {
      this.setState({
        editingLabel: null,
      })
    }
    if (projectionMatrixs.length > 0) {
      this.SubCanvas.deleteRect()
    }
    main.mapControl.enabled = true
    main.mapControl.enableRotate = true
  }
  // 全选快捷键
  handleAllLabel = () => {
    const { checkedLabels, labels } = this.state
    checkedLabels.length = 0
    labels.forEach((item) => {
      checkedLabels.push({ object: item })
    })
    checkedLabels.forEach((item) => {
      this.checkedslightLabel(item.object)
    })
  }

  handleClickAddLabel = (type) => {
    const { objectTypes } = this.props
    const { activeList, fromURL } = this.state
    if (type === 'more') {
      // 操作添加菜单状态,右击点出菜单
      this.setState({
        isAddListVisible: true
      })
    } else {
      this.setState({
        activeList: type,
        labelAdding: true,
        isAddListVisible: false,
        initLabel: {
          type: type,
          dimension: fromURL.groupName && objectTypes.length > 0 ? JSON.parse(objectTypes.find(item => item.name == type).size) : MATERIAL_DIMENSION[type.toUpperCase()]
        }
      })
    }
    if (activeList === type) {  //重复点击同一个图标
      this.setState({
        activeList: '',
        labelAdding: false,
      })
      const main = this.views[MAIN]
      main.mapControl.enabled = true
      main.mapControl.enableRotate = true
    }
  }
  handleToggleStatsInfo = () => {
    const { isStatsVisible } = this.state
    this.setState({ isStatsVisible: !isStatsVisible })
  }

  handleCopyUrl = async () => {
    const { dispatch, t } = this.props
    try {
      if (!location.search.split('&')[1]) {
        await navigator.clipboard.writeText(`${location}&frame=${this.state.currentFrame}`)
      } else {
        await navigator.clipboard.writeText(location)
      }
      dispatch(
        alertActions.success({
          type: 'success',
          detail: t('alerts.copySuccess')
        })
      )
    } catch (err) {
      dispatch(
        alertActions.success({
          type: 'error',
          detail: t('alerts.copyFailure')
        })
      )
    }
  }

  handleChangePointSize = value => {
    this.setState({ pointSize: value })
    this.currentPCD.material.size = value
  }

  handleChangePointColor = (value, prop) => {
    this.setState({ [prop]: value }, () => {
      const { pointHue: h, pointSaturation: s, pointLightness: l } = this.state
      this.currentPCD.material.color.setHSL(h, s, l)
    })
  }

  handleCopyPath = () => {
    const { dispatch, t } = this.props

    dispatch(
      alertActions.success({
        type: 'success',
        detail: t('alerts.copySuccess')
      })
    )
  }

  handleChangeNoLabels = e => {
    this.setState({
      noLabels: e.target.checked,
      isEdited: e.target.checked
    })
  }

  /** load Data */
  loadData = callback => {
    const { currentFrame, cameraProp, projectionMatrixs } = this.state
    const { frames, location, bag } = this.props
    // let { bagName } = location.state.record
    let { bagName } = (this.state.fromURL.isUrl) ? this.state.fromURL : location.state.record
    this.bufferIndex = currentFrame
    const index = currentFrame - 1
    const timestamp = frames[index].timestamp
    if (this.buffer[timestamp] && this.buffer[timestamp].pcd) {
      this.addPCD(this.buffer[timestamp].pcd)
      if (projectionMatrixs.length > 0) {
        this.SubCanvas.initAddImg(this.buffer[timestamp].camera)
      }
      this.pointsKDTree = this.buffer[timestamp].kdTree
      callback()
    } else {
      this.buffer[timestamp] = {}
      const getPCD = db['PCD'].get(Number(timestamp))
      const getCAMERA = db['CAMERA'].get(Number(timestamp))
      Promise.all([getPCD, getCAMERA])
        .then(result => {
          // Make sure there must be pcd in db
          if (result[0] && result[1]) {
            // Add PCD to buffer
            const [pcd, kdTree] = this.loadPCD(result[0].data)
            this.buffer[timestamp].pcd = pcd
            this.buffer[timestamp].kdTree = kdTree
            this.addPCD(this.buffer[timestamp].pcd)
            this.pointsKDTree = this.buffer[timestamp].kdTree

            if (result[1]) {
              // Add CAMERA to buffer
              this.buffer[timestamp].camera = result[1].data
              if (projectionMatrixs.length > 0) {
                this.SubCanvas.initAddImg(this.buffer[timestamp].camera)
              }
            }

            callback()
          } else {
            const data = {
              bagName: bagName,
              sensorsList: ['PCD', ...cameraProp],
              startFrametime: timestamp,
              endFrametime: timestamp,
              framesList: [timestamp]
            }
            dataServices.getData(data, onMessage, onEnd)
          }
        })
        .catch(e => {
          console.log(e)
        })
    }

    // Test and preload next 5 frames
    this.japStep()
    const messageCamera = []
    const onMessage = data => {
      // For buffer
      if (data.sensorname === 'PCD') {
        const [pcd, kdTree] = this.loadPCD(data.data)
        this.buffer[timestamp].pcd = pcd
        this.buffer[timestamp].kdTree = kdTree
        this.addPCD(this.buffer[timestamp].pcd)
        this.pointsKDTree = this.buffer[timestamp].kdTree
        this.dataWorker.postMessage({ action: 'PUT_DATA', data })
      } else {
        messageCamera.push(data)
      }
    }
    const onEnd = () => {
      this.buffer[timestamp].camera = []
      messageCamera.forEach((item) => {
        this.buffer[timestamp].camera.push('data:image/jpeg;base64,' + item.data)
      })
      let camerasData = {
        data: this.buffer[timestamp].camera,
        frametime: Number(timestamp)
      }
      // For cache
      this.dataWorker.postMessage({ action: 'PUT_CAMERA_DATA', camerasData })
      if (projectionMatrixs.length > 0) {
        this.SubCanvas.initAddImg(this.buffer[timestamp].camera)
      }
      callback()
      console.log('loadData ends')
    }
  }

  preloadData = () => {
    const { cameraProp } = this.state
    const { frames, location } = this.props
    // let { bagName } = location.state.record
    let { bagName } = (this.state.fromURL.isUrl) ? this.state.fromURL : location.state.record

    const startIndex = this.bufferIndex
    const endIndex =
      this.bufferIndex + 5 > frames.length
        ? frames.length
        : this.bufferIndex + 5
    const promiseQueue = []

    // startIndex includes, endIndex excludes
    for (let index = startIndex; index < endIndex; ++index) {
      const timestamp = frames[index].timestamp
      // If not in buffer
      if (!this.buffer[timestamp]) {
        this.buffer[timestamp] = {}
        promiseQueue.push(
          new Promise((resolve, reject) => {
            const getPCD = db['PCD'].get(Number(timestamp))
            const getCAMERA = db['CAMERA'].get(Number(timestamp))
            Promise.all([getPCD, getCAMERA])
              .then(result => {
                if (result[0] && result[1]) {
                  const [pcd, kdTree] = this.loadPCD(result[0].data)
                  this.buffer[timestamp].pcd = pcd
                  this.buffer[timestamp].kdTree = kdTree
                  this.buffer[timestamp].camera = result[1].data
                  return resolve()
                } else {
                  this.buffer[timestamp] = undefined
                  return resolve(timestamp)
                }
              })
              .catch(e => {
                console.log(e)
                this.buffer[timestamp] = undefined
                return reject()
              })
          })
        )
      }
    }

    Promise.all(promiseQueue).then(result => {
      // Filter frames which need to request data
      const framesList = result.filter(item => {
        return !!item
      })
      if (framesList.length > 0) {
        const data = {
          bagName: bagName,
          sensorsList: ['PCD', ...cameraProp],
          startFrametime: frames[startIndex].timestamp,
          endFrametime: frames[endIndex - 1].timestamp,
          framesList
        }
        dataServices.getData(data, onMessage, onEnd)
      }
    })
    let messageCamera = []
    const onMessage = data => {
      this.buffer[data.frametime] || (this.buffer[data.frametime] = {})
      // For buffer
      if (data.sensorname === 'PCD') {
        const [pcd, kdTree] = this.loadPCD(data.data)
        this.buffer[data.frametime].pcd = pcd
        this.buffer[data.frametime].kdTree = kdTree
        // For cache
        this.dataWorker.postMessage({ action: 'PUT_DATA', data })
      } else {
        messageCamera.push(data)
      }
    }

    const onEnd = () => {
      if (messageCamera.length > 0) {
        let cameraByFrametime = {}
        messageCamera.forEach((item) => {
          if (!cameraByFrametime[item.frametime]) {
            cameraByFrametime[item.frametime] = []
            cameraByFrametime[item.frametime].push(item)
          } else {
            cameraByFrametime[item.frametime].push(item)
          }
        })
        for (let i in cameraByFrametime) {
          this.buffer[i].camera = []
          cameraByFrametime[i].forEach((item) => {
            this.buffer[i].camera.push('data:image/jpeg;base64,' + item.data)
          })
          let camerasData = {
            data: this.buffer[i].camera,
            frametime: Number(i)
          }
          //For cache
          this.dataWorker.postMessage({ action: 'PUT_CAMERA_DATA', camerasData })
        }
      }
      console.log('preload ends')
    }
  }

  japStep = () => {
    const { frames } = this.props

    if (this.bufferIndex === frames.length) return

    const index = this.bufferIndex
    const timestamp = frames[index].timestamp

    const getPCD = db['PCD'].get(Number(timestamp))
    const getCAMERA = db['CAMERA'].get(Number(timestamp))

    Promise.all([getPCD, getCAMERA])
      .then(result => {
        if (!result[0] && !result[1]) {
          this.preloadData()
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  /** PCD */
  loadPCD = data => {
    const mesh = this.loader.parse(base64ToArrayBuffer(data), 'name')

    dyePCD(mesh)
    this.updatePCD(mesh.material)

    const kdTree = createPointsKDTree(mesh)

    return [mesh, kdTree]
  }

  addPCD = pcd => {
    const { firstLoading } = this.state

    if (this.currentPCD) {
      this.scene.remove(this.currentPCD)
    }

    // Set point size
    this.updatePCD(pcd.material)

    this.scene.add(pcd)
    this.currentPCD = pcd

    firstLoading && this.setState({ firstLoading: false })
  }

  updatePCD = material => {
    const {
      pointSize,
      pointHue: h,
      pointSaturation: s,
      pointLightness: l
    } = this.state

    material.size = pointSize // Points size
    material.sizeAttenuation = false
    // material.color.setHSL(h, s, l)
    // material.vertexColors = true
  }

  /** Add & remove label */
  addLabel = position => {
    if (!this.isRedoOrUndoIng) {
      this.queueManager.saveSnap(this.state)
    }
    const { labels, initLabel, projectionMatrixs } = this.state
    let type = initLabel.type
    let dimension = initLabel.dimension
    // 3D Box
    const mesh = this.createLabel({ type, position, dimension })
    this.scene.add(mesh)
    labels.push(mesh)
    this.highlightLabel(mesh)
    this.pointCameraAtLabel(mesh, this.views[TOP])
    this.pointCameraAtLabel(mesh, this.views[SIDE])
    this.updateLabelsCounter({ type: 'create', payload: mesh.userData.type })
    // Create rects
    this.setState({
      labels,
      editingLabel: mesh,
      checkedLabels: [{ object: mesh }],
      noLabels: false,
    })
  }

  removeLabels = targetLabels => {
    // 删除
    const { labels, isEdited, editingLabel, projectionMatrixs } = this.state
    const deleteLabels = []
    if (!this.isRedoOrUndoIng) {
      this.queueManager.saveSnap(this.state)
    }
    targetLabels.forEach((targetLabel, index) => {
      changePointsColorInBox(
        targetLabel,
        this.currentPCD,
        this.pointsKDTree,
        PCD_COLOR
      )

      // Remove label tag
      targetLabel.children[2].element.remove()
      // Remove label mesh
      this.scene.remove(targetLabel)
      // Remove rects
      if (projectionMatrixs.length > 0) {
        this.SubCanvas.deleteRect()
      }
      labels.splice(labels.indexOf(targetLabel), 1)
      this.updateLabelsCounter({
        type: 'remove',
        payload: targetLabel.userData.type
      })
    })
    this.setState({ labels })
    isEdited || this.setState({ isEdited: true })
  }

  handleUpdateLabels = (objects, currentEditingLabel) => {
    const { labels, fromURL, hideLabels } = this.state
    const { objectTypes, t } = this.props
    let newchangedTypes = []
    // Clean labels
    labels.forEach(label => {
      label.children[2].element.remove()
      this.scene.remove(label)
      changePointsColorInBox(
        label,
        this.currentPCD,
        this.pointsKDTree,
        PCD_COLOR
      )
      this.updateLabelsCounter({
        type: 'remove',
        payload: label.userData.type
      })
    })
    labels.length = 0
    this.setState(
      {
        labelsCounter: {}
      },
      () => {
        // Push new labels
        objects.forEach(object => {
          if (!object.showName) {
            object.color = MATERIAL_COLORS[object.type.toUpperCase()]
            object.showName =  t(`type.${object.type}`)
          }
          let mesh = this.createLabel(object)
          if (objectTypes.filter(item => item.name == object.type && item.color == object.color && item.showName == object.showName).length == 0) {
            let changedType = { id: uuid(), showName: object.showName, color: object.color, type: object.type }
            newchangedTypes.push(changedType)
            this.setState({ changedTypes: newchangedTypes })
          }
          // 判断当前是否存在正在编辑状态的Label，如有则设置
          if (currentEditingLabel && currentEditingLabel.userData.id === mesh.userData.id && this.isRedoOrUndoIng) {
            this.handleSelectLabel(mesh)
          }
          this.scene.add(mesh)
          labels.push(mesh)
          this.updateLabelsCounter({
            type: 'create',
            payload: mesh.userData.type
          })
        })
      }
    )
    this.setState({
      labels,
    })
  }

  updateLabelsCounter = ({ type, payload }) => {
    let { labelsCounter } = this.state
    const create = data => {
      labelsCounter[data]
        ? (labelsCounter[data] += 1)
        : (labelsCounter[data] = 1)
    }

    const remove = data => {

      this.state.labelsCounter[data] -= 1

      if (!labelsCounter[data]) {
        delete labelsCounter[data]
      }
    }

    const update = data => {
      remove(data.old)
      create(data.new)
    }

    const handler = { create, remove, update }
    handler[type](payload)

    this.setState({ labelsCounter })
  }

  highlightLabel = label => {
    this.unhighlightLabel()
    label.children[0].material.color.set(MATERIAL_COLORS.HIGHLIGHT)
    label.children[2].element.style.backgroundColor = label.color
    // MATERIAL_COLORS[label.userData.type.toUpperCase()]
  }

  unhighlightLabel = () => {
    const { editingLabel } = this.state

    if (editingLabel) {
      editingLabel.children[0].material.color.set(
        editingLabel.userData.fail
          ? MATERIAL_COLORS.FAIL
          : editingLabel.color
        // : MATERIAL_COLORS[editingLabel.userData.type.toUpperCase()]
      )
      editingLabel.children[2].element.style.backgroundColor = ''
    }
  }

  assignForName = label => {
    const { labels, currentFrame } = this.state
    if (labels.length === 0) {
      label.forName = 1
    } else {
      let labelForName = 1
      labelForName = labels.indexOf(label) >= 0 ? labels.indexOf(label) + 1 : labels[labels.length - 1].forName + 1
      label.forName = labelForName
    }
  }

  assignID = label => {
    const { labels, currentFrame } = this.state
    // if (labels.length === 0) {
    //   label.userData.id = 0
    // } else {
    //   let labelId = 0
    //   labels.forEach((item) => {
    //     let labelName = Number(item.name.split('-')[1])
    //     if (labelName === currentFrame) {
    //       labelId = item.userData.id + 1
    //     }
    //   })
    label.userData.id = uuid()
    // }
  }

  setFail = (label, value) => {
    label.userData.fail = value
    // Change tag color
    label.children[2].element.style.color = value ? MATERIAL_COLORS.FAIL : ''
  }
  handleToggleCamera = () => {
    const { cameraShowing } = this.state
    this.setState({ cameraShowing: !cameraShowing })
  }
  /**
   * 撤销
   */
  handleStepBackward = () => {
    if (this.queueManager.undoQueue.length === 0) {
      return
    }
    this.isRedoOrUndoIng = true
    const state = this.queueManager.undo(this.state)
    const lastStepLabels = state.labels
    const objects = []
    lastStepLabels.forEach((label) => {
      objects.push({
        color: label.color,
        dimension: [label.userData.dimension.x, label.userData.dimension.y, label.userData.dimension.z],
        id: label.userData.id,
        name: label.name,
        position: [label.position.x, label.position.y, label.position.z],
        rotation: [label.rotation._x, label.rotation._y, label.rotation._z, label.rotation._order],
        scale: [label.scale.x, label.scale.y, label.scale.z],
        showName: label.showName,
        type: label.userData.type
      })
    })
    // 判断当前是否存在正在编辑状态的Label，如有则设置
    this.handleUpdateLabels(objects, state.editingLabel)
    setTimeout(() => {
      // 等待setState更新完成之后结束撤销状态
      this.isRedoOrUndoIng = false
    })
  }
  /**
   * 回退
   */
  handleStepForward = () => {
    if (this.queueManager.redoQueue.length === 0) {
      return
    }
    this.isRedoOrUndoIng = true
    const state = this.queueManager.redo(this.state)
    const lastStepLabels = state.labels
    const objects = []
    lastStepLabels.forEach((label) => {
      objects.push({
        color: label.color,
        dimension: [label.userData.dimension.x, label.userData.dimension.y, label.userData.dimension.z],
        id: label.userData.id,
        name: label.name,
        position: [label.position.x, label.position.y, label.position.z],
        rotation: [label.rotation._x, label.rotation._y, label.rotation._z, label.rotation._order],
        scale: [label.scale.x, label.scale.y, label.scale.z],
        showName: label.showName,
        type: label.userData.type
      })
    })
    // 判断当前是否存在正在编辑状态的Label，如有则设置
    this.handleUpdateLabels(objects, state.editingLabel)
    setTimeout(() => {
      // 等待setState更新完成之后结束回退状态
      this.isRedoOrUndoIng = false
    })
  }

  render() {
    const {
      currentFrame,
      totalFrames,
      labels,
      labelsCounter,
      editingLabel,
      labelAdding,
      helpersShowing,
      auxShowing,
      isInfoVisible,
      isStatsVisible,
      firstLoading,
      isEdited,
      noLabels,
      pointSize,
      activeList,
      isAddListVisible,
      cameraShowing,
      fromURL,
      changedTypes,
      checkedLabels
    } = this.state
    const { user, frames, location, t, getSome, objectTypes } = this.props
    document.title = '3D跟踪标注视图' + '-' + fromURL.id
    const recordData = {
      ...getSome,
      firstFrameIndex: fromURL.firstFrameIndex,
      id: fromURL.id
    }
    const labelsCollection = labels.map(label => (
      <div
        className={`views-collection-row${editingLabel && label.uuid === editingLabel.uuid ? ' highlight' : ''
          }`}
        key={label.uuid}
        onClick={e => this.handleSelectLabel(label, e)}
      >
        <div className="views-collection-left">
          <LabelType
            // color={MATERIAL_COLORS[label.userData.type.toUpperCase()]}
            color={label.color}
          />
          <span
            style={{ color: label.userData.fail ? MATERIAL_COLORS.FAIL : '' }}
          >
            {label.name}
          </span>
        </div>
        {/* {editingLabel && label.uuid === editingLabel.uuid ? ( */}
        <div className="views-collection-right">
          <Tooltip title={t('tooltip.hide')} mouseEnterDelay={0.3}>
            <LegacyIcon type={label.visible ? 'eye' : 'eye-invisible'}
              onClick={e => this.handleToggleLabelVisibility(e, label)}
              style={{ marginRight: '0.5rem' }} />
          </Tooltip>
        </div>
      </div>
    ))

    const emptyCollection = (
      <div className="views-collection-empty">
        <div>
          <InboxOutlined style={{ fontSize: '30px' }} />
        </div>
        {t('noLabels')}
      </div>
    )

    const frame = frames[currentFrame - 1]
    let dataURL = ''
    let openState = false

    if (frame) {
      const timestamp = frame.timestamp
      // console.log('props', this.props)
      // const { bagName, startTimestamp, status } = location.state.record
      const { bagName, startTimestamp, status } = (this.state.fromURL.isUrl) ? this.state.fromURL : location.state.record

      const time = bagName.split('-')[1]

      const dataPath = `/${time.substring(0, 4)}/${time.substring(
        4,
        6
      )}/${time.substring(
        6,
        8
      )}/${bagName}/Object/${startTimestamp}/${timestamp}.txt`

      dataURL = OPEN_BASE_URL + dataPath
      openState = status === 'objects_ready'
    }

    return (
      <div className="views">
        <div className="views-top-panel">
          <div className="views-left-panel">
            <div
              className="views-main"
              ref={container => (this.views[MAIN].container = container)}
            >
              <CameraCanvas onRef={node => this.SubCanvas = node} viewData={this.state} />
              <div
                className="views-aux"
                style={{ visibility: auxShowing ? 'visible' : 'hidden' }}
              >
                <div className="views-box">
                  <div className="views-box-bar">
                    <div className="views-name">{t('viewsName.top')}</div>
                  </div>
                  <div
                    className="views-top"
                    ref={container => (this.views[TOP].container = container)}
                  />
                </div>
                <div className="views-box">
                  <div className="views-box-bar">
                    <div className="views-name">{t('viewsName.side')}</div>
                  </div>
                  <div
                    className="views-side"
                    ref={container => (this.views[SIDE].container = container)}
                  />
                </div>
              </div>

              <HoverPanel
                active={labelAdding}
                style={{
                  left: '56%',
                  transform: 'translateX(-50%)',
                  zIndex: 1
                }}
              >
                <IconButton
                  type="delete"
                  onClick={this.handleRemoveLabel}
                />
                <IconButton
                  type="reload"
                  onClick={() => this.handleResetCamera(MAIN)}
                />
                <IconButton
                  type="ArrowLeft"
                  onClick={this.handleStepBackward}
                />
                <IconButton
                  type="ArrowRight"
                  onClick={this.handleStepForward}
                />
                <Divider type="vertical" style={{ margin: '0 10px', background: '#fff' }} />
                <IconButton
                  active={cameraShowing}
                  type="camera"
                  onClick={this.handleToggleCamera}
                />
                <IconButton
                  active={helpersShowing}
                  type="table"
                  onClick={this.handleToggleHelpers}
                />
                <IconButton
                  active={auxShowing}
                  type="block"
                  onClick={this.handleToggleAuxViews}
                />
                <IconButton
                  active={isStatsVisible}
                  type="bar-chart"
                  onClick={this.handleToggleStatsInfo}
                />
                <Divider type="vertical" style={{ margin: '0 10px', background: '#fff' }} />
                <IconButton
                  type="copy"
                  onClick={this.handleCopyUrl}
                />
                {/* <span  style={{paddingLeft:'10px'}}>
                  {t('labelsBox')}
                </span> */}
              </HoverPanel>
              <LabelsStats
                counter={labelsCounter}
                style={{ right: auxShowing ? '35%' : '' }}
                visible={isStatsVisible}
                labels={labels}
              />
              <Addlabel
                handleClick={this.handleClickAddLabel}
                stateList={activeList}
                objectTypes={objectTypes}
              />
              <AddlabeLlist
                handleClick={this.handleClickAddLabel}
                stateList={activeList}
                visible={isAddListVisible}
                objectTypes={objectTypes}
              />
            </div>
          </div>
          <div className="views-right-panel">
            <div className="views-panel-inner">
              <Tabs type="card">
                <TabPane tab={t('labelsTab')} key="0">
                  <div className="ant-tabpane-wrapper">
                    <div className="views-labels-collection">
                      <div className="views-collection-scroll">
                        {labelsCollection.length
                          ? labelsCollection
                          : emptyCollection}
                      </div>
                    </div>
                    {editingLabel ? (
                      <AttributesTable
                        label={editingLabel}
                        user={user}
                        onChange={this.handleInputLabel}
                        onFocusHandler={this.onFocusHandler}
                        onBlurHandler={this.onBlurHandler}
                        objectTypes={objectTypes}
                        groupName={fromURL.groupName}
                        changedTypes={changedTypes}
                      />
                    ) : null}
                  </div>
                </TabPane>
                <TabPane tab={t('settingsTab')} key="1">
                  <div className="ant-tabpane-wrapper">
                    <div className="views-settings">
                      <div className="views-setting-row">
                        <div className="views-setting-title">
                          {t('settings.pcd')}
                        </div>
                      </div>
                      <div className="views-setting-row">
                        <div className="views-setting-head">
                          {t('settings.pointSize')}
                        </div>
                        <div className="views-setting-control">
                          <Slider
                            value={pointSize}
                            step={0.01}
                            min={0.5}
                            max={2}
                            marks={{
                              0.5: {
                                style: sliderLabelStyle,
                                label: '0.5'
                              },
                              2: {
                                style: sliderLabelStyle,
                                label: '2'
                              }
                            }}
                            onChange={this.handleChangePointSize}
                          />
                        </div>
                      </div>
                      <Divider style={{ margin: '1.2rem 0' }} />
                      <div className="views-setting-row">
                        <div className="views-setting-title">
                          {t('settings.frameState')}
                        </div>
                      </div>
                      <div className="views-setting-row">
                        <FrameInfo
                          frame={frame}
                          // record={location.state.record}
                          // record={this.state.fromURL.isUrl ? this.state.fromURL : location.state.record}
                          record={recordData}
                          visible={isInfoVisible}
                        />
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab={t('shortcutsTab')} key="2">
                  <div className="ant-tabpane-wrapper">
                    <Shortcuts user={user} />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="views-bottom-panel">
          <div className="views-frames-slider">
            <div className="views-slider-bar">
              <Slider
                value={typeof currentFrame === 'number' ? currentFrame : 1}
                min={1}
                max={totalFrames}
                onChange={this.handleInputFrame}
              />
            </div>
            <div className="views-slider-input">
              <InputNumber
                value={currentFrame}
                min={1}
                max={totalFrames}
                size="small"
                style={{ marginLeft: '1rem' }}
                onChange={this.handleInputFrame}
                onFocus={this.onFocusHandler}
                onBlur={this.onBlurHandler}
              />
              <span className="views-slider-input-frames">/ {totalFrames}</span>
            </div>
          </div>
          <Divider type="vertical" />
          <div className="views-frames-actions">
            <div className="views-frames-action">
              <Checkbox
                checked={noLabels}
                disabled={!!labels.length}
                onChange={this.handleChangeNoLabels}
              >
                {t('noLabels')}
              </Checkbox>
            </div>
            <div className="views-frames-action">
              <Button
                type="primary"
                size="small"
                onClick={this.handleSave}
                disabled={!isEdited}
              >
                {t('btns.save')}
              </Button>
            </div>
          </div>
          {user.role === role.admin ? (
            <>
              <Divider type="vertical" />
              <div style={{ pointerEvents: openState ? 'auto' : 'none' }}>
                <div className="views-frames-actions">
                  <div className="views-frames-action">
                    <Tooltip title={dataURL}>
                      <CopyToClipboard text={dataURL}>
                        <Button
                          type="primary"
                          size="small"
                          disabled={!openState}
                          onClick={this.handleCopyPath}
                        >
                          .txt
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <LoadingCover loading={firstLoading} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    bag: state.bag.curBag,
    frames: state.annotation.frames.sort(
      (frameA, frameB) => frameA.timestamp - frameB.timestamp
    ),
    objects: state.annotation.objects.sort(
      (objectA, objectB) => objectA.id - objectB.id
    ),
    objId: state.annotation.objId,
    message: state.alert.message,
    getSome: state.getSome,
    objectTypes: state.objectType.objectTypes,
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation('views')(Viewer3Dtracking))
)
