let en = {
  common: {
    role: {
      admin: 'Admin',
      groupAdmin: 'GroupAdmin',
      globalAnnotator: 'Annotator',
      groupAnnotator: 'GroupAnnotator',
      globalExaminer: 'Examiner',
      groupExaminer: 'GroupExaminer',
      developer: 'Developer'
    },
    statusText: {
      unassigned: 'Unassigned',
      assigned: 'Assigned',
      annotating: 'Annotating',
      readyforexam: 'Annotated',
      examining: 'Examining',
      finish: 'Finished',
      objects_ready: 'Ready'
    },
    annotTypeText: {
      '3d': '3D',
      '2d': '2D',
      cityscape: 'CityScape',
      fusion: 'Fusion',
      signal: 'Signal',
      '3d_tracking': '3D tracking',
      '2d_tracking': '2D tracking',
      'link': 'link',
    },
    btn: {
      submit: 'Submit',
      delete: 'Delete',
      reset: 'Reset',
      clear: 'Clear',
      search: 'Search',
      query: 'Query',
      edit: 'Edit',
      open: 'Open',
      finish: 'Finish',
      select: 'Select',
      update: 'Update',
      start: 'Start',
      reject: 'Reject',
      get: 'Get',
      calculation:'Calculation'
    },
    msg: {
      updateSuccessful: 'Update Successful',
      updateFailed: 'Update Failed',
      startSuccessful: 'Start Successful',
      startFailed: 'Start Failed',
      submitSuccessful: 'Submit Successful',
      submitFailed: 'Submit Failed',
      finishSuccessful: 'Finish Successful',
      finishFailed: 'Finish Failed',
      rejectSuccessful: 'Reject Successful',
      rejectFailed: 'Reject Failed',
      resetSuccessful: 'Reset Successful',
      resetFailed: 'Reset Failed',
      getSuccessful: 'Get Successful',
      getFailed: 'Get Failed'
    },
    tip: {
      noData: 'No Data'
    },
    username: 'Username',
    bagID: 'Bag ID',
    bagName: 'Bag Name',
    bagDescription: 'Bag Desc',
    startTimestamp: 'Start Timestamp',
    endTimestamp: 'End Timestamp',
    type: 'Type',
    labelsCount: 'Labels Count',
    annotatorID: 'Annotator ID',
    examinerID: 'Examiner ID',
    status: 'Status',
    progress: 'Progress',
    finishdate: 'Finish Date',
    accuracy: 'Accuracy',
    date: 'Date',
    user: 'User',
    group: 'Group',
    annotations: 'Annotations',
    labels: 'Labels',
    annotator: 'Annotator',
    examiner: 'Examiner',
    all: 'All',
    time:'Time',
    executor:'Executor'
  },
  login: {
    loginBtn: 'Login',
    usernamePlaceholder: 'Username / Email',
    passwordPlaceholder: 'Password',
    usernameMsg: 'Username cannot be empty',
    passwordMsg: 'Password cannot be empty',
    messages: {
      unkown: 'Unkown error.',
      noUser:
        'User does not exist. Please make sure the username or email is correct.',
      wrongPwd: 'The passoword is incorrect.'
    },
    loginFailed: 'Login failed',
    logo: 'ALLRIDE.AI'
  },
  notAuth: {
    content: 'Permission denied'
  },
  header: {
    home: 'Home',
    users: 'Users',
    annotations: 'Annotations',
    bags: 'Bags',
    statistics: 'Statistics',
    'viewer-3d': 'Viewer 3D',
    'viewer-2d': 'Viewer 2D',
    'viewer-fusion': 'Viewer Fusion',
    'viewer-cityscape': 'Viewer Cityscape',
    'viewer-signal': 'Viewer Signal',
    'viewer-3d_tracking': 'Viewer 3D Tracking',
    'viewer-2d_tracking': 'Viewer 2D Tracking',
    'viewer-link':'Joint annotation',
    groups: 'Groups',
    test: 'Test',
    profile: 'Profile',
    logout: 'Logout'
  },
  adminHome: {
    usersGroupsTitle: 'Users and Groups',
    annotationsTitle: 'Annotations',
    bagsTitle: 'Bags',
    objectTypeTitle: 'Labels',
    taskTypeTitle: 'Task Types',
    statisticsTitle: 'Statistics',
    groupsTitle: 'Groups',
    btns: {
      allUsers: 'All users',
      allGroups: 'All groups',
      allAnnotations: 'All annotations',
      allStatistics: 'All statistics',
      allBags: 'All bags',
      allObjectTypes: 'All Labels',
      allTaskTypes: 'All Task Types'
    },
    stats: {
      total: 'Total',
      groups: 'Groups'
    }
  },
  gAdminHome: {
    usersTitle: 'Users',
    annotationsTitle: 'Annotations',
    statisticsTitle: 'Statistics',
    btns: {
      allUsers: 'All users',
      allAnnotations: 'All annotations',
      allStatistics: 'All statistics'
    },
    stats: {
      total: 'Total'
    }
  },
  annotHome: {
    btns: {
      allAnnotations: 'All annotations'
    },
    stats: {
      total: 'Total'
    }
  },
  users: {
    createUserTitle: 'Create new user',
    editUserTitle: 'Edit user',
    username: 'Username',
    groupId: 'Group ID',
    role: 'Roles',
    email: 'Email',
    phone: 'Phone',
    description: 'Description',
    password: 'Password',
    activeStatus: 'Status',
    group: 'Group',
    groupUser: 'Group User',
    globalUser: 'Golbal User',
    btns: {
      edit: 'Edit',
      create: 'Create',
      update: 'Update',
      delete: 'Delete',
      reset: 'Reset',
      filter: 'Filter',
      search: 'Search'
    },
    alerts: {
      updateSuccess: 'Update success',
      updateFailure: 'Update failure',
      createSuccess: 'Create success',
      deleteSuccess: 'Delete success',
      deleteFailure: 'Delete failure',
      unkown: 'Unkown error',
      usernameExists: 'Username exists',
      emailExists: 'Email exists'
    },
    pop: {
      deleteUser: 'Are you sure delete this user?',
      okText: 'Yes',
      cancelText: 'Cancel'
    },
    tooltip: {
      create: 'New user'
    },
    plhd: {
      group: 'Select group(s)'
    },
    active: 'Active',
    inactive: 'Inactive',
    searchPlhd: 'Search username',
    noUsers: 'No users'
  },
  annot: {
    startMonth: 'Start Date',
    endMonth: 'End Date',
    finished: 'Finished',
    accuracy: 'Accuracy',
    title: {
      edit: 'Edit Annotation',
      annotatorsList: 'Annotators List',
      examinersList: 'Examiners List',
      groupsList: 'Groups List'
    },
    btn: {
      selectAll: 'Select $t(all)',
      selectAnnotator: 'Select $t(annotator)',
      selectExaminer: 'Select $t(examiner)',
      selectGroup: 'Select $t(group)',
      editAll: 'Edit $t(all)'
    },
    unlabeledPages: 'Unlabeled Pages',
    startPage: 'Start Page',
    endPage: 'End Page',
    object: 'Query Object',
    name: 'Name',
    bagDate: 'Bag Date'
  },
  annotations: {
    activeStatus: 'Active status',
    selectAnnotator: 'Select annotator',
    selectExaminer: 'Select examiner',
    selectStatus: 'Select status',
    viewProgress: 'View progress',
    startPage: 'Start page',
    endPage: 'End page',
    unlabeledAnnots: 'Unlabeled pages',
    btns: {
      start: 'Start',
      open: 'Open',
      submit: 'Submit',
      finish: 'Finish',
      update: 'Update',
      search: 'Search',
      reset: 'Reset',
      clear: 'Clear'
    },
    alert: {
      startFailure: 'Start failure',
      submitSuccess: 'Submit success',
      submitFailure: 'Submit failure',
      finishSuccess: 'Finish success',
      finishFailure: 'Finish failure',
      getSuccess: 'Get success',
      getFailure: 'Get failure',
      updateSuccess: 'Update success',
      updateFailure: 'Update failure'
    },
    messages: {
      unkown: 'unkown error',
      emptyFrame: 'This annotation has unlabeld frames'
    },
    tooltip: {
      get: 'Get task'
    },
    active: 'Active',
    inactive: 'Inactive',
    searchPlhd: 'Search username',
    noUsers: 'No users'
  },
  objectTypes: {
    createObjectTypeTitle: 'Create New Label',
    name: 'Label Name',
    size: 'Label Size',
    btns: {
      reset: 'Reset',
      search: 'Search',
      clear: 'Reset',
      update: 'Update',
      create: 'Create',
      delete: 'Delete'
    },
    alert: {
      createSuccess: 'Create success',
      createFailure: 'Create failure',
      updateSuccess: 'Update success',
      updateFailure: 'Update failure',
      deleteSuccess: 'Delete success',
      deleteFailure: 'Delete failure'
    },
    pop: {
      deleteObjectType: 'Are you sure delete this label?',
      okText: 'Yes',
      cancelText: 'Cancel'
    },
    searchPlhd: 'Search name',
    noObjectTypes: 'No Label',
    selectGroup: 'Select Group',
    tooltip: {
      create: 'New group'
    }
  },
  taskTypes: {
    createObjectTypeTitle: 'Create New Task Type',
    name: 'Task Type Name',
    description: 'Description',
    objecttypes: 'Labels',
    btns: {
      reset: 'Reset',
      search: 'Search',
      clear: 'Reset',
      update: 'Update',
      create: 'Create',
      delete: 'Delete'
    },
    alert: {
      createSuccess: 'Create success',
      createFailure: 'Create failure',
      updateSuccess: 'Update success',
      updateFailure: 'Update failure',
      deleteSuccess: 'Delete success',
      deleteFailure: 'Delete failure'
    },
    pop: {
      deleteObjectType: 'Are you sure delete this Task Type?',
      okText: 'Yes',
      cancelText: 'Cancel'
    },
    searchPlhd: 'Search name',
    noTaskTypes: 'No Task Type',
    selectGroup: 'Select Group',
    tooltip: {
      create: 'New group'
    }
  },
  bags: {
    name: 'Name',
    startTimestamp: 'Start timestamp',
    endTimestamp: 'End timestamp',
    description: 'Description',
    groupId: 'Group ID',
    group: 'Group',
    priority: 'Priority',
    status: 'Status',
    btns: {
      reset: 'Reset',
      search: 'Search',
      clear: 'Reset',
      update: 'Update',
      delete: 'Delete',
      edit: 'Edit'
    },
    alert: {
      updateSuccess: 'Update success',
      updateFailure: 'Update failure',
      deleteSuccess: 'Delete success',
      deleteFailure: 'Delete failure'
    },
    pop: {
      deleteBag: 'Are you sure delete this bag?',
      okText: 'Yes',
      cancelText: 'Cancel'
    },
    searchPlhd: 'Search name',
    noBags: 'No bags',
    selectGroup: 'Select Group',
    editBagTitle: 'Edit Bag'
  },
  groups: {
    createGroupTitle: 'Create new group',
    editGroupTitle: 'Edit group',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    description: 'Description',
    active: 'Active',
    inactive: 'Inactive',
    activeStatus: 'Status',
    btns: {
      edit: 'Edit',
      create: 'Create',
      update: 'Update',
      delete: 'Delete',
      filter: 'Filter',
      reset: 'Reset'
    },
    alerts: {
      updateSuccess: 'Update success',
      updateFailure: 'Update failure',
      createSuccess: 'Create success',
      deleteSuccess: 'Delete success',
      deleteFailure: 'Delete failure',
      unkown: 'Unkown error',
      nameExists: 'Group name exists'
    },
    pop: {
      deleteGroup: 'Are you sure delete this group?',
      okText: 'Yes',
      cancelText: 'Cancel'
    },
    tooltip: {
      create: 'New group'
    }
  },
  statistics: {
    summary: 'Summary',
    records: 'Records',
    statistics: 'Statistics',
    annotations: 'Annotations',
    labels: 'Labels',
    averageAccuracy: 'Average accuracy',
    noAnnotators: 'No annotators',
    noRecords: 'No records',
    startTime: 'Start month',
    endTime: 'End month',
    btns: {
      inquery: 'Inquery',
      search: 'Search',
      reset: 'Reset'
    },
    searchPlhd: 'Search username',
    total:'Total',
    taskDetails:'Task details',
    framesNum:'Frames number',
    boxBuiding:'Box buiding',
    workload:'workload',
    reviewFrame:'Review frame'
  },
  views: {
    labelsBox:'Label By Box',
    labelsFrame:'Label By Frame',
    labelsTab: 'Labels',
    shortcutsTab: 'Guides',
    settingsTab: 'Settings',
    relation:'relation',
    viewsName: {
      top: 'Top',
      side: 'Side',
      rear: 'Rear'
    },
    camerasName: {
      front: 'Front'
    },
    btns: {
      save: 'Save'
    },
    attrs: {
      id:'ID',
      name: 'Name',
      type: 'Type',
      position: 'Position',
      scale: 'Scale',
      dimension: 'Dimension',
      rotation: 'Rotation',
      fail: 'Fail',
      length: 'length',
      width: 'width',
      height: 'height',
      xAxis: 'x axis',
      yAxis: 'y axis',
      zAxis: 'z axis',
      pointsCount: 'Points Count'
    },
    type: {
      car: 'Car',
      pedestrian: 'Pedestrian',
      cyclist: 'Cyclist',
      bicycle: 'Bicycle',
      tricycle: 'Tricycle',
      truck: 'Truck',
      cone: 'Cone',
      trolley: 'Trolley',
      static: 'Static',
      movable: 'Movable',
    },
    info: {
      time: 'Time',
      frameStatus: 'Frame status',
      labelStatus: 'Label status',
      count: 'Labels count',
      created: 'Created',
      notCreated: 'Not created',
      ready: 'Ready',
      finish: 'Finish',
      annotationId: 'Annotation ID'
    },
    sc: {
      commond: 'Commond',
      selected: 'Label selected',
      keybinding: 'Keybinding',
      add: 'Add mode',
      remove: 'Remove mode',
      helpers: 'Toggle helpers',
      adjustingViewAngle: 'adjusting angle of view',
      camera: 'Toggle camera',
      aux: 'Toggle auxiliaries',
      frameDetail: 'Toggle frame details',
      frameStatistics:'Toggle frame statistics',
      globalView:'Switch to global default view',
      save: 'Save labels',
      next: 'Next frame',
      previous: 'Previous frame',
      move: 'Move',
      nextFive: 'Next five frame',
      firstFrame:'First frame',
      lastFrame:'Last frame',
      previousFive: 'Previous five frame',
      transform: 'Transform',
      rotate: 'Rotate',
      forward: 'forward',
      back: 'back',
      left: 'left',
      right: 'right',
      up: 'up',
      down: 'down',
      lengthen: 'lengthen',
      shorten: 'shorten',
      widen: 'widen',
      narrow: 'narrow',
      thicken: 'thicken',
      thin: 'thin',
      zcw: 'z(cw)',
      zccw: 'z(ccw)',
      zflip: 'z(flip)',
      ycw: 'y(cw)',
      yccw: 'y(ccw)',
      fail: 'Mark fail',
      pressShift: 'Press shift to adjust slightly',
      pressMouseLeft: 'Select a position, size, scaling, or rotation option, hold down the left mouse button and drag slightly to make slight adjustments',
      multipleDelete: 'Hold down shift + left mouse button to make multiple selections, then click the delete button or delete key to delete',
      pressCtrl: 'Press control to transform label bilaterally',
      transformOrigin: 'Default transform origin: left, back and bottom',
      space: 'Space',
      boxOperation:'Box operation - other',
      boxBuiding:'Frame building',
      deleteBox:'Delete box',
      InvertBox:'Invert the current box',
      viewOperation:'View operation',
      otherOperations:'Other operations',
      frameOperation:'Frame operation',
      copyBoxPrevious:'Copy the selected box to the previous frame',
      copyBoxNext:'Copy the selected box to the next frame',
      boxSelection:'Box Selection',
      selectBoxDesc:'Select only one box, and invert other boxes',
      selectAddBoxDesc:'Incrementally select a box or deselect a box',
      selectAllBoxDesc:'Select all boxes',
      fit: 'Scale to fit(`)',
      flip: 'Flip image (F)',
      hide: 'Hide label(H)',
      center: 'Align center (G)',
      fill: 'Fill label (U)',
      addAssociation:'Add association',
      deleteAssociation:'Delete association'
    },
    settings: {
      pcd: 'PCD',
      camera: 'Camera',
      pointSize: 'Point size',
      pointHue: 'Hue',
      pointSaturation: 'Saturation',
      pointLightness: 'Lightness',
      imageFlip: 'Image flip',
      frameState:'Frame state'
    },
    tooltip: {
      remove: 'Remove',
      openData: 'Open data'
    },
    alerts: {
      saveSuccess: 'Save success',
      saveFailure: 'Save failure',
      copySuccess: 'Copy success'
    },
    noLabels: 'No Objects',
    missingImage: 'Missing Image'
  },
  viewer3d: {
    msg: {
      controlLocked: 'Control Locked'
    }
  },
  viewer2d: {
    tooltip: {
      rectangle: 'Rectangle (R)',
      square: 'Square (Z)',
      polygon: 'Polygon (P)',
      fit: 'Scale to fit (S)',
      fitQuote: 'Scale to fit ( ` )',
      center: 'Align center (C)',
      flip: 'Flip image (F)',
      fill: 'Fill label (U)',
      zoom: 'Zoom',
      opacity: 'Opacity',
      car:'car(1)',
      pedestrian:'pedestrian(2)',
      cone:'cone(3)',
      cyclist:'cyclist(4)',
      truck:'truck(5)',
      bus:'bus(6)',
    },
    tab: {
      labels: 'Labels',
      guides: 'Guides',
      id:'id',
      type: 'type',
    },
    btns: {
      save: 'Save'
    },
    type: {
      up: 'Up',
      down: 'Down',
      up_down: 'Up & Down',
      left: 'Left',
      right: 'Right',
      left_right: 'Left & Right',
      single: 'No',
      red: 'Red Light',
      red_time: 'Red Time',
      green: 'Green Light',
      green_time: 'Green Time',
      yellow: 'Yellow Light',
      yellow_time: 'Yellow Time',
      black: 'No Display',
      black_time: 'No Time Display',
      car: 'Car',
      pedestrian: 'Pedestrian',
      truck: 'Truck',
      van: 'Van',
      trolley: 'Trolley',
      bus: 'Bus',
      cyclist: 'Cyclist',
      cone: 'Cone',
      barrier: 'Barrier',
      other: 'Other',
      train: 'Train',
      bicycle: 'Bicycle',
      motorcycle: 'Motorcycle',
      person: 'Person',
      rider: 'Rider',
      train_group: 'Trains',
      bicycle_group: 'Bicycles',
      motorcycle_group: 'Motorcycles',
      person_group: 'People',
      rider_group: 'Riders',
      car_group: 'Cars',
      truck_group: 'Trucks',
      bus_group: 'Buses',
    },
    alerts: {
      saveSuccess: 'Save success',
      saveFailure: 'Save failure',
      copySuccess: 'Copy success'
    },
    sc: {
      commond: 'Commond',
      selected: 'Label selected',
      keybinding: 'Keybinding',
      rectangle: 'Rectangle tool',
      fit: 'Scale to fit',
      center: 'Align center',
      flip: 'Flip image',
      fill: 'Fill label',
      move: 'Move image',
      save: 'Save labels',
      next: 'Next frame',
      prev: 'Prev frame',
      remove: 'Remove label',
      clickMove: 'Drag',
      ctxMenu: 'Context Menu',
      rightClick: 'Right Click',
      backspace: 'Backspace',
      hide: 'Hide label',
      moveUp: 'Move up',
      moveDown: 'Move down',
      moveLeft: 'Move left',
      moveRight: 'Move right',
      fail: 'Mark fail',
      rightDrag: 'Right Drag',
      space: 'Space',
    },
    info: {
      time: 'Time',
      frameStatus: 'Frame status',
      labelStatus: 'Label status',
      count: 'Labels count',
      created: 'Created',
      notCreated: 'Not created',
      ready: 'Ready',
      finish: 'Finish',
      annotationId: 'Annotation ID'
    },
    noLabels: 'No Objects',
    fail: 'Fail'
  }
}

export default en
