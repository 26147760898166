import React from 'react'
import './AttributesTable.scss'
import { withTranslation } from 'react-i18next'

import { InputNumber, IconButton, LabelType } from '../../../common'
import { MATERIAL_COLORS } from '../../constants'
import { role } from '../../../_helpers'

import { Input, Select, Divider, Checkbox } from 'antd'

const Option = Select.Option

const AttributesTable = ({ label, user, onChange, t, onFocusHandler, onBlurHandler, objectTypes, groupName, changedTypes }) => {
  return (
    <div className="views-labels-attributes">
      <div className="views-attributes-row">
        <span className="views-label-head">{t('attrs.id')}</span>
        <div className="views-label-content">
          <Input
            size="small"
            value={label.name}
            onChange={e => onChange(e.target.value.replace(/[^A-Za-z0-9\-]/g, ''), 'name')}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
          />
        </div>
      </div>
      <div className="views-attributes-row">
        <div className="views-label-head">{t('attrs.type')}</div>
        <div className="views-label-content">
          <div className="views-label-content-row">
            <Select
              size="small"
              value={label.userData.type}
              onChange={value => onChange(value, 'type')}
            >
              {
                groupName && objectTypes.length > 0 ? objectTypes.map(type => (
                  <Option value={type.name} key={type.id}>
                    <LabelType color={type.color} />
                    {t(`${type.showName}`)}
                  </Option>
                )
                ) :
                  <Select.OptGroup label="">
                    <Option value="truck">
                      <LabelType color={MATERIAL_COLORS.TRUCK} />
                      {t('type.truck')}
                    </Option>
                    <Option value="car">
                      <LabelType color={MATERIAL_COLORS.CAR} />
                      {t('type.car')}
                    </Option>
                    <Option value="pedestrian">
                      <LabelType color={MATERIAL_COLORS.PEDESTRIAN} />
                      {t('type.pedestrian')}
                    </Option>
                    <Option value="cyclist">
                      <LabelType color={MATERIAL_COLORS.CYCLIST} />
                      {t('type.cyclist')}
                    </Option>
                    <Option value="bicycle">
                      <LabelType color={MATERIAL_COLORS.BICYCLE} />
                      {t('type.bicycle')}
                    </Option>
                    <Option value="tricycle">
                      <LabelType color={MATERIAL_COLORS.TRICYCLE} />
                      {t('type.tricycle')}
                    </Option>
                    <Option value="cone">
                      <LabelType color={MATERIAL_COLORS.CONE} />
                      {t('type.cone')}
                    </Option>
                    <Option value="trolley">
                      <LabelType color={MATERIAL_COLORS.TROLLEY} />
                      {t('type.trolley')}
                    </Option>
                    <Option value="movable">
                      <LabelType color={MATERIAL_COLORS.MOVABLE} />
                      {t('type.movable')}
                    </Option>
                    <Option value="static">
                      <LabelType color={MATERIAL_COLORS.STATIC} />
                      {t('type.static')}
                    </Option>
                  </Select.OptGroup>
              }
              {changedTypes.length > 0 ? Object.values(changedTypes).map(item => (
                <Option value={item.type} key={item.id} style={{ display: 'none' }}>
                  <LabelType color={item.color} />
                  {t(`type.${item.type}`)}
                </Option>)
              ) : null}
            </Select>
          </div>
        </div>
      </div>
      <div className="views-attributes-row">
        <div className="views-label-head">
          <span className="views-label-head-plhd" />
          {t('attrs.position')}
        </div>
        <div className="views-label-content">
          <div className="views-label-content-row">
            <div className="views-label-content-mark">x</div>
            <div className="views-label-content-mark">y</div>
            <div className="views-label-content-mark">z</div>
          </div>
          <div className="views-label-content-row">
            <InputNumber
              value={label.position.x}
              onChange={value => onChange(value, 'position', 'x')}
            />
            <InputNumber
              value={label.position.y}
              onChange={value => onChange(value, 'position', 'y')}
            />
            <InputNumber
              value={label.position.z}
              onChange={value => onChange(value, 'position', 'z')}
            />
          </div>
        </div>
      </div>
      <div className="views-attributes-row">
        <div className="views-label-head">
          <span className="views-label-head-plhd" />
          {t('attrs.scale')}
        </div>
        <div className="views-label-content">
          <div className="views-label-content-row">
            <div className="views-label-content-mark">x</div>
            <div className="views-label-content-mark">y</div>
            <div className="views-label-content-mark">z</div>
          </div>
          <div className="views-label-content-row">
            <InputNumber
              value={label.scale.x}
              min="0.01"
              onChange={value => onChange(value, 'scale', 'x')}
            />
            <InputNumber
              value={label.scale.y}
              min="0.01"
              onChange={value => onChange(value, 'scale', 'y')}
            />
            <InputNumber
              value={label.scale.z}
              min="0.01"
              onChange={value => onChange(value, 'scale', 'z')}
            />
          </div>
        </div>
      </div>
      <div className="views-attributes-row">
        <div className="views-label-head">
          <span className="views-label-head-plhd" />
          {t('attrs.dimension')}
        </div>
        <div className="views-label-content">
          <div className="views-label-content-row">
            <div className="views-label-content-mark">{t('attrs.length')}</div>
            <div className="views-label-content-mark">{t('attrs.width')}</div>
            <div className="views-label-content-mark">{t('attrs.height')}</div>
          </div>
          <div className="views-label-content-row">
            <InputNumber
              value={label.userData.dimension.x}
              min="0.01"
              onChange={value => onChange(value, 'dimension', 'x')}
            />
            <InputNumber
              value={label.userData.dimension.y}
              min="0.01"
              onChange={value => onChange(value, 'dimension', 'y')}
            />
            <InputNumber
              value={label.userData.dimension.z}
              min="0.01"
              onChange={value => onChange(value, 'dimension', 'z')}
            />
          </div>
        </div>
      </div>
      <div className="views-attributes-row">
        <div className="views-label-head">
          <span className="views-label-head-plhd" />
          {t('attrs.rotation')}
        </div>
        <div className="views-label-content">
          <div className="views-label-content-row">
            <div className="views-label-content-mark">{t('attrs.zAxis')}</div>
          </div>
          <div className="views-label-content-row">
            {/* <InputNumber
          value={label.rotation.x}
          onChange={value => onChange(value, 'rotation', 'x')}
        />
        <InputNumber
          value={label.rotation.y}
          onChange={value => onChange(value, 'rotation', 'y')}
        /> */}
            <InputNumber
              value={label.rotation.z}
              max="6.28"
              min="0"
              onChange={value => onChange(value, 'rotation', 'z')}
            />
            <IconButton
              type="sync"
              onClick={() =>
                onChange(
                  label.rotation.z + 3.14 < 6.28
                    ? label.rotation.z + 3.14
                    : label.rotation.z - 3.14,
                  'rotation',
                  'z'
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="views-attributes-row">
        <div className="views-label-head">{t('attrs.pointsCount')}</div>
        <div className="views-label-content">
          <div className="views-label-content-row">
            {label.userData.pointsCount}
          </div>
        </div>
      </div>
      {[role.globalExaminer, role.groupExaminer].includes(user.role) ? (
        <>
          <Divider style={{ margin: '1.2rem 0' }} />
          <div className="views-attributes-row">
            <span className="views-label-head">{t('attrs.fail')}</span>
            <Checkbox
              checked={label.userData.fail}
              onChange={e => onChange(e.target.checked, 'fail')}
            />
          </div>
        </>
      ) : null}
    </div>
  )
}

export default withTranslation('views')(AttributesTable)
