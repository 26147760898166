import { taskTypeConstants } from '../_constants'
import { taskTypeServices } from '../_services'

export const taskTypeActions = {
  getAll,
  create,
  update,
  delete: deletetaskType
}

function getAll(params) {
  return dispatch => {
    dispatch(request())

    return taskTypeServices.getAll(params).then(
      data => {
        dispatch(success(data.tasktypes))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: taskTypeConstants.GET_ALL_REQUEST }
  }

  function success(taskTypes) {
    return { type: taskTypeConstants.GET_ALL_SUCCESS, taskTypes }
  }

  function failure() {
    return { type: taskTypeConstants.GET_ALL_FAILURE }
  }
}

function create(data) {
  return dispatch => {
    dispatch(request())

    return taskTypeServices.create(data).then(
      data => {
        dispatch(success(data.taskType))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: taskTypeConstants.CREATE_REQUEST }
  }

  function success(taskType) {
    return { type: taskTypeConstants.CREATE_SUCCESS, taskType }
  }

  function failure() {
    return { type: taskTypeConstants.CREATE_FAILURE }
  }
}

function update(data) {
  return dispatch => {
    dispatch(request())

    return taskTypeServices.update(data).then(
      data => {
        dispatch(success(data.taskType))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: taskTypeConstants.UPDATE_REQUEST }
  }

  function success(taskType) {
    return { type: taskTypeConstants.UPDATE_SUCCESS, taskType }
  }

  function failure() {
    return { type: taskTypeConstants.UPDATE_FAILURE }
  }
}

function deletetaskType(data) {
  return dispatch => {
    dispatch(request())

    return taskTypeServices.delete(data).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: taskTypeConstants.DELETE_REQUEST }
  }

  function success() {
    return { type: taskTypeConstants.DELETE_SUCCESS }
  }

  function failure() {
    return { type: taskTypeConstants.DELETE_FAILURE }
  }
}
