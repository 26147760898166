import React from 'react'
import './FrameInfo.scss'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import { Tooltip } from 'antd'

const FrameInfo = ({ frame, record, visible = true, t }) => {
  return (
    <div
      className="viewer2-frame-info"
      style={{ visibility: visible ? 'visible' : 'hidden' }}
    >
      <div className="viewer2-info-row">{`${t('info.frameStatus')}: ${
        frame ? t(`info.${frame.status}`) : '----'
      }`}</div>
      <div className="viewer2-info-row">{`${t('info.labelStatus')}: ${
        frame
          ? frame.annotationobjectsId > 0
            ? t('info.created')
            : t('info.notCreated')
          : '----'
      }`}</div>
      <div className="viewer2-info-row">
        {`${t('info.time')}: `}
        {frame ? (
          <Tooltip title={frame.timestamp}>
            <span className="viewer2-info-time">
              {moment(parseInt(frame.timestamp)).format('YYYY-MM-DD  HH:mm:ss')}
            </span>
          </Tooltip>
        ) : (
          '----'
        )}
      </div>
      <div className="viewer2-info-row">{`${t('info.annotationId')}: ${
        record ? record.id : '----'
      }`}</div>
      <div className="viewer2-info-row">{`${t('bagID')}: ${
        record ? record.bagId : '----'
      }`}</div>
    </div>
  )
}

export default withTranslation('viewer2d')(FrameInfo)
