// Constants
export const PAD = 20

export const TOOLTIP_DELAY = 0.3

export const TOOL = {
  NULL: 0,
  RECTANGLE: 1,
  POLYGON: 2
}

export const CURSOR = {
  GRAB: 'grab',
  DEFAULT: 'default',
  MOVE: 'move',
  CROSSHAIR: 'crosshair'
}

export const ZOOM = {
  MIN: 50,
  MAX: 500,
  PRECISION: 0
}

export const OPACITY = {
  MIN: 0.3,
  MAX: 1,
  PRECISION: 2
}

export const TYPE = {
  PERSON: 'person',
  RIDER: 'rider',
  BICYCLE: 'bicycle',
  MOTORCYCLE: 'motorcycle',
  CAR: 'car',
  BUS: 'bus',
  TRUCK: 'truck',
  TRAIN: 'train',
  PERSON_GROUP: 'person_group',
  RIDER_GROUP: 'rider_group',
  BICYCLE_GROUP: 'bicycle_group',
  MOTORCYCLE_GROUP: 'motorcycle_group',
  CAR_GROUP: 'car_group',
  BUS_GROUP: 'bus_group',
  TRUCK_GROUP: 'truck_group',
  TRAIN_GROUP: 'train_group',
}

// export const COLOR = {
//   PERSON: '#FF3C3C',
//   RIDER: '#FFFF00',
//   CAR: '#00BFFF',
//   TRUCK: '#00FFFF',
//   BUS: '#00FF00',
//   BICYCLE: '#FF8C00',
//   MOTORCYCLE: '#FF00FF',
//   TRAIN: '#FFFFFF',
//   PERSON_GROUP: '#8B0000',
//   RIDER_GROUP: '#808000',
//   CAR_GROUP: '#2874A6',
//   TRUCK_GROUP: '#EB984E',
//   BUS_GROUP: '#228B22',
//   BICYCLE_GROUP: '#A0522D',
//   MOTORCYCLE_GROUP: '#8B008B',
//   TRAIN_GROUP: '#C0C0C0',
// }

export const COLOR = {
  CAR: '#00BFFF',
  CAR_GROUP: '#2874A6',
  BUS: '#FF3C3C',
  BUS_GROUP: '#8B0000',
  TRUCK: '#00FF00',
  TRUCK_GROUP: '#228B22',

  PERSON: '#FF69B4',
  PERSON_GROUP: '#C71585',
  RIDER: '#FFFF00',
  RIDER_GROUP: '#FFD700',
  BICYCLE: '#FF8C00',
  BICYCLE_GROUP: '#8B4513',
  MOTORCYCLE: '#00FFFF',
  MOTORCYCLE_GROUP: '#2F4F4F',

  TRAIN: '#FFFFFF',
  TRAIN_GROUP: '#C0C0C0',
}

export const REC_THRESHOLD = 3
export const DEFAULT = {
  ZOOM_RATIO_PP: 100,
  IMAGE_OPACITY: 0.8,
  TYPE: TYPE.CAR,
  ALPHA: 0.2,
  STROKE_WIDTH: 1.5
}

export const OPEN_BASE_URL = 's3://allride.ai-cardata/car/label'
