import React, { Component } from 'react'
import './Annotations.scss'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import cn from 'classnames'

import {
  annotationActions,
  alertActions,
  userActions,
  groupActions,
  objectTypeActions
} from '../_actions'
import { getFilteredUsers, isAnnotator, isExaminer, isAdmin } from '../_utils'
import { role as ROLE } from '../_helpers/role'
import {
  STATUS_ING,
  STATUS_ED,
  TAG_COLOR,
  TYPE_COLOR,
  TYPE,
  SHOW_TYPE,
  DEFAULT_VALUE
} from './constants'

import {
  CaretRightOutlined,
  CheckOutlined,
  EditOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  StopOutlined,
  ToTopOutlined,
  SearchOutlined,
  PicRightOutlined
} from '@ant-design/icons'

import {
  Table,
  ConfigProvider,
  Button,
  Tag,
  message,
  DatePicker,
  Select,
  Statistic,
  Checkbox,
  Drawer,
  Modal,
  Dropdown,
  Menu,
  Spin,
  InputNumber,
  Tooltip, Divider, Input, Typography, Space,
  Row,
  Col
} from 'antd'
const { RangePicker } = DatePicker
const { Column } = Table
const { Option } = Select
const { Item } = Menu

const modalBodyStyle = {
  backgroundColor: '#202020'
}

const Delay = 280

const PageSize = 10

const InitPagination = {
  current: 1,
  pageSize: PageSize,
  total: 0,
  showQuickJumper: true
}

const GAdminDisabledStatuses = [
  STATUS_ING.UNASSIGNED,
  STATUS_ED.EXAMINING,
  STATUS_ED.FINISHED,
  STATUS_ED.OBJECTSREADY
]

const newMoment = new moment()

const CurrentMonthFinish = newMoment.clone().add(7,'days')
const StartMonthFinish = newMoment.clone().subtract(1, 'months')
const CurrentMonthBag = newMoment.clone().add(7,'days')
const StartMonthBag = newMoment.clone().subtract(12, 'months')

// Func
const isStatusDisabled = status => GAdminDisabledStatuses.includes(status)

class Annotations extends Component {
  state = {
    isDrawerVisible: false,
    isModalVisible: false,
    isStatModalVisible: false,
    drawerContent: 'annotator',
    idSearch: false,

    selectedRecord: {},
    selectedRowKeysObjects: [],
    selectedRowKeysAnnots: [],
    selectedTag: [],

    tags: [],
    newTag: '',
    newSearch: '',

    object: [],
    bagDate: [StartMonthBag, CurrentMonthBag],
    finishDate: [StartMonthFinish, CurrentMonthFinish],
    totalObjectscount: 0,
    avgAccuracy: 0,

    filter: {
      finished: false,
      status: Object.values(STATUS_ING),
      type: Object.values(SHOW_TYPE)
    },
    pagination: InitPagination,

    unlabeledIndices: [],
    startPage: null,
    endPage: null,
    stat: {},
  }

  componentDidMount() {
    const { user, location, dispatch } = this.props
    const from = location.state && location.state.from

    if (from && from.state) {
      const { object, bagDate, finishDate, filter, pagination } = from.state
      this.setState(
        {
          object,
          bagDate: [moment.unix(bagDate[0]), moment.unix(bagDate[1])],
          finishDate: [moment.unix(finishDate[0]), moment.unix(finishDate[1])],
          filter,
          pagination
        },
        this.getAnnotations
      )
    } else {
      this.getAnnotations()

    }
    this.getTags()
    this.getGroups()
    const curRole = user.role

    isAdmin(curRole) && dispatch(userActions.getAll(1, Number.MAX_SAFE_INTEGER))
    curRole === ROLE.admin && dispatch(groupActions.getAll())
  }

  componentDidUpdate(prevProps) {
    let { unlabeledIndices } = this.state
    const { dispatch, message: alertMessage, frames } = this.props
    // console.log(this.props)
    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 1, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 1, () =>
          dispatch(alertActions.clear())
        )
      }
    }

    if (frames.length > 0 && frames !== prevProps.frames) {
      unlabeledIndices = []
      frames.forEach((frame, index) => {
        if (!frame.annotationobjectsId || frame.annotationobjectsId < 0) {
          unlabeledIndices.push(index + 1)
        }
      })
      let unlabeledIndice = []
      let i = 0
      let right = 0, left, arrstring
      while (i < unlabeledIndices.length) {
        while (unlabeledIndices[i + 1] == unlabeledIndices[i] + 1) {
          i++
        }
        left = i
        arrstring = unlabeledIndices[right] + '~' + unlabeledIndices[left]
        console.log
        i++
        right = i
        unlabeledIndice.push(arrstring)
      }

      this.setState({ unlabeledIndices: unlabeledIndice })
    }
  }

  /** UI Handlers */
  // Drawer & Modal
  handleOpenPortal = (name, drawerContent) => {
    this.setState({ [`is${name}Visible`]: true, drawerContent })
  }

  handleClosePortal = name => {
    this.setState({ [`is${name}Visible`]: false, isStatModalVisible: false })

    name === 'Modal' &&
      setTimeout(
        () =>
          this.setState({
            selectedRecord: {},
            selectedRowKeysAnnots: [],
            unlabeledIndices: [],
            startPage: null,
            endPage: null
          }),
        Delay
      )

    name === 'Drawer' &&
      setTimeout(() => this.setState({ selectedRowKeysObjects: [] }), Delay)
  }

  handleInquery = (type) => {
    const { dispatch, t } = this.props
    if (type == 'calculation') {
      dispatch(annotationActions.calcuStats({})).then(() => {
        // dispatch(
        //   alertActions.success({
        //     type: 'success',
        //     detail: t(`msg.calculateSuccessful`)
        //   })
        // )
        message.success('最新标注数据已统计')
      })
        .catch(() => {
          dispatch(
            alertActions.error({
              type: 'error',
              detail: t(`msg.calculateSuccessful`)
            })
          )
        })
    }

  }

  handleChangeObject = ({ key }) => {
    switch (key) {
      case '1':
        this.setState({ object: [] }, this.getAnnotations)
        break
      case '2':
        this.handleOpenPortal('Drawer', 'annotator')
        break
      case '3':
        this.handleOpenPortal('Drawer', 'examiner')
        break
      case '4':
        this.handleOpenPortal('Drawer', 'group')
        break
    }
  }

  handleSelectUser = drawerContent => {
    const { selectedRecord } = this.state

    this.setState({
      selectedRowKeysObjects: [selectedRecord[`${drawerContent}Id`]]
    })
    this.handleOpenPortal('Drawer', drawerContent)
  }

  handleSelectRow = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeysObjects: [selectedRows[0].id] })
  }

  handleSelectRowAnnots = (selectedRowKeys) => {
    this.setState({ selectedRowKeysAnnots: selectedRowKeys })
  }

  handleChangeProgressInput = (value, field) => {
    this.setState({ [field]: value })
  }

  /** Action Handlers */
  // Filters
  handleQueryObject = () => {
    const { selectedRowKeysObjects, drawerContent } = this.state

    this.setState(
      { object: [selectedRowKeysObjects[0], drawerContent] },
      this.getAnnotations
    )
  }

  handleChangeFilter = (value, key) => {
    let { filter } = this.state
    filter[key] = value
    if (key === 'finished') {
      // A little hack here to eliminate flicking when status is changing
      filter.status = []
      this.setState({ filter })

      setTimeout(() => {
        filter.status = Object.values(value ? STATUS_ED : STATUS_ING)

        this.setState(
          { filter, pagination: InitPagination },
          this.getAnnotations
        )
      }, Delay)
    } else {
      this.setState({ filter, pagination: InitPagination }, this.getAnnotations)
    }
  }

  handleChangeDates = (value, dateName) => {
    this.setState(
      { [`${dateName}Date`]: value, pagination: InitPagination },
      this.getAnnotations
    )
  }

  onNewSearch = event => {
    this.setState({ newSearch: event.target.value.toString() })
  }

  onHandleSearch = () => {
    //传给后端数据
    this.setState({ pagination: InitPagination }, this.getAnnotations)
  }

  // Table
  handleTableChange = (pagination, filters, sorter) => {
    const { object, bagDate, finishDate, filter, idSearch } = this.state
    let { location, history } = this.props
    // console.log('ta')
    const stringBagDate = [bagDate[0].unix(), bagDate[1].unix()]
    const stringFinishDate = [finishDate[0].unix(), finishDate[1].unix()]

    location.state = {
      object,
      bagDate: stringBagDate,
      finishDate: stringFinishDate,
      filter,
      pagination
    }

    history.replace({
      pathname: location.pathname,
      state: {
        from: location
      }
    })
    if (idSearch) {
      this.setState({ idSearch: false })
    } else {
      this.setState({ pagination }, this.getAnnotations)
    }


  }

  handleUpdateUser = clear => {
    let {
      selectedRecord,
      selectedRowKeysObjects,
      selectedRowKeysAnnots,
      drawerContent
    } = this.state

    const userId = clear ? -1 : selectedRowKeysObjects[0]

    let data = { [`${drawerContent}Id`]: userId }
    let updateAction = annotationActions.updateAnnotation

    if (selectedRecord.id) {
      data.id = selectedRecord.id
    } else {
      data.ids = selectedRowKeysAnnots
      updateAction = annotationActions.updateAllAnnotations
    }

    this.dispatchAndUpdate(updateAction(data), 'update', () => {
      selectedRecord[`${drawerContent}Id`] = userId

      this.setState({ selectedRecord })
      clear && this.setState({ selectedRowKeysObjects: [] })
    })
  }

  handleUpdateStatus = value => {
    const { selectedRowKeysAnnots, selectedRecord } = this.state

    let data = { status: value }
    let updateAction = annotationActions.updateAnnotation

    if (selectedRecord.id) {
      data.id = selectedRecord.id
    } else {
      data.ids = selectedRowKeysAnnots
      updateAction = annotationActions.updateAllAnnotations
    }

    this.dispatchAndUpdate(updateAction(data), 'update', () => {
      selectedRecord.status = value
      this.setState({ selectedRecord })
    })
  }

  handleUpdateGroup = value => {
    const { dispatch, t } = this.props
    const { selectedRecord, selectedRowKeysAnnots } = this.state
    let data = { groupName: value ? value : 'none' }

    if (selectedRecord.id) {
      data.id = selectedRecord.id
      dispatch(annotationActions.updateTag(data)).then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t(`msg.updateSuccessful`)
          })
        )
        selectedRecord.groupName = value
        this.setState({ selectedRecord })
        this.getAnnotations()
      })
        .catch(e => {
          dispatch(
            alertActions.error({
              type: 'error',
              detail: t(`msg.updateFailed`)
            })
          )
        })
    } else {
      data.ids = selectedRowKeysAnnots
      dispatch(annotationActions.updateAllAnnotations(data)).then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t(`msg.updateSuccessful`)
          })
        )
        selectedRecord.groupName = value
        this.setState({ selectedRecord })
        this.getAnnotations()
      })
        .catch(e => {
          dispatch(
            alertActions.error({
              type: 'error',
              detail: t(`msg.updateFailed`)
            })
          )
        })
    }
  }

  handleUpdateTag = value => {
    const { dispatch, t } = this.props
    const { selectedTag, selectedRecord, selectedRowKeysAnnots } = this.state
    let tags = value.length !== 0 ? value.join(',') : 'none'
    let data = { tag: tags }

    if (selectedRecord.id) {
      data.id = selectedRecord.id
      dispatch(annotationActions.updateTag(data)).then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t(`msg.updateSuccessful`)
          })
        )
        selectedRecord.tag = tags
        this.setState({ selectedRecord })
        this.getAnnotations()
      })
        .catch(e => {
          dispatch(
            alertActions.error({
              type: 'error',
              detail: t(`msg.updateFailed`)
            })
          )
        })
    } else {
      data.ids = selectedRowKeysAnnots
      dispatch(annotationActions.updateAllAnnotations(data)).then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t(`msg.updateSuccessful`)
          })
        )
        selectedRecord.tag = tags
        this.setState({ selectedRecord })
        this.getAnnotations()
      })
        .catch(e => {
          dispatch(
            alertActions.error({
              type: 'error',
              detail: t(`msg.updateFailed`)
            })
          )
        })
    }
  }

  onNewTag = (event) => {
    this.setState({ newTag: event.target.value })
    // console.log('newtag', event.target.value)
  }

  addTag = (event) => {
    const { dispatch, t } = this.props
    let { newTag } = this.state
    let flag = true
    let { tags } = this.props

    event.preventDefault()
    // console.log('addtag', this.state.newTag)
    //给后端传新tag
    // console.log(new Set(tags))
    tags.map(tag => {
      if (tag.tag == newTag
      )
        flag = false
    })
    if (flag) {
      if (!newTag) {
        dispatch(
          alertActions.success({
            type: 'error',
            detail: t(`msg.createFailed`) + '，不能添加空标签'
          })
        )
      } else {
        dispatch(annotationActions.createTag(newTag))
          .then(() => {
            dispatch(
              alertActions.success({
                type: 'success',
                detail: t(`msg.createSuccessful`)
              })
            )
            //再请求一次tag接口
            this.getTags()
          })
          .catch(e => {
            dispatch(
              alertActions.error({
                type: 'error',
                detail: t(`msg.createFailed`)
              })
            )
            this.getTags()
          })
      }

    } else {
      dispatch(
        alertActions.error({
          type: 'error',
          detail: t(`msg.createFailed`) + '，该标签已存在'
        })
      )
    }

  }

  handleEdit = record => {
    this.setState({ selectedRecord: record }, this.getFrames)

    this.handleOpenPortal('Modal')
  }

  handleEditAll = () => {
    this.handleOpenPortal('Modal')
  }

  handleStart = record => {
    const { user, history } = this.props

    const data = {
      id: record.id,
      status: isAnnotator(user.role)
        ? STATUS_ING.ANNOTATING
        : STATUS_ED.EXAMINING
    }

    this.dispatchAndUpdate(
      annotationActions.updateAnnotation(data),
      'start',
      () => history.push(this.getNavObject(record))
    )
  }

  handleSubmit = record => {
    const data = { id: record.id, status: STATUS_ED.UNEXAMNIED }

    this.dispatchAndUpdate(annotationActions.updateAnnotation(data), 'submit')
  }

  handleFinish = record => {
    const data = { id: record.id, status: STATUS_ED.FINISHED }

    this.dispatchAndUpdate(annotationActions.updateAnnotation(data), 'finish')
  }

  handleReject = record => {
    const data = { id: record.id, status: STATUS_ING.ANNOTATING }

    this.dispatchAndUpdate(annotationActions.updateAnnotation(data), 'reject')
  }

  handleStat = record => {
    const { dispatch } = this.props
    this.setState({ selectedRecord: record, isStatModalVisible: true })
    dispatch(annotationActions.getStat({ annotation_id: record.id })).then(() => {
      const { stat } = this.props
      this.setState({ stat: { ...stat, finishdate: moment.unix(parseInt(stat.finishdate)).format('YYYY-MM-DD kk:mm:ss'), } })
    })


    // this.handleOpenPortal('Modal')
  }

  handleResetProgress = () => {
    const { selectedRecord, startPage, endPage } = this.state
    const { frames, dispatch } = this.props

    const startIndex = (startPage >= 1 ? startPage : 1) - 1
    const endIndex = (endPage <= frames.length ? endPage : frames.length) - 1

    const data = {
      bag_id: selectedRecord.bagId,
      start_timestamp: frames[startIndex].timestamp,
      end_timestamp: frames[endIndex].timestamp
    }

    this.dispatchAndUpdate(annotationActions.resetFrame(data), 'reset', () => {
      dispatch(
        annotationActions.updateAnnotation({
          id: selectedRecord.id,
          progress: `-- / ${frames.length}`
        })
      )

      this.getFrames()
    })
  }

  handleAssign = () => {
    const { user } = this.props

    const data = { userId: user.id, role: user.role }

    this.dispatchAndUpdate(annotationActions.assignAnnotation(data), 'get')
  }

  dispatchAndUpdate = (action, message, successCallback, errorCallback) => {
    const { dispatch, t } = this.props

    dispatch(action)
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t(`msg.${message}Successful`)
          })
        )
        this.getAnnotations()

        successCallback && successCallback()
      })
      .catch(e => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t(`msg.${message}Failed`)
          })
        )

        errorCallback && errorCallback()
      })
  }

  /** Others */
  getAnnotations = async (value, confirm) => {
    let { object, bagDate, finishDate, filter, pagination, newSearch, idSearch } = this.state
    const { dispatch, user } = this.props
    if (value) {
      pagination.current = 1
      // this.setState({ searchText: selectedKeys[0] })
      let params = { id: value }
      dispatch(annotationActions.getAllAnnotations(params)).then(
        ({ totalCount, totalObjectscount, avgAccuracy }) => {
          pagination.total = totalCount

          this.setState({ totalObjectscount, avgAccuracy, pagination, idSearch: true })
        }
      )
      return
    }
    let params = {
      startTimestampStart: filter.finished
        ? 0
        : bagDate[0].startOf('day').valueOf(),
      startTimestampEnd: filter.finished
        ? CurrentMonthFinish.endOf('day').valueOf()
        : bagDate[1].endOf('day').valueOf(),
      finishdateStart: filter.finished
        ? finishDate[0].startOf('day').unix()
        : 0,
      finishdateEnd: filter.finished
        ? finishDate[1].endOf('day').unix()
        : CurrentMonthFinish.endOf('day').unix(),
      status: filter.status,
      type: filter.type,
      page: pagination.current,
      pagesize: pagination.pageSize,
      print: [newSearch + 'p']
    }

    if (object.length) {
      params[`${object[1]}Id`] = object[0]
    }

    isAnnotator(user.role) && (params.annotatorId = user.id)
    isExaminer(user.role) && (params.examinerId = user.id)

    dispatch(annotationActions.getAllAnnotations(params)).then(
      ({ totalCount, totalObjectscount, avgAccuracy }) => {
        pagination.total = totalCount

        this.setState({ totalObjectscount, avgAccuracy, pagination })
      }
    )
  }

  getTags = () => {
    const { dispatch } = this.props
    dispatch(
      annotationActions.getAllTags()
    ).then(
      () => {
      }
    )
  }

  getGroups = () => {
    const { dispatch } = this.props
    dispatch(
      objectTypeActions.getGroup()
    ).then(
      () => {
      }
    )
  }

  getFrames = () => {
    const { selectedRecord } = this.state
    const { dispatch } = this.props

    dispatch(
      annotationActions.getAllFrames(
        selectedRecord.bagId,
        selectedRecord.startTimestamp,
        selectedRecord.endTimestamp,
        ['ready', 'finish']
      )
    )
  }

  getStatusTag = status => {
    const { t } = this.props

    return <Tag color={TAG_COLOR[status]}>{t(`statusText.${status}`)}</Tag>
  }

  getTypesTag = types => {
    const { t } = this.props
    return <Tag color={TYPE_COLOR[types]} style={{color:'black'}}>{t(`typesText.${types}`)}</Tag>
  }

  getName = text => {
    if (text && text !== 'none') {
      return text
    } else {
      return DEFAULT_VALUE
    }
  }

  getUserDetail = text =>{
    if(text){
      const {users,groups}=this.props
      let userName,userGroup
      for(let i in users){
        if(users[i].id==text){
          userName=users[i].username||DEFAULT_VALUE
          userGroup=users[i].groupId||'--'
        }
      }
      if(userGroup!=='--'){
        for(let i in groups){
          if(groups[i].id==userGroup){
            userGroup=groups[i].name
          }
        }
      }
      return <Tooltip title={`${userName}(${userGroup})`}>{text}</Tooltip>
    }else{
      return DEFAULT_VALUE
    }
  }

  getNavObject = record => {
    const { object, bagDate, finishDate, filter, pagination } = this.state

    const stringBagDate = [bagDate[0].unix(), bagDate[1].unix()]
    const stringFinishDate = [finishDate[0].unix(), finishDate[1].unix()]
    return {
      pathname: `/annotations/viewer-${record.type}`,
      state: {
        record,
        object,
        bagDate: stringBagDate,
        finishDate: stringFinishDate,
        filter,
        pagination
      },
      search: `id=${record.id}`
    }
  }

  handleSearch = (value, confirm) => {
    const { dispatch, t } = this.props
    const { pagination } = this.state
    confirm()
    // this.setState({ searchText: selectedKeys[0] })
    let params = { id: value }
    dispatch(annotationActions.getAllAnnotations(params)).then(
      ({ totalCount, totalObjectscount, avgAccuracy }) => {
        pagination.total = totalCount

        this.setState({ totalObjectscount, avgAccuracy, pagination })
      }
    )

  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  getColumnSearchProps = dataIndex => {
    const { t } = this.props

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: '0.5rem'}}>
          <Input
            ref={node => {
              this.searchInput = node
            }}
            // placeholder={t('searchPlhd')}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.getAnnotations(selectedKeys[0], confirm)}
            style={{ width: 188, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t('btn.reset')}
          </Button>
          <Button
            type="primary"
            onClick={() => this.getAnnotations(selectedKeys[0], confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {t('btn.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined
          }} />
      ),
      //   onFilter: (value, record) =>{
      //     const {dispatch,t} = this.props
      //     const {pagination} = this.state

      // let params={id:value}
      // dispatch(annotationActions.getAllAnnotations(params)).then(
      //   ({ totalCount, totalObjectscount, avgAccuracy }) => {
      //     pagination.total = totalCount

      //     this.setState({ totalObjectscount, avgAccuracy, pagination })
      //   }
      // )

      //   }
      //     ,
      //   onFilterDropdownVisibleChange: visible => {
      //     if (visible) {
      //       setTimeout(() => this.searchInput.select())
      //     }
      //   },
      render: text => text.toString()
    }
  }

  render() {
    const {
      isDrawerVisible,
      isModalVisible,
      drawerContent,
      selectedRowKeysObjects,
      selectedRowKeysAnnots,
      selectedRecord,
      object,
      bagDate,
      finishDate,
      totalObjectscount,
      avgAccuracy,
      filter,
      pagination,
      unlabeledIndices,
      startPage,
      endPage,
      isStatModalVisible,
      stat
    } = this.state
    const {
      user,
      users,
      groups,
      annotations,
      frames,
      gettingAnnotations,
      updatingAnnotation,
      gettingFrames,
      tags,
      t,
      objectTypesgroups
    } = this.props
    const curRole = user.role
    const realTags = () => {
      var newArr = []
      var arrId = []
      for (var item of tags) {
        if (arrId.indexOf(item.tag) == -1) {
          arrId.push(item.tag)
          newArr.push(item)
        }
      }
      return newArr
    }

    let newTagValue

    const rowSelectionObjects = {
      type: 'radio',
      columnWidth: '30px',
      selectedRowKeys: selectedRowKeysObjects,
      onChange: this.handleSelectRow
    }

    const rowSelectionAnnots = isAdmin(curRole)
      ? {
        columnWidth: '30px',
        fixed: true,
        selectedRowKeys: selectedRowKeysAnnots,
        onChange: this.handleSelectRowAnnots,
        preserveSelectedRowKeys: true
      }
      : null

    let drawerDataSource
    switch (drawerContent) {
      case 'annotator':
      case 'examiner':
        drawerDataSource = getFilteredUsers(users, drawerContent, true)
        break
      case 'group':
        drawerDataSource = groups
        break
      default:
        drawerDataSource = []
        break
    }

    const drawerDataName = drawerContent === 'group' ? 'name' : 'username'

    const renderEmpty = () => (
      <div className="annot-empty">{t('tip.noData')}</div>
    )

    const menu = (
      <Menu onClick={this.handleChangeObject}>
        <Item key={1}>{t('btn.selectAll')}</Item>
        <Item key={2}>{t('btn.selectAnnotator')}</Item>
        <Item key={3}>{t('btn.selectExaminer')}</Item>
        {curRole === ROLE.admin ? (
          <Item key={4}>{t('btn.selectGroup')}</Item>
        ) : null}
      </Menu>
    )

    return (
      <div className="annot">
        <div className="annot-filter">
          {isAdmin(curRole) ? (
            <>
              <h3 className="annot-filter-title">{t('object')}</h3>
              <Dropdown overlay={menu}>
                <Button size="small">
                  {object.length ? `${t(object[1])}: ${object[0]}` : t('all')}
                </Button>
              </Dropdown>
            </>
          ) : null}
          <h3 className="annot-filter-title">{t('type')}</h3>
          <Select
            value={filter.type}
            mode="multiple"
            onChange={value => this.handleChangeFilter(value, 'type')}
            style={{ minWidth: '5rem' }}
            size="small"
          >
            {Object.values(TYPE).map(type => (
              <Option key={type}>{t(`annotTypeText.${type}`)}</Option>
            ))}
          </Select>
          <h3 className="annot-filter-title">{t('status')}</h3>
          <div className="annot-filter-status">
            <Select
              value={filter.status}
              mode="multiple"
              onChange={value => this.handleChangeFilter(value, 'status')}
              style={{ minWidth: '5rem' }}
              size="small"
            >
              {Object.values(STATUS_ING).map(status => (
                <Option disabled={filter.finished} key={status}>
                  {t(`statusText.${status}`)}
                </Option>
              ))}
              {Object.values(STATUS_ED).map(status => (
                // <Option disabled={!filter.finished} key={status}>
                <Option key={status}>{t(`statusText.${status}`)}</Option>
              ))}
            </Select>
            <span className="annot-filter-tip">{t('finished')}</span>
            <Checkbox
              checked={filter.finished}
              onChange={e =>
                this.handleChangeFilter(e.target.checked, 'finished')
              }
            />
          </div>
          <h3 className="annot-filter-title">{t('bagDate')}</h3>
          <div className="annot-filter-date">
            <RangePicker
              placeholder={[t('startMonth'), t('endMonth')]}
              allowClear={false}
              disabled={filter.finished}
              value={bagDate}
              mode={[]}
              onChange={value => this.handleChangeDates(value, 'bag')}
              size="small"
            />
          </div>
          <h3 className="annot-filter-title">{t('finishdate')}</h3>
          <div className="annot-filter-date">
            <RangePicker
              placeholder={[t('startMonth'), t('endMonth')]}
              allowClear={false}
              disabled={!filter.finished}
              value={finishDate}
              mode={[]}
              onChange={value => this.handleChangeDates(value, 'finish')}
              size="small"
            />
          </div>
          <h3 className="annot-filter-title">{t('search')}</h3>
          <div className="annot-filter-date" style={{ display: 'flex' }}>
            <Input onChange={this.onNewSearch} size="small" onPressEnter={this.onHandleSearch} />
            <Button shape='circle' size="small" type='primary' icon={<SearchOutlined />} style={{ marginLeft: '1rem' }} ghost onClick={this.onHandleSearch} />
          </div>
        </div>
        <div className="annot-stats">
          <Statistic
            title={<h3 className="annot-stats-title">{t('annotations')}</h3>}
            value={pagination.total}
          />
          <Statistic
            title={<h3 className="annot-stats-title">{t('labels')}</h3>}
            value={totalObjectscount}
          />
          <Statistic
            title={<h3 className="annot-stats-title">{t('accuracy')}</h3>}
            suffix="%"
            value={
              avgAccuracy ? (avgAccuracy * 100).toPrecision(5) : DEFAULT_VALUE
            }
          />
        </div>
        <div className="annot-table">
          <div className="annot-table-wrapper">
            {isAdmin(curRole) ? (
              <div className="annot-table-topbar">
                <Button
                  type="primary"
                  size="small"
                  disabled={!selectedRowKeysAnnots.length}
                  onClick={this.handleEditAll}
                >
                  {`${t('btn.editAll')} (${selectedRowKeysAnnots.length})`}
                </Button>
              </div>
            ) : null}
            <ConfigProvider renderEmpty={renderEmpty}>
              <Table
                dataSource={annotations}
                rowSelection={rowSelectionAnnots}
                loading={gettingAnnotations}
                scroll={{ x: true }}
                pagination={pagination}
                onChange={this.handleTableChange}
                size="small"
                rowKey="id"
              >
                <Column title="ID" dataIndex="id" key="id" {...isAdmin(curRole)?this.getColumnSearchProps('id'):null} />
                <Column title="Bag ID" dataIndex="bagId" key="bagId" />
                <Column
                  title={t('bagName')}
                  dataIndex="bagName"
                  key="bagName"
                />
                <Column
                  title={t('bagDescription')}
                  dataIndex="description"
                  key="description"
                  render={text => text || DEFAULT_VALUE}
                />
                {/* <Column
                  title={t('startTimestamp')}
                  dataIndex="startTimestamp"
                  key="startTimestamp"
                />
                <Column
                  title={t('endTimestamp')}
                  dataIndex="endTimestamp"
                  key="endTimestamp"
                /> */}
                <Column
                  title={t('type')}
                  dataIndex="type"
                  key="type"
                  render={text => this.getTypesTag(text)} />
                <Column
                  title={'Label'}
                  dataIndex="groupName"
                  key="groupName"
                  render={text => this.getName(text)}
                />
                <Column
                  title={'Tag'}
                  dataIndex="tag"
                  key="tag"
                  render={text => this.getName(text)}
                />

                {isAdmin(curRole) || isExaminer(curRole) ? (
                  <Column
                    title={t('annotator')}
                    dataIndex="annotatorId"
                    key="annotatorId"
                    render={text => this.getUserDetail(text)}
                  />
                ) : null}
                {isAdmin(curRole) ? (
                  <Column
                    title={t('examiner')}
                    dataIndex="examinerId"
                    key="examinerId"
                    render={text => this.getUserDetail(text)}
                  />
                ) : null}
                <Column
                  title={t('status')}
                  dataIndex="status"
                  key="status"
                  render={text => this.getStatusTag(text)}
                />
                <Column
                  title={t('progress')}
                  dataIndex="progress"
                  key="progress"
                  render={text => text || DEFAULT_VALUE}
                />
                <Column
                  title={t('finishdate')}
                  dataIndex="finishdate"
                  key="finishdate"
                  render={text =>
                    text
                      ? moment.unix(text).format('YYYY-MM-DD')
                      : DEFAULT_VALUE
                  }
                />
                <Column
                  title={t('labelsCount')}
                  dataIndex="objectscount"
                  key="objectscount"
                  render={text => text || DEFAULT_VALUE}
                />
                <Column
                  title={t('accuracy')}
                  dataIndex="accuracy"
                  key="accuracy"
                  render={text =>
                    text ? `${(text * 100).toPrecision(5)}%` : DEFAULT_VALUE
                  }
                />
                <Column
                  key="action"
                  title={
                    isExaminer(curRole) ? (
                      <div className="annot-table-actions">
                        <Tooltip title={t('btn.get')}>
                          <Button
                            icon={<PlusOutlined />}
                            size="small"
                            type="primary"
                            onClick={this.handleAssign}
                          />
                        </Tooltip>
                      </div>
                    ) : null
                  }
                  fixed="right"
                  render={(text, record) => {
                    const EditBtn = props => (
                      <Tooltip title={t('btn.edit')}>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => this.handleEdit(record)}
                          icon={<EditOutlined />}
                          {...props}
                        ></Button>
                      </Tooltip>
                    )

                    const OpenBtn = props => (
                      <Tooltip title={t('btn.open')}>
                        <Link to={this.getNavObject(record)}>
                          <Button
                            type="primary"
                            size="small"
                            ghost
                            icon={<FolderOpenOutlined />}
                            {...props}
                          ></Button>
                        </Link>
                      </Tooltip>
                    )

                    const StartBtn = props => (
                      <Tooltip title={t('btn.start')}>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => this.handleStart(record)}
                          ghost
                          icon={<CaretRightOutlined />}
                          {...props}
                        ></Button>
                      </Tooltip>
                    )

                    const SubmitBtn = props => (
                      <Tooltip title={t('btn.submit')}>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => this.handleSubmit(record)}
                          icon={<ToTopOutlined />}
                          {...props}
                        ></Button>
                      </Tooltip>
                    )

                    const FinishBtn = props => (
                      <Tooltip title={t('btn.finish')}>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => this.handleFinish(record)}
                          icon={<CheckOutlined />}
                          {...props}
                        ></Button>
                      </Tooltip>
                    )

                    const RejectBtn = props => (
                      <Tooltip title={t('btn.reject')}>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => this.handleReject(record)}
                          icon={<StopOutlined />}
                          {...props}
                        ></Button>
                      </Tooltip>
                    )

                    const StatBtn = props => (
                      <Tooltip title={t('btn.stat')}>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => this.handleStat(record)}
                          icon={<PicRightOutlined />}
                          {...props}
                        ></Button>
                      </Tooltip>
                    )

                    let btns = null

                    if (isAnnotator(curRole)) {
                      switch (record.status) {
                        case STATUS_ING.ASSIGNED:
                          btns = <StartBtn />
                          break
                        case STATUS_ING.ANNOTATING:
                          btns = (
                            <>
                              <SubmitBtn />
                              <OpenBtn />
                            </>
                          )
                          break
                        default:
                          btns = <OpenBtn disabled />
                          break
                      }
                    }

                    if (isExaminer(curRole)) {
                      switch (record.status) {
                        case STATUS_ED.UNEXAMNIED:
                          btns = <StartBtn />
                          break
                        case STATUS_ED.EXAMINING:
                          btns = (
                            <>
                              <FinishBtn />
                              <RejectBtn />
                              <OpenBtn />
                            </>
                          )
                          break
                        case STATUS_ED.FINISHED:
                          btns = <OpenBtn />
                          break
                        case STATUS_ED.OBJECTSREADY:
                          btns = (<>
                            <OpenBtn disabled />
                            <StatBtn />
                          </>)
                        default:
                          btns = <OpenBtn disabled />
                          break
                      }
                    }

                    if (isAdmin(curRole)) {
                      btns = (
                        <>
                          <EditBtn />
                          <OpenBtn />
                          {record.status == STATUS_ED.OBJECTSREADY ? <StatBtn /> : null}
                        </>
                      )
                    }

                    return <div className="annot-table-actions">{btns}</div>
                  }}
                />
              </Table>
            </ConfigProvider>
          </div>
        </div>
        <Modal
          title={t('title.stat')}
          visible={isStatModalVisible}
          bodyStyle={modalBodyStyle}
          footer={null}
          onCancel={() => this.handleClosePortal('Modal')}
        >
          <div className="annot-stat">

            <div className="annot-stat-row">
              <h3>{t('finishDate')}：{stat.finishdate}</h3>
              <Row>
                <Col span={12}>{t('framesNum')}：{stat.frames || 0}</Col>
                <Col span={12}>{t('boxesNum')}：{stat.o || 0}</Col>
              </Row>
            </div>
            <div className="annot-stat-row">
              <h3>{t('annotator')}：{stat.annotatorName}（{stat.annotatorGroup || '暂无'}）</h3>
              <Row>
                <Col span={12}>{t('sumitBoxes')}：{stat.i || 0}</Col>
                <Col span={12}>{t('validBoxes')}：{stat.annotatorBuildCount || 0}</Col>
              </Row>
            </div>
            <div className="annot-stat-row">
              <h3>{t('examiner')}：{stat.examinerName}（{stat.examinerGroup || '暂无'}）</h3>
              <Row>
                <Col span={12}>{t('fillBoxes')}：{stat.examinerBuildCount || 0}</Col>
                <Col span={12}>{t('adjustBoxes')}：{stat.examinerExamCount || 0}</Col>
              </Row>
            </div>
            <div className="annot-stat-row">
              {isAdmin(curRole) ? <Button type='primary'
                onClick={() => this.handleInquery('calculation')}
              >
                {t('btn.calculation')}
              </Button> : null}
            </div>
          </div>
        </Modal>
        <Modal
          title={t('title.edit')}
          visible={isModalVisible}
          bodyStyle={modalBodyStyle}
          footer={null}
          onCancel={() => this.handleClosePortal('Modal')}
          forceRender
        >
          <div className="annot-edit">
            <div className="annot-edit-row">
              <label>{t('startTimestamp')}</label>
              <div className="annot-edit-user">
                {selectedRecord.startTimestamp}
              </div>
              <label>{t('endTimestamp')}</label>
              <div className="annot-edit-user">
                {selectedRecord.endTimestamp}
              </div>
              <label>{t('annotator')}</label>
              <div className="annot-edit-user">
                <Button
                  size="small"
                  onClick={() => this.handleSelectUser('annotator')}
                >
                  {selectedRecord.annotatorId > 0
                    ? selectedRecord.annotatorId
                    : t('btn.select')}
                </Button>
              </div>
              <label>{t('examiner')}</label>
              <div className="annot-edit-user">
                <Button
                  size="small"
                  onClick={() => this.handleSelectUser('examiner')}
                >
                  {selectedRecord.examinerId > 0
                    ? selectedRecord.examinerId
                    : t('btn.select')}
                </Button>
              </div>

              <label>{t('status')}</label>
              <Select
                value={selectedRecord.status}
                onChange={value => this.handleUpdateStatus(value)}
                style={{ minWidth: '3rem' }}
                size="small"
              >
                {Object.values(STATUS_ING)
                  .concat(Object.values(STATUS_ED))
                  .map(status => (
                    <Option
                      key={status}
                      disabled={
                        curRole === ROLE.groupAdmin && isStatusDisabled(status)
                      }
                    >
                      {t(`statusText.${status}`)}
                    </Option>
                  ))}
              </Select>

              {curRole === 'admin' ? (<>
                <label>{'Label'}</label>
                <Select
                  value={(selectedRecord.groupName !== 'none' && selectedRecord.groupName) ? selectedRecord.groupName : null}
                  onChange={value => this.handleUpdateGroup(value)}
                  style={{ minWidth: '3rem' }}
                  size="small"
                  allowClear
                >
                  {Object.values(objectTypesgroups)
                    .map(group => (
                      group.status == 'active' ?
                        (<Option
                          key={group.groupName}
                        // disabled={
                        //   curRole === ROLE.groupAdmin && isStatusDisabled(status)
                        // }
                        >
                          {t(`${group.groupName}`)}
                        </Option>) :
                        null
                    ))}
                </Select></>) : null}</div>
            <div className='annot-edit-arow'>
              {curRole === 'admin' ? (<>
                <label>{'Tag'}</label>
                <Select
                  value={(selectedRecord.tag !== 'none' && selectedRecord.tag) ? selectedRecord.tag.split(',') : []}
                  mode='multiple'
                  onChange={value => this.handleUpdateTag(value)}
                  style={{ minWidth: '3rem' }}
                  size="small"
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space align="center" style={{ padding: '0 8px 4px' }}>
                        <Input placeholder="请输入新标签" onChange={this.onNewTag} value={newTagValue} />
                        <Typography.Link style={{ whiteSpace: 'nowrap' }} onClick={this.addTag}>
                          <PlusOutlined /> 添加新标签
                        </Typography.Link>
                      </Space>
                    </>
                  )}
                >
                  {Object.values(realTags())
                    .map(tag => (
                      tag.tag ?
                        (<Option
                          key={tag.tag}
                        // disabled={
                        //   curRole === ROLE.groupAdmin && isStatusDisabled(status)
                        // }
                        >
                          {t(`${tag.tag}`)}
                        </Option>) :
                        null
                    ))}
                </Select>
              </>) : null}
              {selectedRecord.id ? (
                <>
                  <label>{t('unlabeledPages')}</label>
                  <Spin spinning={!!gettingFrames}>
                    <div className="annot-unlabeled">
                      <div className="annot-unlabeled-chart">
                        {unlabeledIndices.join(', ')}
                      </div>
                      <div className="annot-unlabeled-range">
                        <InputNumber
                          value={startPage}
                          min={1}
                          max={frames.length + 1}
                          onChange={value =>
                            this.handleChangeProgressInput(value, 'startPage')
                          }
                          size="small"
                          placeholder={t('startPage')}
                        />
                        <span>-</span>
                        <InputNumber
                          value={endPage}
                          min={1}
                          max={frames.length + 1}
                          onChange={value =>
                            this.handleChangeProgressInput(value, 'endPage')
                          }
                          size="small"
                          placeholder={t('endPage')}
                        />
                        <Button
                          type="primary"
                          onClick={() => this.handleResetProgress()}
                          loading={updatingAnnotation}
                          disabled={!(endPage && startPage)}
                          size="small"
                        >
                          {t('btn.reset')}
                        </Button>
                      </div>
                    </div>
                  </Spin>
                </>
              ) : null}
            </div>
          </div>
        </Modal>
        <Drawer
          title={t(`title.${drawerContent}sList`)}
          visible={isDrawerVisible}
          drawerStyle={modalBodyStyle}
          onClose={() => this.handleClosePortal('Drawer')}
        >
          <ConfigProvider renderEmpty={renderEmpty}>
            <Table
              dataSource={drawerDataSource}
              rowSelection={rowSelectionObjects}
              size="small"
              rowKey="id"
            >
              <Column title="ID" dataIndex="id" key="id" />
              <Column
                title={t(drawerDataName)}
                dataIndex={drawerDataName}
                key={drawerDataName}
              // {...this.getColumnSearchProps('username')}
              />
            </Table>
          </ConfigProvider>
          <div
            className={cn('annot-drawer-actions', {
              one: !(selectedRecord.id || selectedRowKeysAnnots.length)
            })}
          >
            {selectedRecord.id || selectedRowKeysAnnots.length ? (
              <>
                <Button
                  size="small"
                  onClick={() => this.handleUpdateUser(true)}
                >
                  {t('btn.clear')}
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => this.handleUpdateUser()}
                >
                  {t('btn.update')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  size="small"
                  onClick={this.handleQueryObject}
                  loading={gettingAnnotations}
                >
                  {t('btn.query')}
                </Button>
              </>
            )}
          </div>
        </Drawer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log('state', state)
  return {
    user: state.authentication.user,
    annotations: state.annotation.annotations,
    gettingAnnotations: state.annotation.gettingAnnotations,
    updatingAnnotation: state.annotation.updatingAnnotation,
    users: state.authentication.users,
    gettingUsers: state.authentication.gettingUsers,
    groups: state.group.groups,
    frames: state.annotation.frames,
    tags: state.tag.tags,
    gettingFrames: state.annotation.gettingFrames,
    message: state.alert.message,
    objectTypesgroups: state.objectType.objectTypesgroups,
    stat: state.annotation.statSum
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation('annot')(Annotations))
)
