export const userConstants = {
  CREATE_REQUEST: 'USER_CREATE_REQUEST',
  CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  CREATE_FAILURE: 'USER_CREATE_FAILURE',

  AUTH_REQUEST: 'USER_AUTH_REQUEST',
  AUTH_SUCCESS: 'USER_AUTH_SUCCESS',
  AUTH_FAILURE: 'USER_AUTH_FAILURE',

  GET_REQUEST: 'USER_GET_REQUEST',
  GET_SUCCESS: 'USER_GET_SUCCESS',
  GET_FAILURE: 'USER_GET_FAILURE',

  GET_ALL_REQUEST: 'USER_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'USER_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'USER_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

  DELETE_REQUEST: 'USER_DELETE_REQUEST',
  DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  DELETE_FAILURE: 'USER_DELETE_FAILURE',

  GETHOME_REQUEST:'GET_HOME_REQUEST',
  GETHOME_SUCCESS:'GET_HOME_SUCCESS',
  GETHOME_FAILURE:'GET_HOME_FAILURE',

  LOGOUT: 'USER_LOGOUT'
}
