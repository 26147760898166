import { axios } from '../_helpers'

export const bagServices = {
  get,
  getAll,
  update,
  create,
  delete: deleteBag,
  getByName
}

function get(params) {
  return axios
    .get('bagservice/get', { params, data: {} })
    .then(function(response) {
      return response.data
    })
}

function getAll(params) {
  return axios
    .get('bagservice/getAll', { params, data: {} })
    .then(function(response) {
      return response.data
    })
}

function update(data) {
  return axios.post('bagservice/update', data).then(function(response) {
    return response.data
  })
}

function create(data) {
  return axios.post('bagservice/create', data).then(function(response) {
    return response.data
  })
}

function deleteBag(data) {
  return axios.post('bagservice/delete', data).then(function(response) {
    return response.data
  })
}

function getByName(data) {
  return axios.post('bagservice/getByName', data).then(function(response) {
    return response.data
  })
}
