import React, { Component } from 'react'
import './ViewerSignal.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { fabric } from 'fabric'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
  toPrecision,
  uuid,
  hexToRgb,
  recreateDB,
  extractObject2D
} from '../_utils'
import {
  PAD,
  TOOLTIP_DELAY,
  TOOL,
  CURSOR,
  ZOOM,
  OPACITY,
  COLOR,
  REC_THRESHOLD,
  DEFAULT,
  TYPE,
  OPEN_BASE_URL
} from './constants'
import { annotationActions, alertActions } from '../_actions'
import { history, role } from '../_helpers'
import { dataServices } from '../_services'
import { db } from '../_db'
import DataWorker from '../_workers/data.worker'

import {
  IconButton,
  InputNumber as InputNum,
  LabelType,
  LoadingCover
} from '../common'
import { Guides, FrameInfo, LabelsStats } from './components'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { DeleteOutlined, EyeOutlined, InboxOutlined } from '@ant-design/icons'
import {
  Tooltip,
  Slider,
  Tabs,
  Button,
  Divider,
  InputNumber,
  Select,
  message,
  Checkbox,
} from 'antd'
const { TabPane } = Tabs
const Option = Select.Option

// Style Presets
const toolIconStyle = {
  fontSize: '20px'
}

const horizontalDividerStyle = {
  margin: '0.5rem 0'
}

class ViewerSignal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      labels: [],
      labelsCounter: {},
      selectedLabel: null,
      currentFrame: 1,
      totalFrames: 1,
      zoomRatioPP: DEFAULT.ZOOM_RATIO_PP,
      imageOpacity: DEFAULT.IMAGE_OPACITY,
      selectedTool: TOOL.NULL,
      isFlipped: false,
      hasFill: false,
      isCtxMenuVisible: false,
      isEdited: false,
      noLabels: false,
      isInfoVisible: true,
      isStatsVisible: true,
      firstLoading: true
    }

    // Canvas
    this.canvas
    this.canvasRoot
    this.fcanvas
    this.toolbar
    this.tabs
    this.typeSelect
    // Image
    this.viewWidth
    this.viewHeight
    this.imageWidth
    this.imageHeight
    this.currentImage
    // Shape
    this.shapeMemo = {
      started: false,
      target: null,
      originX: 0,
      originY: 0
    }
    this.readyToPan
    this.panning
    // Canvas context menu
    this.top = 0
    this.left = 0
    // Data
    this.dataWorker
    this.buffer = {}
    this.bufferIndex = 0
    this.unlabeledIndices = []
  }

  // 组件插入DOM中立即调用：读取所有帧的信息
  componentDidMount() {
    const { dispatch, location } = this.props

    if (location.state) {
      const { bagId, startTimestamp, endTimestamp } = location.state.record

      // Get frames when component did mount
      if (bagId && startTimestamp && endTimestamp) {
        dispatch(
          annotationActions.getAllFrames(bagId, startTimestamp, endTimestamp, [
            'ready',
            'finish'
          ])
        )

        // Initialize views
        setTimeout(this.init, 0)
      }
    } else {
      history.replace('/annotations')
    }

    // Initialize data worker
    this.dataWorker = new DataWorker()
  }

  // 组件更新时调用
  componentDidUpdate(prevProps) {
    const { firstLoading } = this.state
    const { dispatch, frames, objects, message: alertMessage } = this.props

    // 显示：保存成功/失败
    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }

    // Get objects when: do have frames && not other props changes
    if (frames.length > 0 && frames !== prevProps.frames) {
      const lastFrameIndex = frames.length - 1
      let firstFrameIndex = lastFrameIndex

      // 找出所有未标注的帧，第一页设置为第一个未标注帧
      frames.forEach((frame, index) => {
        if (!frame.annotationobjectsId || frame.annotationobjectsId < 0) {
          this.unlabeledIndices.push(index)
          firstFrameIndex === lastFrameIndex && (firstFrameIndex = index)
        }
      })

      // 都标注了则回到第一页
      if (this.unlabeledIndices.length === 0) {
        firstFrameIndex = 0
      }

      this.setState(
        {
          currentFrame: firstFrameIndex + 1,
          totalFrames: frames.length
        },
        () => {
          // 读取标签并加载
          if (frames[firstFrameIndex].annotationobjectsId > 0) {
            dispatch(
              annotationActions.getObject(
                frames[firstFrameIndex].annotationobjectsId
              )
            )
          }
          this.loadData()
        }
      )
    }

    // 后面再打开且标签未加载，则加载标签
    if (!firstLoading && objects !== prevProps.objects) {
      // When no objects, reuse objects in the previous frame
      this.updateLabels(objects)
      // When no objects, set noLabels
      !objects.length && this.setState({ noLabels: true })
    }
  }

  // 取消快捷键
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)

    // Kill worker before leaving
    this.dataWorker.terminate()

    // Clear db before leaving
    recreateDB(db)
  }

  // 快捷键事件注册
  bindEventListeners = () => {
    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  }

  // 更改窗口大小
  onWindowResize = () => {
    const canvasWidth =
      document.documentElement.clientWidth -
      this.toolbar.clientWidth -
      this.tabs.clientWidth

    this.fcanvas.setWidth(canvasWidth)
  }

  // 快捷键定义
  onKeyDown = e => {
    const { currentFrame, firstLoading } = this.state
    const { user } = this.props

    // Disable all keyboard events when first loading
    if (firstLoading) return

    // Shift + 左右键翻页， +S 保存
    if (e.shiftKey) {
      switch (e.code) {
        case 'ArrowRight':
          this.handleInputFrame(currentFrame + 1)
          break
        case 'ArrowLeft':
          this.handleInputFrame(currentFrame - 1)
          break
        case 'KeyS':
          this.handleSave()
          break
        default:
          break
      }
    } else if (this.getAssistKey(e)) {  // 无用
      this.readyToPan = true
      this.setCursor()

      switch (e.code) {
        case 'KeyI':
          this.handleInspect()
          break
        case 'KeyS':
          this.handleSave()
          break
        default:
          break
      }
    } else {
      // 单快捷键
      switch (e.code) {
        case 'KeyZ':
          this.handleSelectTool(TOOL.SQUARE)
          break
        case 'KeyR':
          this.handleSelectTool(TOOL.RECTANGLE)
          break
        case 'KeyP':
          this.handleSelectTool(TOOL.POLYGON)
          break
        case 'KeyS':
          this.scaleToFit()
          break
        case 'KeyC':
          this.setCenter()
          break
        case 'KeyF':
          this.handleFlipImage()
          break
        case 'KeyU':
          this.handleFillLabel()
          break
        case 'KeyH':
          this.handleToggleLabelVisibility(e)
          break
        case 'Backspace':
          this.handleRemoveLabel(e)
          break
        case 'ArrowLeft':
          this.handleMoveObject('left')
          break
        case 'ArrowRight':
          this.handleMoveObject('right')
          break
        case 'ArrowUp':
          this.handleMoveObject('up')
          break
        case 'ArrowDown':
          this.handleMoveObject('down')
          break
        case 'KeyQ':
          ;[role.globalExaminer, role.groupExaminer].includes(user.role) &&
            this.handleExamineLabel(e)
          break
        default:
          break
      }
    }
  }

  onKeyUp = e => {
    if (this.readyToPan) {
      this.readyToPan = false
      this.setCursor()
    }
  }

  // canvas 初始化
  init = () => {
    // strokeUniform works better without scalingCache
    fabric.Object.prototype.noScaleCache = false

    this.fcanvas = new fabric.Canvas(this.canvas, {
      preserveObjectStacking: true,
      width: this.canvasRoot.clientWidth,
      height: this.canvasRoot.clientHeight,
      selection: false,
      selectionColor: 'transparent',
      selectionBorderColor: 'transparent',
      backgroundColor: 'transparent',
      defaultCursor: 'default',
      fireRightClick: true,
      stopContextMenu: true
    })

    this.fcanvas.on({
      'mouse:wheel': this.handleMouseWheel,
      'mouse:down': this.handleMouseDown,
      'mouse:move': this.handleMouseMove,
      'mouse:up': this.handleMouseUp,
      'object:moving': this.handleObjectMoving,
      'object:scaling': this.handleObjectScaling,
      'selection:created': this.handleObjectSelected,
      'selection:updated': this.handleObjectUpdated,
      'selection:cleared': this.handleObjectCleared
    })

    this.bindEventListeners()
  }

  // 选择工具
  handleSelectTool = tool => {
    let { selectedTool } = this.state

    this.setState(
      {
        selectedTool: tool === selectedTool ? TOOL.NULL : tool
      },
      () => {
        this.setCursor()
      }
    )
  }

  // 翻页
  handleInputFrame = value => {
    const { totalFrames } = this.state
    const { dispatch, frames } = this.props

    if (value > 0 && value <= totalFrames) {
      // 翻页自动保存
      this.handleSave()

      this.setState(
        {
          currentFrame: value
        },
        () => {
          this.setState({ isEdited: false, noLabels: false })
          // 读标签数据
          if (frames[value - 1].annotationobjectsId > 0) {
            dispatch(
              annotationActions.getObject(frames[value - 1].annotationobjectsId)
            )
          }
          // 加载图片及标签数据
          this.loadData()
        }
      )
    }
  }

  // 选择标签
  handleSelectLabel = label => {
    const { selectedLabel } = this.state
    label === selectedLabel ? this.clearLabel() : this.selectLabel(label)
  }

  // 填充标签
  handleFillLabel = () => {
    const { hasFill } = this.state

    this.setState({ hasFill: !hasFill }, () => {
      this.fcanvas.getObjects().forEach((object, index) => {
        index && object.set({ fill: this.getFillColor(object.fill) })
      })

      this.fcanvas.requestRenderAll()
    })
  }

  // 翻转图像
  handleFlipImage = () => {
    const { isFlipped } = this.state

    this.currentImage.rotate(isFlipped ? 0 : 180)
    this.currentImage.setCoords()
    this.fcanvas.requestRenderAll()

    this.setState({ isFlipped: !isFlipped })
  }

  // 删除标签
  handleRemoveLabel = (e, label) => {
    const { selectedLabel } = this.state

    e.stopPropagation()
    this.closeCtxMenu()

    this.removeLabel(label || selectedLabel)
  }

  // 审查标签
  handleExamineLabel = (e, label) => {
    const { selectedLabel } = this.state

    e.stopPropagation()

    if (!label && !selectedLabel) return

    label
      ? (label.fail = !label.fail)
      : (selectedLabel.fail = !selectedLabel.fail)

    this.forceUpdate()
    this.setEdited()
  }

  // 修改图片透明度
  handleChangeImageOpacity = value => {
    if (value < OPACITY.MIN) {
      value = OPACITY.MIN
    } else if (value > OPACITY.MAX) {
      value = OPACITY.MAX
    } else {
      this.fcanvas.item(0).set({ opacity: value })
      this.fcanvas.requestRenderAll()
    }

    this.setState({ imageOpacity: toPrecision(value, OPACITY.PRECISION) })
  }

  // 修改类型
  handleChangeType = (value, label) => {
    this.typeSelect.blur()

    // Change type
    this.updateLabelsCounter({
      type: 'update',
      payload: { old: label.type, new: value }
    })
    label.type = value

    // Change color
    const color = COLOR[value.toUpperCase()]
    label.target.set({
      fill: this.getFillColor(color),
      stroke: color
    })

    this.fcanvas.requestRenderAll()

    this.setEdited()
    this.setFail()
  }

  // 保存标签
  handleSave = () => {
    const { labels, currentFrame, isEdited } = this.state
    const { dispatch, frames, location, t } = this.props
    const { id } = location.state.record

    if (!isEdited) return

    const objects = labels.map(label =>
      extractObject2D(label, this.currentImage)
    )

    const data = {
      size: {
        width: this.currentImage.width,
        height: this.currentImage.height
      },
      objects
    }

    const objectId = frames[currentFrame - 1].annotationobjectsId

    dispatch(
      objectId > 0
        ? annotationActions.updateObject(data, objectId, objects.length)
        : annotationActions.createObject(
            data,
            frames[currentFrame - 1].id,
            objects.length
          )
    )
      .then(object => {
        // When created objects, remove that frame from indices
        if (!(objectId > 0)) {
          frames[currentFrame - 1].annotationobjectsId = object.id

          this.unlabeledIndices.splice(
            this.unlabeledIndices.findIndex(
              value => value === currentFrame - 1
            ),
            1
          )
        }

        // Update this annotation detail and progress
        dispatch(
          annotationActions.updateAnnotation({
            id,
            progress: `${frames.length - this.unlabeledIndices.length} / ${
              frames.length
            }`
          })
        )

        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.saveSuccess')
          })
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({ type: 'error', detail: t('alerts.saveFailure') })
        )
      })
  }

  // 收放：左下角当前帧信息
  handleToggleFrameInfo = () => {
    const { isInfoVisible } = this.state
    this.setState({ isInfoVisible: !isInfoVisible })
  }

  // 收放：右下角统计信息
  handleToggleStatsInfo = () => {
    const { isStatsVisible } = this.state
    this.setState({ isStatsVisible: !isStatsVisible })
  }

  // 暂时无用
  handleInspect = () => {
    console.log(this.state.labels)
  }

  // 无标注框
  handleChangeNoLabels = e => {
    this.setState({
      noLabels: e.target.checked,
      isEdited: e.target.checked
    })
  }

  // .txt ?
  handleCopyPath = () => {
    const { dispatch, t } = this.props

    dispatch(
      alertActions.success({
        type: 'success',
        detail: t('alerts.copySuccess')
      })
    )
  }

  // 隐藏标签
  handleToggleLabelVisibility = (e, label) => {
    const { selectedLabel } = this.state

    e.stopPropagation()

    if (!label && !selectedLabel) return

    const target = label ? label.target : selectedLabel.target
    target.visible = !target.visible
    this.fcanvas.requestRenderAll()

    // Clear seletedLabel and rigger setState btw
    this.clearLabel()
  }

  // 滚轮操作：缩放图片
  handleMouseWheel = opt => {
    opt.e.preventDefault()
    opt.e.stopPropagation()

    const delta = opt.e.deltaY
    let zoomRatio = this.fcanvas.getZoom()

    // Get new zoom ratio
    zoomRatio = delta > 0 ? zoomRatio - 0.05 : zoomRatio + 0.05
    this.setZoom(zoomRatio * 100, opt.pointer)
  }

  // 按键操作：左键新建框、选中标签，右键拖拽图片位置
  handleMouseDown = opt => {
    const { selectedTool, isCtxMenuVisible, zoomRatioPP } = this.state

    // 隐藏标签菜单
    if (isCtxMenuVisible) {
      this.typeSelect.blur()
      // !!Hack method, avoid use setTimeout like this
      // To make sure Select blur firstly then context menu hide
      setTimeout(() => this.setState({ isCtxMenuVisible: false }), 0)
    }

    // 调整框
    if (this.readyToPan) {
      this.panning = true
      return
    }

    const pointer = this.fcanvas.getPointer(opt.e)

    // 新建框
    if (opt.button === 1) {
      // 已经有选中的话，不新建
      if (this.fcanvas.getActiveObject()) return

      // 确保点在图片范围内
      if (this.containsPointer(pointer)) {
        // 保存起始点
        this.shapeMemo.originX = pointer.x
        this.shapeMemo.originY = pointer.y

        switch (selectedTool) {
          case TOOL.SQUARE:
          case TOOL.RECTANGLE:
            const color = COLOR[DEFAULT.TYPE.toUpperCase()]
            const strokeWidth = (DEFAULT.STROKE_WIDTH / zoomRatioPP) * 100

            const rect = new fabric.Rect({
              left: pointer.x,
              top: pointer.y,
              originX: 'left',
              originY: 'top',
              width: 0,
              height: 0,
              hoverCursor: 'move',
              fill: this.getFillColor(color),
              stroke: color,
              strokeWidth,
              strokeUniform: true,
              hasBorders: false,
              cornerColor: '#fff',
              cornerSize: 8,
              borderOpacityWhenMoving: 0.3,
              hasRotatingPoint: false
            })

            // 保存形状实例
            this.shapeMemo.target = rect
            this.shapeMemo.started = true

            this.fcanvas.add(rect)
            break
          default:
            break
        }
      }
    }

    // 右键
    if (opt.button === 3) {
      opt.e.preventDefault()
      opt.e.stopPropagation()

      // 选择框
      const label = this.handleObjectSelected(opt)

      if (label) {  // 选中进入目录
        this.top = opt.e.offsetY - 15
        this.left = opt.e.offsetX + 8
        this.setState({isCtxMenuVisible: true})
      } else {      // 未选中则进入拖拽状态
        this.dragging = true
        this.dragging_start_x = pointer.x
        this.dragging_start_y = pointer.y
      }
    }
  }

  // 移动操作：左键新建框、移动框，右键拖拽图片
  handleMouseMove = opt => {
    const { selectedTool, isCtxMenuVisible } = this.state

    // 拖拽
    if (this.dragging) {
      const current_pointer = this.fcanvas.getPointer(opt.e)
      const delta_pointer = new fabric.Point(
        current_pointer.x - this.dragging_start_x,
        current_pointer.y - this.dragging_start_y,
      )
      this.fcanvas.relativePan(delta_pointer)
      return
    }

    // 调整已有框
    if (this.panning) {
      isCtxMenuVisible && this.setState({ isCtxMenuVisible: false })
      this.handleRelativePan(opt.e)
      return
    }

    // 没有开始新建框
    if (!this.shapeMemo.started) return

    switch (selectedTool) {
      case TOOL.SQUARE:
      case TOOL.RECTANGLE:
        const { target, originX, originY } = this.shapeMemo
        const pointer = this.fcanvas.getPointer(opt.e)
        const { left, top, width, height } = this.currentImage.getBoundingRect(true)

        var x = pointer.x
        var y = pointer.y
        x = Math.max(x, left)
        x = Math.min(x, left + width)
        y = Math.max(y, top)
        y = Math.min(y, top + height)
        var w = Math.abs(originX - x)
        var h
        if (selectedTool == TOOL.RECTANGLE) {
          h = Math.abs(originY - y)
        } else if (selectedTool == TOOL.SQUARE) {
          h = w
        }

        originX > pointer.x && target.set({ left: Math.abs(x) })
        // 稍微有点问题：会跳变
        originY > pointer.y && target.set({ top: Math.abs(originY - h) })

        target.set({ width: w })
        target.set({ height: h })

        this.fcanvas.requestRenderAll()
        break
      default:
        break
    }
  }

  // 结束鼠标按键操作
  handleMouseUp = opt => {
    const { selectedTool } = this.state

    this.dragging = false

    if (this.panning) {
      this.panning = false
      return
    }

    if (!this.shapeMemo.started) return

    const pointer = this.fcanvas.getPointer(opt.e)

    switch (selectedTool) {
      case TOOL.SQUARE:
      case TOOL.RECTANGLE:
        const { target, originX, originY } = this.shapeMemo
        if ( // 太小的框不要：3个像素及以下
          Math.abs(pointer.x - originX) > REC_THRESHOLD &&
          Math.abs(pointer.y - originY) > REC_THRESHOLD
        ) {
          target.setCoords()
          this.createLabel(this.shapeMemo.target)
        } else {
          this.fcanvas.remove(target)
        }
        this.shapeMemo.started = false
      default:
        break
    }
  }

  // 图片移动
  handleRelativePan = e => {
    const delta = new fabric.Point(e.movementX, e.movementY)
    this.fcanvas.relativePan(delta)
  }

  // 移动标签
  handleObjectMoving = opt => {
    const obj = opt.target
    const { left, top, width, height } = this.currentImage.getBoundingRect(true)

    // Left check
    obj.left < left && obj.set({ left })

    // Right check
    const rightBoundary = left + width - obj.getScaledWidth()
    obj.left > rightBoundary && obj.set({ left: rightBoundary })

    // Top check
    obj.top < top && obj.set({ top })

    // Bottom check
    const bottomBoundary = top + height - obj.getScaledHeight()
    obj.top > bottomBoundary && obj.set({ top: bottomBoundary })

    this.setEdited()
    this.setFail()
  }

  // 选择标签
  handleObjectSelected = opt => {
    const { labels } = this.state
    const label = labels.find(label => label.target === opt.target)

    if (label) {
      this.setState({
        selectedLabel: label
      })

      const activeObj = this.fcanvas.getActiveObject()

      if (activeObj !== label.target) {
        this.fcanvas.setActiveObject(label.target)
        this.fcanvas.requestRenderAll()
      }
    }

    return label
  }

  // 更新标签
  handleObjectUpdated = opt => {
    const { labels } = this.state
    const label = labels.find(label => label.target === opt.target)

    this.setState({
      selectedLabel: label
    })
  }

  // 停止选择标签
  handleObjectCleared = opt => {
    this.setState({
      selectedLabel: null
    })
  }

  // 缩放标签
  handleObjectScaling = opt => {
    this.setEdited()
    this.setFail()
  }

  // 移动标签
  handleMoveObject = direction => {
    const activeLabel = this.fcanvas.getActiveObject()
    const step = 1
    const { left, top, width, height } = this.currentImage.getBoundingRect(true)

    if (activeLabel) {
      switch (direction) {
        case 'up':
          const newTop = activeLabel.top - step

          newTop > top && activeLabel.set('top', newTop)
          break
        case 'down':
          const newBottom = activeLabel.top + step
          const bottomBoundary = top + height - activeLabel.getScaledHeight()

          newBottom < bottomBoundary && activeLabel.set('top', newBottom)
          break
        case 'left':
          const newLeft = activeLabel.left - step

          newLeft > left && activeLabel.set('left', newLeft)
          break
        case 'right':
          const newRight = activeLabel.left + step
          const rightBoundary = left + width - activeLabel.getScaledWidth()

          newRight < rightBoundary && activeLabel.set('left', newRight)
          break
        default:
          break
      }

      this.fcanvas.requestRenderAll()
      activeLabel.setCoords()
      this.setEdited()
      this.setFail()
    }
  }

  // 创建标签
  createLabel = target => {
    const { labels, noLabels } = this.state

    const label = {
      id: uuid(),
      type: DEFAULT.TYPE,
      target
    }

    labels.push(label)
    this.updateLabelsCounter({ type: 'create', payload: label.type })

    this.setState({ labels })
    this.setEdited()

    noLabels && this.setState({ noLabels: false })
  }

  // 删除标签
  removeLabel = label => {
    const { labels, selectedLabel } = this.state

    if (label) {
      this.fcanvas.remove(label.target)

      label === selectedLabel && this.setState({ selectedLabel: null })

      labels.splice(labels.indexOf(label), 1)
      this.updateLabelsCounter({ type: 'remove', payload: label.type })

      this.setState({ isEdited: labels.length ? true : false })
    }
  }

  // 更新标签
  updateLabels = objects => {
    const { angle, aCoords, scaleX: scale } = this.currentImage
    // Check if image is rotated
    const { x, y } = angle ? aCoords.br : aCoords.tl

    // 去掉上一页的标签
    this.fcanvas.remove(...this.fcanvas.getObjects().slice(1))

    // 清空标签计数器
    this.setState({ labelsCounter: {} }, () => {
      const { zoomRatioPP } = this.state

      const labels = objects.map(
        ({ id, type, xmin, ymin, xmax, ymax, fail }) => {
          const color = COLOR[type.toUpperCase()]
          const strokeWidth = (DEFAULT.STROKE_WIDTH / zoomRatioPP) * 100

          const rect = new fabric.Rect({
            left: x + xmin * scale,
            top: y + ymin * scale,
            originX: 'left',
            originY: 'top',
            width: (xmax - xmin) * scale,
            height: (ymax - ymin) * scale,
            hoverCursor: 'move',
            fill: this.getFillColor(color),
            stroke: color,
            strokeWidth,
            strokeUniform: true,
            hasBorders: false,
            cornerColor: '#fff',
            cornerSize: 8,
            borderOpacityWhenMoving: 0.3,
            hasRotatingPoint: false
          })

          this.fcanvas.add(rect)
          this.updateLabelsCounter({ type: 'create', payload: type })

          return {
            id,
            type,
            fail,
            target: rect
          }
        }
      )

      this.setState({ labels })
    })
  }

  // 选择标签
  selectLabel = label => {
    this.setState({
      selectedLabel: label
    })

    this.fcanvas.setActiveObject(label.target)
    this.fcanvas.requestRenderAll()
  }

  // 清空标签
  clearLabel = () => {
    this.setState({
      selectedLabel: null,
      isCtxMenuVisible: false
    })

    this.fcanvas.discardActiveObject()
    this.fcanvas.requestRenderAll()
  }

  // 更新标签计数器
  updateLabelsCounter = ({ type, payload }) => {
    let { labelsCounter } = this.state

    const create = data => {
      labelsCounter[data]
        ? (labelsCounter[data] += 1)
        : (labelsCounter[data] = 1)
    }

    const remove = data => {
      this.state.labelsCounter[data] -= 1

      if (!labelsCounter[data]) {
        delete labelsCounter[data]
      }
    }

    const update = data => {
      remove(data.old)
      create(data.new)
    }

    const handler = {
      create,
      remove,
      update
    }

    handler[type](payload)

    this.setState({ labelsCounter })
  }

  // 绘制图片
  setImage = timestamp => {
    const { isFlipped, imageOpacity, firstLoading } = this.state
    const { objects } = this.props

    const currentImage = this.buffer[timestamp]
    const scale = this.getImageDisplayRatio(currentImage)

    this.currentImage = new fabric.Image(currentImage, {
      scaleX: scale,
      scaleY: scale,
      hasBorders: false,
      hasControls: false,
      selectable: false,
      angle: isFlipped ? 180 : 0,
      opacity: imageOpacity,
      hoverCursor: 'default'
    })

    this.fcanvas.insertAt(this.currentImage, 0, true)
    this.fcanvas.centerObject(this.currentImage)

    // 第一次打开加载标签
    if (firstLoading) {
      this.updateLabels(objects)
      this.setState({
        firstLoading: false,
        noLabels: !this.unlabeledIndices.length && !objects.length
      })
    }
  }

  // 图片显示比例
  getImageDisplayRatio = image => {
    const viewWidth = this.canvas.clientWidth
    const viewHeight = this.canvasRoot.clientHeight
    const ratio = (image.width + 2 * PAD) / (image.height + 2 * PAD)
    let displayRatio = ratio

    // Set canvas image diplay size
    if (viewWidth / viewHeight > ratio) {
      this.imageHeight = viewHeight - 2 * PAD
      this.imageWidth = this.imageHeight * ratio

      displayRatio = this.imageHeight / image.height
    } else {
      this.imageWidth = viewWidth - 2 * PAD
      this.imageHeight = this.imageWidth / ratio

      displayRatio = this.imageWidth / image.width
    }

    return displayRatio
  }

  // 辅助按键：ALT
  getAssistKey = e => {
    return e.altKey
  }

  // 光标
  setCursor = () => {
    const { selectedTool } = this.state

    // Canvas
    this.fcanvas.defaultCursor = this.readyToPan ? CURSOR.GRAB : CURSOR.DEFAULT
    this.fcanvas.hoverCursor = this.fcanvas.defaultCursor

    // Objects
    this.fcanvas.getObjects().forEach((object, index) => {
      if (index) {
        // Shapes
        object.selectable = !this.readyToPan
        object.hoverCursor = this.readyToPan ? CURSOR.GRAB : CURSOR.MOVE
      } else {
        // Image
        object.hoverCursor = this.readyToPan
          ? CURSOR.GRAB
          : selectedTool
          ? CURSOR.CROSSHAIR
          : CURSOR.DEFAULT
      }
    })

    this.fcanvas.requestRenderAll()
  }

  // 缩放比例
  setZoom = (zoomRatioPP, pointer) => {
    let zoomRatio = zoomRatioPP / 100
    zoomRatioPP = toPrecision(zoomRatioPP, ZOOM.PRECISION)

    // Set pointer to canvas center if no pointer passes
    if (!pointer) {
      const center = this.fcanvas.getCenter()
      pointer = new fabric.Point(center.left, center.top)
    }

    if (zoomRatioPP < ZOOM.MIN) {
      zoomRatioPP = ZOOM.MIN
    } else if (zoomRatioPP > ZOOM.MAX) {
      zoomRatioPP = ZOOM.MAX
    } else {
      this.fcanvas.getObjects().forEach((object, index) => {
        if (index) {
          object.strokeWidth = DEFAULT.STROKE_WIDTH / zoomRatio
        }
      })

      this.fcanvas.zoomToPoint(pointer, zoomRatio)
    }

    this.setState({ zoomRatioPP })
  }

  // 中心
  setCenter = () => {
    const center = this.fcanvas.getCenter()
    const zoom = this.fcanvas.getZoom()

    const pointer = new fabric.Point(
      center.left * (zoom - 1),
      center.top * (zoom - 1)
    )

    this.fcanvas.absolutePan(pointer)
  }

  // 图片包含该点
  containsPointer = ({ x, y }) => {
    const { left, top, width, height } = this.currentImage.getBoundingRect(true)

    return x > left && x < left + width && y > top && y < top + height
  }

  // 恢复缩放
  scaleToFit = () => {
    this.setZoom(100)
    this.setCenter()
  }

  // 关闭目录菜单
  closeCtxMenu = () => {
    const { isCtxMenuVisible } = this.state
    isCtxMenuVisible && this.setState({ isCtxMenuVisible: false })
  }

  // 得到填充颜色
  getFillColor = color => {
    const { hasFill } = this.state

    // To test the color is hex or rgba
    const c =
      color[0] === '#'
        ? hexToRgb(color)
        : color.split(/\D+/).filter(item => item)

    return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${hasFill ? DEFAULT.ALPHA : 0})`
  }

  // 加载数据
  loadData = () => {
    const { currentFrame } = this.state
    const { frames, location } = this.props
    let { bagName } = location.state.record

    this.bufferIndex = currentFrame
    const index = currentFrame - 1
    const timestamp = frames[index].timestamp

    // Data buffered
    if (this.buffer[timestamp]) {
      this.setImage(timestamp)
    } else {
      db['CAMERA_1']
        .get(timestamp)
        .then(data => {
          // Data is in db
          if (data) {
            this.loadImageFromData(data.data, image => {
              // Add image to buffer
              this.buffer[timestamp] = image
              this.setImage(timestamp)
            })
          } else {
            // Data is not in db
            const data = {
              bagName: bagName,
              sensorsList: ['CAMERA_1'],
              startFrametime: timestamp,
              endFrametime: timestamp,
              framesList: [timestamp]
            }

            dataServices.getData(data, onMessage)
          }
        })
        .catch(e => {
          console.log(e)
        })
    }

    // Test and preload next 5 frames
    this.japStep()

    const onMessage = data => {
      this.loadImageFromData(data.data, image => {
        this.buffer[timestamp] = image
        this.setImage(timestamp)
      })

      // For cache
      this.dataWorker.postMessage({ action: 'PUT_DATA', data })
    }
  }

  // 预加载
  preloadData = () => {
    const { frames, location } = this.props
    let { bagName } = location.state.record

    const startIndex = this.bufferIndex
    const endIndex =
      this.bufferIndex + 5 > frames.length
        ? frames.length
        : this.bufferIndex + 5
    const promiseQueue = []

    // startIndex includes, endIndex excludes
    for (let index = startIndex; index < endIndex; ++index) {
      const timestamp = frames[index].timestamp

      // If not in buffer
      if (!this.buffer[timestamp]) {
        promiseQueue.push(
          new Promise((resolve, reject) => {
            db['CAMERA_1']
              .get(timestamp)
              .then(data => {
                if (data) {
                  this.loadImageFromData(data.data, image => {
                    this.buffer[timestamp] = image
                  })

                  return resolve()
                } else {
                  return resolve(timestamp)
                }
              })
              .catch(e => {
                console.log(e)
                return reject()
              })
          })
        )
      }
    }

    Promise.all(promiseQueue).then(result => {
      // Filter frames which need to request data
      const framesList = result.filter(item => !!item)

      if (framesList.length > 0) {
        const data = {
          bagName: bagName,
          sensorsList: ['CAMERA_1'],
          startFrametime: frames[startIndex].timestamp,
          endFrametime: frames[endIndex - 1].timestamp,
          framesList
        }

        dataServices.getData(data, onMessage, onEnd)
        console.log('Preload starts')
      }
    })

    const onMessage = data => {
      // For buffer
      this.loadImageFromData(data.data, image => {
        this.buffer[data.frametime] = image
      })

      // For cache
      this.dataWorker.postMessage({ action: 'PUT_DATA', data })
    }

    const onEnd = () => {
      console.log('Preload ends')
    }
  }

  // 加载
  japStep = () => {
    const { frames } = this.props

    if (this.bufferIndex === frames.length) return

    const index = this.bufferIndex
    const timestamp = frames[index].timestamp

    db['CAMERA_1']
      .get(timestamp)
      .then(data => {
        data || this.preloadData()
      })
      .catch(e => {
        console.log(e)
      })
  }

  // 加载图片
  loadImageFromData = (data, onLoad) => {
    const image = new Image()
    image.src = 'data:image/jpeg;base64,' + data

    image.onload = () => onLoad(image)
  }

  // 无标注复选框可编辑
  setEdited = () => {
    const { isEdited } = this.state
    isEdited || this.setState({ isEdited: true })
  }

  // 原标注错误
  setFail = () => {
    const { selectedLabel } = this.state
    const { user } = this.props

    // Auto mark fail when examiner edits
    if (
      [role.globalExaminer, role.groupExaminer].includes(user.role) &&
      !selectedLabel.fail
    ) {
      selectedLabel.fail = true
      this.setState({ selectedLabel })
    }
  }

  render() {
    const {
      selectedTool,
      currentFrame,
      totalFrames,
      labels,
      labelsCounter,
      selectedLabel,
      isFlipped,
      hasFill,
      zoomRatioPP,
      imageOpacity,
      isCtxMenuVisible,
      isEdited,
      noLabels,
      isInfoVisible,
      isStatsVisible,
      firstLoading
    } = this.state
    const { user, frames, location, t } = this.props

    // 右侧标签列表
    const labelsCollection = labels.map(label => (
      <li
        className={`viewer2-labels-collection-item${
          label === selectedLabel ? ' selected' : ''
        }`}
        style={{ cursor: label.target.visible ? '' : 'not-allowed' }}
        onClick={() => label.target.visible && this.handleSelectLabel(label)}
        key={label.id}
      >
        <div className="viewer2-label-row">
          <div className="viewer2-label-type">
            <LabelType color={COLOR[label.type.toUpperCase()]} />
            {t(`type.${label.type}`)}
            <span
              className="viewer2-label-tag"
              style={{ visibility: label.fail ? 'visible' : 'hidden' }}
            />
          </div>
          <div className="viewer2-label-actions">
            <button
              className="viewer2-btn"
              onClick={e => this.handleToggleLabelVisibility(e, label)}
            >
              <LegacyIcon type={label.target.visible ? 'eye' : 'eye-invisible'} />
            </button>
          </div>
        </div>
      </li>
    ))

    // 列表为空
    const emptyCollection = (
      <div className="viewer2-labels-collection-empty">
        <div>
          <InboxOutlined style={{ fontSize: '30px' }} />
        </div>
        {t('noLabels')}
      </div>
    )

    // 标签修改框
    const selectRender = (
      <Select
        ref={el => {
          this.typeSelect = el
        }}
        size="small"
        showArrow={false}
        value={selectedLabel && selectedLabel.type}
        onChange={value => this.handleChangeType(value, selectedLabel)}
      >
        {Object.keys(TYPE).map(key => (
          <Option value={TYPE[key]} key={key}>
            <LabelType color={COLOR[key]} />
            {t(`type.${TYPE[key]}`)}
          </Option>
        ))}
      </Select>
    )

    const frame = frames[currentFrame - 1]

    let dataURL = ''
    let openState = false

    if (frame) {
      const timestamp = frame.timestamp
      const { bagName, startTimestamp, status } = location.state.record
      const time = bagName.split('-')[1]

      const dataPath = `/${time.substring(0, 4)}/${time.substring(
        4,
        6
      )}/${time.substring(
        6,
        8
      )}/${bagName}/Object/${startTimestamp}/${timestamp}.txt`

      dataURL = OPEN_BASE_URL + dataPath
      openState = status === 'objects_ready'
    }

    return (
      <div className="viewer2">
        {/* 主界面：左侧工具栏 + 中间画布 + 右侧信息栏 */}
        <div className="viewer2-up">
          {/* 左侧工具栏： 编辑 + 显示*/}
          <div className="viewer2-toolbar" ref={el => (this.toolbar = el)}>
            {/* 编辑工具 */}
            <div className="viewer2-toolbar-top">
              {/* 长方形工具 */}
              <Tooltip
                title={t('tooltip.rectangle')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton
                    type="gateway"
                    iconStyle={toolIconStyle}
                    active={selectedTool === TOOL.RECTANGLE}
                    onClick={() => this.handleSelectTool(TOOL.RECTANGLE)}
                  />
                </span>
              </Tooltip>
              {/* 正方形工具 */}
              <Tooltip
                title={t('tooltip.square')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton
                    type="border"
                    iconStyle={toolIconStyle}
                    active={selectedTool === TOOL.SQUARE}
                    onClick={() => this.handleSelectTool(TOOL.SQUARE)}
                  />
                </span>
              </Tooltip>
              <Divider style={horizontalDividerStyle} />
              {/* 缩放工具 */}
              <Tooltip
                title={t('tooltip.fit')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton onClick={this.scaleToFit}>
                    <i className="viewer2-icon-fit" />
                  </IconButton>
                </span>
              </Tooltip>
              {/* 居中工具 */}
              <Tooltip
                title={t('tooltip.center')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton onClick={this.setCenter}>
                    <i className="viewer2-icon-center" />
                  </IconButton>
                </span>
              </Tooltip>
              {/* 翻转工具 */}
              <Tooltip
                title={t('tooltip.flip')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton
                    type="sync"
                    iconStyle={toolIconStyle}
                    active={isFlipped}
                    onClick={this.handleFlipImage}
                  />
                </span>
              </Tooltip>
              {/* 填充 */}
              <Tooltip
                title={t('tooltip.fill')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton
                    type="border-outer"
                    iconStyle={toolIconStyle}
                    active={hasFill}
                    onClick={this.handleFillLabel}
                  />
                </span>
              </Tooltip>
              <Divider style={horizontalDividerStyle} />
              {/* 缩放 */}
              <Tooltip
                title={t('tooltip.zoom')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-input">
                  <InputNum
                    value={zoomRatioPP}
                    max={ZOOM.MAX}
                    min={ZOOM.MIN}
                    step={1}
                    precision={0}
                    style={{
                      padding: '0 0 0 1px',
                      margin: 0,
                      fontSize: '0.7rem',
                      textAlign: 'right'
                    }}
                    onChange={this.setZoom}
                  />
                  <span className="viewer2-toolbar-input-suffix">%</span>
                </span>
              </Tooltip>
              {/* 透明度 */}
              <Tooltip
                title={t('tooltip.opacity')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-input">
                  <InputNum
                    value={imageOpacity}
                    max={OPACITY.MAX}
                    min={OPACITY.MIN}
                    precision={OPACITY.PRECISION}
                    style={{
                      padding: '0',
                      fontSize: '0.7rem',
                      textAlign: 'center'
                    }}
                    onChange={this.handleChangeImageOpacity}
                  />
                </span>
              </Tooltip>
            </div>
            {/* 显示工具 */}
            <div className="viewer2-toolbar-bottom">
              {/* 标签统计 */}
              <span className="viewer2-toolbar-btn">
                <IconButton
                  type="bar-chart"
                  iconStyle={toolIconStyle}
                  active={isStatsVisible}
                  onClick={this.handleToggleStatsInfo}
                />
              </span>
              {/* 帧信息 */}
              <span className="viewer2-toolbar-btn">
                <IconButton
                  type="info-circle"
                  iconStyle={toolIconStyle}
                  active={isInfoVisible}
                  onClick={this.handleToggleFrameInfo}
                />
              </span>
            </div>
          </div>
          {/* 中间画布：图片 + 弹出窗口 + 显示信息 */}
          <div className="viewer2-canvas">
            {/* 图片 */}
            <div
              className="viewer2-canvas-wrapper"
              ref={el => (this.canvasRoot = el)}
            >
              <canvas ref={canvas => (this.canvas = canvas)} />
            </div>
            {/* 弹出窗口 */}
            <div
              className="viewer2-canvas-ctx"
              style={{
                display: isCtxMenuVisible ? '' : 'none',
                top: this.top,
                left: this.left
              }}
            >
              {/* 标签类型选择下拉框 */}
              <div className="viewer2-canvas-ctx-select">{selectRender}</div>
              <div className="viewer2-canvas-ctx-divider" />
              <button className="viewer2-btn" onClick={this.handleRemoveLabel}>
                <DeleteOutlined />
              </button>
              <button
                className="viewer2-btn"
                onClick={e => this.handleToggleLabelVisibility(e)}
              >
                <EyeOutlined />
              </button>
              {[role.globalExaminer, role.groupExaminer].includes(user.role) &&
              selectedLabel ? (
                <button
                  className="viewer2-btn"
                  onClick={this.handleExamineLabel}
                >
                  <i
                    className={`viewer2-icon-fail${
                      selectedLabel.fail ? ' active' : ''
                    }`}
                  />
                </button>
              ) : null}
            </div>
            {/* 帧信息 */}
            <FrameInfo
              frame={frame}
              record={location.state.record}
              visible={isInfoVisible}
            />
            {/* 标签统计 */}
            <LabelsStats counter={labelsCounter} visible={isStatsVisible} />
          </div>
          {/* 右侧信息栏 */}
          <div className="viewer2-tabs" ref={el => (this.tabs = el)}>
            <Tabs type="card">
              {/* 标签列表 */}
              <TabPane tab={t('tab.labels')} key="0">
                <div className="ant-tabpane-wrapper">
                  <ul className="viewer2-labels-collection">
                    {labelsCollection.length
                      ? labelsCollection
                      : emptyCollection}
                  </ul>
                </div>
              </TabPane>
              {/* 帮助信息 */}
              <TabPane tab={t('tab.guides')} key="1">
                <div className="ant-tabpane-wrapper">
                  <Guides user={user} />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
        {/* 下方控制操作 */}
        <div className="viewer2-bottom">
          {/* 控制栏：进度条 + 页数 */}
          <div className="viewer2-control">
            {/* 进度条 */}
            <div className="viewer2-control-slider">
              <Slider
                value={typeof currentFrame === 'number' ? currentFrame : 1}
                min={1}
                max={totalFrames}
                onChange={this.handleInputFrame}
              />
            </div>
            {/* 页数指定 */}
            <div className="viwer2-control-input">
              <InputNumber
                value={currentFrame}
                min={1}
                max={totalFrames}
                size="small"
                style={{ marginLeft: '1rem' }}
                onChange={this.handleInputFrame}
              />
              <span className="viewer2-control-frames">/ {totalFrames}</span>
            </div>
          </div>
          <Divider type="vertical" />
          {/* 操作栏：按钮*/}
          <div className="viewer2-actions">
            {/* 无标注 */}
            <div className="viewer2-actions-btn">
              <Checkbox
                checked={noLabels}
                disabled={!!labels.length}
                onChange={this.handleChangeNoLabels}
              >
                {t('noLabels')}
              </Checkbox>
            </div>
            {/* 保存按钮 */}
            <div className="viewer2-actions-btn">
              <Button
                size="small"
                type="primary"
                disabled={!isEdited}
                onClick={this.handleSave}
              >
                {t('btns.save')}
              </Button>
            </div>
          </div>
          {user.role === role.admin ? (
            <>
            {/* .txt */}
              <Divider type="vertical" />
              <div style={{ pointerEvents: openState ? 'auto' : 'none' }}>
                <div className="viewer2-actions">
                  <div className="viewer2-action-btn">
                    <Tooltip title={dataURL}>
                      <CopyToClipboard text={dataURL}>
                        <Button
                          type="primary"
                          size="small"
                          disabled={!openState}
                          onClick={this.handleCopyPath}
                        >
                          .txt
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <LoadingCover loading={firstLoading} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    frames: state.annotation.frames.sort(
      (frameA, frameB) => frameA.timestamp - frameB.timestamp
    ),
    objects: state.annotation.objects.sort(
      (objectA, objectB) => objectA.id - objectB.id
    ),
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation('viewer2d')(ViewerSignal))
)
