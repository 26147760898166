import { axios } from '../_helpers'
import Cookies from 'universal-cookie'
import jwtDecode from 'jwt-decode'

import { userConstants } from '../_constants'
import { userServices } from '../_services'
import { history } from '../_helpers'

export const userActions = {
  login,
  logout,
  create,
  get,
  getAll,
  update,
  deleteUser,
  getHome
}

const cookies = new Cookies()

function login(username, password) {
  return dispatch => {
    dispatch(request())

    const data = {
      username,
      password
    }

    return userServices.login(data).then(
      data => {
        const token = data.token
        const decoded = jwtDecode(token)

        // Set Expires
        cookies.set('_token', token, { expires: new Date(decoded.exp * 1000) })
        axios.defaults.headers.common['Token'] = token
        axios.defaults.headers['Token'] = token

        dispatch(success(decoded.User)) // Notice: User, not user
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: userConstants.AUTH_REQUEST }
  }

  function success(user) {
    return { type: userConstants.AUTH_SUCCESS, user }
  }

  function failure() {
    return { type: userConstants.AUTH_FAILURE }
  }
}

function logout() {
  return dispatch => {
    dispatch({ type: userConstants.LOGOUT })

    cookies.remove('_token')
    history.push('/login')
  }
}

function create(data) {
  return dispatch => {
    dispatch(request())

    return userServices.create(data).then(
      data => {
        dispatch(success(data.user))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: userConstants.CREATE_REQUEST }
  }

  function success(user) {
    return { type: userConstants.CREATE_SUCCESS, user }
  }

  function failure() {
    return { type: userConstants.CREATE_FAILURE }
  }
}

function get(id) {
  return dispatch => {
    dispatch(request())

    // When refreshed, reset token
    axios.defaults.headers['Token'] = cookies.get('_token')

    const params = { id }

    return userServices.get(params).then(
      data => {
        dispatch(success(data.user))
      },
      error => {
        dispatch(failure())

        // When token cannot be verified, logout and return to Login
        if (error.response.data.detail.includes('invalid')) {
          dispatch(logout())
        }

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: userConstants.GET_REQUEST }
  }

  function success(user) {
    return { type: userConstants.GET_SUCCESS, user }
  }

  function failure() {
    return { type: userConstants.GET_FAILURE }
  }
}

function getAll(page, pagesize, role,print) {
  return dispatch => {
    dispatch(request())

    const params = {
      page: page,
      pagesize: pagesize,
      role: role,
      print:print
    }

    return userServices.getAll(params).then(
      data => {
        dispatch(success(data.users, page, data.totalCount || 0, role))
        return { total: data.totalCount }
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: userConstants.GET_ALL_REQUEST }
  }

  function success(users, page, total, role) {
    let k = role ? role + 'total' : 'total'
    let res = { type: userConstants.GET_ALL_SUCCESS, users, page }
    res[k] = total
    return res
  }

  function failure() {
    return { type: userConstants.GET_ALL_FAILURE }
  }
}

function update(data) {
  return dispatch => {
    dispatch(request())

    return userServices.update(data).then(
      data => {
        dispatch(success(data.user))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: userConstants.UPDATE_REQUEST }
  }

  function success(user) {
    return { type: userConstants.UPDATE_SUCCESS, user }
  }

  function failure() {
    return { type: userConstants.UPDATE_FAILURE }
  }
}

function deleteUser(id) {
  return dispatch => {
    dispatch(request())

    const data = { id }

    return userServices.deleteUser(data).then(
      data => {
        dispatch(success(data.user))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: userConstants.DELETE_REQUEST }
  }

  function success(user) {
    return { type: userConstants.DELETE_SUCCESS, user }
  }

  function failure() {
    return { type: userConstants.DELETE_FAILURE }
  }
}

function getHome() {
  return dispatch => {
    dispatch(request())


    return userServices.getHome().then(
      data => {
        dispatch(success(data))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: userConstants.GETHOME_REQUEST }
  }

  function success(data) {
    return { type: userConstants.GETHOME_SUCCESS, data }
  }

  function failure() {
    return { type: userConstants.GETHOME_FAILURE }
  }
}
