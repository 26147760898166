import React from 'react'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Input, InputNumber, Button } from 'antd'

const BagForm = ({
  form,
  onSubmit,
  formItemLayout,
  tailFormItemLayout
}) => {
  const { getFieldDecorator, validateFields } = form

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((error, values) => {
      if (!error) {
        onSubmit(values)
      }
    })
  }

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Name can not be empty' }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Start timestamp">
        {getFieldDecorator('start_timestamp', {
          rules: [
            { required: true, message: 'Start timestamp can not be empty' }
          ]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="End timestamp">
        {getFieldDecorator('end_timestamp', {
          rules: [{ required: true, message: 'End timestamp can not be empty' }]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="Group ID">
        {getFieldDecorator('group_id', {
          rules: [{ required: true, message: 'Group ID can not be empty' }]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="Priority">
        {getFieldDecorator('priority', {
          rules: [{ required: true, message: 'Priority can not be empty' }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Status">
        {getFieldDecorator('status')(<Input />)}
      </Form.Item>
      <Form.Item label="Description">
        {getFieldDecorator('description')(<Input />)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Create bag
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'bag form' })(BagForm)
