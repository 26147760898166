import { role as ROLE } from '../_helpers/role'

export function dataToFields(data) {
  const fields = {}

  // Notice: Set password to ''
  Object.keys(data).forEach(key => {
    fields[key] = { value: key === 'password' ? '' : data[key] }
  })

  return fields
}

export function fieldsToData(fields, data) {
  // Notice: If password is '', it shouldn't be in data
  Object.keys(fields).forEach(key => {
    if (key === 'password' && !fields.password.value) return

    // if (key === 'group_id') {
    //   fields[key].value = fields.group_id.value.map(id => parseInt(id))
    // }

    data[key] = fields[key].value
  })

  return data
}

export const ROLES = [
  'admin',
  'groupAdmin',
  'globalExaminer',
  'groupExaminer',
  'globalAnnotator',
  'groupAnnotator',
  'developer'
]

export function getRole(role) {
  // Roles precedence

  if (role) {
    for (let ROLE of ROLES) {
      if (role.includes(ROLE)) return ROLE
    }
  }

  return null
}

export function getFilteredUsers(users, role, active = false) {
  if (!users) {
    return undefined
  }

  return users.filter(
    user => (!active || user.isActive) && user.role.includes(role.substring(1))
  )
}

export function isAnnotator(role) {
  return role === ROLE.globalAnnotator || role === ROLE.groupAnnotator
}

export function isExaminer(role) {
  return role === ROLE.globalExaminer || role === ROLE.groupExaminer
}

export function isAdmin(role) {
  return role === ROLE.admin || role === ROLE.groupAdmin
}
