import { groupConstants } from '../_constants'
import { groupServices } from '../_services'

export const groupActions = {
  create,
  get,
  getAll,
  update,
  deleteGroup
}

function create(data) {
  return dispatch => {
    dispatch(request())

    return groupServices.create(data).then(
      data => {
        dispatch(success(data.group))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: groupConstants.CREATE_REQUEST }
  }

  function success(group) {
    return { type: groupConstants.CREATE_SUCCESS, group }
  }

  function failure() {
    return { type: groupConstants.CREATE_FAILURE }
  }
}

function get(id) {
  return dispatch => {
    dispatch(request())

    const params = { id }

    return groupServices.get(params).then(
      data => {
        dispatch(success(data.group))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: groupConstants.GET_REQUEST }
  }

  function success(group) {
    return { type: groupConstants.GET_SUCCESS, group }
  }

  function failure() {
    return { type: groupConstants.GET_FAILURE }
  }
}

function getAll() {
  return dispatch => {
    dispatch(request())

    const params = {
      page: 1,
      pagesize: 1000
    }

    return groupServices.getAll(params).then(
      data => {
        dispatch(success(data.groups))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: groupConstants.GET_ALL_REQUEST }
  }

  function success(groups) {
    return { type: groupConstants.GET_ALL_SUCCESS, groups }
  }

  function failure() {
    return { type: groupConstants.GET_ALL_FAILURE }
  }
}

function update(data) {
  return dispatch => {
    dispatch(request())

    return groupServices.update(data).then(
      data => {
        dispatch(success(data.group))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: groupConstants.UPDATE_REQUEST }
  }

  function success(group) {
    return { type: groupConstants.UPDATE_SUCCESS, group }
  }

  function failure() {
    return { type: groupConstants.UPDATE_FAILURE }
  }
}

function deleteGroup(id) {
  return dispatch => {
    dispatch(request())

    const data = { id }

    return groupServices.deleteGroup(data).then(
      data => {
        dispatch(success(data.group))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: groupConstants.DELETE_REQUEST }
  }

  function success(group) {
    return { type: groupConstants.DELETE_SUCCESS, group }
  }

  function failure() {
    return { type: groupConstants.DELETE_FAILURE }
  }
}
