import React from 'react'
import './Guides.scss'
import { withTranslation } from 'react-i18next'

import { getRole } from '../../../_utils'

import { CustomIcon } from '../../../common'
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Divider } from 'antd'

const Guides = ({ user, t }) => (
  <div className="viewer2-shortcuts">
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">
        <span className="viewer2-shortcuts-title">{t('sc.commond')}</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.rectangle')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">R</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.fit')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">S</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.flip')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">F</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.center')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">C</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.fill')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">U</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.move')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">{t('sc.rightDrag')}</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.save')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="viewer2-shortcuts-key">S</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.next')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="viewer2-shortcuts-key">
          <ArrowRightOutlined />
        </span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.prev')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="viewer2-shortcuts-key">
          <ArrowLeftOutlined />
        </span>
      </div>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">
        <span className="viewer2-shortcuts-title">{t('sc.selected')}</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.remove')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">{t('sc.backspace')}</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.ctxMenu')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">{t('sc.rightClick')}</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.hide')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">H</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.moveUp')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">
          <ArrowUpOutlined />
        </span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.moveDown')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">
          <ArrowDownOutlined />
        </span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.moveLeft')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">
          <ArrowLeftOutlined />
        </span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.moveRight')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">
          <ArrowRightOutlined />
        </span>
      </div>
    </div>
    {getRole(user.role) === 'examiner' ? (
      <div className="viewer2-shortcuts-row">
        <div className="viewer2-shortcuts-command">{t('sc.fail')}</div>
        <div className="viewer2-shortcuts-keybinding">
          <span className="viewer2-shortcuts-key">Q</span>
        </div>
      </div>
    ) : null}
  </div>
)

export default withTranslation('viewer2d')(Guides)
