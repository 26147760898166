import { grpc } from '@improbable-eng/grpc-web'
import { DataRequest } from '../_proto/proto/sensordata_pb'
import { StreamingSensors } from '../_proto/proto/sensordata_pb_service'

export const dataServices = {
  getData,
}

const host = '/data/v1'

function getData(
  { bagName, sensorsList, startFrametime, endFrametime, framesList },
  onMessage,
  onEnd,
) {
  const dataRequest = new DataRequest()

  dataRequest.setRosbagname(bagName)
  dataRequest.setSensorsList(sensorsList)
  dataRequest.setStartframetime(startFrametime)
  dataRequest.setEndframetime(endFrametime)
  dataRequest.setFramesList(framesList)

  // For inspect request data
  // console.log(dataRequest.toObject())

  return grpc.invoke(StreamingSensors.GetSensors, {
    host,
    request: dataRequest,
    onHeaders: headers => {},
    onMessage: message => {
      onMessage && onMessage(message.toObject())
    },
    onEnd: (...end) => {
      onEnd && onEnd(end)
    },
  })
}
