import React from 'react'
import './HoverPanel.scss'

const HoverPanel = ({ active, children, style }) => {
  return (
    <div
      className={`hover-panel${active ? ' active' : ''}`}
      style={style}
    >
      <div className="hover-panel-inner">{children}</div>
    </div>
  )
}

export default HoverPanel
