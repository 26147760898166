import React from 'react'
import './PlusInfo.scss'
import { withTranslation } from 'react-i18next'

import { InputNumber, LabelType } from '../../../common'
import { MATERIAL_COLORS } from '../../constants'

import { Select, Button } from 'antd'

const Option = Select.Option
const AttributesTable = ({ label, onChange,visible, t }) => {
  return (
    <div className="views-labels-attributes views-labels-init-attributes" style={{ display: visible ? 'block' : 'none' }}>
      <div className="views-attributes-row">
        <div className="views-label-head">{t('attrs.type')}</div>
        <div className="views-label-content">
          <div className="views-label-content-row">
            <Select
              size="small"
              value={label.type}
              style={{width:'8rem'}}
              onChange={value => onChange(value, 'type')}
            >
              <Option value="truck">
                <LabelType color={MATERIAL_COLORS.TRUCK} />
                {t('type.truck')}
              </Option>
              <Option value="car">
                <LabelType color={MATERIAL_COLORS.CAR} />
                {t('type.car')}
              </Option>
              <Option value="pedestrian">
                <LabelType color={MATERIAL_COLORS.PEDESTRIAN} />
                {t('type.pedestrian')}
              </Option>
              <Option value="cyclist">
                <LabelType color={MATERIAL_COLORS.CYCLIST} />
                {t('type.cyclist')}
              </Option>
              <Option value="bicycle">
                <LabelType color={MATERIAL_COLORS.BICYCLE} />
                {t('type.bicycle')}
              </Option>
              <Option value="tricycle">
                <LabelType color={MATERIAL_COLORS.TRICYCLE} />
                {t('type.tricycle')}
              </Option>
              <Option value="cone">
                <LabelType color={MATERIAL_COLORS.CONE} />
                {t('type.cone')}
              </Option>
              <Option value="trolley">
                <LabelType color={MATERIAL_COLORS.TROLLEY} />
                {t('type.trolley')}
              </Option>
              <Option value="movable">
                <LabelType color={MATERIAL_COLORS.MOVABLE} />
                {t('type.movable')}
              </Option>
              <Option value="static">
                <LabelType color={MATERIAL_COLORS.STATIC} />
                {t('type.static')}
              </Option>
            </Select>
            {/* <Button
              type="primary"
              size="small"
              onClick={()=>handleSaveInit()}
            >
              {t('btns.save')}
            </Button> */}
          </div>
        </div>
      </div>
      <div className="views-attributes-row">
        <div className="views-label-head">
          <span className="views-label-head-plhd" />
          {t('attrs.dimension')}
        </div>
        <div className="views-label-content">
          <div className="views-label-content-row">
            <div className="views-label-content-mark">{t('attrs.length')}</div>
            <div className="views-label-content-mark">{t('attrs.width')}</div>
            <div className="views-label-content-mark">{t('attrs.height')}</div>
          </div>
          <div className="views-label-content-row">
            <InputNumber
              value={label.dimension.x}
              min="0.01"
              disabled={true}
              onChange={value => onChange(value, 'dimension', 'x')}
            />
            <InputNumber
              value={label.dimension.y}
              min="0.01"
              disabled={true}
              onChange={value => onChange(value,'dimension','y')}
            />
            <InputNumber
              value={label.dimension.z}
              min="0.01"
              disabled={true}
              onChange={value => onChange(value,'dimension','z')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation('views')(AttributesTable)
