import React, { Component } from 'react'
import './AdminHome.scss'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import {
  userActions,
  annotationActions,
  bagActions,
  objectTypeActions,
  taskTypeActions,
  groupActions,
  alertActions
} from '../_actions'
import { role } from '../_helpers'

import { Card, Divider, Button, Statistic, message } from 'antd'

const bodyStyle = {
  backgroundColor: '#202020',
  padding: '1.5rem 1.5rem 1rem'
}

const defaultDividerStyle = {
  margin: '1rem 0'
}

const verticalDividerStyle = {
  height: '1rem'
}

class AdminHome extends Component {
  componentDidMount() {
    const { dispatch } = this.props

    dispatch(userActions.getHome())

    // dispatch(
    //   annotationActions.getAllAnnotations({
    //     finishdate_start: 0,
    //     finishdate_end: new moment().valueOf(),
    //     page: 1,
    //     pagesize: 1
    //   })
    // )

    // Object.values(role).forEach(roleName =>
    //   dispatch(userActions.getAll(1, 1, roleName))
    // )

    // dispatch(bagActions.getAll(1, 1))

    // dispatch(objectTypeActions.getAll())

    // dispatch(taskTypeActions.getAll())

    // dispatch(groupActions.getAll())
  }

  componentDidUpdate(prevProps) {
    const { dispatch, message: alertMessage } = this.props

    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }
  }

  render() {
    const {
      // annotationsCount,
      users,
      homeData,
      groups,
      t
    } = this.props
    let roleCount = {}
    for (let r of Object.values(role)) {
      let k = r + 'total'
      roleCount[r] = this.props[k]
    }
    return (
      <div className="admin">
        <div className="admin-panel">
          <div className="admin-card-container">
            <Card
              title={t('usersGroupsTitle')}
              bordered={false}
              bodyStyle={bodyStyle}
            >
              <div className="admin-card-body">
                <div className="admin-statistic-container">
                  <Statistic
                    title={t('common:role.admin')}
                    value={homeData.admin || 0}
                  />
                </div>
                <div className="admin-statistic-container">
                  <Statistic
                    title={t('common:role.globalAnnotator')}
                    value={
                      (Number(homeData.globalAnnotator) ) +
                      (Number(homeData.groupAnnotator) )
                    }
                  />
                </div>
                <div className="admin-statistic-container">
                  <Statistic
                    title={t('common:role.globalExaminer')}
                    value={
                      (Number(homeData.groupExaminer)  ) +
                      (Number(homeData.globalExaminer)  )
                    }
                  />
                </div>
                <div className="admin-statistic-container">
                  <Statistic
                    title={t('common:role.developer')}
                    value={homeData.developer || 0}
                  />
                </div>
                <div className="admin-statistic-container">
                  <Statistic
                    title={t('stats.groups')}
                    value={homeData.groups || 0}
                  />
                </div>
              </div>
              <Divider style={defaultDividerStyle} />
              <div className="admin-card-actions">
                <Link to="/users">
                  <Button type="primary" size="small" ghost>
                    {t('btns.allUsers')}
                  </Button>
                </Link>
                <Divider type="vertical" style={verticalDividerStyle} />
                <Link to="/groups">
                  <Button type="primary" size="small" ghost>
                    {t('btns.allGroups')}
                  </Button>
                </Link>
              </div>
            </Card>
          </div>
          <div className="admin-card-container">
            <Card
              title={t('statisticsTitle')}
              bordered={false}
              bodyStyle={bodyStyle}
            >
              <div className="admin-card-body">
                <div className="admin-statistic-container">
                  <Statistic
                    title={t('stats.total')}
                    value={
                      homeData.stat || 0
                    }
                  />
                </div>
              </div>
              <Divider style={defaultDividerStyle} />
              <div className="admin-card-actions">
                <Link to="/statistics">
                  <Button type="primary" size="small" ghost>
                    {t('btns.allStatistics')}
                  </Button>
                </Link>
              </div>
            </Card>
          </div>
        </div>
        <div className="admin-panel">
          <div className="admin-card-container">
            <Card
              title={t('annotationsTitle')}
              bordered={false}
              bodyStyle={bodyStyle}
            >
              <div className="admin-card-body">
                <div className="admin-statistic-container">
                  <Statistic
                    title={t('stats.total')}
                    value={homeData.annotations || 0}
                  />
                </div>
              </div>
              <Divider style={defaultDividerStyle} />
              <div className="admin-card-actions">
                <Link to="/annotations">
                  <Button type="primary" size="small" ghost>
                    {t('btns.allAnnotations')}
                  </Button>
                </Link>
              </div>
            </Card>
          </div>
          <div className="admin-card-container">
            <Card title={t('bagsTitle')} bordered={false} bodyStyle={bodyStyle}>
              <div className="admin-card-body">
                <div className="admin-statistic-container">
                  <Statistic title={t('stats.total')} value={homeData.bags || 0} />
                </div>
              </div>
              <Divider style={defaultDividerStyle} />
              <div className="admin-card-actions">
                <Link to="/bags">
                  <Button type="primary" size="small" ghost>
                    {t('btns.allBags')}
                  </Button>
                </Link>
              </div>
            </Card>
          </div>
        </div>
        <div className="admin-panel">
          <div className="admin-card-container">
            <Card
              title={t('taskTypeTitle')}
              bordered={false}
              bodyStyle={bodyStyle}
            >
              <div className="admin-card-body">
                <div className="admin-statistic-container">
                  <Statistic
                    title={t('stats.total')}
                    value={homeData.tasktypes || 0}
                  />
                </div>
              </div>
              <Divider style={defaultDividerStyle} />
              <div className="admin-card-actions">
                <Link to="/tasktypes">
                  <Button type="primary" size="small" ghost>
                    {t('btns.allTaskTypes')}
                  </Button>
                </Link>
              </div>
            </Card>
          </div>

          <div className="admin-card-container">
            <Card
              title={t('objectTypeTitle')}
              bordered={false}
              bodyStyle={bodyStyle}
            >
              <div className="admin-card-body">
                <div className="admin-statistic-container">
                  <Statistic
                    title={t('stats.total')}
                    value={homeData.objectTypes || 0}
                  />
                </div>
              </div>
              <Divider style={defaultDividerStyle} />
              <div className="admin-card-actions">
                <Link to="/objecttypes">
                  <Button type="primary" size="small" ghost>
                    {t('btns.allObjectTypes')}
                  </Button>
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let res = {
    users: state.authentication.users,
    creatingUser: state.authentication.creatingUser,
    // annotationsCount: state.annotation.total,
    // bagsCount: state.bag.total,
    // objectTypes: state.objectType.objectTypes,
    // taskTypes: state.taskType.taskTypes,
    // groups: state.group.groups,
    homeData: state.authentication.homeData,
    message: state.alert.message
  }
  // for (let r of Object.values(role)) {
  //   let k = r + 'total'
  //   res[k] = state.authentication[k]
  // }
  return res
}

export default connect(mapStateToProps)(
  withTranslation(['adminHome', 'users', 'groups', 'common'])(AdminHome)
)
