import {
  annotationConstants,
  frameConstants,
  objectConstants,
  tagConstants,
  getSomeConstants,
} from '../_constants'
import { annotationServices } from '../_services'
import { alertActions } from './alert.actions'

export const annotationActions = {
  getAllAnnotations,
  createAnnotation,
  updateAnnotation,
  updateAllAnnotations,
  assignAnnotation,
  getFrame,
  getAllFrames,
  createFrame,
  updateFrame,
  resetFrame,
  getObject,
  getAllObjects,
  createObject,
  updateObject,
  getAllTags,
  createTag,
  updateTag,
  getSome,
  getStat,
  getStatSum,
  calcuStats
}

/** Annotations */
function getAllAnnotations(params) {
  return dispatch => {
    dispatch(request())

    return annotationServices.getAllAnnotations(params).then(
      ({ annotations, bags, ...stats }) => {
        if (annotations && bags) {
          annotations.forEach(annotation => {
            const targetBag = bags.find(bag => bag.id === annotation.bagId)
            if (targetBag) {
              annotation.bagName = targetBag.name
              annotation.description = targetBag.description
            }
          })
        }

        dispatch(success(annotations, params.page, stats.totalCount))

        return stats
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: annotationConstants.GET_ALL_REQUEST }
  }

  function success(annotations, page, total) {
    return {
      type: annotationConstants.GET_ALL_SUCCESS,
      annotations,
      page,
      total
    }
  }

  function failure() {
    return { type: annotationConstants.GET_ALL_FAILURE }
  }
}

function createAnnotation(data) {
  return dispatch => {
    dispatch(request())

    return annotationServices.createAnnotation(JSON.stringify(data)).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: annotationConstants.CREATE_REQUEST }
  }

  function success() {
    return { type: annotationConstants.CREATE_SUCCESS }
  }

  function failure() {
    return { type: annotationConstants.CREATE_FAILURE }
  }
}

function updateAnnotation(data) {
  return dispatch => {
    dispatch(request())

    return annotationServices.updateAnnotation(JSON.stringify(data)).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: annotationConstants.UPDATE_REQUEST }
  }

  function success() {
    return { type: annotationConstants.UPDATE_SUCCESS }
  }

  function failure() {
    return { type: annotationConstants.UPDATE_FAILURE }
  }
}

function updateAllAnnotations(data) {
  return dispatch => {
    dispatch(request())

    return annotationServices.updateAllAnnotations(JSON.stringify(data)).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: annotationConstants.UPDATE_ALL_REQUEST }
  }

  function success() {
    return { type: annotationConstants.UPDATE_ALL_SUCCESS }
  }

  function failure() {
    return { type: annotationConstants.UPDATE_ALL_FAILURE }
  }
}

function assignAnnotation(data) {
  return dispatch => {
    dispatch(request())

    return annotationServices.assignAnnotation(JSON.stringify(data)).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: annotationConstants.ASSIGN_REQUEST }
  }

  function success() {
    return { type: annotationConstants.ASSIGN_SUCCESS }
  }

  function failure() {
    return { type: annotationConstants.ASSIGN_FAILURE }
  }
}

/** Frames */
function getFrame(id) {
  return dispatch => {
    dispatch(request())

    const params = { id }

    return annotationServices.getAllObjects(params).then(
      data => {
        dispatch(success(JSON.parse(data.object.data))) // Notice: object, not objects
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: objectConstants.GET_REQUEST }
  }

  function success(objects) {
    return { type: objectConstants.GET_SUCCESS, objects }
  }

  function failure() {
    return { type: objectConstants.GET_FAILURE }
  }
}

function getAllFrames(bagId, startTimestamp, endTimestamp, status) {
  return dispatch => {
    dispatch(request())

    return annotationServices
      .getAllFrames({
        bagId: bagId,
        startTimestamp: startTimestamp,
        endTimestamp: endTimestamp,
        status
      })
      .then(
        data => {
          dispatch(success(data.frames))
        },
        error => {
          dispatch(failure())

          return Promise.reject(error.response.data)
        }
      )
  }

  function request() {
    return { type: frameConstants.GET_ALL_REQUEST }
  }

  function success(frames) {
    return { type: frameConstants.GET_ALL_SUCCESS, frames }
  }

  function failure() {
    return { type: frameConstants.GET_ALL_FAILURE }
  }
}

function createFrame(data) {
  return dispatch => {
    dispatch(request())

    return annotationServices.createFrame(JSON.stringify(data)).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure)

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: frameConstants.CREATE_REQUEST }
  }

  function success() {
    return { type: frameConstants.CREATE_SUCCESS }
  }

  function failure() {
    return { type: frameConstants.CREATE_FAILURE }
  }
}

function updateFrame(data) {
  return dispatch => {
    dispatch(request())

    return annotationServices.updateFrame(data).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure)

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: frameConstants.CREATE_REQUEST }
  }

  function success() {
    return { type: frameConstants.CREATE_SUCCESS }
  }

  function failure() {
    return { type: frameConstants.CREATE_FAILURE }
  }
}

function resetFrame(data) {
  return dispatch => {
    dispatch(request())

    return annotationServices.resetFrame(data).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure)

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: frameConstants.RESET_REQUEST }
  }

  function success() {
    return { type: frameConstants.RESET_SUCCESS }
  }

  function failure() {
    return { type: frameConstants.RESET_FAILURE }
  }
}

/** Object */
function getObject(id) {
  return dispatch => {
    dispatch(request())

    const params = { id }

    return annotationServices.getObject(params).then(
      data => {
        // Decide 2D/3D/Fusion data
        const objData = JSON.parse(data.object.data) // Notice: object, not objects
        const objId = data.object.id
        const objects = objData.size
          ? objData.objects // 2D data
          : objData.pcd
            ? objData.pcd // Fusion data
            : objData // 3D data
        dispatch(successId(objId))
        dispatch(success(objects))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: objectConstants.GET_REQUEST }
  }

  function success(objects) {
    return { type: objectConstants.GET_SUCCESS, objects }
  }
  function successId(objId) {
    return { type: objectConstants.GET_ID_SUCCESS, objId }
  }

  function failure() {
    return { type: objectConstants.GET_FAILURE }
  }
}

function getAllObjects() {
  return dispatch => {
    dispatch(request())

    const params = {}

    return annotationServices.getObject(params).then(
      data => {
        dispatch(success(JSON.parse(data.object.data)))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: objectConstants.GET_ALL_REQUEST }
  }

  function success(allObjects) {
    return { type: objectConstants.GET_ALL_SUCCESS, allObjects }
  }

  function failure() {
    return { type: objectConstants.GET_ALL_FAILURE }
  }
}

function createObject(data, frameId, objectsCount, userId, annotationId) {
  return dispatch => {
    dispatch(request())

    return annotationServices
      .createObject({
        object: {
          data: JSON.stringify(data),
          count: objectsCount,
          // id:frameId,
          count:objectsCount,
          operator_id: userId,
        },
        // data: JSON.stringify(data),
        // count: objectsCount,
        frame_id: frameId,
        // id: frameId,
        // operator_id: userId,
        // annotation_id: annotationId
      })
      .then(
        data => {
          dispatch(success(data.object))

          return Promise.resolve(data.object)
        },
        error => {
          dispatch(failure())

          return Promise.reject(error.response.data)
        }
      )
  }

  function request() {
    return { type: objectConstants.CREATE_REQUEST }
  }

  function success(object) {
    return { type: objectConstants.CREATE_SUCCESS, object }
  }

  function failure() {
    return { type: objectConstants.CREATE_FAILURE }
  }
}

function updateObject(data, objectId, objectsCount, userId, annotationId) {
  return dispatch => {
    dispatch(request())

    return annotationServices
      .updateObject({
        id: objectId,
        data: JSON.stringify(data),
        count: objectsCount,
        operator_id: userId,
        // annotation_id: annotationId
      })
      .then(
        data => {
          dispatch(success(data.object))
          dispatch(alertActions.success('Labels saved'))
          return Promise.resolve(data.object)
        },
        error => {
          dispatch(failure())

          return Promise.reject(error.response.data)
        }
      )
  }

  function request() {
    return { type: objectConstants.UPDATE_REQUEST }
  }

  function success(object) {
    return { type: objectConstants.UPDATE_SUCCESS, object }
  }

  function failure() {
    return { type: objectConstants.UPDATE_FAILURE }
  }
}

/** Tags */
function getAllTags() {
  return dispatch => {
    dispatch(request())

    const params = {}

    return annotationServices.getAllTags(params).then(
      data => {
        dispatch(success(data))
      },
      error => {
        dispatch(failure())
        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: tagConstants.GET_ALL_REQUEST }
  }

  function success(data) {
    return { type: tagConstants.GET_ALL_SUCCESS, data }
  }

  function failure() {
    return { type: tagConstants.GET_ALL_FAILURE }
  }
}

function createTag(data) {
  return dispatch => {
    dispatch(request())

    return annotationServices
      .createTag({ tag: data })
      .then(
        data => {
          dispatch(success())

        },
        error => {
          dispatch(failure())

          return Promise.reject(error.response.data)
        }
      )
  }

  function request() {
    return { type: tagConstants.CREATE_REQUEST }
  }

  function success() {
    return { type: tagConstants.CREATE_SUCCESS }
  }

  function failure() {
    return { type: tagConstants.CREATE_FAILURE }
  }
}

function updateTag(data) {
  return dispatch => {
    dispatch(request())

    return annotationServices
      .updateTag(JSON.stringify(data))
      .then(
        () => {
          dispatch(success())
        },
        error => {
          dispatch(failure())

          return Promise.reject(error.response.data)
        }
      )
  }

  function request() {
    return { type: tagConstants.UPDATE_REQUEST }
  }

  function success() {
    return { type: tagConstants.UPDATE_SUCCESS }
  }

  function failure() {
    return { type: tagConstants.UPDATE_FAILURE }
  }
}

function getSome(params) {
  return dispatch => {
    dispatch(request())

    return annotationServices
      .getSome(params)
      .then(
        (data) => {
          dispatch(success(data))
        },
        error => {
          dispatch(failure())

          return Promise.reject(error.response.data)
        }
      )
  }

  function request() {
    return { type: getSomeConstants.GET_ALL_REQUEST }
  }

  function success(data) {
    return { type: getSomeConstants.GET_ALL_SUCCESS, data }
  }

  function failure() {
    return { type: getSomeConstants.GET_ALL_FAILURE }
  }
}

function getStat(params) {
  return dispatch => {
    dispatch(request())

    return annotationServices
      .getStat(params)
      .then(
        (data) => {
          dispatch(success(data))
        },
        error => {
          dispatch(failure())

          return Promise.reject(error.response.data)
        }
      )
  }

  function request() {
    return { type: annotationConstants.GET_STAT_REQUEST }
  }

  function success(data) {
    return { type: annotationConstants.GET_STAT_SUCCESS, data }
  }

  function failure() {
    return { type: annotationConstants.GET_STAT_FAILURE }
  }
}

function getStatSum(params) {
  return dispatch => {
    dispatch(request())

    return annotationServices
      .getStat(params)
      .then(
        (data) => {
          dispatch(success(data))
        },
        error => {
          dispatch(failure())

          return Promise.reject(error.response.data)
        }
      )
  }

  function request() {
    return { type: annotationConstants.GET_STATSUM_REQUEST }
  }

  function success(data) {
    return { type: annotationConstants.GET_STATSUM_SUCCESS, data }
  }

  function failure() {
    return { type: annotationConstants.GET_STATSUM_FAILURE }
  }
}

function calcuStats(params) {
  return dispatch => {
    dispatch(request())

    return annotationServices
      .calcuStats(params)
      .then(
        (data) => {
          dispatch(success(data))
        },
        error => {
          dispatch(failure())

          return Promise.reject(error.response.data)
        }
      )
  }

  function request() {
    return { type: annotationConstants.GET_CALCUSTAT_REQUEST }
  }

  function success(data) {
    return { type: annotationConstants.GET_CALCUSTAT_SUCCESS, data }
  }

  function failure() {
    return { type: annotationConstants.GET_CALCUSTAT_FAILURE }
  }
}