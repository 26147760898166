import React, { Component } from 'react'
import './Layout.scss'
import { withRouter } from 'react-router-dom'
import { Header } from '../../common/'

class Layout extends Component {
  render() {
    const { location } = this.props

    return (
      <div className="layout">
        <Header key={location.key} />
        <div className="layout-main">{this.props.children}</div>
      </div>
    )
  }
}

export default withRouter(Layout)
