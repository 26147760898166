export const annotationConstants = {
  GET_ALL_REQUEST: 'ANNOTATION_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'ANNOTATION_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'ANNOTATION_GET_ALL_FAILURE',

  CREATE_REQUEST: 'ANNOTATION_CREATE_REQUEST',
  CREATE_SUCCESS: 'ANNOTATION_CREATE_SUCCESS',
  CREATE_FAILURE: 'ANNOTATION_CREATE_FAILURE',

  UPDATE_REQUEST: 'ANNOTATION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ANNOTATION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ANNOTATION_UPDATE_FAILURE',

  UPDATE_ALL_REQUEST: 'ANNOTATION_ALL_UPDATE_REQUEST',
  UPDATE_ALL_SUCCESS: 'ANNOTATION_ALL_UPDATE_SUCCESS',
  UPDATE_ALL_FAILURE: 'ANNOTATION_ALL_UPDATE_FAILURE',

  ASSIGN_REQUEST: 'ANNOTATION_ASSIGN_REQUEST',
  ASSIGN_SUCCESS: 'ANNOTATION_ASSIGN_SUCCESS',
  ASSIGN_FAILURE: 'ANNOTATION_ASSIGN_FAILURE',

  GET_STAT_REQUEST: 'GET_STAT_REQUEST',
  GET_STAT_SUCCESS: 'GET_STAT_SUCCESS',
  GET_STAT_FAILURE: 'GET_STAT_FAILURE',

  GET_STATSUM_REQUEST: 'GET_STATSUM_REQUEST',
  GET_STATSUM_SUCCESS: 'GET_STATSUM_SUCCESS',
  GET_STATSUM_FAILURE: 'GET_STATSUM_FAILURE',

  GET_CALCUSTAT_REQUEST: 'GET_CALCUSTAT_REQUEST',
  GET_CALCUSTAT_SUCCESS: 'GET_CALCUSTAT_SUCCESS',
  GET_CALCUSTAT_FAILURE: 'GET_CALCUSTAT_FAILURE',
}

export const frameConstants = {
  GET_ALL_REQUEST: 'FRAME_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'FRAME_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'FRAME_GET_ALL_FAILURE',

  GET_REQUEST: 'FRAME_GET_ALL_REQUEST',
  GET_SUCCESS: 'FRAME_GET_REQUEST',
  GET_FAILURE: 'FRAME_GET_FAILURE ',

  CREATE_REQUEST: 'FRAME_CREATE_REQUEST',
  CREATE_SUCCESS: 'FRAME_CREATE_SUCCESS',
  CREATE_FAILURE: 'FRAME_CREATE_FAILURE',

  UPDATE_REQUEST: 'FRAME_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'FRAME_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'FRAME_UPDATE_FAILURE',

  RESET_REQUEST: 'FRAME_RESET_REQUEST',
  RESET_SUCCESS: 'FRAME_RESET_SUCCESS',
  RESET_FAILURE: 'FRAME_RESET_FAILURE'
}

export const objectConstants = {
  GET_REQUEST: 'OBJECT_GET_REQUEST',
  GET_SUCCESS: 'OBJECT_GET_SUCCESS',
  GET_ID_SUCCESS: 'OBJECT_GET_ID_SUCCESS',
  GET_FAILURE: 'OBJECT_GET_FAILURE',

  GET_ALL_REQUEST: 'OBJECT_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'OBJECT_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'OBJECT_GET_ALL_FAILURE',

  CREATE_REQUEST: 'OBJECT_CREATE_REQUEST',
  CREATE_SUCCESS: 'OBJECT_CREATE_SUCCESS',
  CREATE_FAILURE: 'OBJECT_CREATE_FAILURE',

  UPDATE_REQUEST: 'OBJECT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'OBJECT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'OBJECT)_UPDATE_FAILURE'
}

export const PCD_COLOR = [104 / 255, 187 / 255, 108 / 255]
