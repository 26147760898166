import { tagConstants } from "../_constants"

const initState = {
    tags: []
}

export function tag(state = initState, action) {
    switch (action.type) {
        case tagConstants.GET_ALL_REQUEST:
            return {
                ...state,
                gettingTags: true
            }
        case tagConstants.GET_ALL_SUCCESS:
            // console.log('suta',action)
            return {
                tags: action.data.tag,
                // tags: ['1','2','3','4'],
                gettingTags: false
            }
        case tagConstants.GET_ALL_FAILURE:
            // console.log('fail')
            return {
                // tags: ['1','2','3','4'],
                gettingTags: false
            }
        case tagConstants.CREATE_REQUEST:
            return {
                ...state,
                creatingTags: true
            }
        case tagConstants.CREATE_SUCCESS:
            return {
                ...state,
                creatingTags: false
            }
        case tagConstants.CREATE_FAILURE:
            return {
                ...state,
                creatingTags: false
            }
        case tagConstants.UPDATE_REQUEST:
            return {
                ...state,
                updatingTags: true
            }
        case tagConstants.UPDATE_SUCCESS:
            return {
                ...state,
                updatingTags: false
            }
        case tagConstants.UPDATE_FAILURE:
            return {
                ...state,
                updatingTags: false
            }
        default:
            return state
    }
}