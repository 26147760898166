import { userConstants } from '../_constants'
import { ROLES } from '../_utils'

const initailState = {
  user: null,
  users: [],
  page: 0,
  total: 0,
  homeData:{}
}

export function authentication(state = initailState, action) {
  switch (action.type) {
    // Auth
    case userConstants.AUTH_REQUEST:
      return {
        ...state,
        loggingIn: true
      }
    case userConstants.AUTH_SUCCESS:
      return {
        ...state,
        user: action.user,
        loggingIn: false
      }
    case userConstants.AUTH_FAILURE:
      return {
        ...state,
        loggingIn: false
      }
    // Logout
    case userConstants.LOGOUT:
      return initailState
    // Get
    case userConstants.GET_REQUEST:
      return state
    case userConstants.GET_SUCCESS:
      return {
        ...state,
        user: action.user
      }
    case userConstants.GET_FAILURE:
      return state
    // Get all
    case userConstants.GET_ALL_REQUEST:
      return {
        ...state,
        gettingUsers: true
      }
    case userConstants.GET_ALL_SUCCESS:
      let res = {
        ...state,
        users: action.users,
        page: action.page,
        gettingUsers: false
      }
      if (action.total) {
        res['total'] = action.total
      }
      for (let r of ROLES) {
        let k = r + 'total'
        if (action[k]) {
          res[k] = action[k]
        }
      }
      return res
    case userConstants.GET_ALL_FAILURE:
      return {
        ...state,
        gettingUsers: false
      }
    // Create
    case userConstants.CREATE_REQUEST:
      return {
        ...state,
        creatingUser: true
      }
    case userConstants.CREATE_SUCCESS:
      return {
        ...state,
        creatingUser: false
      }
    case userConstants.CREATE_FAILURE:
      return {
        ...state,
        creatingUser: false
      }
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        updatingUser: true
      }
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updatingUser: false
      }
    case userConstants.UPDATE_FAILURE:
      return {
        ...state,
        updatingUser: false
      }
    case userConstants.DELETE_REQUEST:
      return state
    case userConstants.DELETE_SUCCESS:
      return state
    case userConstants.DELETE_FAILURE:
      return state
    case userConstants.GETHOME_REQUEST:
      return state
    case userConstants.GETHOME_SUCCESS:
      return {
        ...state,
        homeData:action.data
      }
    case userConstants.GETHOME_FAILURE:
      return state
    default:
      return state
  }
}
