// import { cloneDeep } from 'lodash'
function deepCloneObject3D(object3D) {
  // 创建一个新的 Object3D 实例
  var clonedObject = new THREE.Object3D()
  // 遍历原始对象的子对象
  object3D.children.forEach(function (child) {
    // 检查子对象是否为 CSS2DObject
    if (child instanceof THREE.CSS2DObject) {
      // 如果是 CSS2DObject，跳过并继续下一个子对象
      return
    }

    // 递归深拷贝子对象并将其添加到克隆对象中
    var clonedChild = deepCloneObject3D(child)
    clonedObject.add(clonedChild)
  })

  // 复制其他属性（位置、缩放等）
  clonedObject.position.copy(object3D.position)
  clonedObject.scale.copy(object3D.scale)
  clonedObject.rotation.copy(object3D.rotation)
  // 可以根据需要复制其他属性
  clonedObject.userData = JSON.parse(JSON.stringify(object3D.userData))
  clonedObject.name = object3D.name
  clonedObject.showName = object3D.showName
  clonedObject.color = object3D.color
  clonedObject.drawMode = object3D.drawMode
  clonedObject.forName = object3D.forName
  clonedObject.frustumCulled = object3D.frustumCulled
  return clonedObject
}


// 实现撤销回退队列的管理功能
class QueueManager {
  constructor() {
    // 最大回退操作步骤
    this.maxStep = 60

    this.undoQueue = []
    this.redoQueue = []
  }

  saveSnap(state) {
    const labels = state.labels.map((label) => deepCloneObject3D(label))
    const snap = {
      ...state,
      labels,
    }
    if (this.undoQueue.length >= this.maxStep) {
      this.undoQueue.shift()
    }
    this.undoQueue.push(snap)
    // 注意！！每次执行命令时清空重做栈
    this.redoQueue = []
  }

  /**
   * 撤销操作
   * @param {*} state 组件的state 当前状态
   */
  undo(state) {
    const labels = state.labels.map((label) => deepCloneObject3D(label))
    const snap = {
      ...state,
      labels,
    }
    const currentState = this.undoQueue[this.undoQueue.length - 1]
    this.redoQueue.push(snap)
    this.undoQueue.pop()
    return currentState
  }

  /**
   * 回退操作
   * @param {*} state 组件的state 当前状态
   */
  redo(state) {
    const labels = state.labels.map((label) => deepCloneObject3D(label))
    const snap = {
      ...state,
      labels,
    }
    const currentState = this.redoQueue[this.redoQueue.length - 1]
    this.undoQueue.push(snap)
    this.redoQueue.pop()
    return currentState
  }

  /**
   * 获取撤销队列
   */
  getUndoQueue() {
    return this.undoQueue
  }

  /**
   * 获取回退队列
   */
  getRedoQueue() {
    return this.redoQueue
  }
}

/* bug手中过，片叶不沾身！ */

export default QueueManager