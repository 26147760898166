export const taskTypeConstants = {
  GET_REQUEST: 'TASK_TYPE_GET_REQUEST',
  GET_SUCCESS: 'TASK_TYPE_GET_SUCCESS',
  GET_FAILURE: 'TASK_TYPE_GET_FAILURE',

  GET_ALL_REQUEST: 'TASK_TYPE_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'TASK_TYPE_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'TASK_TYPE_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'TASK_TYPE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'TASK_TYPE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TASK_TYPE_UPDATE_FAILURE',

  CREATE_REQUEST: 'TASK_TYPE_CREATE_REQUEST',
  CREATE_SUCCESS: 'TASK_TYPE_CREATE_SUCCESS',
  CREATE_FAILURE: 'TASK_TYPE_CREATE_FAILURE',

  DELETE_REQUEST: 'TASK_TYPE_DELETE_REQUEST',
  DELETE_SUCCESS: 'TASK_TYPE_DELETE_SUCCESS',
  DELETE_FAILURE: 'TASK_TYPE_DELETE_FAILURE'
}
