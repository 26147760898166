import React, { Component } from 'react'
import './Groups.scss'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { groupActions, alertActions } from '../_actions'
import { GroupForm } from '../common'
import { dataToFields, fieldsToData } from '../_utils'

import { FolderOutlined, PlusOutlined } from '@ant-design/icons'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import {
  Table,
  ConfigProvider,
  Button,
  Input,
  Checkbox,
  Modal,
  message,
  Divider,
  Tag,
  Switch,
  Popconfirm,
  Tooltip,
} from 'antd'

const { Column } = Table
const CheckboxGroup = Checkbox.Group

const modalBodyStyle = {
  backgroundColor: '#202020',
  padding: '1.5rem 1.5rem 0.5rem'
}

const formItemLayout = {
  labelAlign: 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const tailFormItemLayout = {
  wrapperCol: {
    span: 18,
    offset: 6
  }
}

const GROUP_STATUS_COLOR = {
  ACTIVE: '#1A5276',
  INACTIVE: '#404040'
}

const modalWidth = 700

const customizeRenderEmpty = () => (
  <div className="groups-empty">
    <FolderOutlined style={{ fontSize: '3rem', color: '#797979' }} />
    <p>No groups</p>
  </div>
)

const GroupEditForm = Form.create({
  //
  name: 'groupEdit',
  //
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields)
  },
  //
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({
        ...props.name,
        value: props.name ? props.name.value : ''
      }),
      email: Form.createFormField({
        ...props.email,
        value: props.email ? props.email.value : ''
      }),
      phonenumber: Form.createFormField({
        ...props.phonenumber,
        value: props.phonenumber ? props.phonenumber.value : ''
      }),
      description: Form.createFormField({
        ...props.description,
        value: props.description ? props.description.value : ''
      }),
      isActive: Form.createFormField({
        ...props.isActive,
        value: props.isActive ? props.isActive.value : false
      })
    }
  },
  //
  onValuesChange(_, values) {}
})(({ form, onSubmit, onDelete, loading, t }) => {
  const { getFieldDecorator } = form

  return (
    <Form {...formItemLayout}>
      <Form.Item label={t('name')}>
        {getFieldDecorator('name')(<Input />)}
      </Form.Item>
      <Form.Item label={t('email')}>
        {getFieldDecorator('email', {
          rules: [
            { type: 'email', required: false, message: 'Invalid email address' }
          ]
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('phone')}>
        {getFieldDecorator('phonenumber')(<Input />)}
      </Form.Item>
      <Form.Item label={t('description')}>
        {getFieldDecorator('description')(
          <Input.TextArea autosize={{ minRows: 1, maxRows: 4 }} />
        )}
      </Form.Item>
      <Form.Item label={t('activeStatus')}>
        {getFieldDecorator('isActive', { valuePropName: 'checked' })(
          <Switch />
        )}
      </Form.Item>
      <Divider />
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" onClick={onSubmit} loading={loading}>
          {t('btns.update')}
        </Button>
        <Popconfirm
          title={t('pop.deleteGroup')}
          onConfirm={onDelete}
          okText={t('pop.okText')}
          cancelText={t('pop.cancelText')}
        >
          <span className="groups-delete-button">{t('btns.delete')}</span>
        </Popconfirm>
      </Form.Item>
    </Form>
  )
})

class Groups extends Component {
  state = {
    fields: {},
    isEditVisible: false,
    isCreateVisible: false
  }

  componentDidMount() {
    const { dispatch, groups } = this.props
    groups.length || dispatch(groupActions.getAll())
  }

  componentDidUpdate(prevProps) {
    const { dispatch, message: alertMessage } = this.props

    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }
  }

  handleOpenEditModal = record => {
    this.isActive = !!record.isActive

    this.setState({
      fields: dataToFields(record),
      isEditVisible: true
    })
  }

  handleCloseEditModal = () => {
    this.setState({
      fields: {},
      isEditVisible: false
    })
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }))
  }

  handleToggleCreateModal = () => {
    const { isCreateVisible } = this.state

    this.setState({
      isCreateVisible: !isCreateVisible
    })
  }

  handleCreateGroup = values => {
    const { dispatch, t } = this.props

    // Group default is active
    values.isActive = true

    dispatch(groupActions.create(values))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.createSuccess')
          })
        )
        dispatch(groupActions.getAll())

        this.handleToggleCreateModal()
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: this.translateErrorToMessage(error.detail)
          })
        )
      })
  }

  handleUpdateGroup = () => {
    const { fields } = this.state
    const { dispatch, t } = this.props

    const data = fieldsToData(fields, {
      isActive: this.isActive
    })

    dispatch(groupActions.update(data))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.updateSuccess')
          })
        )
        dispatch(groupActions.getAll())
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alerts.updateFailure')
          })
        )
      })
  }

  handleDeleteGroup = () => {
    const { fields } = this.state
    const { dispatch, t } = this.props

    dispatch(groupActions.deleteGroup(fields.id.value))
      .then(() => {
        this.handleCloseEditModal()

        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.deleteSuccess')
          })
        )
        dispatch(groupActions.getAll())
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alerts.deleteFailure')
          })
        )
      })
  }

  getColumnStatusProps = dataIndex => {
    const { t } = this.props

    return {
      filters: [
        {
          text: 'active',
          value: true
        },
        {
          text: 'inactive',
          value: false
        }
      ],
      // '!!': Convert 'undefined' to 'false'
      onFilter: (value, record) => !!record[dataIndex] === value,
      filterDropdown: ({
        filters,
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        const checkboxes = filters.map(filter => (
          <div className="groups-checkboxes-row" key={filter.value.toString()}>
            <Checkbox value={filter.value}>{t(filter.text)}</Checkbox>
          </div>
        ))

        return (
          <div style={{ padding: '0.5rem' }}>
            <div className="groups-checkboxes">
              <CheckboxGroup
                value={selectedKeys}
                onChange={checkedValues => setSelectedKeys(checkedValues)}
              >
                {checkboxes}
              </CheckboxGroup>
            </div>
            <Button
              onClick={clearFilters}
              size="small"
              style={{ marginRight: '0.5rem' }}
            >
              {t('btns.reset')}
            </Button>
            <Button type="primary" onClick={confirm} size="small">
              {t('btns.filter')}
            </Button>
          </div>
        )
      }
    }
  }

  translateErrorToMessage = detail => {
    const { t } = this.props

    const MESSAGES = [
      t('alerts.unkown') + ': ' + detail,
      t('alerts.nameExists')
    ]
    const ERRORS = ['groupname exists']

    const index = ERRORS.reduce(
      (prevValue, ERROR, index) =>
        detail.includes(ERROR) ? index + 1 : prevValue,
      0
    )

    return MESSAGES[index]
  }

  render() {
    const { isEditVisible, isCreateVisible, fields } = this.state
    const {
      groups,
      group: self,
      gettingGroups,
      updatingGroup,
      creatingGroup,
      t
    } = this.props

    return (
      <div className="groups">
        <div className="groups-table-panel">
          <div className="groups-table-wrapper">
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
              <Table dataSource={groups} rowKey="id" loading={gettingGroups}>
                <Column title="ID" dataIndex="id" key="id" />
                <Column title={t('name')} dataIndex="name" key="name" />
                <Column title={t('email')} dataIndex="email" key="email" />
                <Column
                  title={t('phone')}
                  dataIndex="phonenumber"
                  key="phonenumber"
                />
                <Column
                  title={t('description')}
                  dataIndex="description"
                  key="description"
                />
                <Column
                  title={t('activeStatus')}
                  dataIndex="isActive"
                  key="activeStatus"
                  render={(text, record) => {
                    return (
                      <span>
                        {text ? (
                          <Tag color={GROUP_STATUS_COLOR.ACTIVE}>
                            {t('active')}
                          </Tag>
                        ) : (
                          <Tag color={GROUP_STATUS_COLOR.INACTIVE}>
                            {t('inactive')}
                          </Tag>
                        )}
                      </span>
                    )
                  }}
                  {...this.getColumnStatusProps('isActive')}
                />
                <Column
                  title={
                    <Tooltip title={t('tooltip.create')}>
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        ghost
                        onClick={this.handleToggleCreateModal}
                      />
                    </Tooltip>
                  }
                  key="action"
                  render={(text, record) => (
                    <Button
                      type="primary"
                      size="small"
                      ghost
                      onClick={() => this.handleOpenEditModal(record)}
                    >
                      {t('btns.edit')}
                    </Button>
                  )}
                />
              </Table>
              <Modal
                title={t('editGroupTitle')}
                bodyStyle={modalBodyStyle}
                width={modalWidth}
                footer={null}
                visible={isEditVisible}
                onCancel={this.handleCloseEditModal}
                destroyOnClose
              >
                <GroupEditForm
                  {...fields}
                  onChange={this.handleFormChange}
                  onSubmit={this.handleUpdateGroup}
                  onDelete={this.handleDeleteGroup}
                  updating={updatingGroup}
                  t={t}
                />
              </Modal>
              <Modal
                title={t('createGroupTitle')}
                bodyStyle={modalBodyStyle}
                width={modalWidth}
                footer={null}
                visible={isCreateVisible}
                onCancel={this.handleToggleCreateModal}
                destroyOnClose
              >
                <GroupForm
                  formItemLayout={formItemLayout}
                  tailFormItemLayout={tailFormItemLayout}
                  onSubmit={this.handleCreateGroup}
                  loading={creatingGroup}
                  t={t}
                />
              </Modal>
            </ConfigProvider>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    let gs = state.group.groups ? state.group.groups.sort((groupA, groupB) => groupA.id - groupB.id) : undefined
  return {
    group: state.group.group,
    groups: gs,
    gettingGroups: state.group.gettingGroups,
    updatingGroup: state.group.updatingGroup,
    creatingGroup: state.group.creatingGroup,
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(
  withTranslation(['groups', 'common'])(Groups)
)
