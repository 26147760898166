import React, { Component } from 'react'
import './Header.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { userActions } from '../../_actions'
import { LangButton, Profile } from '../'

import { ArrowLeftOutlined } from '@ant-design/icons'

import { Dropdown, Menu, Divider, Modal } from 'antd'

const modalBodyStyle = {
  backgroundColor: '#202020',
  padding: '1.5rem 1.5rem 0.5rem'
}

class Header extends Component {
  state = {
    profileVisible: false
  }

  handleToggleProfile = () => {
    this.setState({ profileVisible: !this.state.profileVisible })
  }

  handleLogout = () => {
    const { dispatch } = this.props

    dispatch(userActions.logout())
  }

  handleBack = () => {
    const { location, history } = this.props

    const paths = location.pathname.split('/')
    paths.pop()

    history.replace({
      pathname: `/${paths.pop()}`,
      state: {
        from: location
      }
    })
  }

  render() {
    const { profileVisible } = this.state
    const { location, user, t } = this.props

    const menu = (
      <Menu>
        <Menu.Item key="translation">
          <LangButton />
        </Menu.Item>
        <Menu.Item key="person">
          <div>
            <div onClick={this.handleToggleProfile}>{t('profile')}</div>
          </div>
        </Menu.Item>
        <Menu.Item key="loginout">
          <div onClick={this.handleLogout}>{t('logout')}</div>
        </Menu.Item>
      </Menu>
    )

    const isHome = location.pathname === '/'

    return (
      <header className="header">
        <div className="header-left">
          <button
            className="header-back-button"
            onClick={this.handleBack}
            style={{ visibility: isHome ? 'hidden' : 'visible' }}
          >
            <ArrowLeftOutlined />
          </button>
          <Divider type="vertical" />
          <div className="header-location">
            {isHome ? t('home') : t(location.pathname.split('/').pop())}
          </div>
        </div>
        <div className="header-right">
          <Dropdown overlay={menu} placement="bottom">
            <span className="header-user">{user && user.username}</span>
          </Dropdown>
        </div>

        <Modal
          title={t('profile')}
          bodyStyle={modalBodyStyle}
          width={600}
          footer={null}
          visible={profileVisible}
          onCancel={this.handleToggleProfile}
          destroyOnClose
        >
          <Profile />
        </Modal>
      </header>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation('header')(Header))
)
