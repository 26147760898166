import React from 'react'
import './AddlabeLlist.scss'
import { withTranslation } from 'react-i18next'

import { MATERIAL_COLORS,MATERIAL_LIST } from '../../constants'

const AddlabeLlist = ({handleClick,stateList,visible, t ,objectTypes }) => {
  const hideTypes = objectTypes?(objectTypes.length>5?objectTypes.slice(6):null):null
  return (
    <div className="labels-addList-row" style={{ visibility: visible ? 'visible' : 'hidden' }}>
      <ul className="labels-lists">
       {objectTypes.length>0? (hideTypes?hideTypes.map((item,index)=>{
          return(
            <li key={index} className={stateList=== item.name ? 'add-active':''} onClick={(type)=>handleClick(item.name)}>
                <span style={{background:item.color}}></span>{item.showName}
              </li>
          )
        }):null)
        :
          MATERIAL_LIST.map((item,index)=>{
            return (
             <li key={index} className={stateList=== item.type ? 'add-active':''} onClick={(type)=>handleClick(item.type)}>
               <span style={{background:MATERIAL_COLORS[item.type.toUpperCase()]}}></span>{t(`type.${item.type}`)}
             </li>
             )
          })
        }
      </ul>
    </div>
  )
}

export default withTranslation('views')(AddlabeLlist)
