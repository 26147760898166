import React, { Component } from 'react'
import './TaskTypes.scss'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { TaskTypeForm } from '../common'

import { alertActions, taskTypeActions, objectTypeActions } from '../_actions'

import { FolderOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'

import { Table, ConfigProvider, message, Input, Button, Modal, Popconfirm, Tooltip } from 'antd'
const { Column } = Table
const modalWidth = 750

const formItemLayout = {
  labelAlign: 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const tailFormItemLayout = {
  wrapperCol: {
    span: 18,
    offset: 6
  }
}

const modalBodyStyle = {
  backgroundColor: '#202020',
  padding: '1.5rem 1.5rem 0.5rem'
}

class TaskTypes extends Component {
  state = {
    isCreateVisible: false
  }

  componentDidMount() {
    const { dispatch, taskTypes, objectTypes } = this.props

    taskTypes.length || dispatch(taskTypeActions.getAll())
    objectTypes.length || dispatch(objectTypeActions.getAll())
  }

  componentDidUpdate(prevProps) {
    const { dispatch, message: alertMessage } = this.props

    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }
  }

  handleToggleCreateModal = () => {
    const { isCreateVisible } = this.state

    this.setState({
      isCreateVisible: !isCreateVisible
    })
  }

  handleCreateTaskType = values => {
    const { dispatch, t } = this.props
    values.objecttypes = values.objecttypes.join(',')

    dispatch(
      taskTypeActions.create({
        name: values.name,
        objecttypes: JSON.stringify(values.objecttypes),
        description: values.description
      })
    )
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alert.createSuccess')
          })
        )
        dispatch(taskTypeActions.getAll())
        this.setState({
          isCreateVisible: false
        })
      })
      .catch(e => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alert.createFailue')
          })
        )
      })
  }

  handleDeleteTaskType = record => {
    const { dispatch, t } = this.props

    const data = {
      id: record.id
    }

    dispatch(taskTypeActions.delete(data))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alert.deleteSuccess')
          })
        )
        dispatch(taskTypeActions.getAll())
      })
      .catch(e => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alert.deleteFailure')
          })
        )
      })
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  getColumnSearchProps = dataIndex => {
    const { t } = this.props

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: '0.5rem' }}>
          <Input
            ref={node => {
              this.searchInput = node
            }}
            placeholder={t('searchPlhd')}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t('btns.reset')}
          </Button>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {t('btns.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined
          }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex] &&
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select())
        }
      },
      render: text => (text ? text.toString() : '')
    }
  }

  render() {
    const { isCreateVisible, creatingTaskType } = this.state
    const { taskTypes, gettingTaskTypes, objectTypes, t } = this.props

    const customizeRenderTaskTypesEmpty = () => (
      <div className="taskTypes-empty">
        <FolderOutlined style={{ fontSize: '3rem', color: '#797979' }} />
        <p>{t('noTaskTypes')}</p>
      </div>
    )

    let displayTaskTypes = []
    for (let i in taskTypes) {
      let task = taskTypes[i]
      let displayTask = {}
      displayTask.id = task.id
      displayTask.name = task.name
      displayTask.description = task.description
      if (task.objectType) {
        displayTask.objectType = []
        for (let j in task.objectType) {
          displayTask.objectType.push(task.objectType[j].name)
        }
      }
      displayTaskTypes.push(displayTask)
    }

    return (
      <div className="taskTypes">
        <div className="taskTypes-table-panel">
          <ConfigProvider renderEmpty={customizeRenderTaskTypesEmpty}>
            <Table
              dataSource={displayTaskTypes}
              rowKey="id"
              loading={gettingTaskTypes}
            >
              <Column title="ID" dataIndex="id" key="id" />
              <Column
                title={t('name')}
                dataIndex="name"
                key="name"
                {...this.getColumnSearchProps('name')}
              />
              <Column
                title={t('description')}
                dataIndex="description"
                key="description"
              />
              <Column
                title={t('objecttypes')}
                dataIndex="objectType"
                key="objectType"
                render={(text) => text && text.join(', ')}
              />
              <Column
                title={
                  <Tooltip title={t('tooltip.create')}>
                    <Button
                      size="small"
                      type="primary"
                      icon={<PlusOutlined />}
                      ghost
                      onClick={this.handleToggleCreateModal}
                    />
                  </Tooltip>
                }
                key="actions"
                render={(text, record) => (
                  <Popconfirm
                    title={t('pop.deleteTaskType')}
                    onConfirm={() => this.handleDeleteTaskType(record)}
                    okText={t('pop.okText')}
                    cancelText={t('pop.cancelText')}
                  >
                    <Button size="small" type="primary" ghost>
                      {t('btns.delete')}
                    </Button>
                  </Popconfirm>
                )}
              />
            </Table>
          </ConfigProvider>

          <Modal
            title={t('createTaskTypeTitle')}
            bodyStyle={modalBodyStyle}
            width={modalWidth}
            footer={null}
            visible={isCreateVisible}
            onCancel={this.handleToggleCreateModal}
            destroyOnClose
          >
            <TaskTypeForm
              formItemLayout={formItemLayout}
              tailFormItemLayout={tailFormItemLayout}
              onSubmit={this.handleCreateTaskType}
              loading={creatingTaskType}
              t={t}
              objectTypes={objectTypes}
            />
          </Modal>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    taskTypes: state.taskType.taskTypes,
    objectTypes: state.objectType.objectTypes,
    gettingTaskTypes: state.taskType.gettingTaskTypes,
    deletingTaskType: state.taskType.deletingTaskType,
    creatingTaskType: state.taskType.creatingTaskType,
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(
  withTranslation(['taskTypes', 'common'])(TaskTypes)
)
