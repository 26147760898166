import Dexie from 'dexie'

console.log('create db')

export const db = new Dexie('AnnotationDB')
db.version(1).stores({
  PCD: 'frametime',
  CAMERA_1: 'frametime',
  OBJECT: 'frametime',
  CAMERA: 'frametime',
  LABEL_3D: 'frametime',
  LABEL_2D: 'frametime',
})

