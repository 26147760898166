import React from 'react'
import './Shortcuts.scss'
import { withTranslation } from 'react-i18next'

import { role } from '../../../_helpers'
import { CustomIcon } from '../../../common'
import { Divider } from 'antd'

const Shortcuts = ({ user, t }) => (
  <div className="views-shortcuts">
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.frameOperation')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.previous')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          C
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.next')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          V
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.previousFive')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          C
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.nextFive')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          V
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.firstFrame')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          C
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.lastFrame')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          V
        </span>
      </div>
    </div>

    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.boxSelection')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.selectBoxDesc')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          Left mouse button
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.selectAddBoxDesc')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          Left mouse button
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.selectAllBoxDesc')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          A
        </span>
      </div>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.selected')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command"  style={{width:'8rem'}}>{t('sc.copyBoxNext')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          Q
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command"  style={{width:'8rem'}}>{t('sc.copyBoxPrevious')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Ctrl</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          Q
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.move')}</div>
      <div className="views-shortcuts-keybinding">
        <div className="views-shortcuts-row">
          <div className="views-shortcuts-mark-row-up">
            <span className="views-shortcuts-key-ph" />
            <span className="views-shortcuts-key">
              W
              <span className="views-shortcuts-key-mark-up">
                {t('sc.forward')}
              </span>
            </span>
            <span className="views-shortcuts-key-gutter" />
            <span className="views-shortcuts-key">
              E<span className="views-shortcuts-key-mark-up">{t('sc.up')}</span>
            </span>
            <span className="views-shortcuts-key">
              R
              <span className="views-shortcuts-key-mark-up">
                {t('sc.down')}
              </span>
            </span>
          </div>
        </div>
        <div className="views-shortcuts-row">
          <div className="views-shortcuts-mark-row-down">
            <span className="views-shortcuts-key">
              A
              <span className="views-shortcuts-key-mark-down">
                {t('sc.left')}
              </span>
            </span>
            <span className="views-shortcuts-key">
              S
              <span className="views-shortcuts-key-mark-down">
                {t('sc.back')}
              </span>
            </span>
            <span className="views-shortcuts-key">
              D
              <span className="views-shortcuts-key-mark-down">
                {t('sc.right')}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.transform')}</div>
      <div className="views-shortcuts-keybinding">
        <div className="views-shortcuts-row">
          <div className="views-shortcuts-mark-row-up">
            <span className="views-shortcuts-key">
              U
              <span className="views-shortcuts-key-mark-up">
                {t('sc.thicken')}
              </span>
            </span>
            <span className="views-shortcuts-key">
              I
              <span className="views-shortcuts-key-mark-up">
                {t('sc.thin')}
              </span>
            </span>
            <span className="views-shortcuts-key-gutter" />
            <span className="views-shortcuts-key">
              O
              <span className="views-shortcuts-key-mark-up">
                {t('sc.lengthen')}
              </span>
            </span>
          </div>
        </div>
        <div className="views-shortcuts-row">
          <div className="views-shortcuts-mark-row-down">
            <span className="views-shortcuts-key-ph" />
            <span className="views-shortcuts-key-gutter" />
            <span className="views-shortcuts-key">
              K
              <span className="views-shortcuts-key-mark-down">
                {t('sc.narrow')}
              </span>
            </span>
            <span className="views-shortcuts-key">
              L
              <span className="views-shortcuts-key-mark-down">
                {t('sc.shorten')}
              </span>
            </span>
            <span className="views-shortcuts-key">
              ;
              <span className="views-shortcuts-key-mark-down">
                {t('sc.widen')}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.rotate')}</div>
      <div className="views-shortcuts-keybinding">
        <div className="views-shortcuts-row">
          <div className="views-shortcuts-mark-row-up">
            <span className="views-shortcuts-key-gutter" />
            <span className="views-shortcuts-key">
              F
              <span className="views-shortcuts-key-mark-up">
                {t('sc.zflip')}
              </span>
            </span>
          </div>
        </div>
        <div className="views-shortcuts-row">
          <div className="views-shortcuts-mark-row-down">
            <span className="views-shortcuts-key">
              X
              <span className="views-shortcuts-key-mark-down">
                {t('sc.zcw')}
              </span>
            </span>
            <span className="views-shortcuts-key">
              Z
              <span className="views-shortcuts-key-mark-down">
                {t('sc.zccw')}
              </span>
            </span>
            {/* <span className="views-shortcuts-key-gutter" />
          <span className="views-shortcuts-key">
            N
            <span className="views-shortcuts-key-mark-down">{t('sc.ycw')}</span>
          </span>
          <span className="views-shortcuts-key">
            M
            <span className="views-shortcuts-key-mark-down">
              {t('sc.yccw')}
            </span>
          </span> */}
          </div>
        </div>
      </div>
    </div>
    {[role.globalExaminer, role.groupExaminer].includes(user.role) ? (
      <div className="views-shortcuts-row">
        <div className="views-shortcuts-command">{t('sc.fail')}</div>
        <div className="views-shortcuts-keybinding">
          <div className="views-shortcuts-mark-row-down">
            <span className="views-shortcuts-key-gutter" />
            <span className="views-shortcuts-key">Q</span>
          </div>
        </div>
      </div>
    ) : null}
    <div className="views-shortcuts-row">
      <span className="views-shortcuts-comment">*{t('sc.pressShift')}</span>
    </div>
    <div className="views-shortcuts-row">
      <span className="views-shortcuts-comment">*{t('sc.pressCtrl')}</span>
    </div>
    <div className="views-shortcuts-row">
      <span className="views-shortcuts-comment">
        *{t('sc.transformOrigin')}
      </span>
    </div>
    <div className="views-shortcuts-row">
      <span className="views-shortcuts-comment">*{t('sc.pressMouseLeft')}</span>
    </div>
    <div className="views-shortcuts-row">
      <span className="views-shortcuts-comment">*{t('sc.multipleDelete')}</span>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />

    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.boxOperation')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.boxBuiding')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">1~6</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.deleteBox')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Backspace</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.InvertBox')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Escape</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.boxHiding')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">H</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.viewOperation')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.helpers')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">1</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.aux')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">2</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.frameStatistics')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">3</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.adjustingViewAngle')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift/Ctrl</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">Left mouse button</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command" style={{width:'9rem'}}>{t('sc.globalView')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">`</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.otherOperations')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.save')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">ALT</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="viewer2-shortcuts-key cplx">S</span>
      </div>
    </div>

  </div>
)

export default withTranslation('views')(Shortcuts)
