import React from 'react'
import './MiddleList.scss'
import { withTranslation } from 'react-i18next'

import { MATERIAL_RECT_COLORS,MATERIAL_COLORS } from '../../constants'
import { LabelType } from '../../../common'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { DeleteOutlined, InboxOutlined, LinkOutlined } from '@ant-design/icons'
import { Button } from 'antd'

const MiddleList = ({ relations,relationsLabel,disabledFlag,handleSelectRelation,handleRemoveLabel,handleMiddleLabelVisibility,handleAddRelation,t }) => {
  const rectsCollection = relations.map(relation => (
    <li
      className={`viewer2-labels-collection-item${
        relation === relationsLabel ? ' selected' : ''
      }`}
      onClick={() => handleSelectRelation(relation)}
      key={relation.id}
      id={relation.id}
    >
      <div className="viewer2-label-row">
        <div className="viewer2-label-type views-label-rect">
          <div className="views-rect-box">
            <button
              className="viewer2-btn"
              onClick={e => handleMiddleLabelVisibility(e, relation)}
              >
              <LegacyIcon type={relation.rect.visible ? 'eye' : 'eye-invisible'} />
            </button>
            <LabelType color={MATERIAL_RECT_COLORS[relation.rect.type.toUpperCase()]} />
            {relation.rect.name}
          </div>
          <div className="views-label-box">
            {relation.label.name}
            <LabelType color={MATERIAL_COLORS[relation.label.type.toUpperCase()]} />
          </div>
          <div className="views-rect-link"><LinkOutlined /></div>
        </div>
        <div className="viewer2-label-actions">
          <button className="viewer2-btn" onClick={(e) => handleRemoveLabel(relation,e)}>
            <DeleteOutlined />
          </button>
        </div>
      </div>
    </li>
  ))

  const emptyCollection = (
    <div className="viewer2-labels-collection-empty">
      <div>
        <InboxOutlined style={{ fontSize: '30px' }} />
      </div>
      {t('noLabels')}
    </div>
  )

  return (
    <div className="views-label-relation">
      <div className="views-relation-button"><Button type="primary" disabled={disabledFlag} onClick={() => handleAddRelation()}>{t('relation')}( R )</Button></div>
      <div className="views-relation-lists">
        <ul className="viewer2-labels-collection">
          {rectsCollection.length
            ? rectsCollection
            : emptyCollection}
        </ul>
      </div>
    </div>
  )
}

export default withTranslation('views')(MiddleList)
