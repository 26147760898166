import React, { Component } from 'react'
import './ObjectTypes.scss'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { ObjectTypeForm } from '../common'

import { alertActions, objectTypeActions } from '../_actions'

import { FolderOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'

import { Table, ConfigProvider, message, Input, Button, Modal, Popconfirm, Tooltip, Select, Form, Tag } from 'antd'
import { getKeyThenIncreaseKey } from 'antd/lib/message'
import { Icon } from '@ant-design/compatible'
import Icons from '@ant-design/icons'


import { ReactComponent as Car } from '../_assets/car copy.svg'
import { ReactComponent as Cone } from '../_assets/cone copy.svg'
import { ReactComponent as Pedestrian } from '../_assets/pedestrian copy.svg'
import { ReactComponent as Truck } from '../_assets/truck copy.svg'
import { ReactComponent as Bicycle } from '../_assets/bicycle copy.svg'
import { ReactComponent as Cyclist } from '../_assets/cyclist copy.svg'
import { ReactComponent as Bus } from '../_assets/bus copy.svg'
import { validateNumber } from '../_utils'

const { Column } = Table
const modalWidth = 750
const modalWidthGroup = 650

const formItemLayout = {
  labelAlign: 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const tailFormItemLayout = {
  wrapperCol: {
    span: 18,
    offset: 6
  }
}

const modalBodyStyle = {
  backgroundColor: '#202020',
  padding: '1.5rem 1.5rem 0.5rem',
}

class ObjectTypes extends Component {
  state = {
    isCreateVisible: false,
    isAltObjectType: false,
    isshowGroups: false,
    isCreateGroups: false,
    isAltGroups: false,
    groupRecord: {},
    objectTypeRecord: {},
    selectedGroup: '',
  }

  componentDidMount() {
    const { dispatch, objectTypes } = this.props
    dispatch(objectTypeActions.getGroup()).then(() => {
      if (this.props.objectTypesgroups.length > 0) {
        this.setState({ selectedGroup: this.props.objectTypesgroups[0].groupName })
        dispatch(objectTypeActions.getByGroup({ groupName: this.props.objectTypesgroups[0].groupName }))
      }
    })
    objectTypes.length || dispatch(objectTypeActions.getAll())


  }

  componentDidUpdate(prevProps) {
    const { dispatch, message: alertMessage } = this.props

    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }
  }

  handleChangeGroups = (value) => {
    const { dispatch, t } = this.props
    this.setState({ selectedGroup: value })
    const data = { groupName: value }
    if (value && value !== '') {
      dispatch(objectTypeActions.getByGroup(data))
    } else {
      dispatch(objectTypeActions.getAll())
    }

  }

  handleIcon = (record) => {
    const iconArr = [
      { name: 'Car', component: Car },
      { name: 'Cone', component: Cone },
      { name: 'Pedestrian', component: Pedestrian },
      { name: 'Truck', component: Truck },
      { name: 'Bicycle', component: Bicycle },
      { name: 'Cyclist', component: Cyclist },
      { name: 'Bus', component: Bus },
      // {name:'More',component:More},
    ]
    if (record.icon) {

      for (let item in iconArr) {
        if (iconArr[item].name == record.icon) {

          return iconArr[item].component
        }
      }
    }

  }

  handleToggleCreateModal = () => {
    const { isCreateVisible } = this.state

    this.setState({
      isCreateVisible: !isCreateVisible,
    })
  }

  handleToggleCreateGroupModal = () => {
    const { isCreateGroups } = this.state

    this.setState({
      isCreateGroups: !isCreateGroups,
    })
  }

  handleToggleShowGroupModal = () => {
    const { isshowGroups } = this.state

    this.setState({
      isshowGroups: !isshowGroups,
    })
  }

  handleAltGroup = (record) => {
    this.setState({ isAltGroups: true, groupRecord: record })
  }

  handleCreateObjectType = values => {
    const { dispatch, t } = this.props
    values = { ...values, showName: values.showName.replace(/^\s+|\s+$/g, ""), name: values.name.replace(/^\s+|\s+$/g, "") }
    console.log(values)
    if (this.state.isCreateVisible) {
      // if (values.size.z == null) {
      //   delete values.size.z
      // }
      dispatch(
        objectTypeActions.create({
          name: values.name,
          shortName:values.shortName,
          size: JSON.stringify(values.size),
          color: values.color,
          groupName: values.groupName,
          icon: values.icon,
          showName: values.showName,
          sortNumber: values.sortNumber
        })
      )
        .then(() => {
          dispatch(
            alertActions.success({
              type: 'success',
              detail: t('alert.createSuccess')
            })
          )
          if (this.state.selectedGroup && this.state.selectedGroup !== '') {
            dispatch(objectTypeActions.getByGroup({ groupName: this.state.selectedGroup }))
          } else {
            dispatch(objectTypeActions.getAll())
          }
          // dispatch(objectTypeActions.getByGroup({ groupName: this.state.selectedGroup }))
          this.setState({
            isCreateVisible: false
          })
        })
        .catch(e => {
          dispatch(
            alertActions.error({
              type: 'error',
              detail: t('alert.createFailure') + ',' + t((e.detail == 'existed') ? 'alert.existedObjectType' : '')
            })
          )
        })
    } else if (this.state.isAltObjectType) {
      // if (values.size.z == null || values.size.z == '') {
      //   delete values.size.z
      // }
      dispatch(
        objectTypeActions.update({
          id: this.state.objectTypeRecord.id,
          name: values.name,
          shortName:values.shortName,
          size: JSON.stringify(values.size),
          color: values.color,
          groupName: values.groupName,
          icon: values.icon,
          showName: values.showName,
          sortNumber: values.sortNumber
        })
      )
        .then(() => {
          dispatch(
            alertActions.success({
              type: 'success',
              detail: t('alert.updateSuccess')
            })
          )
          if (this.state.selectedGroup && this.state.selectedGroup !== '') {
            dispatch(objectTypeActions.getByGroup({ groupName: this.state.selectedGroup }))
          } else {
            dispatch(objectTypeActions.getAll())
          }
          this.setState({
            isAltObjectType: false
          })
        })
        .catch(e => {
          dispatch(
            alertActions.error({
              type: 'error',
              detail: t('alert.updateFailure') + ',' + t((e.detail == 'existed') ? 'alert.existedObjectType' : '')
            })
          )
        })
    }


  }

  handleUpdateObjectType = record => {

    let objectTypeRecord = {
      ...record,
      ...JSON.parse(record.size),
    }
    this.setState({ isAltObjectType: true, objectTypeRecord })
  }

  handleDeleteObjectType = record => {
    const { dispatch, t } = this.props

    const data = {
      id: record.id
    }

    dispatch(objectTypeActions.delete(data))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alert.deleteSuccess')
          })
        )
        dispatch(objectTypeActions.getAll())
      })
      .catch(e => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alert.deleteFailure')
          })
        )
      })
  }

  handleDeleteGroup = record => {
    const { dispatch, t } = this.props
    const data = { id: record.id }
    dispatch(objectTypeActions.deleteGroup(data)).then(() => {
      dispatch(
        alertActions.success({
          type: 'success',
          detail: t('alert.deleteSuccess')
        })
      )
      dispatch(objectTypeActions.getGroup())
    })
      .catch(e => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alert.deleteFailure') + ',' + t((e.detail == 'bound') ? 'alert.bondedGroups' : '')
          })
        )
      })
  }

  handleStatus = record => {
    const { dispatch, t } = this.props
    const data = { id: record.id, status: record.status == 'hide' ? 'active' : 'hide' }
    dispatch(objectTypeActions.updateGroup(data)).then(() => {
      dispatch(
        alertActions.success({
          type: 'success',
          detail: t('alert.updateSuccess')
        })
      )
      dispatch(objectTypeActions.getGroup())
    })
      .catch(e => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alert.updateFailure')
          })
        )
      })
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  getColumnSearchProps = dataIndex => {
    const { t } = this.props

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: '0.5rem' }}>
          <Input
            ref={node => {
              this.searchInput = node
            }}
            placeholder={t('searchPlhd')}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t('btns.reset')}
          </Button>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {t('btns.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined
          }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex] &&
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select())
        }
      },
      render: text => (text ? text.toString() : '')
    }
  }

  submitAltGroup = (values) => {
    const { dispatch, t } = this.props
    values = { groupName: values.groupName.replace(/^\s+|\s+$/g, "") }
    console.log(values)
    if (this.state.isAltGroups) {
      let data = { ...values, id: this.state.groupRecord.id }
      dispatch(objectTypeActions.updateGroup(data)).then(
        () => {
          dispatch(
            alertActions.success({
              type: 'success',
              detail: t('alert.updateSuccess')
            })
          )
          dispatch(objectTypeActions.getByGroup({ groupName: this.state.selectedGroup }))
          dispatch(objectTypeActions.getGroup())
          this.setState({ isAltGroups: false })
        })
        .catch(e => {
          dispatch(
            alertActions.error({
              type: 'error',
              detail: t('alert.updateFailure') + ',' + t((e.detail == 'existed') ? 'alert.existedGroup' : '')
            })
          )
        })
    } else if (this.state.isCreateGroups) {
      let data = { ...values }
      dispatch(objectTypeActions.create(data)).then(
        () => {
          dispatch(
            alertActions.success({
              type: 'success',
              detail: t('alert.createSuccess')
            })
          )
          dispatch(objectTypeActions.getGroup())
          this.setState({ isCreateGroups: false })
        })
        .catch(e => {
          dispatch(
            alertActions.error({
              type: 'error',
              detail: t('alert.createFailure') + ',' + t((e.detail == 'existed') ? 'alert.existedGroup' : '')
            })
          )
        })
    }

  }




  render() {
    const { isCreateVisible, creatingObjectType, isCreateGroups, isshowGroups, isAltGroups, groupRecord, isAltObjectType, objectTypeRecord, selectedGroup } = this.state
    const { objectTypes, gettingObjectTypes, t, objectTypesgroups } = this.props
    // const objectTypesgroups = [{id:1,groupName:'红绿灯',status:'active'},{id:2,groupName:'交通',status:'hide'}]
    const TAG_COLOR = {
      hide: '#943126',
      active: '#21618C',
    }
    const customizeRenderObjectTypesEmpty = () => (
      <div className="objectTypes-empty">
        <FolderOutlined style={{ fontSize: '3rem', color: '#797979' }} />
        <p>{t('noObjectTypes')}</p>
      </div>
    )

    const rowSelection = {
      type: 'radio',
      columnWidth: '30px',
      onChange: this.handleSelectRow
    }

    return (
      <div className="objectTypes">
        <div className='objectTypes-filter'>
          <Select
            className='objectTypes-filter-item'
            value={selectedGroup}
            onChange={this.handleChangeGroups}
            style={{ minWidth: '10rem' }}
            size="small"
            allowClear>
            {(objectTypesgroups ? objectTypesgroups : []).map(group => (
              <Select.Option key={group.groupName}>{t(`${group.groupName}`)}</Select.Option>
            ))}
          </Select>

          <Button
            className='objectTypes-filter-item'
            size="small"
            onClick={this.handleToggleShowGroupModal}
          >
            {t(`编辑标签组`)}
          </Button>
          <Button
            className='objectTypes-filter-item'
            size="small"
            onClick={this.handleToggleCreateGroupModal}
          >
            {t(`新建标签组`)}
          </Button>
        </div>
        <div className="objectTypes-table-panel">
          <ConfigProvider renderEmpty={customizeRenderObjectTypesEmpty}>
            <Table
              dataSource={objectTypes}
              rowKey="id"
              loading={gettingObjectTypes}
              pagination={false}
            >
              <Column title="ID" dataIndex="id" key="id" />
              <Column
                title={t('objecttypes')}
                render={(text, record) => (
                  <div>
                    {
                      record.icon ? (<Icons style={{ marginRight: '1rem', color: record.color }} component={this.handleIcon(record)} />) :
                        (<div style={{ width: '1rem', height: '1rem', backgroundColor: record.color, display: 'inline-block', marginRight: '1rem' }}></div>)}
                    <span>{record.showName}</span>

                  </div>

                )}
              />
              <Column
                title={t('name')}
                dataIndex="name"
                key="name"
                {...this.getColumnSearchProps('name')}
              />
              <Column title={t('defaultSize')} dataIndex="size" key="size" />
              <Column
                title={
                  <Tooltip title={t('tooltip.create')}>
                    <Button
                      size="small"
                      type="primary"
                      icon={<PlusOutlined />}
                      ghost
                      onClick={this.handleToggleCreateModal}
                    />
                  </Tooltip>
                }
                key="actions"
                render={(text, record) => (
                  <div>
                    <Button className='objectTypes-filter-item' size="small" type="primary" onClick={() => this.handleUpdateObjectType(record)}> {t('btns.alt')}</Button>
                    <Popconfirm
                      title={t('pop.deleteObjectType')}
                      onConfirm={() => this.handleDeleteObjectType(record)}
                      okText={t('pop.okText')}
                      cancelText={t('pop.cancelText')}
                    >
                      <Button size="small" type="primary" ghost>
                        {t('btns.delete')}
                      </Button>
                    </Popconfirm>
                  </div>
                )}
              />
            </Table>
          </ConfigProvider>

          <Modal
            title={isAltObjectType ? t('altObjectTypeTitle') : t('createObjectTypeTitle')}
            bodyStyle={modalBodyStyle}
            style={{ top: 60 }}
            width={modalWidth}
            footer={null}
            visible={isCreateVisible || isAltObjectType}
            onCancel={() => this.setState({ isCreateVisible: false, isAltObjectType: false })}
            destroyOnClose
          >
            <ObjectTypeForm
              formItemLayout={formItemLayout}
              tailFormItemLayout={tailFormItemLayout}
              onSubmit={this.handleCreateObjectType}
              loading={creatingObjectType}
              objectTypeRecord={isAltObjectType ? objectTypeRecord : {}}
              isAltObjectType={isAltObjectType}
              objectTypesgroups={objectTypesgroups}
              t={t}
            />
          </Modal>

          <Modal
            title={t('objectTypeGroup')}
            bodyStyle={modalBodyStyle}
            width={modalWidthGroup}
            style={{ top: 40 }}
            footer={null}
            visible={isshowGroups}
            onCancel={this.handleToggleShowGroupModal}
            destroyOnClose
          >
            <Table
              dataSource={objectTypesgroups}
              rowKey="id"
              loading={gettingObjectTypes}
              pagination={false}
              scroll={{ y: 500 }}
            >
              <Column
                title={t('group')}
                dataIndex="groupName"
                key="id" />
              <Column
                title={t('status')}
                dataIndex="status"
                key="id"
                render={(text, record) => (
                  <Tag color={TAG_COLOR[text]}>{t(`tags.${text}`)}</Tag>
                )}
              />
              <Column
                title={t('operate')}
                key="operate"
                width={280}
                render={(text, record) => (
                  <div>
                    <Button size="small" type="primary" ghost className='objectTypes-filter-item' onClick={() => this.handleAltGroup(record)}>
                      {t('btns.alt')}
                    </Button>
                    <Popconfirm
                      title={t('pop.deleteObjectTypeGroup')}
                      onConfirm={() => this.handleDeleteGroup(record)}
                      okText={t('pop.okText')}
                      cancelText={t('pop.cancelText')}
                    >
                      <Button size="small" type="primary" ghost className='objectTypes-filter-item'>
                        {t('btns.delete')}
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title={record.status == 'active' ? t('pop.hideObjectTypeGroup') : t('pop.acctiveObjectTypeGroup')}
                      onConfirm={() => this.handleStatus(record)}
                      okText={t('pop.okText')}
                      cancelText={t('pop.cancelText')}
                    >
                      <Button size="small" type="primary" ghost className='objectTypes-filter-item'>
                        {record.status == 'active' ? t('btns.hide') : t('btns.active')}
                      </Button>
                    </Popconfirm>
                  </div>)}
              />
            </Table>
          </Modal>


          <Modal
            title={isAltGroups ? t('altObjectTypeGroup') : t('createobjectTypeGroup')}
            bodyStyle={modalBodyStyle}
            width={modalWidthGroup}
            footer={null}
            visible={isAltGroups || isCreateGroups}
            onCancel={() => { this.setState({ isAltGroups: false, isCreateGroups: false }) }}
            destroyOnClose>
            <Form
              initialValues={isAltGroups ? groupRecord : {}}
              onFinish={this.submitAltGroup}>
              <Form.Item
                name='groupName'
                // initialValue
                label={t('group')}
                rules={[
                  {
                    required: true,
                    message: 'Please input your groupName!',
                  }
                ]}>
                <Input />
              </Form.Item>
              <Button type="primary" style={{ margin: '2rem 15rem 2rem' }} htmlType="submit">
                {t('pop.okText')}
              </Button>


            </Form>

          </Modal>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log('state', state)
  return {
    objectTypes: state.objectType.objectTypes.sort(
      (objectTypeA, objectTypeB) => objectTypeA.id - objectTypeB.id
    ),
    objectTypesgroups: state.objectType.objectTypesgroups.sort(
      (objectTypesgroupA, objectTypesgroupB) => objectTypesgroupA.id - objectTypesgroupB.id
    ),
    gettingObjectTypes: state.objectType.gettingObjectTypes,
    deletingObjectType: state.objectType.deletingObjectType,
    creatingObjectType: state.objectType.creatingObjectType,
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(
  withTranslation(['objectTypes', 'common'])(ObjectTypes)
)
