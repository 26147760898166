import { localeConstants } from '../_constants'

const initState = {
  lang: null
}

export function locale(state = initState, action) {
  switch (action.type) {
    case localeConstants.CHANGE_LANG:
      return {
        ...state,
        lang: action.lang
      }
    default:
      return state
  }
}
