import React from 'react'
import './Guides.scss'
import { withTranslation } from 'react-i18next'

import { getRole } from '../../../_utils'

import { CustomIcon } from '../../../common'
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Divider } from 'antd'

const Guides = ({ user, t }) => (
  <div className="viewer2-shortcuts">
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">
        <span className="viewer2-shortcuts-title">{t('sc.frameOperation')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.previous')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          C
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.next')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          V
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.previousFive')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          C
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.nextFive')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          V
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.firstFrame')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          C
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.lastFrame')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          V
        </span>
      </div>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.selected')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.copyBoxNext')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          Q
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.copyBoxPrevious')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Ctrl</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          Q
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command" style={{width:'4rem'}}>{t('sc.move')}</div>
      <div className="views-shortcuts-keybinding">
        <div className="views-shortcuts-row">
          <div className="views-shortcuts-mark-row-up">
            <span className="views-shortcuts-key">
            <ArrowUpOutlined /><span className="views-shortcuts-key-mark-up">{t('sc.up')}</span>
            </span>
            <span className="views-shortcuts-key">
            <ArrowDownOutlined />
              <span className="views-shortcuts-key-mark-up">
                {t('sc.down')}
              </span>
            </span>
            <span className="views-shortcuts-key">
            <ArrowLeftOutlined />
              <span className="views-shortcuts-key-mark-up">
                {t('sc.left')}
              </span>
            </span>
            <span className="views-shortcuts-key">
            <ArrowRightOutlined />
              <span className="views-shortcuts-key-mark-up">
                {t('sc.right')}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.boxOperation')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.boxBuiding')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">1~6</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.deleteBox')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Backspace</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.InvertBox')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Escape</span>
      </div>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.viewOperation')}</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.fit')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">`</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.flip')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">F</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.hide')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">H</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.center')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">G</span>
      </div>
    </div>
    <div className="viewer2-shortcuts-row">
      <div className="viewer2-shortcuts-command">{t('sc.fill')}</div>
      <div className="viewer2-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key">U</span>
      </div>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.save')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="viewer2-shortcuts-key cplx">S</span>
      </div>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.boxSelection')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.selectBoxDesc')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          Left mouse button
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.selectAddBoxDesc')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          Left mouse button
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.selectAllBoxDesc')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          A
        </span>
      </div>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />
    {getRole(user.role) === 'examiner' ? (
      <div className="viewer2-shortcuts-row">
        <div className="viewer2-shortcuts-command">{t('sc.fail')}</div>
        <div className="viewer2-shortcuts-keybinding">
          <span className="viewer2-shortcuts-key">Q</span>
        </div>
      </div>
    ) : null}
  </div>
)

export default withTranslation('views')(Guides)
