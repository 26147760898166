export const groupConstants = {
  GET_REQUEST: 'GROUP_GET_REQUEST',
  GET_SUCCESS: 'GROUP_GET_SUCCESS',
  GET_FAILURE: 'GROUP_GET_FAILURE',
  GET_ALL_REQUEST: 'GROUP_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'GROUP_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'GROUP_GET_ALL_FAILURE',
  UPDATE_REQUEST: 'GROUP_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'GROUP_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'GROUP_UPDATE_FAILURE',
  CREATE_REQUEST: 'GROUP_CREATE_REQUEST',
  CREATE_SUCCESS: 'GROUP_CREATE_SUCCESS',
  CREATE_FAILURE: 'GROUP_CREATE_FAILURE',
  DELETE_REQUEST: 'GROUP_DELETE_REQUEST',
  DELETE_SUCCESS: 'GROUP_DELETE_SUCCESS',
  DELETE_FAILURE: 'GROUP_DELETE_FAILURE',
}