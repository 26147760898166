import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie'

import { WithAuth } from '../'

const AuthRoute = ({ component, auth, cookies, allCookies,title, ...rest }) => {
  const token = cookies.get('_token')

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <WithAuth token={token} auth={auth} title={title}>{component}</WithAuth>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default withCookies(AuthRoute)
