/** Rewritten and adapted from https://github.com/mrdoob/three.js/blob/master/editor/js/libs/ui.js.
 * It's a complex feature that make values bidirectional as well as update asynchronously.
 * Hence, fully uncontrolled component is relatively reasonable.
 * Notice, internal input value is a string. So, in-and-out values should be converted before passing.
 */

import React, { Component } from 'react'
import './InputNumber.scss'

import { validateNumber, toPrecision } from '../../_utils'
import { relativeTimeThreshold } from 'moment'

class InputNumber extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value.toString(),
      disabled:this.props.disabled,
      internalChange: false
    }

    const { step, max, min, precision } = this.props

    this.step = step || step === 0 ? step : 0.01
    this.max = max || max === 0 ? max : 500
    this.min = min || min === 0 ? min : -500
    this.precision = precision || precision === 0 ? precision : 3

    this.input
    this.distance
    this.onMouseDownValue
    this.pointer
    this.prevPointer
  }

  /** This method is used for distinguishing internal changes and external changes.
   * Thus, 'internalChange' is added to state as a flag.
   * Every internal change should set it 'true' then reset it 'false' in this method.
   */
  static getDerivedStateFromProps(props, state) {
    if (state.internalChange) {
      return { value: state.value, internalChange: false }
    }

    if (props.value.toString() !== state.value) {
      return { value: props.value.toString() }
    }

    return null
  }

  componentDidMount() {
    this.input.addEventListener('keydown', e => {
      e.stopPropagation()

      if (e.code === 'Enter') {
        this.input.blur()
      }
    })

    this.input.addEventListener('mousedown', this.onMouseDown)
    this.input.addEventListener('blur', this.onBlur)
  }

  /** Listeners, interesting usage */
  onMouseDown = e => {
    const { value } = this.state

    // Keep initial values
    this.distance = 0
    this.onMouseDownValue = Number(value) // Convert input value from string to number
    this.prevPointer = [e.clientX, e.clientY]

    // Add global listeners on dragging
    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
  }

  onMouseMove = e => {
    let { value } = this.state

    this.pointer = [e.clientX, e.clientY]
    this.distance +=
      this.pointer[0] -
      this.prevPointer[0] -
      (this.pointer[1] - this.prevPointer[1])

    // Calculate new value by distance and step
    let newValue =
      this.onMouseDownValue +
      (this.distance / (e.shiftKey ? 10 : 100)) * this.step * 100
    // Ensure new value lays between min and max value
    newValue = Math.min(this.max, Math.max(this.min, newValue))

    if (value !== newValue) {
      this.setState(
        {
          value: toPrecision(newValue, this.precision).toString(),
          internalChange: true
        },
        this.submitValue
      )
    }

    this.prevPointer = [e.clientX, e.clientY]
  }

  onMouseUp = () => {
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  onBlur = () => {
    this.submitValue()
  }

  handleInputChange = e => {
    const value = e.target.value.trim()

    if (validateNumber(value)) {
      this.setState({
        value,
        internalChange: true
      })
    }
  }

  submitValue = () => {
    const { value: propValue, onChange } = this.props
    let { value: stateValue } = this.state

    // When input value is '' or '-', reset value.
    if (stateValue === '' || stateValue === '-') {
      this.setState({
        value: propValue,
        internalChange: true
      })
    } else {
      onChange && onChange(Number(stateValue))
    }
  }

  render() {
    const { style } = this.props
    const { value,disabled } = this.state

    return (
      <input
        className="input-number"
        value={value}
        style={style}
        disabled={disabled||false}
        onChange={this.handleInputChange}
        ref={el => (this.input = el)}
      />
    )
  }
}

export default InputNumber
