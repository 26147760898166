import React from 'react'
import './Addlabel.scss'
import { withTranslation } from 'react-i18next'
import { MATERIAL_COLORS } from '../../constants'

import Icon from '@ant-design/icons'
import { ReactComponent as Carc } from '../../../_assets/car copy.svg'
import { ReactComponent as Conec } from '../../../_assets/cone copy.svg'
import { ReactComponent as Pedestrianc } from '../../../_assets/pedestrian copy.svg'
import { ReactComponent as Truckc } from '../../../_assets/truck copy.svg'
import { ReactComponent as Bicyclec } from '../../../_assets/bicycle copy.svg'
import { ReactComponent as Cyclistc } from '../../../_assets/cyclist copy.svg'
import { ReactComponent as Busc } from '../../../_assets/bus copy.svg'
import { ReactComponent as Morec } from '../../../_assets/more.svg'


import { ReactComponent as Car } from '../../../_assets/car.svg'
import { ReactComponent as Cone } from '../../../_assets/cone.svg'
import { ReactComponent as Pedestrian } from '../../../_assets/pedestrian.svg'
import { ReactComponent as Truck } from '../../../_assets/truck.svg'
import { ReactComponent as Bicycle } from '../../../_assets/bicycle.svg'
import { ReactComponent as Cyclist } from '../../../_assets/cyclist.svg'
import { ReactComponent as Bus } from '../../../_assets/bus.svg'
import { ReactComponent as More } from '../../../_assets/more.svg'


const Addlabel = ({ stateList, handleClick, t, objectTypes }) => {
  const showTypes = objectTypes.length>0 ? (objectTypes.length >= 6 ? objectTypes.slice(0, 6) : objectTypes) : null
  const hideTypes = objectTypes.length>0 ? (objectTypes.length >= 6 ? objectTypes.slice(6) : null) : null
  const handleIcon = (record) => {
    const iconArr = [
      { name: 'Car', component: Carc },
      { name: 'Cone', component: Conec },
      { name: 'Pedestrian', component: Pedestrianc },
      { name: 'Truck', component: Truckc },
      { name: 'Bicycle', component: Bicyclec },
      { name: 'Cyclist', component: Cyclistc },
      { name: 'Bus', component: Busc },
      // {name:'More',component:More},
    ]
    if (record.icon) {

      for (let item in iconArr) {
        if (iconArr[item].name == record.icon) {

          return iconArr[item].component
        }
      }
    }

  }
  return (
    <div className="views-addlabel-row">
      <ul className="views-label-lists">
        {
          showTypes ? showTypes.map((item, index) => (
            <li className={stateList === item.name ? `add-active-${item.color}` : ''} onClick={(num) => handleClick(item.name)} key={item.id}
              style={(stateList === item.name ? {
                border: `3px solid ${item.color}`,
                background: `rgba($color: ${item.color}, $alpha: '0.2')`
              }: null) }
            >
              <span>{index + 1}</span>
              {item.icon ?
                <Icon style={{ marginRight: '1rem', color: item.color }} component={handleIcon(item)} />
                : (<div style={{ width: '1rem', height: '1rem', backgroundColor: item.color, display: 'inline-block' }}></div>)}
            </li>
          )) : <span>
            <li className={stateList === 'car' ? 'add-active-car' : ''} onClick={(num) => handleClick('car')}><span>1</span><Icon component={Car} /></li>
            <li className={stateList === 'cone' ? 'add-active-cone' : ''} onClick={(num) => handleClick('cone')}><span>2</span><Icon component={Cone} /></li>
            <li className={stateList === 'pedestrian' ? 'add-active-pedestrian' : ''} onClick={(num) => handleClick('pedestrian')}><span>3</span><Icon component={Pedestrian} /></li>
            <li className={stateList === 'truck' ? 'add-active-truck' : ''} onClick={(num) => handleClick('truck')}><span>4</span><Icon component={Truck} /></li>
            <li className={stateList === 'bicycle' ? 'add-active-bicycle' : ''} onClick={(num) => handleClick('bicycle')}><span>5</span><Icon component={Bicycle} /></li>
            <li className={stateList === 'cyclist' ? 'add-active-cyclist' : ''} onClick={(num) => handleClick('cyclist')}><span>6</span><Icon component={Cyclist} /></li>
          </span>}
        {hideTypes||!objectTypes.length?<li className={stateList === 'more' ? 'add-active-more' : ''} onClick={(num) => handleClick('more')}><Icon component={More} /></li>:null}
      </ul>
    </div>
  )
}

export default withTranslation('views')(Addlabel)
