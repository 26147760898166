import React from 'react'
import ReactDOM from 'react-dom'
import './index.less'
import './index.scss'

import { Provider } from 'react-redux'
import { store } from './_helpers'
import App from './App'

import './_helpers/i18n'

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
)
