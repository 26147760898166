export const STATUS_ING = {
  UNASSIGNED: 'unassigned',
  ASSIGNED: 'assigned',
  ANNOTATING: 'annotating'
}

export const STATUS_ED = {
  UNEXAMNIED: 'readyforexam',
  EXAMINING: 'examining',
  FINISHED: 'finish',
  OBJECTSREADY: 'objects_ready'
}

export const TAG_COLOR = {
  [STATUS_ING.UNASSIGNED]: '#943126',
  [STATUS_ING.ASSIGNED]: '#5B2C6F',
  [STATUS_ING.ANNOTATING]: '#21618C',
  [STATUS_ED.UNEXAMNIED]: '#1D8348',
  [STATUS_ED.EXAMINING]: '#9C640C',
  [STATUS_ED.FINISHED]: '#404040',
  [STATUS_ED.OBJECTSREADY]: '#616A6B'
}

export const TYPE_COLOR = {
  ['3d']: '#CCFF99',
  ['2d']: '#FF6666',
  ['3d_tracking']: '#99CC66',
  ['2d_tracking']: '#CC0033',
  ['link']: '#FFFF66',
}

export const TYPE = {
  '2D': '2d',
  '3D': '3d',
  TRACKING_3D: '3d_tracking',
  TRACKING_2D: '2d_tracking',
  Link: 'link',
  // SIGNAL: 'signal',
  // CITYSCAPE: 'cityscape',
  // FUSION: 'fusion',
}

export const SHOW_TYPE = {
  '2D': '2d',
  '3D': '3d',
  TRACKING_3D: '3d_tracking',
  // TRACKING_2D: '2d_tracking',
  // Link: 'link',
}

export const DEFAULT_VALUE = '---'
