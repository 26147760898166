
import createKDTree from 'static-kdtree'
import ndarray from 'ndarray'
import { Box3, Vector3 } from 'three'
import { Float32BufferAttribute, Color } from 'three'
import { PCD_COLOR } from '../_constants'

export function extractObject3D(label) {
  return {
    id: label.userData.id,
    name: label.name,
    color:label.color,
    showName:label.showName,
    type: label.userData.type,
    position: label.position.toArray(),
    rotation: label.rotation.toArray(),
    scale: label.scale.toArray(),
    dimension: label.userData.dimension.toArray(),
    fail: label.userData.fail,
  }
}

export function extractObject2D(label, image) {
  const { angle, aCoords, scaleX: scale } = image
  const { x, y } = angle ? aCoords.br : aCoords.tl
  const { left: lLeft, top: lTop, width, height, scaleX, scaleY } = label.target

  const left = lLeft - x
  const top = lTop - y
  return {
    id: label.id,
    showName:label.showName,
    color:label.color,
    name:label.name,
    type: label.type,
    xmin: (left / scale).toFixed(10),
    ymin: (top / scale).toFixed(10),
    xmax: ((left + width * scaleX) / scale).toFixed(10),
    ymax: ((top + height * scaleY) / scale).toFixed(10),
    fail: label.fail,
  }
}

export function extractObjectFusion(label, imageWidth, imageHeight) {
  const results = []

  // Add PCD first
  results.push(extractObject3D(label))

  // Then cameras
  label.userData.rects.forEach(rect => {
    const { left, top, width, height, scaleX, scaleY } = rect

    const xmin = Math.max(0, left)
    const ymin = Math.max(0, top)
    const xmax = Math.min(imageWidth, left + width * scaleX)
    const ymax = Math.min(imageHeight, top + height * scaleY)

    if (xmax > xmin && ymax > ymin) {
      results.push({
        id: label.userData.id,
        type: label.userData.type,
        color:label.color,
        showName:label.showName,
        xmin,
        ymin,
        xmax,
        ymax,
        fail: label.fail,
      })
    }
  })

  return results
}

export function base64ToArrayBuffer(encodedData) {
  const data = atob(encodedData)

  // download(data, 'binary_error.pcd')

  const view = new Uint8Array(data.length)

  for (let i = 0; i < data.length; i++) {
    view[i] = data.charCodeAt(i)
  }

  return view.buffer
}

// Method from Dixie official https://github.com/dfahlander/Dexie.js/issues/521
export function recreateDB(db) {
  return db.delete().then(() => db.open())
}

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b
  })

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null
}

export function download(content, filename) {
  var eleLink = document.createElement('a')
  eleLink.download = filename
  eleLink.style.display = 'none'
  var blob = new Blob([content])
  eleLink.href = URL.createObjectURL(blob)
  document.body.appendChild(eleLink)
  eleLink.click()
  document.body.removeChild(eleLink)
}

export const dyePCD = pcd => {
  const count = pcd.geometry.attributes.position.count
  const colors = []

  for (let i = 0; i < count; ++i) {
    colors.push(...PCD_COLOR)
  }

  pcd.geometry.setAttribute('color', new Float32BufferAttribute(colors, 3))

  pcd.material.color = new Color()
  pcd.material.vertexColors = true
}

export const createPointsKDTree = pcd => {
  const positions = pcd.geometry.attributes.position.array
  const count = pcd.geometry.attributes.position.count
  return createKDTree(ndarray(positions, [count, 3]))
}

export const changePointsColorInBox = (mesh, pcd, kdTree, color) => {
  const colors = pcd.geometry.attributes.color.array
  const positions = pcd.geometry.attributes.position.array
  let pointsCounter = 0

  mesh.geometry.computeBoundingBox()
  mesh.updateMatrixWorld(true)

  const bbox = new Box3()
  bbox.setFromObject(mesh)
  const meshMatrixInverse = new THREE.Matrix4().getInverse(mesh.matrixWorld)

  // !!! Hack, DO NOT USE
  const children = mesh.children
  mesh.children = []
  const inverseMesh = mesh.clone()
  mesh.children = children

  inverseMesh.applyMatrix(meshMatrixInverse)
  const inversePoint = new Vector3()

  const box = new Box3()
  kdTree.range(Object.values(bbox.min), Object.values(bbox.max), i => {
    inversePoint.set(
      positions[i * 3],
      positions[i * 3 + 1],
      positions[i * 3 + 2]
    )

    inversePoint.applyMatrix4(meshMatrixInverse)
    box.setFromObject(inverseMesh)

    if (box.containsPoint(inversePoint)) {
      colors[i * 3] = color[0]
      colors[i * 3 + 1] = color[1]
      colors[i * 3 + 2] = color[2]
      pointsCounter++
    }
  })

  pcd.geometry.attributes.color.needsUpdate = true

  return pointsCounter
}
