import { combineReducers } from 'redux'

import { authentication } from './authentication.reducer'
import { alert } from './alert.reducer'
import { annotation } from './annotation.reducer'
import { bag } from './bag.reducer'
import { objectType } from './objectType.reducer'
import { taskType } from './taskType.reducer'
import { group } from './group.reducer'
import { locale } from './locale.reducer'
import {tag} from './tag.reducer'
import {getSome} from './getSome.reducer'

const rootReducer = combineReducers({
  authentication,
  alert,
  annotation,
  bag,
  objectType,
  taskType,
  group,
  locale,
  tag,
  getSome,
})

export default rootReducer
