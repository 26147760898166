import { axios } from '../_helpers'

export const userServices = {
  login,
  create,
  get,
  getAll,
  update,
  deleteUser,
  getHome,
}

/** Auth */
function login(data) {
  return axios.post('authservice/auth', data).then(response => {
    return response.data
  })
}

/** Create user */
function create(data) {
  return axios.post('accountservice/create', data).then(response => {
    return response.data
  })
}

/** Get user */
function get(params) {
  return axios
    .get('accountservice/get', { params, data: {} })
    .then(response => {
      return response.data
    })
}

// Delayed get for testing
// function get(params) {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       return axios
//         .get('accountservice/get', { params, data: {} })
//         .then(function(response) {
//           return resolve(response.data)
//         })
//     }, 50)
//   })
// }

/** Get all users */
function getAll(params) {
  return axios
    .get('accountservice/getAll', { params, data: {} })
    .then(response => {
      return response.data
    })
}

/** Update user */
function update(data) {
  return axios.post('accountservice/update', data).then(response => {
    return response.data
  })
}

function deleteUser(data) {
  return axios.post('accountservice/delete', data).then(response => {
    return response.data
  })
}

function getHome(params) {
  return axios
    .get('accountservice/getHome', { params, data: {} })
    .then(response => {
      return response.data
    })
}
