import React from 'react'
import './WorkChart.scss'
import { withTranslation } from 'react-i18next'
import ReactEcharts from 'echarts-for-react'

const WorkChart = ({ t, statDays }) => {
    const chartOption = {
        title: {
            text: t('workload'),
            textStyle: {
                color: '#fff',
                fontWeight: 'normal'
            },
            x: 'center',
            y: '15px'
        },
        legend: {
            data: [t('annotator'), t('examiner'),],
            right: '20px',
            y: '20px',
            textStyle: {
                color: '#fff'
            },
        },
        tooltip: {
            trigger: 'axis',
            formatter: `${t('annotator')}：{c0}<br/>${t('examiner')}：{c1}`
        },
        grid: {
            x2: 30,
        },
        xAxis: {
            type: 'category',
            // data: ['4-19', '4-18', '4-17', '4-16', '4-15', '4-14', '4-13']
            data: statDays.map(item => item.date)
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            name: t('annotator'),
            // data: [820, 932, 901, 934, 1290, 1330, 1320],
            data: statDays.map(item => item.annotatorWork),
            type: 'line',
            smooth: true
        },
        {
            name: t('examiner'),
            // data: [720, 832, 101, 934, 1290, 1330, 1320],
            data: statDays.map(item => item.examinerWork),
            type: 'line',
            smooth: true
        }
        ],
        dataZoom: [{
            show: true,
            start: 0,
            end: 100
        }]
    }
    return (
        <div className="chart-main" >
            <ReactEcharts option={chartOption} />
        </div >
    )
}

        export default withTranslation('statistics')(WorkChart)