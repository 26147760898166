import { taskTypeConstants } from '../_constants'

const initState = {
    taskTypes: []
}

export function taskType(state = initState, action) {
  switch (action.type) {
    case taskTypeConstants.GET_ALL_REQUEST:
      return {
        ...state,
        gettingtaskTypes: true
      }
    case taskTypeConstants.GET_ALL_SUCCESS:
      return {
        taskTypes: action.taskTypes,
        gettingtaskTypes: false
      }
    case taskTypeConstants.GET_ALL_FAILURE:
      return {
        ...state,
        gettingtaskTypes: false
      }
    case taskTypeConstants.CREATE_REQUEST:
      return {
        ...state,
        creatingtaskType: true
      }
    case taskTypeConstants.CREATE_SUCCESS:
      return {
        ...state,
        creatingtaskType: false
      }
    case taskTypeConstants.CREATE_FAILURE:
      return {
        ...state,
        creatingtaskType: false
      }
    case taskTypeConstants.UPDATE_REQUEST:
      return {
        ...state,
        updatingtaskType: true
      }
    case taskTypeConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updatingtaskType: false
      }
    case taskTypeConstants.UPDATE_FAILURE:
      return {
        ...state,
        updatingtaskType: false
      }
    case taskTypeConstants.DELETE_REQUEST:
      return {
        ...state,
        deletingtaskType: true
      }
    case taskTypeConstants.DELETE_SUCCESS:
      return {
        ...state,
        deletingtaskType: false
      }
    case taskTypeConstants.DELETE_FAILURE:
      return {
        ...state,
        deletingtaskType: false
      }
    default:
      return state
  }
}
