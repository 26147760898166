import React, { Component } from 'react'
import './Login.scss'

import { Redirect, withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { userActions, alertActions } from '../_actions'
import { withTranslation } from 'react-i18next'

import { LangButton } from '../common'
import { Footer } from '../Footer'

import { CloseCircleOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'

import { Button, Input, notification } from 'antd'

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      usernameMsg: '',
      passwordMsg: ''
    }

    this.inputUsername
    this.inputPassword
    this.buttonLogin
  }

  componentDidMount() {
    window.addEventListener('keypress', this.handlePressEnter)
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.handlePressEnter)
  }

  componentDidUpdate(prevProps) {
    const { message, dispatch, t } = this.props

    // An error message apears when there's no massage or the message is the same as the previous
    if (
      message &&
      (!prevProps.message || message.detail !== prevProps.message.detail)
    ) {
      notification.error({
        message: t('loginFailed'),
        description: this.translateErrorToMessage(message.detail),
        duration: 3,
        onClose: () => {
          dispatch(alertActions.clear())
        }
      })
    }
  }

  emitEmpty = () => {
    this.inputUsername.focus()
    this.setState({ username: '', password: '' })

    this.clearMessages()
  }

  handleInputChange = e => {
    const { name, value } = e.target

    this.setState({
      [name]: value.trim()
    })

    this.clearMessages()
  }

  handleLogin = () => {
    const { username, password } = this.state
    const { dispatch, loggingIn, t } = this.props

    if (!username) {
      this.setState({ usernameMsg: t('usernameMsg') })
    }
    if (!password) {
      this.setState({ passwordMsg: t('passwordMsg') })
    }

    if (!loggingIn && username && password) {
      dispatch(userActions.login(username, password)).catch(error => {
        dispatch(alertActions.error(error))
      })
    }
  }

  handlePressEnter = e => {
    const { username, password } = this.state

    if (e.keyCode === 13 && (username || password)) {
      this.handleLogin()
    }
  }

  clearMessages = () => {
    this.setState({
      usernameMsg: '',
      passwordMsg: ''
    })
  }

  translateErrorToMessage = detail => {
    const { t } = this.props

    const MESSAGES = [
      t('messages.unkown') + ': ' + detail,
      t('messages.noUser'),
      t('messages.wrongPwd')
    ]
    const ERRORS = ['record not found', 'given password']

    const index = ERRORS.reduce(
      (prevValue, ERROR, index) =>
        detail.includes(ERROR) ? index + 1 : prevValue,
      0
    )

    return MESSAGES[index]
  }

  render() {
    const { username, password, usernameMsg, passwordMsg } = this.state
    const { loggingIn, cookies, location, t } = this.props
    const { from } = location.state || { from: { pathname: '/' } }

    if (cookies.get('_token')) {
      return <Redirect to={from} />
    }

    const suffix = username ? (
      <CloseCircleOutlined onClick={this.emitEmpty} />
    ) : null

    return (
      <div className="login">
        <div className="login-left" />
        <div className="login-right">
          <div className="login-panel">
            <div className="login-row">
              <div className="login-lang-row">
                <span className="login-text-logo">ALLRIDE.AI</span>
                <LangButton />
              </div>
            </div>
            <div className="login-row">
              <Input
                placeholder={t('usernamePlaceholder')}
                prefix={
                  <UserOutlined style={{ color: 'hsl(0, 0%, 40%)' }} />
                }
                suffix={suffix}
                name="username"
                value={username}
                onChange={this.handleInputChange}
                ref={el => (this.inputUsername = el)}
              />
              <div className="login-message">
                <span>{usernameMsg}</span>
              </div>
            </div>
            <div className="login-row">
              <Input.Password
                placeholder={t('passwordPlaceholder')}
                prefix={
                  <LockOutlined style={{ color: 'hsl(0, 0%, 40%)' }} />
                }
                name="password"
                value={password}
                onChange={this.handleInputChange}
                ref={el => (this.inputPassword = el)}
              />
              <div className="login-message">
                <span>{passwordMsg}</span>
              </div>
            </div>
            <div className="login-row">
              <Button
                type="primary"
                loading={loggingIn}
                onClick={this.handleLogin}
              >
                {t('loginBtn')}
              </Button>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loggingIn: state.authentication.loggingIn,
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(
  withRouter(withCookies(withTranslation('login')(Login)))
)
