import { axios } from '../_helpers'

export const groupServices = {
  get,
  getAll,
  update,
  create,
  deleteGroup
}

function get(params) {
  return axios
    .get('accgroupsvc/get', { params, data: {} })
    .then(function(response) {
      return response.data
    })
}

function getAll(params) {
  return axios
    .get('accgroupsvc/getAll', { params, data: {} })
    .then(function(response) {
      return response.data
    })
}

function update(data) {
  return axios.post('accgroupsvc/update', data).then(function(response) {
    return response.data
  })
}

function create(data) {
  return axios.post('accgroupsvc/create', data).then(function(response) {
    return response.data
  })
}

function deleteGroup(data) {
  return axios.post('accgroupsvc/delete', data).then(response => {
    return response.data
  })
}
