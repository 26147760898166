import { axios } from '../_helpers'

export const taskTypeServices = {
  get,
  getAll,
  update,
  create,
  delete: deleteTaskType
}

function get(params) {
  return axios
    .get('tasktypesvc/get', { params, data: {} })
    .then(function(response) {
      return response.data
    })
}

function getAll(params) {
  return axios
    .get('tasktypesvc/getAll', { params, data: {} })
    .then(function(response) {
      return response.data
    })
}

function update(data) {
  return axios.post('tasktypesvc/update', data).then(function(response) {
    return response.data
  })
}

function create(data) {
  console.log(data)
  return axios.post('tasktypesvc/create', data).then(function(response) {
    return response.data
  })
}

function deleteTaskType(data) {
  return axios.post('tasktypesvc/delete', data).then(function(response) {
    return response.data
  })
}
