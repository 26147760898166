import React from 'react'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Input, InputNumber, Button } from 'antd'

const AnnotationForm = ({
  form,
  onSubmit,
  formItemLayout,
  tailFormItemLayout
}) => {
  const { getFieldDecorator, validateFields } = form

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((error, values) => {
      if (!error) {
        onSubmit(values)
      }
    })
  }

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Annotator ID">
        {getFieldDecorator('annotator_id', {
          rules: [{ required: true, message: 'Annotator ID can not be empty' }]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="Examiner ID">
        {getFieldDecorator('examiner_id', {
          rules: [{ required: true, message: 'Examinar ID can not be empty' }]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="Start timestamp">
        {getFieldDecorator('start_timestamp', {
          rules: [
            { required: true, message: 'Start timestamp can not be empty' }
          ]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="End timestamp">
        {getFieldDecorator('end_timestamp', {
          rules: [{ required: true, message: 'End timestamp can not be empty' }]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="Bag ID">
        {getFieldDecorator('bag_id', {
          rules: [{ required: true, message: 'Bag ID can not be empty' }]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="Type">
        {getFieldDecorator('type', {
          rules: [
            { required: true, message: 'Type can not be empty' },
            {
              type: 'enum',
              enum: ['2d', '3d'],
              message: 'Type only support 2d or 3d'
            }
          ]
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Status">
        {getFieldDecorator('status')(<Input />)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Create annotation
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'annotation form' })(AnnotationForm)
