import { bagConstants } from '../_constants'
import { bagServices } from '../_services'

export const bagActions = {
  get,
  getAll,
  create,
  update,
  delete: deleteBag,
  getByName,
}

function get(bagId) {
  return dispatch => {
    dispatch(request())

    const params = {
      id: bagId,
    }

    return bagServices.get(params).then(
      data => {
        dispatch(success(data.bag))
        return { bag: data.bag }
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: bagConstants.GET_REQUEST }
  }

  function success(bag) {
    return { type: bagConstants.GET_SUCCESS, bag }
  }

  function failure() {
    return { type: bagConstants.GET_FAILURE }
  }
}

function getAll(page, pagesize,print) {
  return dispatch => {
    dispatch(request())

    const params = {
      page: page,
      pagesize: pagesize,
      print:print
    }

    return bagServices.getAll(params).then(
      data => {
        dispatch(success(data.bags, page, data.totalCount))
        return { total: data.totalCount }
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: bagConstants.GET_ALL_REQUEST }
  }

  function success(bags, page, total) {
    return { type: bagConstants.GET_ALL_SUCCESS, bags, page, total }
  }

  function failure() {
    return { type: bagConstants.GET_ALL_FAILURE }
  }
}

function create(data) {
  return dispatch => {
    dispatch(request())

    return bagServices.create(data).then(
      data => {
        dispatch(success(data.bag))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: bagConstants.CREATE_REQUEST }
  }

  function success(bag) {
    return { type: bagConstants.CREATE_SUCCESS, bag }
  }

  function failure() {
    return { type: bagConstants.CREATE_FAILURE }
  }
}

function update(data) {
  return dispatch => {
    dispatch(request())

    return bagServices.update(data).then(
      data => {
        dispatch(success(data.bag))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: bagConstants.UPDATE_REQUEST }
  }

  function success(bag) {
    return { type: bagConstants.UPDATE_SUCCESS, bag }
  }

  function failure() {
    return { type: bagConstants.UPDATE_FAILURE }
  }
}

function deleteBag(data) {
  return dispatch => {
    dispatch(request())

    return bagServices.delete(data).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: bagConstants.DELETE_REQUEST }
  }

  function success() {
    return { type: bagConstants.DELETE_SUCCESS }
  }

  function failure() {
    return { type: bagConstants.DELETE_FAILURE }
  }
}

function getByName(page, pagesize,print) {
  return dispatch => {
    dispatch(request())

    const params = {
      page: page,
      pagesize: pagesize,
      print:print
    }

    return bagServices.getByName(params).then(
      data => {
        dispatch(success(data.bags, page, data.totalCount))
        return { total: data.totalCount }
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: bagConstants.GET_BYNAME_REQUEST }
  }

  function success(bags, page, total) {
    return { type: bagConstants.GET_BYNAME_SUCCESS, bags, page, total }
  }

  function failure() {
    return { type: bagConstants.GET_BYNAME_FAILURE }
  }
}
