export const objectTypeConstants = {
  GET_REQUEST: 'OBJECT_TYPE_GET_REQUEST',
  GET_SUCCESS: 'OBJECT_TYPE_GET_SUCCESS',
  GET_FAILURE: 'OBJECT_TYPE_GET_FAILURE',

  GET_ALL_REQUEST: 'OBJECT_TYPE_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'OBJECT_TYPE_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'OBJECT_TYPE_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'OBJECT_TYPE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'OBJECT_TYPE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'OBJECT_TYPE_UPDATE_FAILURE',

  CREATE_REQUEST: 'OBJECT_TYPE_CREATE_REQUEST',
  CREATE_SUCCESS: 'OBJECT_TYPE_CREATE_SUCCESS',
  CREATE_FAILURE: 'OBJECT_TYPE_CREATE_FAILURE',

  DELETE_REQUEST: 'OBJECT_TYPE_DELETE_REQUEST',
  DELETE_SUCCESS: 'OBJECT_TYPE_DELETE_SUCCESS',
  DELETE_FAILURE: 'OBJECT_TYPE_DELETE_FAILURE',

  GETGROUP_REQUEST:'OBJECT_TYPE_GROUP_GET_REQUEST',
  GETGROUP_SUCCESS:'OBJECT_TYPE_GROUP_GET_SUCCESS',
  GETGROUP_FAILURE:'OBJECT_TYPE_GROUP_GET_FAILURE',

  UPDATEGROUP_REQUEST:'OBJECT_TYPE_GROUP_UPDATE_REQUEST',
  UPDATEGROUP_SUCCESS:'OBJECT_TYPE_GROUP_UPDATE_SUCCESS',
  UPDATEGROUP_FAILURE:'OBJECT_TYPE_GROUP_UPDATE_FAILURE',

  DELETEGROUP_REQUEST:'OBJECT_TYPE_GROUP_DELETE_REQUEST',
  DELETEGROUP_SUCCESS:'OBJECT_TYPE_GROUP_DELETE_SUCCESS',
  DELETEGROUP_FAILURE:'OBJECT_TYPE_GROUP_DELETE_FAILURE',

  GETBYGROUP_REQUEST:'OBJECT_TYPE_GROUP_GETBY_REQUEST',
  GETBYGROUP_SUCCESS:'OBJECT_TYPE_GROUP_GETBY_SUCCESS',
  GETBYGROUP_FAILURE:'OBJECT_TYPE_GROUP_GETBY_FAILURE',

  REFRESH:'OBJECT_TYPE_REFRESH'

}
