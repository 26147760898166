import { objectTypeConstants } from '../_constants'
import { objectTypeServices } from '../_services'

export const objectTypeActions = {
  getAll,
  create,
  update,
  delete: deleteObjectType,
  getGroup,
  updateGroup,
  deleteGroup,
  getByGroup,
  refresh,
}

function getAll(params) {
  return dispatch => {
    dispatch(request())

    return objectTypeServices.getAll(params).then(
      data => {
        dispatch(success(data.objectTypes))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: objectTypeConstants.GET_ALL_REQUEST }
  }

  function success(objectTypes) {
    return { type: objectTypeConstants.GET_ALL_SUCCESS, objectTypes }
  }

  function failure() {
    return { type: objectTypeConstants.GET_ALL_FAILURE }
  }
}

function create(data) {
  return dispatch => {
    dispatch(request())

    return objectTypeServices.create(data).then(
      data => {
        dispatch(success(data.objectType))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: objectTypeConstants.CREATE_REQUEST }
  }

  function success(objectType) {
    return { type: objectTypeConstants.CREATE_SUCCESS, objectType }
  }

  function failure() {
    return { type: objectTypeConstants.CREATE_FAILURE }
  }
}

function update(data) {
  return dispatch => {
    dispatch(request())

    return objectTypeServices.update(data).then(
      data => {
        dispatch(success(data.objectType))
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: objectTypeConstants.UPDATE_REQUEST }
  }

  function success(objectType) {
    return { type: objectTypeConstants.UPDATE_SUCCESS, objectType }
  }

  function failure() {
    return { type: objectTypeConstants.UPDATE_FAILURE }
  }
}

function deleteObjectType(data) {
  return dispatch => {
    dispatch(request())

    return objectTypeServices.delete(data).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: objectTypeConstants.DELETE_REQUEST }
  }

  function success() {
    return { type: objectTypeConstants.DELETE_SUCCESS }
  }

  function failure() {
    return { type: objectTypeConstants.DELETE_FAILURE }
  }
}

function getGroup(params){
  return dispatch=>{
    dispatch(request())

    return objectTypeServices.getGroup(params).then(
      (data)=>{
        dispatch(success(data))
      },
      error=>{
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }
  function request(){
    return {type:objectTypeConstants.GETGROUP_REQUEST}
  }

  function success(data){
    return {type:objectTypeConstants.GETGROUP_SUCCESS,data}
  }

  function failure(){
    return { type: objectTypeConstants.GETGROUP_FAILURE }
  }
}

function updateGroup(data){
  return dispatch =>{
    dispatch(request())
    return objectTypeServices.updateGroup(data).then(
      () => {
      dispatch(success())
    },
    error => {
      dispatch(failure())

      return Promise.reject(error.response.data)
    })
  }
  function request() {
    return { type: objectTypeConstants.UPDATEGROUP_REQUEST }
  }

  function success() {
    return { type: objectTypeConstants.UPDATEGROUP_SUCCESS }
  }

  function failure() {
    return { type: objectTypeConstants.UPDATEGROUP_FAILURE }
  }
}

function deleteGroup(data) {
  return dispatch => {
    dispatch(request())

    return objectTypeServices.deleteGroup(data).then(
      () => {
        dispatch(success())
      },
      error => {
        dispatch(failure())

        return Promise.reject(error.response.data)
      }
    )
  }

  function request() {
    return { type: objectTypeConstants.DELETEGROUP_REQUEST }
  }

  function success() {
    return { type: objectTypeConstants.DELETEGROUP_SUCCESS }
  }

  function failure() {
    return { type: objectTypeConstants.DELETEGROUP_FAILURE }
  }
}

function getByGroup(data){
  return dispatch =>{
    dispatch(request())

    return objectTypeServices.getByGroup(data).then((data)=>{
      dispatch(success(data))
    },
  error => {
    dispatch(failure())

    return Promise.reject(error.response.data)
  }
  )}

  function request() {
    return { type: objectTypeConstants.GETBYGROUP_REQUEST }
  }

  function success(data) {
    return { type: objectTypeConstants.GETBYGROUP_SUCCESS ,data}
  }

  function failure() {
    return { type: objectTypeConstants.GETBYGROUP_FAILURE }
  }
}

function refresh(){
  return dispatch=>{
    return dispatch(refresh())
  }
  function refresh(){
    return {type:objectTypeConstants.REFRESH}
  }
}