import React from 'react'
import './Shortcuts.scss'
import { withTranslation } from 'react-i18next'

import { role } from '../../../_helpers'
import { CustomIcon } from '../../../common'
import { Divider } from 'antd'

const Shortcuts = ({ user, t }) => (
  <div className="views-shortcuts">
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.frameOperation')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.previous')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          C
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.next')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          V
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.previousFive')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          C
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.nextFive')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Shift</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          V
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.firstFrame')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          C
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.lastFrame')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">Alt</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="views-shortcuts-key">
          V
        </span>
      </div>
    </div>

    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.boxSelection')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.selectBoxDesc')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          Left mouse button
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.addAssociation')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          R
        </span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.deleteAssociation')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="views-shortcuts-key">
          E
        </span>
      </div>
    </div>
    <Divider style={{ margin: '1.2rem 0' }} />
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.viewOperation')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command" style={{width:'9rem'}}>{t('sc.globalView')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">`</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">
        <span className="views-shortcuts-title">{t('sc.otherOperations')}</span>
      </div>
    </div>
    <div className="views-shortcuts-row">
      <div className="views-shortcuts-command">{t('sc.save')}</div>
      <div className="views-shortcuts-keybinding">
        <span className="viewer2-shortcuts-key cplx">ALT</span>
        <span className="views-shortcuts-key-ext">+</span>
        <span className="viewer2-shortcuts-key cplx">S</span>
      </div>
    </div>

  </div>
)

export default withTranslation('views')(Shortcuts)
