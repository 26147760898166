import React from 'react'
import { withTranslation } from 'react-i18next'

import { role } from '../../_helpers'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Input, Button, Select } from 'antd'

const { Option, OptGroup } = Select
const UserForm = ({
  form,
  onSubmit,
  formItemLayout,
  tailFormItemLayout,
  loading,
  groups,
  curRole,
  t
}) => {
  const { getFieldDecorator, validateFields, getFieldValue } = form
  const newUserRole = getFieldValue('role')

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((error, values) => {
      if (!error) {
        if (!values.groupId) values.groupId = []

        onSubmit(values)
      }
    })
  }

  const GroupRender = () => {
    // Global user doesn't have to be assigned group
    if (
      curRole != role.admin ||
      newUserRole === role.admin ||
      newUserRole === role.globalAnnotator ||
      newUserRole === role.globalExaminer ||
      newUserRole === role.developer
    )
      return null

    return (
      <Form.Item label={t('group')}>
        {getFieldDecorator('groupId', {
          rules: [{ required: true, message: 'Group is required' }]
        })(
          <Select>
            {groups &&
              groups.map(group => <Option key={group.id}>{group.name}</Option>)}
          </Select>
        )}
      </Form.Item>
    )
  }

  const RolesRender = () => {
    return (
      <Form.Item label={t('role')}>
        {getFieldDecorator('role', {
          rules: [{ required: true, message: 'Roles is required' }]
        })(
          <Select>
            {curRole === role.admin ? (
              <OptGroup label={t('globalUser')}>
                <Option value="admin">{t('common:role.admin')}</Option>
                <Option value="globalAnnotator">
                  {t('common:role.globalAnnotator')}
                </Option>
                <Option value="globalExaminer">
                  {t('common:role.globalExaminer')}
                </Option>
                <Option value="developer">{t('common:role.developer')}</Option>
              </OptGroup>
            ) : null}
            <OptGroup label={t('groupUser')}>
              <Option value="groupAdmin">{t('common:role.groupAdmin')}</Option>
              <Option value="groupAnnotator">
                {t('common:role.groupAnnotator')}
              </Option>
              <Option value="groupExaminer">
                {t('common:role.groupExaminer')}
              </Option>
            </OptGroup>
          </Select>
        )}
      </Form.Item>
    )
  }

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={t('username')}>
        {getFieldDecorator('username', {
          rules: [{ required: true, message: 'Username can not be empty' }]
        })(<Input />)}
      </Form.Item>
      <RolesRender />
      <GroupRender />
      <Form.Item label={t('email')}>
        {getFieldDecorator('email', {
          rules: [
            { type: 'email', required: true, message: 'Invalid email address' }
          ]
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('password')}>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: 'Password can not be empty' }]
        })(<Input.Password />)}
      </Form.Item>
      <Form.Item label={t('phone')}>
        {getFieldDecorator('phonenumber')(<Input />)}
      </Form.Item>
      <Form.Item label={t('description')}>
        {getFieldDecorator('description')(
          <Input.TextArea autosize={{ minRows: 1, maxRows: 4 }} />
        )}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('btns.create')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default withTranslation(['users', 'common'])(
  Form.create({ name: 'user form' })(UserForm)
)
