import React, { Component } from 'react'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'

import { userActions } from '../../_actions'

import { Loading } from '../../Loading'
import { NotAuth } from '../../NotAuth'

class WithAuth extends Component {
  componentDidMount() {
    const { token, user, dispatch } = this.props

    if (!user) {
      const decoded = jwtDecode(token)
      dispatch(userActions.get(decoded.User.id))
    }
  }

  render() {
    const { user, auth, children ,title } = this.props
    document.title = title

    let Component = Loading

    if (user) {
      const role = user.role

      if (!auth.length || auth.includes(role)) {
        Component = typeof children === 'function' ? children : children[role]
      } else {
        Component = NotAuth
      }
    }

    return <Component />
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user
  }
}

export default connect(mapStateToProps)(WithAuth)
