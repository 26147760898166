export const bagConstants = {
  GET_REQUEST: 'BAG_GET_REQUEST',
  GET_SUCCESS: 'BAG_GET_SUCCESS',
  GET_FAILURE: 'BAG_GET_FAILURE',

  GET_ALL_REQUEST: 'BAG_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'BAG_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'BAG_GET_ALL_FAILURE',

  UPDATE_REQUEST: 'BAG_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'BAG_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BAG_UPDATE_FAILURE',

  CREATE_REQUEST: 'BAG_CREATE_REQUEST',
  CREATE_SUCCESS: 'BAG_CREATE_SUCCESS',
  CREATE_FAILURE: 'BAG_CREATE_FAILURE',

  DELETE_REQUEST: 'BAG_DELETE_REQUEST',
  DELETE_SUCCESS: 'BAG_DELETE_SUCCESS',
  DELETE_FAILURE: 'BAG_DELETE_FAILURE',

  GET_BYNAME_REQUEST:'BAG_GET_BYNAME_REQUEST',
  GET_BYNAME_SUCCESS:'BAG_GET_BYNAME_SUCCESS',
  GET_BYNAME_FAILURE:'BAG_GET_BYNAME_FAILURE'
}
