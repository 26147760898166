import { axios } from '../_helpers'

export const objectTypeServices = {
  get,
  getAll,
  update,
  create,
  delete: deleteObjectType,
  getGroup,
  updateGroup,
  deleteGroup,
  getByGroup,
}

function get(params) {
  return axios
    .get('objtypesvc/get', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function getAll(params) {
  return axios
    .get('objtypesvc/getAll', { params, data: {} })
    .then(function (response) {
      return response.data
    })
}

function update(data) {
  return axios.post('objtypesvc/update', data).then(function (response) {
    return response.data
  })
}

function create(data) {
  return axios.post('objtypesvc/create', data).then(function (response) {
    return response.data
  })
}

function deleteObjectType(data) {
  return axios.post('objtypesvc/delete', data).then(function (response) {
    return response.data
  })
}

function getGroup(params){
  return axios.get('objtypesvc/getAllGroup',{ params, data: {} }).then(function(response){
    return response.data
  })
}

function updateGroup(data) {
  return axios.post('objtypesvc/updateGroup', data).then(function (response) {
    return response.data
  })
}

function deleteGroup(data) {
  return axios.post('objtypesvc/deleteGroup', data).then(function (response) {
    return response.data
  })
}

function getByGroup(data) {
  return axios.post('objtypesvc/getByGroup',data).then(function(response){
    return response.data
  })
}