import React from 'react'
import './Addlabel.scss'
import { withTranslation } from 'react-i18next'
import { MATERIAL_COLORS,MATERIAL_LIST,TOOLTIP_DELAY } from '../../constants'

import Icon from '@ant-design/icons'

import { Tooltip } from 'antd'
import { ReactComponent as Carc } from '../../../_assets/car copy.svg'
import { ReactComponent as Conec } from '../../../_assets/cone copy.svg'
import { ReactComponent as Pedestrianc } from '../../../_assets/pedestrian copy.svg'
import { ReactComponent as Truckc } from '../../../_assets/truck copy.svg'
import { ReactComponent as Bicyclec } from '../../../_assets/bicycle copy.svg'
import { ReactComponent as Cyclistc } from '../../../_assets/cyclist copy.svg'
import { ReactComponent as Busc } from '../../../_assets/bus copy.svg'
import { ReactComponent as Morec } from '../../../_assets/more.svg'

import { ReactComponent as Car } from '../../../_assets/car.svg'
import { ReactComponent as Cone } from '../../../_assets/cone.svg'
import { ReactComponent as Pedestrian } from '../../../_assets/pedestrian.svg'
import { ReactComponent as Truck } from '../../../_assets/truck.svg'
import { ReactComponent as Bicycle } from '../../../_assets/bicycle.svg'
import { ReactComponent as Bus } from '../../../_assets/bus.svg'
import { ReactComponent as Cyclist } from '../../../_assets/cyclist.svg'
import { ReactComponent as More } from '../../../_assets/more.svg'


const Addlabel = ({ stateList,handleClick,visible, t ,objectTypes }) => {
  const showTypes = objectTypes ? (objectTypes.length >= 6 ? objectTypes.slice(0, 6) : objectTypes) : null
  const hideTypes = objectTypes ? (objectTypes.length >= 6 ? objectTypes.slice(6) : null) : null
  const handleIcon = (record) => {
    const iconArr = [
      { name: 'Car', component: Carc },
      { name: 'Cone', component: Conec },
      { name: 'Pedestrian', component: Pedestrianc },
      { name: 'Truck', component: Truckc },
      { name: 'Bicycle', component: Bicyclec },
      { name: 'Cyclist', component: Cyclistc },
      { name: 'Bus', component: Busc },
      // {name:'More',component:More},
    ]
    if (record.icon) {

      for (let item in iconArr) {
        if (iconArr[item].name == record.icon) {

          return iconArr[item].component
        }
      }
    }

  }
  return (
    <div className="views-addRect-row">
      <ul className="views-rect-lists">
      {
      showTypes?showTypes.map((item)=>(
        <li className={stateList=== item.name ? `add-active-${item.name}`:''} onClick={(num)=>handleClick(item.name)} key={item.id} style={stateList === item.name?{border: `3px solid ${item.color}`,
        background: `rgba($color: ${item.color}, $alpha: '0.2')`}:null}>
          <Tooltip
                title={item.showName+`（${item.sortNumber?item.sortNumber:'无'}）`}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
            {item.icon?
          <Icon style={{ marginRight: '1rem', color: item.color }}  component={handleIcon(item)}/>
          :(<div style={{ width: '1rem', height: '1rem', backgroundColor: item.color, display: 'inline-block' }}></div>)}
          </Tooltip>
        </li>
      )):<div>
        <li className={stateList=== 'car' ? 'add-active-car':''} onClick={(num)=>handleClick('car')}>
          <Tooltip
                title={t('tooltip.car')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
          <Icon component={Car}/>
          </Tooltip>
        </li>
        <li className={stateList=== 'pedestrian' ? 'add-active-pedestrian':''} onClick={(num)=>handleClick('pedestrian')}>
        <Tooltip
                title={t('tooltip.pedestrian')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
          <Icon component={Pedestrian}/>
          </Tooltip>
        </li>
        <li className={stateList=== 'cone' ? 'add-active-cone':''} onClick={(num)=>handleClick('cone')}>
        <Tooltip
                title={t('tooltip.cone')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
          <Icon component={Cone}/>
          </Tooltip>
        </li>
        <li className={stateList=== 'cyclist' ? 'add-active-cyclist':''} onClick={(num)=>handleClick('cyclist')}>
         <Tooltip
                title={t('tooltip.cyclist')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
          <Icon component={Cyclist}/>
          </Tooltip>
        </li>
        <li className={stateList=== 'truck' ? 'add-active-truck':''} onClick={(num)=>handleClick('truck')}>
         <Tooltip
                title={t('tooltip.truck')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
          <Icon component={Truck}/>
          </Tooltip>
        </li>
        <li className={stateList=== 'bus' ? 'add-active-bus':''} onClick={(num)=>handleClick('bus')}>
         <Tooltip
                title={t('tooltip.bus')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
          <Icon component={Bus}/>
          </Tooltip>
        </li>
        {/* <li className={stateList=== 'more' ? 'add-active-more':''} onClick={(num)=>handleClick('more')}><Icon component={More}/></li> */}
      </div>}
      {hideTypes||!objectTypes?<li className={stateList === 'more' ? 'add-active-more' : ''} onClick={(num) => handleClick('more')}><Icon component={More} /></li>:null}
      </ul>
      <div className="views-addList-row" style={{ visibility: visible ? 'visible' : 'hidden' }}>
        <ul className="views-lists">
        {
        hideTypes?hideTypes.map((item,index)=>{
          return(
            <li key={index} className={stateList=== item.name ? 'add-active':''} onClick={(type)=>handleClick(item.name)}>
                <span style={{background:item.color}}></span>{item.showName}
              </li>
          )
        })
        :
            MATERIAL_LIST.map((item,index)=>{
              return (
              <li key={index} className={stateList=== item.type ? 'add-active':''} onClick={(type)=>handleClick(item.type)}>
                <span style={{background:MATERIAL_COLORS[item.type.toUpperCase()]}}></span>{t(`type.${item.type}`)}
              </li>
              )
            })
          }
        </ul>
    </div>
    </div>
  )
}

export default withTranslation('viewer2d')(Addlabel)
