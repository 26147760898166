import React from 'react'
import './LeftRect.scss'
import { withTranslation } from 'react-i18next'

import { MATERIAL_RECT_COLORS } from '../../constants'
import { LabelType } from '../../../common'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { InboxOutlined } from '@ant-design/icons'

const LeftRect = ({ rects,selectedLabel,handleSelectRect,handleToggleLabelVisibility,t }) => {
  const rectsCollection = rects.map(rect => (
    <li
      className={`viewer2-labels-collection-item${
        rect === selectedLabel ? ' selected' : ''
      }`}
      style={{ cursor: rect.target.visible ? '' : 'not-allowed',display:rect.visibleList?'none':'block' }}
      onClick={() => rect.target.visible && handleSelectRect(rect)}
      key={rect.id}
      id={rect.id}
    >
      <div className="viewer2-label-row">
        <div className="viewer2-label-type">
          <LabelType color={MATERIAL_RECT_COLORS[rect.type.toUpperCase()]} />
          {rect.name}
        </div>
        <div className="viewer2-label-actions">
          <button
            className="viewer2-btn"
            onClick={e => handleToggleLabelVisibility(e, rect)}
          >
            <LegacyIcon type={rect.target.visible ? 'eye' : 'eye-invisible'} />
          </button>
        </div>
      </div>
    </li>
  ))

  const emptyCollection = (
    <div className="viewer2-labels-collection-empty">
      <div>
        <InboxOutlined style={{ fontSize: '30px' }} />
      </div>
      {t('noLabels')}
    </div>
  )

  return (
    <ul className="viewer2-labels-collection">
      {rectsCollection.length
        ? rectsCollection
        : emptyCollection}
    </ul>
  )
}

export default withTranslation('views')(LeftRect)
