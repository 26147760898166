let zh = {
  common: {
    role: {
      admin: '管理员',
      groupAdmin: '组管理员',
      globalAnnotator: '标注员',
      groupAnnotator: '组标注员',
      globalExaminer: '审查员',
      groupExaminer: '组审查员',
      developer: '开发者'
    },
    statusText: {
      unassigned: '未分配',
      assigned: '已分配',
      annotating: '标注中',
      readyforexam: '待审查',
      examining: '审查中',
      finish: '已结束',
      objects_ready: '就绪'
    },
    annotTypeText: {
      '3d': '3D',
      '2d': '2D',
      fusion: '融合',
      cityscape: '调整',
      signal: '红绿灯',
      '3d_tracking': '3D跟踪',
      '2d_tracking': '2D跟踪',
      'link': '关联标注',
    },
    typesText:{
      '3d': '3D',
      '2d': '2D',
      fusion: '融合',
      cityscape: '调整',
      signal: '红绿灯',
      '3d_tracking': '3DT',
      '2d_tracking': '2DT',
      'link': 'Link',
    },
    btn: {
      submit: '提交',
      delete: '删除',
      reset: '重置',
      clear: '清除',
      search: '搜索',
      query: '查询',
      edit: '编辑',
      open: '打开',
      finish: '结束',
      select: '选择',
      update: '更新 ',
      start: '开始',
      reject: '驳回',
      get: '获取',
      calculation:'立即计算',
      stat:'统计结果',
      reset:'重置',
      search:'搜索'
    },
    msg: {
      updateSuccessful: '更新成功',
      updateFailed: '更新失败',
      createSuccessful:'添加成功',
      createFailed:'添加失败',
      startSuccessful: '开始成功',
      startFailed: '开始失败',
      submitSuccessful: '提交成功',
      submitFailed: '提交失败',
      finishSuccessful: '结束成功',
      finishFailed: '结束失败',
      rejectSuccessful: '驳回成功',
      rejectFailed: '驳回失败',
      resetSuccessful: '重置成功',
      resetFailed: '重置失败',
      getSuccessful: '获取成功',
      getFailed: '获取失败',
      calculateSuccessful:'计算成功',
      calculateFailed:'计算失败'
    },
    tip: {
      noData: '无数据'
    },
    username: '用户名',
    bagID: 'Bag ID',
    bagName: 'Bag名称',
    bagDescription: 'Bag描述',
    startTimestamp: '开始时间戳',
    endTimestamp: '结束时间戳',
    type: '类型',
    tag:'标签',
    search:'搜索',
    labelsCount: '标注数',
    annotatorID: '标注员ID',
    examinerID: '审查员ID',
    status: '状态',
    progress: '进度',
    finishdate: '完成日期',
    accuracy: '正确率',
    date: '日期',
    user: '用户',
    group: '用户组',
    annotations: '标注段数',
    labels: '标注数',
    annotator: '标注员',
    examiner: '审查员',
    all: '全部',
    time:'时间',
    executor:'执行人',
    objectTypeGroup:'标签组',
    finishDate:'完成时间',
    framesNum:'总帧数',
    boxBuiding:'建框',
    workload:'工作量',
    reviewFrame:'审框',
    tasksNum:'任务数',
    boxesNum:'总框数',
    sumitBoxes:'提交框',
    validBoxes:'有效框',
    fillBoxes:'补框',
    adjustBoxes:'调框',
  },
  login: {
    loginBtn: '登陆',
    usernamePlaceholder: '用户名 / 邮箱',
    passwordPlaceholder: '密码',
    usernameMsg: '用户名不能为空',
    passwordMsg: '密码不能为空',
    messages: {
      unkown: '未知错误.',
      noUser: '用户不存在. 请检查用户名或邮箱是否正确。',
      wrongPwd: '密码不正确.'
    },
    loginFailed: '登陆失败',
    logo: '中智行'
  },
  notAuth: {
    content: '无操作权限'
  },
  header: {
    home: '首页',
    users: '用户列表',
    annotations: '标注列表',
    bags: 'Bags',
    statistics: '标注统计',
    objecttypes:'自定义标签组',
    'viewer-3d': '3D标注视图',
    'viewer-2d': '2D标注视图',
    'viewer-fusion': '融合标注视图',
    'viewer-cityscape': '2D框调整视图',
    'viewer-signal': '红绿灯标注视图',
    'viewer-3d_tracking': '3D跟踪标注视图',
    'viewer-2d_tracking': '2D跟踪标注视图',
    'viewer-link':'关联标注视图',
    groups: '用户组列表',
    test: '测试页',
    profile: '个人资料',
    logout: '退出登陆'
  },
  adminHome: {
    usersGroupsTitle: '用户 / 用户组',
    annotationsTitle: '标注',
    bagsTitle: 'Bags',
    objectTypeTitle: '标签',
    taskTypeTitle: '任务类别',
    statisticsTitle: '标注统计',
    btns: {
      allUsers: '用户列表',
      allGroups: '用户组列表',
      allAnnotations: '标注列表',
      allStatistics: '统计列表',
      allBags: 'Bag列表',
      allObjectTypes: '标签列表',
      allTaskTypes: '任务类别列表'
    },
    stats: {
      total: '总数',
      groups: '用户组'
    }
  },
  gAdminHome: {
    usersTitle: '用户',
    annotationsTitle: '标注',
    statisticsTitle: '统计',
    btns: {
      allUsers: '用户列表',
      allAnnotations: '标注列表',
      allStatistics: '统计列表'
    },
    stats: {
      total: '总数'
    }
  },
  annotHome: {
    btns: {
      allAnnotations: '标注列表'
    },
    stats: {
      total: '总数'
    }
  },
  users: {
    createUserTitle: '创建新用户',
    editUserTitle: '编辑用户',
    username: '用户名',
    groupId: '用户组ID',
    role: '用户角色',
    email: '邮箱',
    phone: '手机',
    description: '描述',
    password: '密码',
    activeStatus: '激活状态',
    group: '用户组',
    groupUser: '组用户',
    globalUser: '全局用户',
    btns: {
      edit: '编辑',
      create: '创建',
      update: '更新',
      delete: '删除',
      reset: '重置',
      filter: '筛选',
      search: '搜索'
    },
    alerts: {
      updateSuccess: '更新成功',
      updateFailure: '更新失败',
      createSuccess: '创建成功',
      deleteSuccess: '删除成功',
      deleteFailure: '删除失败',
      copySuccess:'复制成功',
      copyFailure:'复制失败',
      unkown: '未知错误',
      usernameExists: '用户名已存在',
      emailExists: '邮箱已存在'
    },
    pop: {
      deleteUser: '确定删除此用户?',
      okText: '确定',
      cancelText: '取消'
    },
    tooltip: {
      create: '新用户'
    },
    plhd: {
      group: '选择用户组'
    },
    active: '已激活',
    inactive: '未激活',
    searchPlhd: '搜索用户名',
    noUsers: '无用户'
  },
  annot: {
    startMonth: '起日期',
    endMonth: '止日期',
    finished: '已完成',
    accuracy: '正确率',
    title: {
      edit: '编辑任务',
      annotatorsList: '标注员列表',
      examinersList: '审查员列表',
      groupsList: '用户组列表',
      stat:'统计结果'
    },
    btn: {
      selectAll: '选择$t(all)',
      selectAnnotator: '选择$t(annotator)',
      selectExaminer: '选择$t(examiner)',
      selectGroup: '选择$t(group)',
      editAll: '编辑$t(all)'
    },
    unlabeledPages: '未标注页',
    startPage: '开始页',
    endPage: '结束页',
    object: '查询对象',
    name: '用户组名',
    bagDate: 'Bag日期'
  },
  annotations: {
    activeStatus: '激活状态',
    selectAnnotator: '选择标注员',
    selectExaminer: '选择审查员',
    selectStatus: '选择状态',
    viewProgress: '查看进度',
    startPage: '开始页',
    endPage: '结束页',
    unlabeledAnnots: '未标注页',
    btns: {
      start: '开始',
      open: '打开',
      submit: '提交',
      finish: '完成',
      update: '更新',
      search: '搜索',
      reset: '重置',
      clear: '置空'
    },
    alert: {
      startFailure: '请稍后重试',
      submitSuccess: '提交成功',
      submitFailure: '提交失败',
      finishSuccess: '提交完成成功',
      finishFailure: '提交完成失败',
      getSuccess: '获取成功',
      getFailure: '获取失败',
      updateSuccess: '更新成功',
      updateFailure: '更新失败'
    },
    messages: {
      unkown: '位置错误',
      emptyFrame: '含有未标注帧'
    },
    tooltip: {
      get: '获取新任务'
    },
    active: '已激活',
    inactive: '未激活',
    searchPlhd: '搜索用户名',
    noUsers: '无用户'
  },
  taskTypes: {
    createTaskTypeTitle: '创建新的任务类别',
    name: '任务类别名',
    description: '描述',
    objecttypes: '标签',
    btns: {
      reset: '重置',
      search: '搜索',
      clear: '置空',
      update: '更新',
      create: '创建',
      delete: '删除'
    },
    alert: {
      createSuccess: '创建成功',
      createFailure: '创建失败',
      updateSuccess: '更新成功',
      updateFailure: '更新失败',
      deleteSuccess: '删除成功',
      deleteFailure: '删除失败'
    },
    pop: {
      deleteTaskType: '确定删除此任务类别?',
      okText: '确定',
      cancelText: '取消'
    },
    searchPlhd: '搜索名称',
    noTaskTypes: '无任务类别',
    selectGroup: '选择用户组',
    tooltip: {
      create: '新增任务类别'
    }
  },
  objectTypes: {
    createObjectTypeTitle: '创建新标签',
    altObjectTypeTitle:'修改标签',
    objectTypeGroup:'标签组',
    altObjectTypeGroup:'修改标签组',
    createobjectTypeGroup:'创建标签组',
    name: '标签名',
    objecttypes:'标签',
    showName:'显示名称',
    size: '标签Size',
    groupName:'标签组',
    group:'名称',
    defaultSize:'3D默认尺寸',
    color:'颜色',
    shortName:'简写名称',
    sortNumber:'排序序号',
    operate:'操作',
    tags:{
      hide:'隐藏中',
      active:'已激活',
    },
    btns: {
      reset: '重置',
      search: '搜索',
      clear: '置空',
      update: '更新',
      create: '创建',
      delete: '删除',
      alt:'修改',
      hide:'隐藏',
      active:'激活',
    },
    alert: {
      createSuccess: '创建成功',
      createFailure: '创建失败',
      updateSuccess: '更新成功',
      updateFailure: '更新失败',
      deleteSuccess: '删除成功',
      deleteFailure: '删除失败',
      existedGroup:'标签组已存在',
      existedObjectType:'标签已存在',
      bondedGroups:'该标签组有任务绑定',
    },
    pop: {
      deleteObjectType: '确定删除此标签?',
      deleteObjectTypeGroup:'确定删除此标签组',
      acctiveObjectTypeGroup:'确定激活此标签组?',
      hideObjectTypeGroup:'确定隐藏此标签组?',
      okText: '确定',
      cancelText: '取消'
    },
    searchPlhd: '搜索名称',
    noObjectTypes: '无标签',
    selectGroup: '选择用户组',
    tooltip: {
      create: '新增标签'
    }
  },
  bags: {
    name: '名称',
    startTimestamp: '开始时间戳',
    endTimestamp: '结束时间戳',
    description: '描述',
    groupId: '组ID',
    group: '用户组',
    priority: '优先级',
    status: '状态',
    btns: {
      reset: '重置',
      search: '搜索',
      clear: '置空',
      update: '更新',
      delete: '删除',
      edit: '编辑'
    },
    alert: {
      updateSuccess: '更新成功',
      updateFailure: '更新失败',
      deleteSuccess: '删除成功',
      deleteFailure: '删除失败'
    },
    pop: {
      deleteBag: '确定删除此Bag?',
      okText: '确定',
      cancelText: '取消'
    },
    searchPlhd: '搜索名称',
    noBags: '无Bags',
    selectGroup: '选择用户组',
    editBagTitle: '编辑Bag'
  },
  groups: {
    createGroupTitle: '创建新分组',
    editGroupTitle: '编辑分组',
    name: '名称',
    email: '邮箱',
    phone: '手机',
    description: '描述',
    active: '已激活',
    inactive: '未激活',
    activeStatus: '激活状态',
    btns: {
      edit: '编辑',
      create: '创建',
      update: '更新',
      delete: '删除',
      filter: '筛选',
      reset: '重置'
    },
    alerts: {
      updateSuccess: '更新成功',
      updateFailure: '更新失败',
      createSuccess: '创建成功',
      deleteSuccess: '删除成功',
      deleteFailure: '删除失败',
      unkown: '未知错误',
      nameExists: '分组名已存在'
    },
    pop: {
      deleteGroup: '确定删除此分组?',
      okText: '确定',
      cancelText: '取消'
    },
    tooltip: {
      create: '新用户组'
    }
  },
  statistics: {
    summary: '结果',
    records: '标注记录',
    statistics: '数据统计',
    annotations: '标注段数',
    labels: '标注数',
    averageAccuracy: '平均正确率',
    noAnnotators: '无标注员',
    noRecords: '无标注记录',
    startTime: '起时间',
    endTime: '止时间',
    btns: {
      inquery: '查询',
      search: '搜索',
      reset: '重置'
    },
    searchPlhd: '搜索用户名',
    total:'合计',
    taskDetails:'任务详情',
    framesNum:'总帧数',
    boxBuiding:'建框',
    workload:'工作量',
    reviewFrame:'审框',
    tasksNum:'任务数',
    boxesNum:'总框数',
    sumitBoxes:'提交框',
    validBoxes:'有效框',
    fillBoxes:'补框',
    adjustBoxes:'调框',
  },
  views: {
    labelsBox:'逐框标注',
    labelsFrame:'逐帧标注',
    labelsTab: '标注',
    settingsTab: '设置',
    shortcutsTab: '操作指南',
    infoTab: '信息',
    relation:'关联',
    viewsName: {
      top: '俯视图',
      side: '侧视图',
      rear: '后视图'
    },
    camerasName: {
      front: '前相机'
    },
    btns: {
      save: '保存'
    },
    attrs: {
      id:'编号',
      name: '名字',
      type: '类型',
      position: '位置',
      scale: '缩放',
      dimension: '尺寸',
      rotation: '旋转',
      fail: '不合格',
      length: '长',
      width: '宽',
      height: '高',
      xAxis: 'x轴',
      yAxis: 'y轴',
      zAxis: 'z轴',
      pointsCount: '点数'
    },
    type: {
      car: '汽车',
      pedestrian: '行人',
      cyclist: '有人两轮车',
      bicycle: '无人两轮车',
      tricycle: '三轮车',
      truck: '卡车',
      cone: '锥桶',
      trolley: '手推车',
      movable: '其他可动',
      static: '其他不可动',
      barrier_cube: '长条车马'
    },
    info: {
      time: '时间',
      frameStatus: '帧状态',
      labelStatus: '标注状态',
      count: '标注数',
      created: '已创建',
      notCreated: '未创建',
      ready: '就绪',
      finish: '完成',
      annotationId: '标注ID'
    },
    sc: {
      commond: '全局指令',
      selected: '标注选中',
      keybinding: '快捷键',
      add: '连增模式',
      remove: '连减模式',
      helpers: '显示辅助线',
      adjustingViewAngle: '调整视图角度',
      camera: '切换相机',
      aux: '切换辅视图',
      frameDetail: '切换帧详情',
      frameStatistics: '切换帧统计',
      globalView:'切换到全局默认视角',
      save: '保存标注',
      next: '跳转下一帧',
      previous: '跳转上一帧',
      nextFive: '跳转后五帧',
      previousFive: '跳转前五帧',
      firstFrame:'跳转第一帧',
      lastFrame:'跳转最后一帧',
      move: '移动',
      transform: '变形',
      rotate: '旋转',
      forward: '前',
      back: '后',
      left: '左',
      right: '右',
      up: '上',
      down: '下',
      lengthen: '加长',
      shorten: '缩短',
      widen: '加宽',
      narrow: '缩窄',
      thicken: '增厚',
      thin: '减薄',
      zcw: 'z轴(顺)',
      zccw: 'z轴(逆)',
      zflip: 'z轴(180)',
      ycw: 'y轴(顺)',
      yccw: 'y轴(逆)',
      fail: '标注不合格',
      pressShift: '加按 shift 键进行轻微调整',
      pressMouseLeft: '选择位置、尺寸、缩放、旋转中某项，按住鼠标左键轻微拖动可进行轻微调整',
      multipleDelete: '多选删除: 按住shift + 鼠标左键进行多选，再点击删除按钮或者删除键进行删除',
      pressCtrl: '加按 control 键以中心调整标注尺寸',
      transformOrigin: '默认变形起点：左，后，底',
      space: '空格',
      boxOperation:'框操作-其他',
      boxHiding:'隐藏框',
      boxBuiding:'建框',
      deleteBox:'删框',
      InvertBox:'反选当前框',
      viewOperation:'视图操作',
      otherOperations:'其他操作',
      frameOperation:'帧操作',
      copyBoxPrevious:'复制数据到上一帧',
      copyBoxNext:'复制数据到下一帧',
      boxSelection:'框选择',
      selectBoxDesc:'唯一选择一个框，反选其他框',
      selectAddBoxDesc:'增量选择某个框，或者反选某个框',
      selectAllBoxDesc:'选择全部框',
      fit: '缩放至适合(`)',
      fit2d: '2D缩放至适合(S)',
      fit3d: '3D缩放至适合(`)',
      flip: '翻转图像(F)',
      hide: '隐藏标注(H)',
      center: '居中(G)',
      fill: '填充标注(U)',
      addAssociation:'新增关联关系',
      deleteAssociation:'删除关联关系'
    },
    settings: {
      pcd: '点云',
      camera: '相机照片',
      pointSize: '点大小',
      pointHue: '色相',
      pointSaturation: '饱和度',
      pointLightness: '明度',
      imageFlip: '翻转',
      frameState:'帧状态'
    },
    tooltip: {
      remove: '移除',
      openData: '打开数据',
      hide:'隐藏标注'
    },
    alerts: {
      saveSuccess: '保存成功',
      saveFailure: '保存失败',
      copySuccess: '复制成功'
    },
    noLabels: '无目标',
    missingImage: '图像缺失'
  },
  viewer3d: {
    msg: {
      controlLocked: '控制锁定'
    }
  },
  viewer2d: {
    tooltip: {
      rectangle: '矩形工具(R)',
      square: '正方形工具(Z)',
      polygon: '多边形工具(P)',
      fit: '缩放至适合(S)',
      fitQuote: '缩放至适合( ` )',
      center: '居中(C)',
      flip: '翻转图像(F)',
      fill: '填充标注(U)',
      zoom: '缩放',
      opacity: '透明度',
      car:'汽车(1)',
      pedestrian:'行人(2)',
      cone:'锥桶(3)',
      cyclist:'骑车人(4)',
      truck:'卡车(5)',
      bus:'公交车(6)',
      revoke:'撤销(Ctrl+Z)',
      copy:'复制',
      forward:'前进(Ctrl+Shift+Z)'
    },
    tab: {
      labels: '标注',
      guides: '操作指南',
      id:'编号',
      type: '类型',
    },
    btns: {
      save: '保存'
    },
    type: {
      up: '仅上方',
      down: '仅下方',
      up_down: '上和下',
      left: '仅左方',
      right: '仅右方',
      left_right: '左和右',
      single: '四周都无',
      red: '红灯',
      red_time: '红灯计时',
      green: '绿灯',
      green_time: '绿灯计时',
      yellow: '黄灯',
      yellow_time: '黄灯计时',
      black: '无显示',
      black_time: '无计时显示',
      car: '汽车',
      pedestrian: '行人',
      truck: '卡车',
      van: '面包车',
      trolley: '手推车',
      bus: '公交车',
      cyclist: '骑车人',
      cone: '锥桶',
      barrier: '水马',
      other: '其他',
      train: '火车',
      bicycle: '自行车',
      motorcycle: '摩托车',
      person: '行人',
      rider: '骑车人',
      train_group: '火车(群)',
      bicycle_group: '自行车(群)',
      motorcycle_group: '摩托车(群)',
      person_group: '行人(群)',
      rider_group: '骑车人(群)',
      car_group: '汽车(群)',
      truck_group: '卡车(群)',
      bus_group: '公交车(群)',
    },
    alerts: {
      saveSuccess: '保存成功',
      saveFailure: '保存失败',
      copySuccess: '复制成功'
    },
    sc: {
      commond: '全局指令',
      selected: '标注选中',
      rectangle: '矩形工具',
      fit: '缩放至适合',
      copyBoxPrevious:'复制数据到上一帧',
      copyBoxNext:'复制数据到下一帧',
      center: '居中',
      flip: '翻转图像',
      fill: '填充标注',
      move: '移动图像',
      save: '保存标注',
      next: '下一帧',
      prev: '上一帧',
      revoke:'撤销',
      forward:'前进',
      remove: '删除标注',
      clickMove: '拖拽',
      ctxMenu: '快捷菜单',
      rightClick: '单击右键',
      backspace: '退格键',
      hide: '隐藏标注',
      moveUp: '上移',
      moveDown: '下移',
      moveLeft: '左移',
      moveRight: '右移',
      fail: '标注不合格',
      rightDrag: '右键拖拽',
      space: '空格',
    },
    info: {
      time: '时间',
      frameStatus: '帧状态',
      labelStatus: '标注状态',
      count: '标注数',
      created: '已创建',
      notCreated: '未创建',
      ready: '就绪',
      finish: '完成',
      annotationId: '标注ID'
    },
    noLabels: '无目标',
    fail: '不合格'
  }
}

export default zh
