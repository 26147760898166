
import { fabric } from 'fabric'
import { MATERIAL_COLORS} from '../../constants'
export function createRect({ left, top, width, height }, type) {
  const color = MATERIAL_COLORS[type.toUpperCase()]
  const rect = new fabric.Rect({
    left,
    top,
    originX: 'left',
    originY: 'top',
    width,
    height,
    hoverCursor: 'move',
    fill: 'rgba(0, 0, 0, 0)',
    stroke: color,
    strokeWidth: 5,
    strokeUniform: true,
    hasBorders: false,
    cornerColor: '#fff',
    cornerSize: 5,
    borderOpacityWhenMoving: 0.3,
    hasRotatingPoint: false,
  })

  return rect
}
export function getBoundingRect(points) {
  const polygon = new fabric.Polygon(points, {
    fill: 'transparent',
    stroke: 'transparent',
    strokeWidth: 0,
    strokeUniform: true,
    hasRotatingPoint: false,
    cornerColor: '#fff',
    cornerSize: 8,
  })

  return polygon.getBoundingRect()
}

export function scaledBoundingRect(points2D) {
  let boundingRect = getBoundingRect(points2D)
  let scaledBoundingRect = {
    left: boundingRect.left,
    top: boundingRect.top,
    width: boundingRect.width,
    height: boundingRect.height,
  }

  return scaledBoundingRect
}

