import React from 'react'
import './LabelsStats.scss'
import { withTranslation } from 'react-i18next'

import { COLOR } from '../../constants'
import { LabelType } from '../../../common'

const LabelsStats = ({ counter, visible = true, style = {}, t, labels }) => {
  const labelsStats = Object.entries(counter).map(entry => (
    <li key={entry[0]} className="views-labels-stats-item">
      <div className="views-labels-stats-type">
        {/* <LabelType color={COLOR[entry[0].toUpperCase()]} />
        {t(`type.${entry[0]}`)} */}
        <LabelType color={labels.find(label => label.userData.type == entry[0]).color} />
        {labels.find(label => label.userData.type == entry[0]).showName}
      </div>
      {entry[1]}
    </li>
  ))

  return (
    <ul
      className="views-labels-stats"
      style={{ ...style, visibility: visible ? 'visible' : 'hidden' }}
    >
      {labelsStats}
    </ul>
  )
}

export default withTranslation('views')(LabelsStats)
