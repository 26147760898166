import { groupConstants } from '../_constants'

const initState = {
  group: null,
  groups: []
}

export function group(state = initState, action) {
  switch (action.type) {
    default:
      return state
    // Get
    case groupConstants.GET_REQUEST:
      return state
    case groupConstants.GET_SUCCESS:
      return {
        ...state,
        group: action.group
      }
    case groupConstants.GET_FAILURE:
      return state
    // Get All
    case groupConstants.GET_ALL_REQUEST:
      return {
        ...state,
        gettingGroups: true
      }
    case groupConstants.GET_ALL_SUCCESS:
      return {
        groups: action.groups,
        gettingGroups: false
      }
    case groupConstants.GET_ALL_FAILURE:
      return {
        ...state,
        gettingBags: false
      }

    // Create
    case groupConstants.CREATE_REQUEST:
      return {
        ...state,
        creatingGroup: true
      }
    case groupConstants.CREATE_SUCCESS:
      return {
        ...state,
        creatingGroup: false
      }
    case groupConstants.CREATE_FAILURE:
      return {
        ...state,
        creatingGroup: false
      }
    case groupConstants.UPDATE_REQUEST:
      return {
        ...state,
        updatingGroup: true
      }
    case groupConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updatingGroup: false
      }
    case groupConstants.UPDATE_FAILURE:
      return {
        ...state,
        updatingGroup: false
      }
    case groupConstants.DELETE_REQUEST:
      return state
    case groupConstants.DELETE_SUCCESS:
      return state
    case groupConstants.DELETE_FAILURE:
      return state
  }
}
