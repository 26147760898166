import { bagConstants } from '../_constants'

const initState = {
  curBag: {},
  bags: [],
  page: 0,
  total: 0
}

export function bag(state = initState, action) {
  switch (action.type) {
    case bagConstants.GET_REQUEST:
      return {
        ...state,
        gettingBag: true
      }
    case bagConstants.GET_SUCCESS:
      return {
        ...state,
        curBag: action.bag,
      }
    case bagConstants.GET_FAILURE:
      return {
        ...state,
        gettingBag: false
      }
    case bagConstants.GET_ALL_REQUEST:
      return {
        ...state,
        gettingBags: true
      }
    case bagConstants.GET_ALL_SUCCESS:
      return {
        bags: action.bags,
        total: action.total,
        page: action.page,
        gettingBags: false
      }
    case bagConstants.GET_ALL_FAILURE:
      return {
        ...state,
        gettingBags: false
      }
    case bagConstants.CREATE_REQUEST:
      return {
        ...state,
        creatingBag: true
      }
    case bagConstants.CREATE_SUCCESS:
      return {
        ...state,
        creatingBag: false
      }
    case bagConstants.CREATE_FAILURE:
      return {
        ...state,
        creatingBag: false
      }
    case bagConstants.UPDATE_REQUEST:
      return {
        ...state,
        updatingBag: true
      }
    case bagConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updatingBag: false
      }
    case bagConstants.UPDATE_FAILURE:
      return {
        ...state,
        updatingBag: false
      }
    case bagConstants.DELETE_REQUEST:
      return {
        ...state,
        deletingBag: true
      }
    case bagConstants.DELETE_SUCCESS:
      return {
        ...state,
        deletingBag: false
      }
    case bagConstants.DELETE_FAILURE:
      return {
        ...state,
        deletingBag: false
      }
      case bagConstants.GET_BYNAME_REQUEST:
        return {
          ...state,
          gettingBags: true
        }
      case bagConstants.GET_BYNAME_SUCCESS:
        return {
          bags: action.bags,
          total: action.total,
          page: action.page,
          gettingBags: false
        }
      case bagConstants.GET_BYNAME_FAILURE:
        return {
          ...state,
          gettingBags: false
        }
    default:
      return state
  }
}
