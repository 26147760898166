import React, { Component } from 'react'
import './Viewer2D.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { composeInitialProps, withTranslation } from 'react-i18next'
import { fabric } from 'fabric'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
  toPrecision,
  uuid,
  hexToRgb,
  recreateDB,
  extractObject2D
} from '../_utils'
import {
  PAD,
  TOOLTIP_DELAY,
  TOOL,
  CURSOR,
  ZOOM,
  OPACITY,
  COLOR,
  REC_THRESHOLD,
  DEFAULT,
  TYPE,
  OPEN_BASE_URL
} from './constants'
import { annotationActions, alertActions, objectTypeActions } from '../_actions'
import { history, role } from '../_helpers'
import { dataServices } from '../_services'
import { db } from '../_db'
import DataWorker from '../_workers/data.worker'

import {
  IconButton,
  InputNumber as InputNum,
  LabelType,
  LoadingCover
} from '../common'
import { Guides, FrameInfo, LabelsStats } from './components'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { DeleteOutlined, EyeOutlined, InboxOutlined, RotateLeftOutlined, RotateRightOutlined, CopyOutlined } from '@ant-design/icons'
import {
  Tooltip,
  Slider,
  Tabs,
  Button,
  Divider,
  InputNumber,
  Select,
  message,
  Checkbox,
  notification
} from 'antd'
const { TabPane } = Tabs
const Option = Select.Option

// Style Presets
const toolIconStyle = {
  fontSize: '20px'
}

const horizontalDividerStyle = {
  margin: '0.5rem 0',
  backgroundColor: '#333'
}

class Viewer2D extends Component {
  constructor(props) {
    super(props)

    this.state = {
      labels: [],
      labelsCounter: {},
      selectedLabel: null,
      currentFrame: 1,
      totalFrames: 1,
      zoomRatioPP: DEFAULT.ZOOM_RATIO_PP,
      imageOpacity: DEFAULT.IMAGE_OPACITY,
      selectedTool: TOOL.NULL,
      isFlipped: false,
      hasFill: false,
      isCtxMenuVisible: false,
      isEdited: false,
      noLabels: false,
      isInfoVisible: true,
      isStatsVisible: true,
      firstLoading: true,
      changedTypes: [],
      copyFlag: false.valueOf,
      fromURL: {
        startTimestamp: '',
        endTimestamp: '',
        bagId: '',
        bagName: '',
        id: '',
        status: '',
        firstFrameIndex: 0,
        groupName: '',
        isUrl: false
      },
    }
    this.history = []
    this.step = -1
    this.editflag = false
    // Canvas
    this.canvas
    this.canvasRoot
    this.fcanvas
    this.toolbar
    this.tabs
    this.typeSelect
    // Image
    this.viewWidth
    this.viewHeight
    this.imageWidth
    this.imageHeight
    this.currentImage
    // Shape
    this.shapeMemo = {
      started: false,
      target: null,
      originX: 0,
      originY: 0
    }
    this.readyToPan
    this.panning
    // Canvas context menu
    this.top = 0
    this.left = 0
    // Data
    this.dataWorker
    this.buffer = {}
    this.bufferIndex = 0
    this.unlabeledIndices = []

    // console.log('search', location.search)
    //不能在didmount中使用，因为setstate是异步的，在didmount渲染周期结束后才会更新state，而实际上我们需要在didmount中就使用更新后的state
    if (location.search) {
      let locationId, locationFrame
      if (location.search.split('?')[1].split('').indexOf('&') > -1) {
        if (location.search.split('?')[1].split('&')[1]) {
          locationId = location.search.split('?')[1].split('&')[0].split('=')[1]
          locationFrame = location.search.split('?')[1].split('&')[1].split('=')[1]
        } else {
          locationId = location.search.split('?')[1].split('=')[1]
          locationFrame = 0
        }
      } else {
        locationId = location.search.split('?')[1].split('=')[1]
        locationFrame = 0
      }



      this.state.fromURL = { ...this.state.fromURL, id: locationId, firstFrameIndex: parseInt(locationFrame) }
    }
  }

  async componentDidMount() {
    const { dispatch, location } = this.props

    const getsome = await dispatch(annotationActions.getSome({ id: this.state.fromURL.id }))
    let forGroup = {}
    if (this.props.getSome.groupName == 'none') {
      forGroup = {

        ...this.props.getSome,
        groupName: null,
      }
    } else {
      forGroup = {
        ...this.props.getSome,
      }
    }

    this.setState({
      fromURL: {
        ...this.state.fromURL,
        ...forGroup

      }
    })
    const { fromURL } = this.state
    // console.log(fromURL)
    if (fromURL.groupName && fromURL.groupName !== '') {
      dispatch(objectTypeActions.getByGroup({ groupName: fromURL.groupName }))
    } else {
      dispatch(objectTypeActions.refresh())
    }
    // dispatch(objectTypeActions.getByGroup({groupName:'信号灯'}))

    // console.log('state', this.state)
    // console.log('props', this.props)
    // console.log('location.state', location.state)
    // if (location.state) {
    //   const { bagId, startTimestamp, endTimestamp } = location.state.record
    //   // Get frames when component did mount
    //   if (bagId && startTimestamp && endTimestamp) {
    //     dispatch(
    //       annotationActions.getAllFrames(bagId, startTimestamp, endTimestamp, [
    //         'ready',
    //         'finish'
    //       ])
    //     )

    //     // Initialize views
    //     setTimeout(this.init, 0)
    //   }
    // }else
    if (fromURL.isUrl) {
      const { bagId, startTimestamp, endTimestamp } = this.state.fromURL

      // Get frames when component did mount
      if (bagId && startTimestamp && endTimestamp) {
        dispatch(
          annotationActions.getAllFrames(bagId, startTimestamp, endTimestamp, [
            'ready',
            'finish'
          ])
        )

        // Initialize views
        setTimeout(this.init, 0)
      }
    } else {
      history.replace('/annotations')
    }

    // Initialize data worker
    this.dataWorker = new DataWorker()
  }

  componentDidUpdate(prevProps) {
    const { firstLoading } = this.state
    const { dispatch, frames, objects, message: alertMessage } = this.props
    // console.log(this.state.selectedLabel)
    // console.log(this.fcanvas)
    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }

    // Get objects when: do have frames && not other props changes
    if (frames.length > 0 && frames !== prevProps.frames) {
      const lastFrameIndex = frames.length - 1
      let firstFrameIndex = lastFrameIndex

      // Find the first frame which object has not be created
      // frames.forEach((frame, index) => {
      //   if (!frame.annotationobjectsId || frame.annotationobjectsId < 0) {
      //     this.unlabeledIndices.push(index)

      //     firstFrameIndex === lastFrameIndex && (firstFrameIndex = index)
      //   }
      // })

      if (this.state.fromURL.firstFrameIndex !== 0) {
        this.unlabeledIndices.push(this.state.fromURL.firstFrameIndex - 1)
        firstFrameIndex = this.state.fromURL.firstFrameIndex - 1
      } else {
        frames.forEach((frame, index) => {
          if (!frame.annotationobjectsId || frame.annotationobjectsId < 0) {
            this.unlabeledIndices.push(index)
            // this.unlabeledIndices.push(3)
            firstFrameIndex === lastFrameIndex && (firstFrameIndex = index)
            // firstFrameIndex === lastFrameIndex && (firstFrameIndex = 3)
          }
        })
      }

      if (this.unlabeledIndices.length === 0) {
        firstFrameIndex = 0
      }

      this.setState(
        {
          currentFrame: firstFrameIndex + 1,
          totalFrames: frames.length
        },
        () => {
          if (frames[firstFrameIndex].annotationobjectsId > 0) {
            dispatch(
              annotationActions.getObject(
                frames[firstFrameIndex].annotationobjectsId
              )
            )
          }

          this.loadData()
        }
      )
    }

    // Put objects into the scene
    if (!firstLoading && objects !== prevProps.objects) {
      // When no objects, reuse objects in the previous frame
      this.updateLabels(objects)
      // When no objects, set noLabels
      !objects.length && this.setState({ noLabels: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)

    this.setState = () => false
    // Kill worker before leaving
    this.dataWorker.terminate()

    // Clear db before leaving
    recreateDB(db)
    this.setState = (state, callback) => {
      return
    }
  }

  bindEventListeners = () => {
    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  }

  onWindowResize = () => {
    const canvasWidth =
      document.documentElement.clientWidth -
      this.toolbar.clientWidth -
      this.tabs.clientWidth

    this.fcanvas.setWidth(canvasWidth)
  }

  onKeyDown = e => {
    const { currentFrame, firstLoading, selectedLabel } = this.state
    const { user } = this.props

    // Disable all keyboard events when first loading
    if (firstLoading) return

    // Change page with shift key
    if (e.shiftKey) {
      switch (e.code) {
        case 'ArrowRight':
          this.handleInputFrame(currentFrame + 1)
          break
        case 'ArrowLeft':
          this.handleInputFrame(currentFrame - 1)
          break
        case 'KeyS':
          this.handleSave()
          break
        case 'KeyZ':
          if (e.ctrlKey) {
            this.handleForward()
          }
          break
        default:
          break
      }
    } else if (this.getAssistKey(e)) {
      this.readyToPan = true
      this.setCursor()

      switch (e.code) {
        case 'KeyI':
          this.handleInspect()
          break
        case 'KeyS':
          this.handleSave()
          break
        case 'KeyN':
          this.handleNolabels()
          break
        default:
          break
      }
    } else if (e.code == 'KeyZ') {
      if (e.shiftKey) {
        this.handleForward()
      } else if (e.ctrlKey) {
        this.handleRevoke()
      }
    }
    else {
      // Shortcuts without assit key
      switch (e.code) {
        case 'KeyR':
          this.handleSelectTool(TOOL.RECTANGLE)
          break
        case 'KeyP':
          this.handleSelectTool(TOOL.POLYGON)
          break
        case 'KeyS':
          this.scaleToFit()
          break
        case 'KeyC':
          this.setCenter()
          break
        case 'KeyF':
          this.handleFlipImage()
          break
        case 'KeyU':
          this.handleFillLabel()
          break
        case 'KeyH':
          this.handleToggleLabelVisibility(e)
          break
        case 'Backspace':
          this.handleRemoveLabel(e)
          break
        case 'ArrowLeft':
          this.handleMoveObject('left')
          break
        case 'ArrowRight':
          this.handleMoveObject('right')
          break
        case 'ArrowUp':
          this.handleMoveObject('up')
          break
        case 'ArrowDown':
          this.handleMoveObject('down')
          break
        // case 'KeyQ':
        //   ;[role.globalExaminer, role.groupExaminer].includes(user.role) &&
        //     this.handleExamineLabel(e)
        //   break
        default:
          break
      }
    }
    if (selectedLabel) {
      if (e.altKey || e.ctrlKey) {
        switch (e.code) {
          case 'KeyQ':
            this.handleInputFrame(currentFrame - 1, true)
            break
        }
        return
      }
      switch (e.code) {
        case 'KeyQ':
          this.handleInputFrame(currentFrame + 1, true)
          break
      }
    }
  }

  onKeyUp = e => {
    if (this.readyToPan) {
      this.readyToPan = false
      this.setCursor()
    }
  }

  init = () => {
    // strokeUniform works better without scalingCache
    fabric.Object.prototype.noScaleCache = false

    this.fcanvas = new fabric.Canvas(this.canvas, {
      preserveObjectStacking: true,
      width: this.canvasRoot.clientWidth,
      height: this.canvasRoot.clientHeight,
      selection: false,
      selectionColor: 'transparent',
      selectionBorderColor: 'transparent',
      backgroundColor: 'transparent',
      defaultCursor: 'default',
      fireRightClick: true,
      stopContextMenu: true
    })

    this.fcanvas.on({
      'mouse:wheel': this.handleMouseWheel,
      'mouse:down': this.handleMouseDown,
      'mouse:move': this.handleMouseMove,
      'mouse:up': this.handleMouseUp,
      'object:moving': this.handleObjectMoving,
      'object:scaling': this.handleObjectScaling,
      'selection:created': this.handleObjectSelected,
      'selection:updated': this.handleObjectUpdated,
      'selection:cleared': this.handleObjectCleared
    })

    this.bindEventListeners()
  }

  // Component Handlers
  handleSelectTool = tool => {
    let { selectedTool } = this.state

    this.setState(
      {
        selectedTool: tool === selectedTool ? TOOL.NULL : tool
      },
      () => {
        this.setCursor()
      }
    )
  }

  handleInputFrame = (value, copyFlag = false) => {
    const { totalFrames, selectedLabel, labels } = this.state
    const { dispatch, frames } = this.props

    if (value > 0 && value <= totalFrames) {
      this.handleSave()
      this.setState(
        {
          currentFrame: value,
          copyFlag: copyFlag,

        },
        () => {
          this.setState({ isEdited: false, noLabels: false })

          if (frames[value - 1].annotationobjectsId > 0) {
            dispatch(
              annotationActions.getObject(frames[value - 1].annotationobjectsId)
            ).then(() => {
              this.addHistory()
              if (copyFlag) {
                this.setState({ copyLabel: selectedLabel })
                this.creatCopyLabels(selectedLabel)
              }
            })
          } else {
            if (copyFlag && selectedLabel) {
              let copyLabel = selectedLabel
              this.fcanvas.remove(...this.fcanvas.getObjects().slice(1))
              this.setState({
                labels: [],
                labelsCounter: {}
              }, () => {
                this.setState({ copyLabel: copyLabel })
                this.creatCopyLabels(copyLabel)
              }
              )
            }
          }

          this.loadData()
          this.history = []
          this.step = -1
        }
      )
    }
  }

  creatCopyLabels = (label) => {
    const { copyLabel, labels } = this.state
    let exitFlag = labels.some(item=>item.id==label.id)
    if(exitFlag){
      this.fcanvas.setActiveObject(label.target)
      return
    }
    this.fcanvas.add(label.target)
    this.fcanvas.setActiveObject(label.target)
    // this.fcanvas.add(label.recTitle)
    // this.updateLabelsCounter({ type: 'create', payload: copyLabel.type })
    // labels.length=0
    labels.push(label)
    this.updateLabelsCounter({ type: 'create', payload: label.type })
    // this.checkedActiveLabel(label)
    this.setState({ isEdited: true,selectedLabel:label })
  }

  handleSelectLabel = label => {
    const { selectedLabel } = this.state

    label === selectedLabel ? this.clearLabel() : this.selectLabel(label)
  }

  handleFillLabel = () => {
    const { hasFill } = this.state

    this.setState({ hasFill: !hasFill }, () => {
      this.fcanvas.getObjects().forEach((object, index) => {
        index && object.set({ fill: this.getFillColor(object.fill) })
      })

      this.fcanvas.requestRenderAll()
    })
  }
  handleFlipImage = () => {
    const { isFlipped } = this.state
    this.fcanvas.getObjects()[0].rotate(isFlipped ? 0 : 180)
    this.fcanvas.getObjects()[0].setCoords()
    this.fcanvas.requestRenderAll()

    this.setState({ isFlipped: !isFlipped })
  }

  handleRemoveLabel = (e, label) => {
    const { selectedLabel } = this.state

    e.stopPropagation()
    this.closeCtxMenu()

    this.removeLabel(label || selectedLabel)
    this.addHistory()
  }

  handleExamineLabel = (e, label) => {
    const { selectedLabel } = this.state

    e.stopPropagation()

    if (!label && !selectedLabel) return

    label
      ? (label.fail = !label.fail)
      : (selectedLabel.fail = !selectedLabel.fail)

    this.forceUpdate()
    this.setEdited()
  }

  handleChangeImageOpacity = value => {
    if (value < OPACITY.MIN) {
      value = OPACITY.MIN
    } else if (value > OPACITY.MAX) {
      value = OPACITY.MAX
    } else {
      this.fcanvas.item(0).set({ opacity: value })
      this.fcanvas.requestRenderAll()
    }

    this.setState({ imageOpacity: toPrecision(value, OPACITY.PRECISION) })
  }

  handleChangeType = (value, label) => {
    const { fromURL } = this.state
    const { objectTypes, t } = this.props
    this.typeSelect.blur()
    // Change type
    this.updateLabelsCounter({
      type: 'update',
      payload: { old: label.type, new: value }
    })
    label.type = value
    label.color = fromURL.groupName && objectTypes.length > 0 ? objectTypes.find(type => type.name == value).color : COLOR[value.toUpperCase()]
    label.showName = fromURL.groupName && objectTypes.length > 0 ? objectTypes.find(type => type.name == value).showName : t(`type.${value}`)
    // Change color
    const color = fromURL.groupName && objectTypes.length > 0 ? objectTypes.find(type => type.name == value).color : COLOR[value.toUpperCase()]
    // console.log('color',color,label.color)
    label.target.set({
      fill: this.getFillColor(color),
      stroke: color,
      id: label.id,
      color: label.color,
      name: label.type,
      showName: label.showName
    })
    // label.target = {
    //   ...label.target,
    //   id: label.id,
    //   color: label.color,
    //   name: label.type,
    //   showName: label.showName
    // }

    // console.log('handlechangetype', label)

    this.fcanvas.requestRenderAll()
    this.addHistory()
    this.setEdited()
    this.setFail()
  }

  handleSave = () => {
    const { labels, currentFrame, isEdited, fromURL } = this.state
    const { dispatch, frames, location, t, user } = this.props
    const { id } = (fromURL.isUrl) ? fromURL : location.state.record

    if (!isEdited) return

    const objects = labels.map(label =>
      extractObject2D(label, this.currentImage)
    )

    const data = {
      size: {
        width: this.currentImage.width,
        height: this.currentImage.height
      },
      objects
    }

    const objectId = frames[currentFrame - 1].annotationobjectsId
    const userId = parseInt(user.id)
    const annotationId = parseInt(fromURL.id)

    dispatch(
      objectId > 0
        ? annotationActions.updateObject(data, objectId, objects.length, userId, annotationId)
        : annotationActions.createObject(
          data,
          frames[currentFrame - 1].id,
          objects.length,
          userId,
          annotationId
        )
    )
      .then(object => {
        // When created objects, remove that frame from indices
        if (!(objectId > 0)) {
          frames[currentFrame - 1].annotationobjectsId = object.id

          this.unlabeledIndices.splice(
            this.unlabeledIndices.findIndex(
              value => value === currentFrame - 1
            ),
            1
          )
        }

        // Update this annotation detail and progress
        dispatch(
          annotationActions.updateAnnotation({
            id,
            progress: `${frames.length - this.unlabeledIndices.length} / ${frames.length
              }`
          })
        )

        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.saveSuccess')
          })
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({ type: 'error', detail: t('alerts.saveFailure') })
        )
      })
  }

  handleToggleFrameInfo = () => {
    const { isInfoVisible } = this.state

    this.setState({ isInfoVisible: !isInfoVisible })
  }

  handleToggleStatsInfo = () => {
    const { isStatsVisible } = this.state

    this.setState({ isStatsVisible: !isStatsVisible })
  }

  handleInspect = () => {
    console.log(this.state.labels)
  }

  handleChangeNoLabels = e => {
    this.setState({
      noLabels: e.target.checked,
      isEdited: e.target.checked
    })
  }

  handleNolabels = () => {
    const { currentFrame, labels } = this.state
    if (!!labels.length) return
    this.setState({
      noLabels: true,
      isEdited: true
    }
      , () => {
        this.handleSave()
        this.handleInputFrame(currentFrame + 1)
      }
    )
  }

  handleCopyPath = () => {
    const { dispatch, t } = this.props

    dispatch(
      alertActions.success({
        type: 'success',
        detail: t('alerts.copySuccess')
      })
    )
  }

  handleToggleLabelVisibility = (e, label) => {
    const { selectedLabel } = this.state

    e.stopPropagation()

    if (!label && !selectedLabel) return

    const target = label ? label.target : selectedLabel.target
    target.visible = !target.visible
    this.fcanvas.requestRenderAll()

    // Clear seletedLabel and rigger setState btw
    this.clearLabel()
  }

  // Canvas Handlers
  handleMouseWheel = opt => {
    opt.e.preventDefault()
    opt.e.stopPropagation()

    const delta = opt.e.deltaY
    let zoomRatio = this.fcanvas.getZoom()

    // Get new zoom ratio
    zoomRatio = delta > 0 ? zoomRatio - 0.05 : zoomRatio + 0.05
    this.setZoom(zoomRatio * 100, opt.pointer)
  }

  handleMouseDown = opt => {
    const { selectedTool, isCtxMenuVisible, zoomRatioPP, fromURL, labels } = this.state
    const { objectTypes, t } = this.props
    // console.log('mousedown')
    // Hide context menu when click0
    if (isCtxMenuVisible) {
      this.typeSelect.blur()
      // !!Hack method, avoid use setTimeout like this
      // To make sure Select blur firstly then context menu hide
      setTimeout(() => this.setState({ isCtxMenuVisible: false }), 0)
    }

    // Deal with panning
    if (this.readyToPan) {
      this.panning = true
      return
    }

    const pointer = this.fcanvas.getPointer(opt.e)

    // Deal with creating new shape
    if (opt.button === 1) {
      // Prevent create new shape when there is active object
      if (this.fcanvas.getActiveObject()) return

      // Check is the pointer within the image area
      if (this.containsPointer(pointer)) {
        // Save origin pointer
        this.shapeMemo.originX = pointer.x
        this.shapeMemo.originY = pointer.y

        switch (selectedTool) {
          case TOOL.RECTANGLE:
            const color = fromURL.groupName && objectTypes.length > 0 ? objectTypes[0].color : COLOR[DEFAULT.TYPE.toUpperCase()]
            const id = uuid()
            const name = fromURL.groupName && objectTypes.length > 0 ? objectTypes[0].name : DEFAULT.TYPE
            const showName = fromURL.groupName && objectTypes.length > 0 ? objectTypes[0].showName : t(`type.${DEFAULT.TYPE}`)
            const strokeWidth = (DEFAULT.STROKE_WIDTH / zoomRatioPP) * 100

            const rect = new fabric.Rect({
              left: pointer.x,
              top: pointer.y,
              originX: 'left',
              originY: 'top',
              width: 0,
              height: 0,
              hoverCursor: 'move',
              fill: this.getFillColor(color),
              stroke: color,
              strokeWidth,
              strokeUniform: true,
              hasBorders: false,
              cornerColor: '#fff',
              cornerSize: 8,
              borderOpacityWhenMoving: 0.3,
              hasRotatingPoint: false,
              color,
              id,
              name,
              showName,
            })

            // Save Rect instance
            this.shapeMemo.target = rect
            // Set starting creating shape flag
            this.shapeMemo.started = true

            this.fcanvas.add(rect)
            break
          default:
            break
        }
      }
      if (this.step + 1 !== this.history.length) {
        this.history.length = this.step + 1
      }
    }

    // Deal with context menu
    if (opt.button === 3) {
      opt.e.preventDefault()
      opt.e.stopPropagation()
      // Set target as active
      const label = this.handleObjectSelected(opt)
      if (label) {
        // Display context menu
        this.top = opt.e.offsetY - 15
        this.left = opt.e.offsetX + 8

        this.setState({
          isCtxMenuVisible: true
        })
      } else {
        this.dragging = true
        this.dragging_start_x = pointer.x
        this.dragging_start_y = pointer.y
      }
    }
  }

  handleMouseMove = opt => {
    const { selectedTool, isCtxMenuVisible } = this.state

    if (this.dragging) {
      const current_pointer = this.fcanvas.getPointer(opt.e)
      const delta_pointer = new fabric.Point(
        current_pointer.x - this.dragging_start_x,
        current_pointer.y - this.dragging_start_y,
      )
      this.fcanvas.relativePan(delta_pointer)
      return
    }

    if (this.panning) {
      // Hide context menu when click
      isCtxMenuVisible && this.setState({ isCtxMenuVisible: false })

      this.handleRelativePan(opt.e)
      return
    }

    if (!this.shapeMemo.started) return

    switch (selectedTool) {
      case TOOL.RECTANGLE:
        const { target, originX, originY } = this.shapeMemo

        const pointer = this.fcanvas.getPointer(opt.e)

        // To be improved: set boundaries
        originX > pointer.x && target.set({ left: Math.abs(pointer.x) })
        originY > pointer.y && target.set({ top: Math.abs(pointer.y) })

        target.set({ width: Math.abs(originX - pointer.x) })
        target.set({ height: Math.abs(originY - pointer.y) })

        this.fcanvas.requestRenderAll()
        break
      default:
        break
    }
  }

  handleMouseUp = opt => {
    const { selectedTool, isEdited } = this.state
    if (opt.button === 1 && this.editflag) {
      this.addHistory()
      this.editflag = false
    }
    this.dragging = false

    if (this.panning) {
      this.panning = false
      return
    }
    if (!this.shapeMemo.started) return
    const pointer = this.fcanvas.getPointer(opt.e)

    switch (selectedTool) {
      case TOOL.RECTANGLE:
        const { target, originX, originY } = this.shapeMemo

        if (
          Math.abs(pointer.x - originX) > REC_THRESHOLD &&
          Math.abs(pointer.y - originY) > REC_THRESHOLD
        ) {
          target.setCoords()
          // console.log('starget',this.shapeMemo.target)
          this.createLabel(this.shapeMemo.target)
          this.addHistory()
        } else {
          this.fcanvas.remove(target)
        }

        this.shapeMemo.started = false
      default:
        break
    }
  }

  handleRelativePan = e => {
    const delta = new fabric.Point(e.movementX, e.movementY)
    this.fcanvas.relativePan(delta)
  }

  // Object handler
  /*
    Not perfect.
    Didn't take rotation into account. Get object bounding could be a better way.
  */
  handleObjectMoving = opt => {
    const obj = opt.target
    const { left, top, width, height } = this.currentImage.getBoundingRect(true)

    // Left check
    obj.left < left && obj.set({ left })

    // Right check
    const rightBoundary = left + width - obj.getScaledWidth()
    obj.left > rightBoundary && obj.set({ left: rightBoundary })

    // Top check
    obj.top < top && obj.set({ top })

    // Bottom check
    const bottomBoundary = top + height - obj.getScaledHeight()
    obj.top > bottomBoundary && obj.set({ top: bottomBoundary })
    this.setEdited()
    this.setFail()
    this.editflag = true
  }

  handleObjectSelected = opt => {
    const { labels } = this.state
    // console.log('opt', opt, labels)
    const label = labels.find(label => label.target.id === opt.target.id)
    if (label) {
      this.setState({
        selectedLabel: label
      })
      const activeObj = this.fcanvas.getActiveObject()
      // console.log('compare', opt.target, label.target)
      if (activeObj !== label.target) {
        this.fcanvas.setActiveObject(opt.target)
        this.fcanvas.requestRenderAll()
      }
    }

    return label
  }

  handleObjectUpdated = opt => {
    const { labels } = this.state
    const label = labels.find(label => label.target === opt.target)

    this.setState({
      selectedLabel: label
    })
  }

  handleObjectCleared = opt => {
    this.setState({
      selectedLabel: null
    })
  }

  /*
    To be improved
    Set scaling boundaries.
  */
  handleObjectScaling = opt => {
    this.editflag = true
    this.setEdited()
    this.setFail()
  }

  handleMoveObject = direction => {
    const activeLabel = this.fcanvas.getActiveObject()
    const step = 1
    const { left, top, width, height } = this.currentImage.getBoundingRect(true)

    if (activeLabel) {
      switch (direction) {
        case 'up':
          const newTop = activeLabel.top - step

          newTop > top && activeLabel.set('top', newTop)
          break
        case 'down':
          const newBottom = activeLabel.top + step
          const bottomBoundary = top + height - activeLabel.getScaledHeight()

          newBottom < bottomBoundary && activeLabel.set('top', newBottom)
          break
        case 'left':
          const newLeft = activeLabel.left - step

          newLeft > left && activeLabel.set('left', newLeft)
          break
        case 'right':
          const newRight = activeLabel.left + step
          const rightBoundary = left + width - activeLabel.getScaledWidth()

          newRight < rightBoundary && activeLabel.set('left', newRight)
          break
        default:
          break
      }

      this.fcanvas.requestRenderAll()
      activeLabel.setCoords()
      this.setEdited()
      this.setFail()
      this.addHistory()
    }
  }

  // Data
  createLabel = target => {
    const { labels, noLabels, fromURL } = this.state
    const { objectTypes, t } = this.props

    const id = target.id
    const showName = target.showName
    const color = target.color
    const name = target.name

    const label = {
      id,
      showName,
      color,
      type: name,
      target,
    }
    labels.push(label)
    this.updateLabelsCounter({ type: 'create', payload: label.type })

    this.setState({ labels })
    this.setEdited()

    noLabels && this.setState({ noLabels: false })
  }

  removeLabel = label => {
    const { labels, selectedLabel } = this.state

    if (label) {
      this.fcanvas.remove(label.target)

      label === selectedLabel && this.setState({ selectedLabel: null })

      labels.splice(labels.indexOf(label), 1)
      this.updateLabelsCounter({ type: 'remove', payload: label.type })

      this.setState({ isEdited: labels.length ? true : false })
    }
  }

  updateLabels = objects => {
    const { angle, aCoords, scaleX: scale } = this.currentImage
    // Check if image is rotated
    const { x, y } = angle ? aCoords.br : aCoords.tl
    const { objectTypes, t } = this.props

    // Remove previous labels
    this.fcanvas.remove(...this.fcanvas.getObjects().slice(1))
    let newchangedTypes = []     // Clear labels Counter then create new labels
    this.setState({ labelsCounter: {} }, () => {
      const { zoomRatioPP, fromURL } = this.state

      const labels = objects.map(
        ({ id, type, xmin, ymin, xmax, ymax, fail, showName, color }) => {
          let newColor, newShowName
          if (showName) {
            newColor = color
            newShowName = showName
          } else {
            newColor = COLOR[type.toUpperCase()]
            // console.log('nc', newColor)
            newShowName = t(`type.${type}`)
          }
          if (objectTypes.filter(item => item.name == type && item.color == newColor && item.showName == newShowName).length == 0) {
            let changedType = { id: uuid(), showName: newShowName, color: newColor, type }
            newchangedTypes.push(changedType)
            // console.log('newchange',newchangedTypes)
            this.setState({ changedTypes: newchangedTypes })
            // console.log('ct',this.state.changedTypes)
          }
          const strokeWidth = (DEFAULT.STROKE_WIDTH / zoomRatioPP) * 100
          const rect = new fabric.Rect({
            left: x + xmin * scale,
            top: y + ymin * scale,
            originX: 'left',
            originY: 'top',
            width: (xmax - xmin) * scale,
            height: (ymax - ymin) * scale,
            hoverCursor: 'move',
            fill: this.getFillColor(newColor),
            stroke: newColor,
            strokeWidth,
            strokeUniform: true,
            hasBorders: false,
            cornerColor: '#fff',
            cornerSize: 8,
            borderOpacityWhenMoving: 0.3,
            hasRotatingPoint: false,
            id,
            showName: newShowName,
            color: newColor,
            name: type
          })

          this.fcanvas.add(rect)
          this.updateLabelsCounter({ type: 'create', payload: type })

          return {
            id,
            showName: newShowName,
            color: newColor,
            type,
            fail,
            target: rect
          }
        }
      )

      // console.log(labels.filter(label=>{return objectTypes.filter(item=>item.name!==label.type)}))
      this.setState({ labels })
    })
  }

  selectLabel = label => {
    this.setState({
      selectedLabel: label
    })

    this.fcanvas.setActiveObject(label.target)
    this.fcanvas.requestRenderAll()
  }

  clearLabel = () => {
    this.setState({
      selectedLabel: null,
      isCtxMenuVisible: false
    })

    this.fcanvas.discardActiveObject()
    this.fcanvas.requestRenderAll()
  }

  updateLabelsCounter = ({ type, payload }) => {
    let { labelsCounter } = this.state

    const create = data => {
      labelsCounter[data]
        ? (labelsCounter[data] += 1)
        : (labelsCounter[data] = 1)
    }

    const remove = data => {
      this.state.labelsCounter[data] -= 1

      if (!labelsCounter[data]) {
        delete labelsCounter[data]
      }
    }

    const update = data => {
      // console.log('update',data.old)
      remove(data.old)
      create(data.new)
    }

    const handler = {
      create,
      remove,
      update
    }

    handler[type](payload)

    this.setState({ labelsCounter })
  }

  // Utils
  setImage = timestamp => {
    const { isFlipped, imageOpacity, firstLoading } = this.state
    const { objects } = this.props

    const currentImage = this.buffer[timestamp]
    const scale = this.getImageDisplayRatio(currentImage)

    this.currentImage = new fabric.Image(currentImage, {
      scaleX: scale,
      scaleY: scale,
      hasBorders: false,
      hasControls: false,
      selectable: false,
      angle: isFlipped ? 180 : 0,
      opacity: imageOpacity,
      hoverCursor: 'default'
    })

    this.fcanvas.insertAt(this.currentImage, 0, true)
    this.fcanvas.centerObject(this.currentImage)
    this.addHistory()
    if (firstLoading) {
      this.updateLabels(objects)
      this.setState({
        firstLoading: false,
        noLabels: !this.unlabeledIndices.length && !objects.length
      })
    }
  }

  getImageDisplayRatio = image => {
    if (this.canvas) {
      const viewWidth = this.canvas.clientWidth
      const viewHeight = this.canvasRoot.clientHeight
      const ratio = (image.width + 2 * PAD) / (image.height + 2 * PAD)
      let displayRatio = ratio

      // Set canvas image diplay size
      if (viewWidth / viewHeight > ratio) {
        this.imageHeight = viewHeight - 2 * PAD
        this.imageWidth = this.imageHeight * ratio

        displayRatio = this.imageHeight / image.height
      } else {
        this.imageWidth = viewWidth - 2 * PAD
        this.imageHeight = this.imageWidth / ratio

        displayRatio = this.imageWidth / image.width
      }

      return displayRatio
    }
  }

  getAssistKey = e => {
    return e.altKey
  }

  setCursor = () => {
    const { selectedTool } = this.state

    // Canvas
    this.fcanvas.defaultCursor = this.readyToPan ? CURSOR.GRAB : CURSOR.DEFAULT
    this.fcanvas.hoverCursor = this.fcanvas.defaultCursor

    // Objects
    this.fcanvas.getObjects().forEach((object, index) => {
      if (index) {
        // Shapes
        object.selectable = !this.readyToPan
        object.hoverCursor = this.readyToPan ? CURSOR.GRAB : CURSOR.MOVE
      } else {
        // Image
        object.hoverCursor = this.readyToPan
          ? CURSOR.GRAB
          : selectedTool
            ? CURSOR.CROSSHAIR
            : CURSOR.DEFAULT
      }
    })

    this.fcanvas.requestRenderAll()
  }

  setZoom = (zoomRatioPP, pointer) => {
    let zoomRatio = zoomRatioPP / 100
    zoomRatioPP = toPrecision(zoomRatioPP, ZOOM.PRECISION)

    // Set pointer to canvas center if no pointer passes
    if (!pointer) {
      const center = this.fcanvas.getCenter()
      pointer = new fabric.Point(center.left, center.top)
    }

    if (zoomRatioPP < ZOOM.MIN) {
      zoomRatioPP = ZOOM.MIN
    } else if (zoomRatioPP > ZOOM.MAX) {
      zoomRatioPP = ZOOM.MAX
    } else {
      this.fcanvas.getObjects().forEach((object, index) => {
        if (index) {
          object.strokeWidth = DEFAULT.STROKE_WIDTH / zoomRatio
        }
      })

      this.fcanvas.zoomToPoint(pointer, zoomRatio)
    }

    this.setState({ zoomRatioPP })
  }

  setCenter = () => {
    const center = this.fcanvas.getCenter()
    const zoom = this.fcanvas.getZoom()

    const pointer = new fabric.Point(
      center.left * (zoom - 1),
      center.top * (zoom - 1)
    )

    this.fcanvas.absolutePan(pointer)
  }

  containsPointer = ({ x, y }) => {
    const { left, top, width, height } = this.currentImage.getBoundingRect(true)

    return x > left && x < left + width && y > top && y < top + height
  }

  scaleToFit = () => {
    this.setZoom(100)
    this.setCenter()
  }

  closeCtxMenu = () => {
    const { isCtxMenuVisible } = this.state
    isCtxMenuVisible && this.setState({ isCtxMenuVisible: false })
  }

  getFillColor = color => {
    const { hasFill } = this.state
    // To test the color is hex or rgba
    const c =
      color[0] === '#'
        ? hexToRgb(color)
        : color.split(/\D+/).filter(item => item)

    return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${hasFill ? DEFAULT.ALPHA : 0})`
  }

  // Data
  loadData = () => {
    const { currentFrame } = this.state
    const { frames, location } = this.props
    let { bagName } = (this.state.fromURL.isUrl) ? this.state.fromURL : location.state.record

    this.bufferIndex = currentFrame
    const index = currentFrame - 1
    const timestamp = frames[index].timestamp

    // Data buffered
    if (this.buffer[timestamp]) {
      this.setImage(timestamp)
    } else {
      db['CAMERA_1']
        .get(timestamp)
        .then(data => {
          // Data is in db
          if (data) {
            this.loadImageFromData(data.data, image => {
              // Add image to buffer
              this.buffer[timestamp] = image
              this.setImage(timestamp)
            })
          } else {
            // Data is not in db
            const data = {
              bagName: bagName,
              sensorsList: ['CAMERA_1'],
              startFrametime: timestamp,
              endFrametime: timestamp,
              framesList: [timestamp]
            }

            dataServices.getData(data, onMessage)
          }
        })
        .catch(e => {
          console.log(e)
        })
    }

    // Test and preload next 5 frames
    this.japStep()

    const onMessage = data => {
      this.loadImageFromData(data.data, image => {
        this.buffer[timestamp] = image
        this.setImage(timestamp)
      })

      // For cache
      this.dataWorker.postMessage({ action: 'PUT_DATA', data })
    }
  }

  preloadData = () => {
    const { frames, location } = this.props
    let { bagName } = (this.state.fromURL.isUrl) ? this.state.fromURL : location.state.record

    const startIndex = this.bufferIndex
    const endIndex =
      this.bufferIndex + 5 > frames.length
        ? frames.length
        : this.bufferIndex + 5
    const promiseQueue = []

    // startIndex includes, endIndex excludes
    for (let index = startIndex; index < endIndex; ++index) {
      const timestamp = frames[index].timestamp

      // If not in buffer
      if (!this.buffer[timestamp]) {
        promiseQueue.push(
          new Promise((resolve, reject) => {
            db['CAMERA_1']
              .get(timestamp)
              .then(data => {
                if (data) {
                  this.loadImageFromData(data.data, image => {
                    this.buffer[timestamp] = image
                  })

                  return resolve()
                } else {
                  return resolve(timestamp)
                }
              })
              .catch(e => {
                console.log(e)
                return reject()
              })
          })
        )
      }
    }

    Promise.all(promiseQueue).then(result => {
      // Filter frames which need to request data
      const framesList = result.filter(item => !!item)

      if (framesList.length > 0) {
        const data = {
          bagName: bagName,
          sensorsList: ['CAMERA_1'],
          startFrametime: frames[startIndex].timestamp,
          endFrametime: frames[endIndex - 1].timestamp,
          framesList
        }

        dataServices.getData(data, onMessage, onEnd)
        console.log('Preload starts')
      }
    })

    const onMessage = data => {
      // For buffer
      this.loadImageFromData(data.data, image => {
        this.buffer[data.frametime] = image
      })

      // For cache
      this.dataWorker.postMessage({ action: 'PUT_DATA', data })
    }

    const onEnd = () => {
      console.log('Preload ends')
    }
  }

  japStep = () => {
    const { frames } = this.props

    if (this.bufferIndex === frames.length) return

    const index = this.bufferIndex
    const timestamp = frames[index].timestamp

    db['CAMERA_1']
      .get(timestamp)
      .then(data => {
        data || this.preloadData()
      })
      .catch(e => {
        console.log(e)
      })
  }

  loadImageFromData = (data, onLoad) => {
    const image = new Image()
    image.src = 'data:image/jpeg;base64,' + data

    image.onload = () => onLoad(image)
  }

  setEdited = () => {
    const { isEdited } = this.state

    isEdited || this.setState({ isEdited: true })
  }

  setFail = () => {
    const { selectedLabel } = this.state
    const { user } = this.props

    // Auto mark fail when examiner edits
    if (
      [role.globalExaminer, role.groupExaminer].includes(user.role) &&
      !selectedLabel.fail
    ) {
      selectedLabel.fail = true
      this.setState({ selectedLabel })
    }
  }
  findKeyByValue = (value) => {
    for (let i in COLOR) {
      if (value === COLOR[i]) {
        return i
      }
    }
  }
  createLabelHistory = (target, color) => {
    const { labels, noLabels } = this.state
    const { objectTypes, objects } = this.props
    const rectType = this.findKeyByValue(color)
    // console.log('cc',color,objectTypes)
    // if(this.state.fromURL.groupName){
    const label = {
      id: target.id,
      type: target.name,
      showName: target.showName,
      color: target.color,
      target
      // type: TYPE[rectType],
      // type:objectTypes.find(type=>type.color==color)?objectTypes.find(type=>type.color==color).name:objects.find(obj=>obj.color==color).showName,
      // showName:objectTypes.find(type=>type.color==color)?objectTypes.find(type=>type.color==color).showName:objects.find(obj=>obj.color==color).showName,
      // color:color,
    }
    // }else{
    //   label = {
    //     id: uuid(),
    //     type: TYPE[rectType],
    //     target
    //   }
    // }
    labels.push(label)
    this.updateLabelsCounter({ type: 'create', payload: label.type })
    this.setState({ labels })
    this.setEdited()

    noLabels && this.setState({ noLabels: false })
  }
  addHistory() {
    const hisArr = ['hasRotatingPoint', 'cornerColor', 'cornerSize', 'hasBorders', 'hasControls', 'selectable', 'hoverCursor', 'scaleY', 'scaleX', 'opacity', 'angle', 'strokeUniform', 'id', 'name', 'showName', 'color']
    this.history.push(this.fcanvas.toDatalessObject(hisArr))
    // console.log('history', this.fcanvas.toDatalessObject(hisArr))
    this.step++
  }
  renderHistory(step) {
    const { labels } = this.state
    this.setState({
      labels: [],
      labelsCounter: {}
    })
    this.fcanvas.loadFromDatalessJSON(this.history[this.step], () => {
      this.fcanvas.getObjects().forEach((item) => {
        if (item.type !== 'image') {
          this.createLabelHistory(item, item.stroke)
        }
      })
    })
  }
  //撤销历史记录
  handleRevoke = () => {
    if (this.step > 0) {
      this.renderHistory(--this.step)
    } else {
      notification.info({
        message: '提示',
        description: '这是最开始的画布，不能再撤销了. '
      })
    }
  }
  handleForward = () => {
    if (this.step + 1 < this.history.length) {
      this.renderHistory(++this.step)
    } else {
      notification.info({
        message: '提示',
        description: '这是最新的画布，不能再前进了. '
      })
    }
  }

  handleCopyUrl = async () => {
    const { dispatch, t } = this.props
    try {
      if (!location.search.split('&')[1]) {
        await navigator.clipboard.writeText(`${location}&frame=${this.state.currentFrame}`)
      } else {
        await navigator.clipboard.writeText(location)
      }
      dispatch(
        alertActions.success({
          type: 'success',
          detail: t('alerts.copySuccess')
        })
      )
    } catch (err) {
      dispatch(
        alertActions.success({
          type: 'error',
          detail: t('alerts.copyFailure')
        })
      )
    }
  }

  render() {
    const {
      selectedTool,
      currentFrame,
      totalFrames,
      labels,
      labelsCounter,
      selectedLabel,
      isFlipped,
      hasFill,
      zoomRatioPP,
      imageOpacity,
      isCtxMenuVisible,
      isEdited,
      noLabels,
      isInfoVisible,
      isStatsVisible,
      firstLoading,
      fromURL,
      changedTypes
    } = this.state
    const { user, frames, location, t, getSome, objectTypes, objects } = this.props
    document.title = '2D标注视图' + '-' + fromURL.id

    const recordData = {
      ...getSome,
      firstFrameIndex: fromURL.firstFrameIndex,
      id: fromURL.id
    }

    // console.log('labels', labels)
    const labelsCollection = labels.map(label => (
      <li
        className={`viewer2-labels-collection-item${label === selectedLabel ? ' selected' : ''
          }`}
        style={{ cursor: label.target.visible ? '' : 'not-allowed' }}
        onClick={() => label.target.visible && this.handleSelectLabel(label)}
        key={label.id}
      >
        <div className="viewer2-label-row">
          <div className="viewer2-label-type">
            {/* <LabelType color={label.showName?label.color:COLOR[label.type.toUpperCase()]} /> */}
            <LabelType color={label.color} />
            {/* {label.showName?label.showName:t(`type.${label.type}`)} */}
            {label.showName}
            <span
              className="viewer2-label-tag"
              style={{ visibility: label.fail ? 'visible' : 'hidden' }}
            />
          </div>
          <div className="viewer2-label-actions">
            <button
              className="viewer2-btn"
              onClick={e => this.handleToggleLabelVisibility(e, label)}
            >
              <LegacyIcon type={label.target.visible ? 'eye' : 'eye-invisible'} />
            </button>
          </div>
        </div>
      </li>
    ))
    // console.log('change',changedTypes)
    const emptyCollection = (
      <div className="viewer2-labels-collection-empty">
        <div>
          <InboxOutlined style={{ fontSize: '30px' }} />
        </div>
        {t('noLabels')}
      </div>
    )
    // console.log(objectTypes)
    const selectRender = (
      <Select
        ref={el => {
          this.typeSelect = el
        }}
        size="small"
        showArrow={false}
        value={selectedLabel && selectedLabel.type}
        onChange={value => this.handleChangeType(value, selectedLabel)}
      >
        {objectTypes.length > 0 ? Object.values(objectTypes).map(key => (
          <Option value={key.name} key={key.id}>
            <LabelType color={key.color} />
            {t(`${key.showName}`)}
          </Option>)
        )
          : Object.keys(TYPE).map(key => (
            <Option value={TYPE[key]} key={key}>
              <LabelType color={COLOR[key]} />
              {t(`type.${TYPE[key]}`)}
            </Option>
          ))}
        {changedTypes.length > 0 ? Object.values(changedTypes).map(item => (
          <Option value={item.type} key={item.id} style={{ display: 'none' }}>
            <LabelType color={item.color} />
            {item.showName}
          </Option>)
        ) : Object.values(changedTypes).map(item => (<Option value={item.type} key={item.type} >
          <LabelType color={item.color} />
          {item.showName}
        </Option>))}

      </Select>
    )

    const frame = frames[currentFrame - 1]

    let dataURL = ''
    let openState = false

    if (frame) {
      const timestamp = frame.timestamp
      const { bagName, startTimestamp, status } = (this.state.fromURL.isUrl) ? this.state.fromURL : location.state.record
      const time = bagName.split('-')[1]

      const dataPath = `/${time.substring(0, 4)}/${time.substring(
        4,
        6
      )}/${time.substring(
        6,
        8
      )}/${bagName}/Object/${startTimestamp}/${timestamp}.txt`

      dataURL = OPEN_BASE_URL + dataPath
      openState = status === 'objects_ready'
    }

    return (
      <div className="viewer2">
        <div className="viewer2-up">
          <div className="viewer2-toolbar" ref={el => (this.toolbar = el)}>
            <div className="viewer2-toolbar-top">
              <Tooltip
                title={t('tooltip.rectangle')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton
                    type="gateway"
                    iconStyle={toolIconStyle}
                    active={selectedTool === TOOL.RECTANGLE}
                    onClick={() => this.handleSelectTool(TOOL.RECTANGLE)}
                  />
                </span>
              </Tooltip>
              {/* <Tooltip
              title={t('tooltip.polygon')}
              placement="right"
              mouseEnterDelay={TOOLTIP_DELAY}
            >
              <span className="viewer2-toolbar-btn">
                <IconButton
                  active={selectedTool === TOOL.POLYGON}
                  onClick={() => this.handleSelectTool(TOOL.POLYGON)}
                >
                  <CustomIcon
                    type="icon-vector-polygon"
                    style={toolIconStyle}
                  />
                </IconButton>
              </span>
            </Tooltip> */}
              <Divider style={horizontalDividerStyle} />
              <Tooltip
                title={t('tooltip.fit')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton onClick={this.scaleToFit}>
                    <i className="viewer2-icon-fit" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={t('tooltip.center')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton onClick={this.setCenter}>
                    <i className="viewer2-icon-center" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={t('tooltip.flip')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton
                    type="sync"
                    iconStyle={toolIconStyle}
                    active={isFlipped}
                    onClick={this.handleFlipImage}
                  />
                </span>
              </Tooltip>
              <Tooltip
                title={t('tooltip.fill')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <IconButton
                    type="border-outer"
                    iconStyle={toolIconStyle}
                    active={hasFill}
                    onClick={this.handleFillLabel}
                  />
                </span>
              </Tooltip>
              <Divider style={horizontalDividerStyle} />
              <Tooltip
                title={t('tooltip.revoke')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <Button type="text" icon={<RotateLeftOutlined />} onClick={this.handleRevoke} />
                </span>
              </Tooltip>
              <Tooltip
                title={t('tooltip.forward')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <Button type="text" icon={<RotateRightOutlined />} onClick={this.handleForward} />
                </span>
              </Tooltip>
              <Tooltip
                title={t('tooltip.copy')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-btn">
                  <Button type="text" icon={<CopyOutlined />} onClick={this.handleCopyUrl} />
                </span>
              </Tooltip>
              <Divider style={horizontalDividerStyle} />
              <Tooltip
                title={t('tooltip.zoom')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-input">
                  <InputNum
                    value={zoomRatioPP}
                    max={ZOOM.MAX}
                    min={ZOOM.MIN}
                    step={1}
                    precision={0}
                    style={{
                      padding: '0 0 0 1px',
                      margin: 0,
                      fontSize: '0.7rem',
                      textAlign: 'right'
                    }}
                    onChange={this.setZoom}
                  />
                  <span className="viewer2-toolbar-input-suffix">%</span>
                </span>
              </Tooltip>
              <Tooltip
                title={t('tooltip.opacity')}
                placement="right"
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <span className="viewer2-toolbar-input">
                  <InputNum
                    value={imageOpacity}
                    max={OPACITY.MAX}
                    min={OPACITY.MIN}
                    precision={OPACITY.PRECISION}
                    style={{
                      padding: '0',
                      fontSize: '0.7rem',
                      textAlign: 'center'
                    }}
                    onChange={this.handleChangeImageOpacity}
                  />
                </span>
              </Tooltip>
            </div>
            <div className="viewer2-toolbar-bottom">
              <span className="viewer2-toolbar-btn">
                <IconButton
                  type="bar-chart"
                  iconStyle={toolIconStyle}
                  active={isStatsVisible}
                  onClick={this.handleToggleStatsInfo}
                />
              </span>
              <span className="viewer2-toolbar-btn">
                <IconButton
                  type="info-circle"
                  iconStyle={toolIconStyle}
                  active={isInfoVisible}
                  onClick={this.handleToggleFrameInfo}
                />
              </span>
            </div>
          </div>
          <div className="viewer2-canvas">
            <div
              className="viewer2-canvas-wrapper"
              ref={el => (this.canvasRoot = el)}
            >
              <canvas ref={canvas => (this.canvas = canvas)} />
            </div>
            <div
              className="viewer2-canvas-ctx"
              style={{
                display: isCtxMenuVisible ? '' : 'none',
                top: this.top,
                left: this.left
              }}
            >
              <div className="viewer2-canvas-ctx-select">{selectRender}</div>
              <div className="viewer2-canvas-ctx-divider" />
              <button className="viewer2-btn" onClick={this.handleRemoveLabel}>
                <DeleteOutlined />
              </button>
              <button
                className="viewer2-btn"
                onClick={e => this.handleToggleLabelVisibility(e)}
              >
                <EyeOutlined />
              </button>
              {[role.globalExaminer, role.groupExaminer].includes(user.role) &&
                selectedLabel ? (
                <button
                  className="viewer2-btn"
                  onClick={this.handleExamineLabel}
                >
                  <i
                    className={`viewer2-icon-fail${selectedLabel.fail ? ' active' : ''
                      }`}
                  />
                </button>
              ) : null}
            </div>
            <FrameInfo
              frame={frame}
              // record={location.state.record}
              record={recordData}
              visible={isInfoVisible}
            />
            <LabelsStats objectTypes={objectTypes} labels={labels} counter={labelsCounter} visible={isStatsVisible} />
          </div>
          <div className="viewer2-tabs" ref={el => (this.tabs = el)}>
            <Tabs type="card">
              <TabPane tab={t('tab.labels')} key="0">
                <div className="ant-tabpane-wrapper">
                  <ul className="viewer2-labels-collection">
                    {labelsCollection.length
                      ? labelsCollection
                      : emptyCollection}
                  </ul>
                </div>
              </TabPane>
              <TabPane tab={t('tab.guides')} key="1">
                <div className="ant-tabpane-wrapper">
                  <Guides user={user} />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
        <div className="viewer2-bottom">
          <div className="viewer2-control">
            <div className="viewer2-control-slider">
              <Slider
                value={typeof currentFrame === 'number' ? currentFrame : 1}
                min={1}
                max={totalFrames}
                onChange={this.handleInputFrame}
              />
            </div>
            <div className="viwer2-control-input">
              <InputNumber
                value={currentFrame}
                min={1}
                max={totalFrames}
                size="small"
                style={{ marginLeft: '1rem' }}
                onChange={this.handleInputFrame}
              />
              <span className="viewer2-control-frames">/ {totalFrames}</span>
            </div>
          </div>
          <Divider type="vertical" />
          <div className="viewer2-actions">
            <div className="viewer2-actions-btn">
              <Checkbox
                checked={noLabels}
                disabled={!!labels.length}
                onChange={this.handleChangeNoLabels}
              >
                {t('noLabels')}
              </Checkbox>
            </div>
            <div className="viewer2-actions-btn">
              <Button
                size="small"
                type="primary"
                disabled={!isEdited}
                onClick={this.handleSave}
              >
                {t('btns.save')}
              </Button>
            </div>
          </div>
          {user.role === role.admin ? (
            <>
              <Divider type="vertical" />
              <div style={{ pointerEvents: openState ? 'auto' : 'none' }}>
                <div className="viewer2-actions">
                  <div className="viewer2-action-btn">
                    <Tooltip title={dataURL}>
                      <CopyToClipboard text={dataURL}>
                        <Button
                          type="primary"
                          size="small"
                          disabled={!openState}
                          onClick={this.handleCopyPath}
                        >
                          .txt
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <LoadingCover loading={firstLoading} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log('state', state)
  return {
    user: state.authentication.user,
    frames: state.annotation.frames.sort(
      (frameA, frameB) => frameA.timestamp - frameB.timestamp
    ),
    objects: state.annotation.objects.sort(
      (objectA, objectB) => objectA.id - objectB.id
    ),
    message: state.alert.message,
    getSome: state.getSome,
    objectTypes: state.objectType.objectTypes,
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation('viewer2d')(Viewer2D))
)
