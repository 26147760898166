import React, { Component } from 'react'
import './Viewer2D3Drelation.scss'
import { withRouter } from 'react-router-dom'
import { connect, connectAdvanced } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { fabric } from 'fabric'
import { createPointsKDTree, changePointsColorInBox, dyePCD } from '../_utils'
fabric.Object.prototype.noScaleCache = false

import { Button, Tabs, Slider, InputNumber, Divider, message, Tooltip, Checkbox } from 'antd'
import { LoadingCover, IconButton, LabelType } from '../common'
import {
  Shortcuts,
  FrameInfo,
  LeftRect,
  RightLabel,
  MiddleList
} from './components'
import { bagActions, annotationActions, alertActions, objectTypeActions } from '../_actions'
import { dataServices, } from '../_services'
import { db } from '../_db'
import DataWorker from '../_workers/data.worker'

import {
  MATERIAL_COLORS,
  MATERIAL_RECT_COLORS,
  PLANE_DISTANCE,
  FIGURE_PRECISION,
  DEFAULT_POINT_SIZE,
  DEFAULT_DIMENSION,
  OPEN_BASE_URL,
  PCD_INBOX_COLOR,
  DEFAULT,
  ZOOM
} from './constants'
import { MAIN } from './constants'
import {
  uuid,
  hexToRgb,
  toPrecision,
  base64ToArrayBuffer,
  recreateDB,
} from '../_utils'
import { history, role } from '../_helpers'

// Three libs
import 'three/examples/js/loaders/PCDLoader'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls'
import '../_lib/DragControls'
import 'three/examples/js/renderers/CSS2DRenderer'

const TabPane = Tabs.TabPane

const sliderLabelStyle = {
  color: '#fff',
  fontSize: '0.8rem',
  userSelect: 'none'
}

function applyPrecisionToLabel(object) {
  const props = ['position', 'scale', 'rotation']
  const subProps = ['x', 'y', 'z']

  props.forEach(prop => {
    subProps.forEach(subProp => {
      object[prop][subProp] = toPrecision(
        object[prop][subProp],
        FIGURE_PRECISION
      )
    })
  })

  return object
}
const toolIconStyle = {
  fontSize: '20px'
}
class Viewer2D3Drelation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentFrame: 1,
      totalFrames: 1,
      editingLabelName: null,
      labels: [],
      editingLabel: null,
      firstLoading: true,
      isEdited: false,
      noLabels: false,
      pointSize: DEFAULT_POINT_SIZE,
      pointHue: MATERIAL_COLORS.PCD.H,
      pointSaturation: MATERIAL_COLORS.PCD.S,
      pointLightness: MATERIAL_COLORS.PCD.L,
      objectClientId: null,
      selectedLabel: null,
      zoomRatioPP: DEFAULT.ZOOM_RATIO_PP,
      cameraBigboxs: {
        canvasRoot: null,
        name: 'camera_A',
        canvas: null,
        fcanvas: null,
        currentImage: null,
      },
      fromURL: {
        startTimestamp: '',
        endTimestamp: '',
        bagId: '',
        bagName: '',
        id: '',
        status: '',
        firstFrameIndex: 0,
        groupName: '',
        isUrl: false
      },
      rects: [],
      relations: [],
      visibleLabel: null,
      relationsLabel: null,
      disabledFlag: true,
      isFlipped: false,
      hasFill: false,
      helpersShowing: false,
      interfaceData: true  //判断是否是新帧还是已经标注过的帧数据
    }
    this.views = [
      {
        name: 'main',
        container: null,
        canvas: null,
        camera: null,
        context: null,
        dimension: {},
        eye: [0, -40, 40],
        up: [0, 0, 1],
        fov: 45,
        mapControl: null,
        render: () => { }
      }
    ]

    this.scene, this.rendererMain,
      this.plane, this.raycaster, this.mouse, this.loader
    this.helpers = []
    this.defaultBoxGeometry, this.defaultBoxMaterial
    this.defaultEdgesGeometry, this.defaultLineMaterial
    this.defaultConeGeometry
    this.dataWorker
    this.currentPCD
    this.buffer = {}
    this.bufferIndex = 0
    this.unlabeledIndices = []
    this.pointsKDTree

    if (location.search) {
      let locationId, locationFrame
      if (location.search.split('?')[1].split('').indexOf('&') > -1) {
        if (location.search.split('?')[1].split('&')[1]) {
          locationId = location.search.split('?')[1].split('&')[0].split('=')[1]
          locationFrame = location.search.split('?')[1].split('&')[1].split('=')[1]
        } else {
          locationId = location.search.split('?')[1].split('=')[1]
          locationFrame = 0
        }
      } else {
        locationId = location.search.split('?')[1].split('=')[1]
        locationFrame = 0
      }
      this.state.fromURL = { ...this.state.fromURL, id: locationId, firstFrameIndex: parseInt(locationFrame) }
    }
  }

  async componentDidMount() {
    const { dispatch, location } = this.props
    const getsome = await dispatch(annotationActions.getSome({ id: this.state.fromURL.id }))
    let forGroup = {}
    if (this.props.getSome.groupName == 'none') {
      forGroup = {

        ...this.props.getSome,
        groupName: null,
      }
    } else {
      forGroup = {
        ...this.props.getSome,
      }
    }

    this.setState({
      fromURL: {
        ...this.state.fromURL,
        ...forGroup

      }
    })
    const { fromURL } = this.state
    if (fromURL.groupName && fromURL.groupName !== '') {
      dispatch(objectTypeActions.getByGroup({ groupName: fromURL.groupName }))
    } else {
      dispatch(objectTypeActions.refresh())
    }

    if (fromURL.isUrl) {
      const { bagId, startTimestamp, endTimestamp } = this.state.fromURL

      // Get frames when component did mount
      if (bagId && startTimestamp && endTimestamp) {
        dispatch(
          annotationActions.getAllFrames(bagId, startTimestamp, endTimestamp, [
            'ready',
            'finish'
          ])
        )

        // Initialize views
        setTimeout(this.init, 0)
      }
    } else {
      history.replace('/annotations')
    }

    // Initialize data worker
    this.dataWorker = new DataWorker()
  }

  componentDidUpdate(prevProps) {
    const { bag, dispatch, frames, objects, objId, message: alertMessage } = this.props
    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 1, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 1, () =>
          dispatch(alertActions.clear())
        )
      }
    }
    // Get objects when: do have frames && not other props changes
    if (frames.length > 0 && frames !== prevProps.frames) {
      const lastFrameIndex = frames.length - 1
      let firstFrameIndex = lastFrameIndex
      if (this.state.fromURL.firstFrameIndex !== 0) {
        this.unlabeledIndices.push(this.state.fromURL.firstFrameIndex - 1)
        firstFrameIndex = this.state.fromURL.firstFrameIndex - 1
      } else {
        frames.forEach((frame, index) => {
          if (!frame.annotationobjectsId || frame.annotationobjectsId < 0) {
            this.unlabeledIndices.push(index)
            // this.unlabeledIndices.push(3)
            firstFrameIndex === lastFrameIndex && (firstFrameIndex = index)
            // firstFrameIndex === lastFrameIndex && (firstFrameIndex = 3)
          }
        })
      }

      if (this.unlabeledIndices.length === 0) {
        firstFrameIndex = 0
      }
      this.setState(
        {
          currentFrame: firstFrameIndex + 1,
          totalFrames: frames.length
        },
        () => {
          this.loadData(() => {
            if (frames[firstFrameIndex].annotationobjectsId > 0) {
              this.setState({
                objectClientId: frames[firstFrameIndex].annotationobjectsId,
                noLabels: false,
                isEdited: false
              })
              dispatch(
                annotationActions.getObject(
                  frames[firstFrameIndex].annotationobjectsId
                )
              )
            } else {
              this.setState({
                interfaceData: false
              })
            }
          })
        }
      )
    }

    // Put objects into the scene
    // if (objects.length > 0 && objects !== prevProps.objects) {
    if (objects !== prevProps.objects) {
      // When no objects, reuse objects in the previous frame
      if (objId === this.state.objectClientId) {
        if (objects.length > 0) {
          objects.map(obj => {
            obj.label.visible = obj.rect.visible
            return obj
          })
          this.setState({ relations: objects }, () => {
            this.filterRelationBybox(this.state.relations)
          })
        } else {
          this.filterRelationBybox(this.state.relations)
          if (this.state.relations.length > 0) { //relations有数据可以保存
            this.setState({
              isEdited: true
            })
          }
        }
      }
    }
  }

  componentWillUnmount() {
    // Remove global listeners before leaving
    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('keydown', this.onKeyDown)
    this.setState = () => false

    // Kill worker before leaving
    this.dataWorker.terminate()

    // Clear db before leaving
    recreateDB(db)
    this.setState = (state, callback) => {
      return
    }
  }

  /** THREE loading trigger */
  init = () => {
    this.createCanvases()
    this.createScene()
    this.createAccessories()
    this.createDefaultBox()
    this.createHelpers()
    this.createRenderers()
    this.createViews()
    this.animate()
  }

  createCanvases = () => {
    this.views.forEach(view => {
      const canvas = document.createElement('canvas')
      view.canvas = canvas
      view.container.append(view.canvas)
    })

    this.bindEventListeners()
  }

  bindEventListeners = () => {
    const main = this.views[MAIN]
    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('keydown', this.onKeyDown, false)
    main.canvas.addEventListener('mousedown', this.onMouseDown)
  }

  /** Listeners */
  onWindowResize = () => {
    const main = this.views[MAIN]

    this.views.forEach(({ name, canvas, camera, dimension }) => {
      dimension.width = canvas.clientWidth
      dimension.height = canvas.clientHeight
      dimension.aspect = dimension.width / dimension.height

      canvas.width = dimension.width * window.devicePixelRatio
      canvas.height = dimension.height * window.devicePixelRatio

      if (camera) {
        switch (name) {
          case 'main':
            camera.aspect = dimension.aspect
            break
        }
        camera.updateProjectionMatrix()
      }
    })

    this.rendererMain.setSize(main.canvas.clientWidth, main.canvas.clientHeight)
    this.labelRenderer.setSize(
      main.canvas.clientWidth,
      main.canvas.clientHeight
    )
  }

  onKeyDown = e => {
    // DIFF
    e.stopPropagation()
    const { currentFrame, editingLabel, firstLoading, totalFrames, selectedLabel, relationsLabel } = this.state
    const { user } = this.props
    // Disable all keyboard events when first loading
    if (firstLoading) return
    if (e.altKey || e.ctrlKey) {
      switch (e.code) {
        case 'KeyS':
          this.handleSave()
          break
        case 'KeyC':
          this.handleInputFrame(1)
          break
        case 'KeyV':
          this.handleInputFrame(totalFrames)
          break
      }
    } else if (e.shiftKey) {
      switch (e.code) {
        case 'KeyC':
          this.handleInputFrame(currentFrame - 5)
          break
        case 'KeyV':
          this.handleInputFrame(currentFrame + 5)
          break
      }
    } else {
      switch (e.code) {
        case 'KeyC':
          this.handleInputFrame(currentFrame - 1)
          break
        case 'KeyV':
          this.handleInputFrame(currentFrame + 1)
          break
        case 'Backquote':
          this.scaleToFit3d()
          break
        case 'KeyS':
          this.scaleToFit2d()
          break
        case 'KeyF':
          this.handleFlipImage()
          break
        case 'KeyU':
          this.handleFillLabel()
          break
        case 'KeyR':
          this.handleKeyAddRelation()
          break
        case 'KeyE':
          this.handleKeyRemoveRelation(e)
          break
        case 'KeyH':
          if (relationsLabel) {
            this.handleMiddleLabelVisibility(e, relationsLabel)
          } else {
            this.handleToggleLabelVisibility(e, selectedLabel)
          }
          break
      }
    }
  }
  onMouseDown = e => {
    const main = this.views[MAIN]
    const {
      labels, editingLabel, relationsLabel
    } = this.state
    e.preventDefault()
    this.mouse.x = (e.offsetX / e.target.clientWidth) * 2 - 1
    this.mouse.y = -(e.offsetY / e.target.clientHeight) * 2 + 1
    this.raycaster.setFromCamera(this.mouse, main.camera)
    const intersects = this.raycaster.intersectObjects(labels)
    if (e.button === 0) {
      if (intersects.length > 0) {
        if (intersects[0].object == editingLabel) {
          if (relationsLabel) {
            this.setRectLabelByRelation(relationsLabel, 'remove')
          }
          this.unhighlightLabel()
          this.setState({
            editingLabel: null,
            disabledFlag: true
          })
        } else {
          this.highlightLabel(intersects[0].object)  // 使标注高亮显示
          this.handleIntersects(intersects[0].object)
        }
      }
    }
    // if (intersects.length > 0 && editingLabel) {
    //   // this.deselectRect()
    //   this.unhighlightLabel()
    //   this.setState({
    //     editingLabel: null
    //   })
    // }
  }
  handleIntersects = label => {
    this.handleClickRelation(label, 'label')
  }


  createRenderers = () => {
    this.rendererMain = new THREE.WebGLRenderer({ antialias: true })
    this.rendererMain.setPixelRatio(window.devicePixelRatio)

    this.labelRenderer = new THREE.CSS2DRenderer()
    this.labelRenderer.domElement.style.position = 'absolute'
    this.labelRenderer.domElement.style.top = 0
    this.labelRenderer.domElement.style.pointerEvents = 'none'
    this.labelRenderer.domElement.style.zIndex = 0
    this.views[0].container.appendChild(this.labelRenderer.domElement)

    //this.onWindowResize() // Get canvases' sizes and set renderers' sizes
  }

  createScene = () => {
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0x151515)
  }

  createAccessories = () => {
    this.plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), PLANE_DISTANCE)
    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()
    this.loader = new THREE.PCDLoader()
  }

  createViews = () => {
    this.views.forEach(view => {
      let camera
      const HALF_WIDTH = 8

      switch (view.name) {
        case 'main':
          camera = new THREE.PerspectiveCamera(
            view.fov,
            view.canvas.width / view.canvas.height,
            0.1,
            400
          )
          break
      }

      camera.position.fromArray(view.eye)
      camera.up.fromArray(view.up)

      view.camera = camera
      view.mapControl = this.createMapControls(view.camera, view.canvas)

      if (view.name !== 'main') {
        // No rotate in other views
        view.mapControl.enableRotate = false
      }

      // Main render for main view, auxiliary render for top/side/rear views
      view.context = view.canvas.getContext('2d')
      view.render =
        view.name === 'main'
          ? () => {
            this.rendererMain.render(this.scene, view.camera)
            view.context.drawImage(this.rendererMain.domElement, 0, 0)
            this.labelRenderer.render(this.scene, view.camera)
          }
          : ''
    })
  }

  createHelpers = () => {
    const defaultBoxMaterial = this.defaultBoxMaterial.clone()
    // Car helper
    const mesh = new THREE.Mesh(this.defaultBoxGeometry, defaultBoxMaterial)
    mesh.material.color.set(MATERIAL_COLORS.HELPER)

    const line = new THREE.LineSegments(
      this.defaultEdgesGeometry,
      this.defaultLineMaterial.clone()
    )
    line.material.color.set(MATERIAL_COLORS.HELPER)
    mesh.add(line)

    const arrow = new THREE.Mesh(this.defaultConeGeometry, defaultBoxMaterial)
    arrow.position.fromArray([2.8, 0, 0])
    arrow.rotation.fromArray([0, 0, -1.57])

    mesh.add(arrow)

    mesh.position.set(0, 0, -PLANE_DISTANCE)
    //mesh.rotateZ(-Math.PI / 2)
    this.helpers.push(mesh)
    //this.scene.add(mesh)

    // Axes helper
    const axesHelper = new THREE.AxesHelper(60)
    this.helpers.push(axesHelper)
    //this.scene.add(axesHelper)

    const gridHelper = new THREE.GridHelper(200, 20)
    gridHelper.rotateX(Math.PI / 2)
    this.helpers.push(gridHelper)
    //this.scene.add(gridHelper)
  }

  createDefaultBox = () => {
    // Create default Box
    this.defaultBoxGeometry = new THREE.BoxBufferGeometry(
      ...Object.values(DEFAULT_DIMENSION)
    ) // Deafult box geometry
    this.defaultBoxMaterial = new THREE.MeshBasicMaterial({
      opacity: 0.2,
      transparent: true
    })

    // Create default Edges
    this.defaultEdgesGeometry = new THREE.EdgesGeometry(this.defaultBoxGeometry)
    this.defaultLineMaterial = new THREE.LineBasicMaterial({
      color: MATERIAL_COLORS.CAR
    })

    // Create default arrow cone
    this.defaultConeGeometry = new THREE.ConeBufferGeometry(
      0.5,
      0.5,
      50,
      1,
      true
    )
  }

  createMapControls = (camera, canvas) => {
    const mapControl = new MapControls(camera, canvas)
    mapControl.enableDamping = true
    mapControl.dampingFactor = 0.3
    mapControl.enableKeys = false
    mapControl.screenSpacePanning = false
    mapControl.minDistance = 1
    mapControl.maxDistance = 300
    mapControl.maxPolarAngle = Math.PI / 2

    return mapControl
  }


  createLabel = ({
    id,
    name,
    type,
    position,
    scale,
    dimension,
    rotation,
    fail,
  }) => {
    const { currentFrame } = this.state
    // Create box
    const defaultBoxMaterial = this.defaultBoxMaterial.clone()
    let mesh = new THREE.Mesh(this.defaultBoxGeometry, defaultBoxMaterial)
    // Create line
    const defaultLineMaterial = this.defaultLineMaterial.clone()
    const line = new THREE.LineSegments(
      this.defaultEdgesGeometry,
      defaultLineMaterial
    )
    mesh.add(line)
    // Create arrow, using the same material with box to change color synchronized
    const arrow = new THREE.Mesh(this.defaultConeGeometry, defaultBoxMaterial)

    arrow.position.fromArray([2.8, 0, 0])
    arrow.rotation.fromArray([0, 0, -1.57])
    mesh.add(arrow)

    // Label customization
    // ID
    id ? (mesh.userData.id = id) : ''
    // Type
    const color = MATERIAL_COLORS[type.toUpperCase()]
    mesh.userData.type = type
    mesh.material.color.set(color)
    mesh.children[0].material.color.set(color)
    // Name
    if (name) {
      mesh.name = name
    } else {
      if (!mesh.userData.id) mesh.userData.id = 1
      mesh.name = `${type}-${currentFrame}-${mesh.userData.id}`
    }

    // Position
    position &&
      (position instanceof THREE.Vector3
        ? mesh.position.copy(position)
        : mesh.position.fromArray(position))

    // Scale
    dimension = Array.isArray(dimension) ? dimension : Object.values(dimension)
    if (dimension) {
      const newScale = [
        dimension[0] / DEFAULT_DIMENSION.x,
        dimension[1] / DEFAULT_DIMENSION.y,
        dimension[2] / DEFAULT_DIMENSION.z
      ]
      mesh.scale.fromArray(newScale)
    }

    // Dimension
    mesh.userData.dimension = new THREE.Vector3(
      ...Object.values(dimension ? dimension : DEFAULT_DIMENSION)
    )
    // Rotation
    rotation && mesh.rotation.fromArray(rotation)

    // Add label tag
    const labelTagDiv = document.createElement('div')
    labelTagDiv.className = 'views-label'
    labelTagDiv.textContent = mesh.name
    // labelTagDiv.style.pointerEvents = 'auto'
    const labelTag = new THREE.CSS2DObject(labelTagDiv)
    labelTag.position.set(0, 0, 3)

    mesh.add(labelTag)

    if (fail) {
      mesh.children[0].material.color.set(MATERIAL_COLORS.FAIL)
      labelTagDiv.style.color = MATERIAL_COLORS.FAIL

      mesh.userData.fail = fail
    }

    // Apply precision
    mesh = applyPrecisionToLabel(mesh)

    const count = changePointsColorInBox(
      mesh,
      this.currentPCD,
      this.pointsKDTree,
      PCD_INBOX_COLOR
    )
    mesh.userData.pointsCount = count

    return mesh
  }

  animate = () => {
    this.views.forEach(view => {
      view.render()
    })
    requestAnimationFrame(this.animate)
  }

  handleInputFrame = (value) => {
    const { totalFrames, relations, editingLabel } = this.state
    const { dispatch, frames } = this.props
    if (value > 0 && value <= totalFrames) {
      const objectId = frames[value - 1].annotationobjectsId
      this.handleSave()
      this.setState(
        {
          currentFrame: value,
          noLabels: false,
          editingLabel: null,
          selectedLabel: null,
          relationsLabel: null,
          editingLabelName: editingLabel ? editingLabel.name : null
        },
        () => {
          this.setButtFlag()
          this.setState({ isEdited: false, noLabels: false })
          this.loadData(() => {
            if (objectId > 0) {
              this.setState({
                objectClientId: objectId,
                interfaceData: true,
                noLabels: false,
                isEdited: false
              })
              dispatch(annotationActions.getObject(objectId))
            } else {
              this.setState({
                interfaceData: false
              })
              console.log('不加载接口数据')
            }
          })
        }
      )
    }
  }
  filterRelationBybox = (relations) => {
    const { labels, rects } = this.state
    for (let i = relations.length - 1; i >= 0; i--) {
      let labelExit = labels.some((item) => {
        return item.name === relations[i].label.name
      })
      let rectExit = rects.some((item) => {
        return item.name === relations[i].rect.name
      })
      if (!(labelExit && rectExit)) {
        relations.splice(i, 1)
      }
    }
    this.setInitList()
    this.setState({
      relations,
    })
  }

  handleSave = () => {
    const { relations, currentFrame, isEdited, fromURL } = this.state
    const { dispatch, frames, location, t, user } = this.props
    const { id } = (fromURL.isUrl) ? fromURL : location.state.record

    if (!isEdited) return

    const objectId = frames[currentFrame - 1].annotationobjectsId
    var objects = null
    objects = relations
    const userId = parseInt(user.id)
    // const annotationId = parseInt(fromURL.id)

    dispatch(
      objectId > 0
        ? annotationActions.updateObject(objects, objectId, objects.length, userId)
        : annotationActions.createObject(
          objects,
          frames[currentFrame - 1].id,
          objects.length,
          userId
        )
    )
      .then(object => {
        // When created objects, remove that frame from indices
        if (!(objectId > 0)) {
          frames[currentFrame - 1].annotationobjectsId = object.id

          this.unlabeledIndices.splice(
            this.unlabeledIndices.findIndex(
              value => value === currentFrame - 1
            ),
            1
          )
        }

        // Update this annotation detail and progress
        dispatch(
          annotationActions.updateAnnotation({
            id,
            progress: `${frames.length - this.unlabeledIndices.length} / ${frames.length
              }`
          })
        )

        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.saveSuccess')
          })
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alerts.saveFailure')
          })
        )
      })
  }

  handleChangePointSize = value => {
    this.setState({ pointSize: value })
    this.currentPCD.material.size = value
  }

  handleCopyPath = () => {
    const { dispatch, t } = this.props
    dispatch(
      alertActions.success({
        type: 'success',
        detail: t('alerts.copySuccess')
      })
    )
  }

  handleChangeNoLabels = e => {
    this.setState({
      noLabels: e.target.checked,
      isEdited: e.target.checked
    })
  }
  byteToString = byte => {
    return JSON.parse(new TextDecoder().decode(base64ToArrayBuffer(byte)))
  }
  /** load Data */
  loadData = callback => {
    const { currentFrame, cameraBigboxs } = this.state
    const { frames, location, bag } = this.props
    let { bagName } = (this.state.fromURL.isUrl) ? this.state.fromURL : location.state.record
    this.bufferIndex = currentFrame
    const index = currentFrame - 1
    const timestamp = frames[index].timestamp
    if (this.buffer[timestamp] && this.buffer[timestamp].pcd) {
      this.addPCD(this.buffer[timestamp].pcd)
      this.pointsKDTree = this.buffer[timestamp].kdTree
      let label2d = this.buffer[timestamp].LABEL_2D || null
      let label3d = this.buffer[timestamp].LABEL_3D || []
      this.addCameraImg(this.buffer[timestamp].camera, label2d)
      this.handleUpdateLabels(label3d)
      callback()
    } else {
      this.buffer[timestamp] = {}
      const getPCD = db['PCD'].get(Number(timestamp))
      const getCAMERA = db['CAMERA_1'].get(Number(timestamp))
      const getLABEL_3D = db['LABEL_3D'].get(Number(timestamp))
      const getLABEL_2D = db['LABEL_2D'].get(Number(timestamp))
      Promise.all([getPCD, getCAMERA, getLABEL_3D, getLABEL_2D])
        .then(result => {
          // Make sure there must be pcd in db
          if (result[0] && result[1]) {
            // Add PCD to buffer
            const [pcd, kdTree] = this.loadPCD(result[0].data)
            this.buffer[timestamp].pcd = pcd
            this.buffer[timestamp].kdTree = kdTree
            this.addPCD(this.buffer[timestamp].pcd)
            this.pointsKDTree = this.buffer[timestamp].kdTree
            this.handleUpdateLabels([])
            if (result[1]) {
              // Add CAMERA to buffer
              this.buffer[timestamp].camera = 'data:image/jpeg;base64,' + result[1].data
              if (result[3]) {
                this.buffer[timestamp].LABEL_2D = this.byteToString(result[3].data)
                this.addCameraImg(this.buffer[timestamp].camera, this.buffer[timestamp].LABEL_2D)
              } else {
                this.addCameraImg(this.buffer[timestamp].camera, null)
              }
            }
            if (result[2]) {
              this.buffer[timestamp].LABEL_3D = this.byteToString(result[2].data)
              this.handleUpdateLabels(this.buffer[timestamp].LABEL_3D)
            }

            callback()
          } else {
            const data = {
              bagName: bagName,
              sensorsList: ['PCD', 'CAMERA_1', 'LABEL_3D', 'LABEL_2D'],
              startFrametime: timestamp,
              endFrametime: timestamp,
              framesList: [timestamp]
            }
            dataServices.getData(data, onMessage, onEnd)
          }
        })
        .catch(e => {
          console.log(e)
        })
    }

    // Test and preload next 5 frames
    this.japStep()
    const onMessage = data => {
      // For buffer
      if (data.sensorname === 'PCD') {
        const [pcd, kdTree] = this.loadPCD(data.data)
        this.buffer[timestamp].pcd = pcd
        this.buffer[timestamp].kdTree = kdTree
        this.addPCD(this.buffer[timestamp].pcd)
        this.pointsKDTree = this.buffer[timestamp].kdTree
        this.handleUpdateLabels([])
      } else if (data.sensorname === 'CAMERA_1') {
        this.buffer[timestamp].camera = 'data:image/jpeg;base64,' + data.data
        this.addCameraImg(this.buffer[timestamp].camera, null)
      } else if (data.sensorname === 'LABEL_3D') {
        //console.log(this.byteToString(data.data))
        this.buffer[data.frametime].LABEL_3D = this.byteToString(data.data)
        this.handleUpdateLabels(this.buffer[data.frametime].LABEL_3D)
      } else if (data.sensorname === 'LABEL_2D') {
        this.buffer[data.frametime].LABEL_2D = this.byteToString(data.data)
        this.addCameraImg(this.buffer[timestamp].camera, this.buffer[data.frametime].LABEL_2D)
      }
      this.dataWorker.postMessage({ action: 'PUT_DATA', data })
    }
    const onEnd = () => {
      callback()
      console.log('loadData ends')
    }
  }

  preloadData = () => {
    const { frames, location } = this.props
    let { bagName } = (this.state.fromURL.isUrl) ? this.state.fromURL : location.state.record

    const startIndex = this.bufferIndex
    const endIndex =
      this.bufferIndex + 5 > frames.length
        ? frames.length
        : this.bufferIndex + 5
    const promiseQueue = []

    // startIndex includes, endIndex excludes
    for (let index = startIndex; index < endIndex; ++index) {
      const timestamp = frames[index].timestamp
      // If not in buffer
      if (!this.buffer[timestamp]) {
        this.buffer[timestamp] = {}
        promiseQueue.push(
          new Promise((resolve, reject) => {
            const getPCD = db['PCD'].get(Number(timestamp))
            const getCAMERA = db['CAMERA_1'].get(Number(timestamp))
            const getLABEL_3D = db['LABEL_3D'].get(Number(timestamp))
            const getLABEL_2D = db['LABEL_2D'].get(Number(timestamp))
            Promise.all([getPCD, getCAMERA, getLABEL_3D, getLABEL_2D])
              .then(result => {
                if (result[0] && result[1] && result[2] && result[3]) {
                  const [pcd, kdTree] = this.loadPCD(result[0].data)
                  this.buffer[timestamp].pcd = pcd
                  this.buffer[timestamp].kdTree = kdTree
                  this.buffer[timestamp].camera = 'data:image/jpeg;base64,' + result[1].data
                  this.buffer[timestamp].LABEL_3D = this.byteToString(result[2].data)
                  this.buffer[timestamp].LABEL_2D = this.byteToString(result[3].data)
                  return resolve()
                } else {
                  this.buffer[timestamp] = undefined
                  return resolve(timestamp)
                }
              })
              .catch(e => {
                console.log(e)
                this.buffer[timestamp] = undefined
                return reject()
              })
          })
        )
      }
    }

    Promise.all(promiseQueue).then(result => {
      // Filter frames which need to request data
      const framesList = result.filter(item => {
        return !!item
      })
      if (framesList.length > 0) {
        const data = {
          bagName: bagName,
          sensorsList: ['PCD', 'CAMERA_1', 'LABEL_3D', 'LABEL_2D'],
          startFrametime: frames[startIndex].timestamp,
          endFrametime: frames[endIndex - 1].timestamp,
          framesList
        }
        dataServices.getData(data, onMessage, onEnd)
      }
    })
    const onMessage = data => {
      this.buffer[data.frametime] || (this.buffer[data.frametime] = {})
      // For buffer
      if (data.sensorname === 'PCD') {
        const [pcd, kdTree] = this.loadPCD(data.data)
        this.buffer[data.frametime].pcd = pcd
        this.buffer[data.frametime].kdTree = kdTree
      } else if (data.sensorname === 'CAMERA_1') {
        this.buffer[data.frametime].camera = 'data:image/jpeg;base64,' + data.data
      } else if (data.sensorname === 'LABEL_3D') {
        this.buffer[data.frametime].LABEL_3D = this.byteToString(data.data)
      } else if (data.sensorname === 'LABEL_2D') {
        this.buffer[data.frametime].LABEL_2D = this.byteToString(data.data)
      }
      // For cache
      this.dataWorker.postMessage({ action: 'PUT_DATA', data })
    }

    const onEnd = () => {
      console.log('preload ends')
    }
  }

  japStep = () => {
    const { frames } = this.props

    if (this.bufferIndex === frames.length) return

    const index = this.bufferIndex
    const timestamp = frames[index].timestamp

    const getPCD = db['PCD'].get(Number(timestamp))
    const getCAMERA = db['CAMERA_1'].get(Number(timestamp))
    Promise.all([getPCD, getCAMERA])
      .then(result => {
        if (!result[0] && !result[1]) {
          this.preloadData()
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  /** PCD */
  loadPCD = data => {
    const mesh = this.loader.parse(base64ToArrayBuffer(data), 'name')

    dyePCD(mesh)
    this.updatePCD(mesh.material)

    const kdTree = createPointsKDTree(mesh)

    return [mesh, kdTree]
  }

  addPCD = pcd => {
    const { firstLoading } = this.state

    if (this.currentPCD) {
      this.scene.remove(this.currentPCD)
    }

    // Set point size
    this.updatePCD(pcd.material)

    this.scene.add(pcd)
    this.currentPCD = pcd

    firstLoading && this.setState({ firstLoading: false })
  }

  updatePCD = material => {
    const {
      pointSize,
      pointHue: h,
      pointSaturation: s,
      pointLightness: l
    } = this.state

    material.size = pointSize // Points size
    material.sizeAttenuation = false
    // material.color.setHSL(h, s, l)
    // material.vertexColors = true
  }

  handleUpdateLabels = objects => {
    const { labels, interfaceData, relations, editingLabelName } = this.state
    // Clean labels
    labels.forEach(label => {
      label.children[2].element.remove()
      this.scene.remove(label)
    })

    labels.length = 0
    // Push new labels
    if (objects && objects.length > 0) {
      objects.forEach(object => {
        let mesh = this.createLabel(object)
        this.scene.add(mesh)
        labels.push(mesh)
        if (editingLabelName === object.name) {
          this.handleSelectLabel(mesh)
        }
      })
    }
    this.setState({
      labels,
    })
  }
  updateLabels = objectData => {
    const { cameraBigboxs, rects, relations, interfaceData, zoomRatioPP, labels, objectClientId, editingLabelName } = this.state
    const { angle, aCoords, scaleX: scale } = cameraBigboxs.currentImage
    const { x, y } = angle ? aCoords.br : aCoords.tl
    // Remove previous labels
    if (cameraBigboxs.fcanvas) {
      cameraBigboxs.fcanvas.remove(...cameraBigboxs.fcanvas.getObjects().slice(1))
      rects.length = 0
      this.setState({ rects })
    }
    // Clear labels Counter then create new labels
    if (objectData) {
      const objects = objectData.objects
      const rects = objects.map(
        ({ id, name, type, xmin, ymin, xmax, ymax, fail }) => {
          const color = MATERIAL_RECT_COLORS[type.toUpperCase()]
          const strokeWidth = (DEFAULT.STROKE_TRACKING_WIDTH / zoomRatioPP) * 100
          const rect = new fabric.Rect({
            left: x + xmin * scale,
            top: y + ymin * scale,
            originX: 'left',
            originY: 'top',
            width: (xmax - xmin) * scale,
            height: (ymax - ymin) * scale,
            hoverCursor: 'move',
            fill: this.getFillColor(color),
            stroke: color,
            strokeWidth,
            strokeUniform: true,
            hasBorders: false,
            cornerColor: '#fff',
            cornerSize: 8,
            borderOpacityWhenMoving: 0.3,
            hasRotatingPoint: false
          })

          cameraBigboxs.fcanvas.add(rect)
          const recTitle = this.creatTitle(rect.left, rect.top, color, name)
          recTitle.visible = false
          cameraBigboxs.fcanvas.add(recTitle)
          this.handleNoSelectable()
          return {
            id,
            type,
            fail,
            name,
            recTitle: recTitle,
            target: rect
          }
        }
      )
      this.setState({ rects })
      this.setState({
        noLabels: false,
        isEdited: false
      })
      if (!interfaceData) {
        this.filterRelationBybox(relations)
        if (this.state.relations.length > 0) { //relations有数据可以保存
          this.setState({
            isEdited: true
          })
        }
      }
    }
    // 判断2d或3d框没数据的情况
    if (this.state.labels.length === 0 || !objectData) {
      if (!interfaceData) {
        this.setState({
          noLabels: true,
          isEdited: true
        })
      }
    } else {
      this.setState({
        noLabels: false,
        isEdited: false
      })
    }
  }
  getFillColor = color => {
    const { hasFill } = this.state

    // To test the color is hex or rgba
    const c =
      color[0] === '#'
        ? hexToRgb(color)
        : color.split(/\D+/).filter(item => item)

    return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${hasFill ? DEFAULT.ALPHA : 0})`
  }
  creatTitle = (titLeft, titTop, fillColor, titleName) => {
    const { zoomRatioPP } = this.state
    const strokeWidth = (DEFAULT.STROKE_TRACKING_WIDTH / zoomRatioPP) * 100
    let cubeArr = []
    let rectBj = new fabric.Rect({ left: titLeft, top: titTop - 21, fill: '', strokeWidth: strokeWidth, stroke: fillColor, width: 90, height: 20 })
    if (titleName) {
      cubeArr.push(rectBj)
      let rectword = new fabric.Textbox(titleName, { fontSize: 12, fontFamily: 'Tahoma', left: titLeft, top: titTop - 18, fill: '#fff', width: 90, textAlign: 'center' })
      cubeArr.push(rectword)
    }
    var group = new fabric.Group(cubeArr, { type: 'rectTitle' })
    return group
  }
  handleNoSelectable = () => {
    const { cameraBigboxs } = this.state
    cameraBigboxs.fcanvas.getObjects().forEach((klass) => {
      klass.selectable = false
    })
    cameraBigboxs.fcanvas.requestRenderAll()
  }

  highlightLabel = label => {
    this.unhighlightLabel()
    label.children[0].material.color.set(MATERIAL_COLORS.HIGHLIGHT)
    label.children[2].element.style.backgroundColor =
      MATERIAL_COLORS[label.userData.type.toUpperCase()]
  }

  unhighlightLabel = () => {
    const { editingLabel } = this.state
    if (editingLabel) {
      editingLabel.children[0].material.color.set(
        editingLabel.userData.fail
          ? MATERIAL_COLORS.FAIL
          : MATERIAL_COLORS[editingLabel.userData.type.toUpperCase()]
      )
      editingLabel.children[2].element.style.backgroundColor = ''
    }
  }

  handleSelectLabel = (label, e) => {
    const { editingLabel, relationsLabel } = this.state
    if (label !== editingLabel) {
      this.highlightLabel(label)
      if (relationsLabel) {
        this.setRectLabelByRelation(relationsLabel, 'remove')
      }
      this.setState({
        editingLabel: label,
      }, () => {
        this.setButtFlag()
      })
    }
    else {
      this.unhighlightLabel()
      this.setState({
        editingLabel: null,
        disabledFlag: true
      })
    }
  }
  // 2d图片
  handleSelectRect = label => {
    const { selectedLabel, cameraBigboxs, relationsLabel } = this.state
    if (label === selectedLabel) {
      this.setState({
        selectedLabel: null,
        disabledFlag: true
      })
      this.checkedDisActiveLabel(label)
    } else {
      if (selectedLabel) {
        this.checkedDisActiveLabel(selectedLabel)
      }
      if (relationsLabel) {
        this.setRectLabelByRelation(relationsLabel, 'remove')
      }
      this.setState({
        selectedLabel: label,
      }, () => {
        this.setButtFlag()
      })
      this.checkedActiveLabel(label)
    }
    cameraBigboxs.fcanvas.requestRenderAll()
  }
  checkedActiveLabel = label => {
    const { cameraBigboxs, zoomRatioPP } = this.state
    const strokeWidth = (DEFAULT.STROKE_TRACKING_WIDTH * 3 / zoomRatioPP) * 100
    label.recTitle.visible = label.target.visible
    label.recTitle.getObjects()[0].set({
      fill: MATERIAL_COLORS[label.type.toUpperCase()],
      strokeWidth: strokeWidth,
    })
    label.target.set({
      strokeWidth: strokeWidth
    })
    cameraBigboxs.fcanvas.requestRenderAll()
  }
  checkedDisActiveLabel = label => {
    const { cameraBigboxs, zoomRatioPP } = this.state
    const strokeWidth = (DEFAULT.STROKE_TRACKING_WIDTH / zoomRatioPP) * 100
    label.recTitle.visible = false
    label.recTitle.getObjects()[0].set({
      fill: '',
      strokeWidth: strokeWidth
    })
    label.target.set({
      strokeWidth: strokeWidth
    })
    cameraBigboxs.fcanvas.requestRenderAll()
  }

  handleToggleLabelVisibility = (e, label) => {
    const { selectedLabel, cameraBigboxs } = this.state
    e.stopPropagation()

    if (!label && !selectedLabel) return
    const target = label ? label.target : selectedLabel.target
    const recTitle = label ? label.recTitle : selectedLabel.recTitle
    target.visible = !target.visible
    if (selectedLabel && label.recTitle === selectedLabel.recTitle) {
      recTitle.visible = target.visible
    } else {
      recTitle.visible = false
    }
    cameraBigboxs.fcanvas.requestRenderAll()
    this.setState({
      visibleLabel: label
    })
  }
  createFcanvases = () => {
    const { cameraBigboxs } = this.state
    fabric.Object.prototype.noScaleCache = false
    cameraBigboxs.fcanvas = new fabric.Canvas(cameraBigboxs.canvas, {
      preserveObjectStacking: true,
      selection: false,
      selectionColor: 'transparent',
      selectionBorderColor: 'transparent',
      backgroundColor: 'transparent',
      defaultCursor: 'default',
      fireRightClick: true,
      stopContextMenu: true,
    })
    cameraBigboxs.fcanvas.on({
      'mouse:wheel': this.handleMouseWheel,
      'mouse:down': this.handleMouseDown,
      'mouse:move': this.handleMouseMove,
      'mouse:up': this.handleMouseUp,
    })
  }
  handleMouseWheel = (opt) => {
    opt.e.preventDefault()
    opt.e.stopPropagation()
    const { cameraBigboxs } = this.state
    const fcanvas = cameraBigboxs.fcanvas

    const delta = opt.e.deltaY
    let zoomRatio = fcanvas.getZoom()
    // Get new zoom ratio
    zoomRatio = delta > 0 ? zoomRatio - 0.05 : zoomRatio + 0.05
    this.setZoom(zoomRatio * 100, opt.pointer)
  }
  setZoom = (zoomRatioPP, pointer) => {
    const { cameraBigboxs, selectedLabel } = this.state
    let zoomRatio = zoomRatioPP / 100
    zoomRatioPP = toPrecision(zoomRatioPP, ZOOM.PRECISION)

    // Set pointer to canvas center if no pointer passes
    if (!pointer) {
      const center = cameraBigboxs.fcanvas.getCenter()
      pointer = new fabric.Point(center.left, center.top)
    }
    if (zoomRatioPP < ZOOM.MIN) {
      zoomRatioPP = ZOOM.MIN
    } else if (zoomRatioPP > ZOOM.MAX) {
      zoomRatioPP = ZOOM.MAX
    } else {
      cameraBigboxs.fcanvas.getObjects().forEach((object, index) => {
        if (index &&(selectedLabel? object !== selectedLabel.target:index)) {
          object.strokeWidth = DEFAULT.STROKE_WIDTH / zoomRatio
        } else if (selectedLabel? object == selectedLabel.target:false) {
          object.strokeWidth = (DEFAULT.STROKE_TRACKING_WIDTH * 3 / zoomRatioPP) * 100
        }
      })
      cameraBigboxs.fcanvas.zoomToPoint(pointer, zoomRatio)
    }

    this.setState({ zoomRatioPP })
  }
  handleMouseDown = opt => {
    const { selectedLabel, rects, cameraBigboxs, relationsLabel } = this.state
    // Deal with creating new shape
    if (opt.button === 1) {
      const label = rects.find(label => label.target === opt.target)
      if (label) {
        if (selectedLabel) {
          this.checkedDisActiveLabel(selectedLabel)
        }
        if (label == selectedLabel) {
          if (relationsLabel) {
            this.setRectLabelByRelation(relationsLabel, 'remove')
          }
          this.setState({
            selectedLabel: null,
            disabledFlag: true
          })
          this.checkedDisActiveLabel(label)
        } else {
          this.handleClickRelation(label, 'rect')
          this.checkedActiveLabel(label)
        }
      }
    }
    const pointer = cameraBigboxs.fcanvas.getPointer(opt.e)
    if (opt.button === 3) {
      opt.e.preventDefault()
      opt.e.stopPropagation()
      this.dragging = true
      this.dragging_start_x = pointer.x
      this.dragging_start_y = pointer.y
    }
  }
  handleMouseMove = opt => {
    const { cameraBigboxs } = this.state
    if (this.dragging) {
      const current_pointer = cameraBigboxs.fcanvas.getPointer(opt.e)
      const delta_pointer = new fabric.Point(
        current_pointer.x - this.dragging_start_x,
        current_pointer.y - this.dragging_start_y,
      )
      cameraBigboxs.fcanvas.relativePan(delta_pointer)
      return
    }
  }
  handleMouseUp = () => {
    this.dragging = false
  }
  // 点击2d和3d图，当点击已关联或者未关联的逻辑
  handleClickRelation = (box, mode) => {
    const { relations, relationsLabel } = this.state
    let elementId, elementDiv
    if (mode == 'label') {
      elementId = document.getElementById(box.uuid)
      elementDiv = document.getElementsByClassName('views-list-3d')[0]
    } else {
      elementId = document.getElementById(box.id)
      elementDiv = document.getElementsByClassName('views-list-2d')[0]
    }

    const exitflag = relations.some((item) => {
      return item.rect.name === box.name && mode === 'rect' || item.label.name === box.name && mode === 'label'
    })

    if (exitflag) {
      relations.forEach((item) => {
        if (item.rect.name === box.name && mode === 'rect' || item.label.name === box.name && mode === 'label') {
          this.setRectLabelByRelation(item, 'add')
          let relationId = document.getElementById(item.id)
          let relationDiv = document.getElementsByClassName('views-relation-lists')[0]
          relationDiv.scrollTo({
            'top': relationId.offsetTop,
            'behavior': 'smooth'
          })
        }
      })
    } else {
      elementDiv.scrollTo({
        'top': elementId.offsetTop,
        'behavior': 'smooth'
      })
      if (relationsLabel) {
        this.setRectLabelByRelation(relationsLabel, 'remove')
      }
    }
    // 设置选中框
    if (mode === 'rect') {
      this.setState({
        selectedLabel: box,
      })
    } else {
      this.setState({
        editingLabel: box,
      })
    }
    this.setButtFlag()
  }
  getImageDisplayRatio = image => {
    const { cameraBigboxs } = this.state
    if (cameraBigboxs.canvas) {
      const canvasRootdWidth = cameraBigboxs.canvasRoot.clientWidth
      const scale = canvasRootdWidth / image.width
      cameraBigboxs.canvas.width = canvasRootdWidth
      cameraBigboxs.canvas.height = image.height * scale
      const viewWidth = canvasRootdWidth
      const viewHeight = cameraBigboxs.canvas.height
      const ratio = (image.width) / (image.height)
      let displayRatio = ratio

      // Set canvas image diplay size
      if (viewWidth / viewHeight > ratio) {
        this.imageHeight = viewHeight
        this.imageWidth = this.imageHeight * ratio

        displayRatio = this.imageHeight / image.height
      } else {
        this.imageWidth = viewWidth
        this.imageHeight = this.imageWidth / ratio

        displayRatio = this.imageWidth / image.width
      }

      return displayRatio
    }
  }

  addCameraImg = (imagesCanvas, label2d) => {
    const { isFlipped, cameraBigboxs } = this.state
    const image = new Image()
    image.src = imagesCanvas
    if (cameraBigboxs.canvas) {
      image.onload = () => {
        const scale = this.getImageDisplayRatio(image)
        const camHeight = image.height * scale
        const panelHeight = document.querySelector('.views-top-panel').offsetHeight
        if (cameraBigboxs.fcanvas === null) {
          this.createFcanvases()
        }
        document.querySelector('.views-top-canvas').style.height = camHeight + 'px'
        document.querySelector('.views-bottom-list').style.height = panelHeight - camHeight - 10 + 'px'
        this.onWindowResize()
        const canvasImg = new fabric.Image(image, {
          scaleX: scale,
          scaleY: scale,
          hasBorders: false,
          hasControls: false,
          selectable: false,
          angle: 0,
          opacity: 1,
          hoverCursor: 'default',
        })
        canvasImg.rotate(isFlipped ? 180 : 0)
        cameraBigboxs.currentImage = canvasImg
        cameraBigboxs.fcanvas.insertAt(canvasImg, 0, true)
        this.updateLabels(label2d)
      }
    }
  }

  /*--关联列表--*/
  handleSelectRelation = (relation) => {
    const { relationsLabel } = this.state
    if (relation === relationsLabel) {
      this.setRectLabelByRelation(relation, 'remove')
    } else {
      this.setRectLabelByRelation(relation, 'add')
    }
  }
  getBoxByRelation = (relation) => {
    const { labels, rects } = this.state
    const labelName = relation.label.name
    const rectName = relation.rect.name
    let box = ['', '']
    labels.forEach((item) => {
      if (item.name === labelName) {
        box[0] = item
      }
    })
    rects.forEach((item) => {
      if (item.name === rectName) {
        box[1] = item
      }
    })
    return box
  }

  setInitList = () => {
    const { labels, rects, relations, cameraBigboxs } = this.state
    relations.forEach((item) => {
      labels.forEach((label) => {
        if (item.label.name === label.name) {
          label.visibleList = true
        }
      })
      rects.forEach((rect) => {
        if (item.rect.name === rect.name) {
          rect.visibleList = true
          rect.target.visible = item.label.visible
          // rect.recTitle.visible = rect.target.visible
        }
      })
    })
    this.setState({
      labels,
      rects
    })
    cameraBigboxs.fcanvas.requestRenderAll()
  }
  setRectLabelByRelation = (relation, flag) => {
    const { selectedLabel, editingLabel, cameraBigboxs } = this.state
    let boxRel = this.getBoxByRelation(relation)
    if (boxRel.length > 0 && boxRel[0] !== '' && boxRel[1] !== '') {
      if (flag === 'add') {
        if (selectedLabel) {
          this.checkedDisActiveLabel(selectedLabel)
        }
        if (editingLabel) {
          this.unhighlightLabel()
        }
        this.setState({
          editingLabel: boxRel[0],
          selectedLabel: boxRel[1],
          relationsLabel: relation,
          disabledFlag: true
        })
        this.highlightLabel(boxRel[0])
        this.checkedActiveLabel(boxRel[1])
      } else {
        this.unhighlightLabel()
        this.setState({
          editingLabel: null,
          selectedLabel: null,
          relationsLabel: null,
        })
        this.checkedDisActiveLabel(boxRel[1])
      }
      cameraBigboxs.fcanvas.requestRenderAll()
    }
  }
  setButtFlag = () => {
    const { relationsLabel } = this.state
    if (this.state.selectedLabel && this.state.editingLabel && !relationsLabel) {
      this.setState({
        disabledFlag: false
      })
    } else {
      this.setState({
        disabledFlag: true
      })
    }
  }

  handleAddRelation = () => {
    const { selectedLabel, editingLabel, relations } = this.state
    if (selectedLabel && editingLabel) {
      let relation = {
        id: uuid(),
        rect: {
          name: selectedLabel.name,
          type: selectedLabel.type,
          id: selectedLabel.id,
          visible: true
        },
        label: {
          id: editingLabel.userData.id,
          name: editingLabel.name,
          type: editingLabel.userData.type
        }
      }
      selectedLabel.visibleList = true
      editingLabel.visibleList = true
      relations.push(relation)
      this.setState({
        isEdited: true,
        editingLabel,
        selectedLabel,
        relations,
        disabledFlag: true,
        relationsLabel: relation
      })
    }
  }
  handleKeyAddRelation = () => {
    const { disabledFlag } = this.state
    if (!disabledFlag) {
      this.handleAddRelation()
    }
  }
  handleKeyRemoveRelation = (e) => {
    const { relationsLabel } = this.state
    if (relationsLabel) {
      this.handleRemoveLabel(relationsLabel, e)
    }
  }
  handleMiddleLabelVisibility = (e, relation) => {
    let boxRel = this.getBoxByRelation(relation)
    if (boxRel[1] !== '') {
      relation.rect.visible = !relation.rect.visible
      this.handleToggleLabelVisibility(e, boxRel[1])
    }
  }
  handleRemoveLabel = (relation, e) => {
    const { relations, relationsLabel, selectedLabel, editingLabel } = this.state
    let boxRel = this.getBoxByRelation(relation)
    if (boxRel[0] !== '' && boxRel[1] !== '') {
      boxRel[0].visibleList = false
      boxRel[1].visibleList = false
    }
    relations.splice(relations.indexOf(relation), 1)
    if (relationsLabel === relation) {
      this.setState({
        disabledFlag: false,
        relationsLabel: null
      })
    }
    this.setState({
      isEdited: true,
    })
    e.stopPropagation()
  }
  scaleToFit2d = () => {
    this.setZoom(100)
    this.setCenter()
    // this.handleResetCamera(MAIN)
  }

  scaleToFit3d = () => {
    // this.setZoom(100)
    // this.setCenter()
    this.handleResetCamera(MAIN)
  }
  handleResetCamera = viewName => {
    this.views[viewName].mapControl.reset()
  }
  setCenter = () => {
    const { cameraBigboxs } = this.state
    const pointer = new fabric.Point(0, 0)
    cameraBigboxs.fcanvas.absolutePan(pointer)
  }
  handleFlipImage = () => {
    const { cameraBigboxs, isFlipped } = this.state
    cameraBigboxs.currentImage.rotate(isFlipped ? 0 : 180)
    cameraBigboxs.currentImage.setCoords()
    cameraBigboxs.fcanvas.requestRenderAll()
    this.setState({ isFlipped: !isFlipped })
  }
  handleFillLabel = () => {
    const { hasFill, cameraBigboxs } = this.state

    this.setState({ hasFill: !hasFill }, () => {
      cameraBigboxs.fcanvas.getObjects().forEach((object, index) => {
        index && object.set({ fill: this.getFillColor(object.fill) })
      })

      cameraBigboxs.fcanvas.requestRenderAll()
    })
  }
  handleToggleHelpers = () => {
    const { helpersShowing } = this.state

    this.helpers.forEach(helper =>
      helpersShowing ? this.scene.remove(helper) : this.scene.add(helper)
    )

    this.setState({ helpersShowing: !helpersShowing })
  }
  render() {
    const {
      currentFrame,
      totalFrames,
      labels,
      firstLoading,
      isEdited,
      noLabels,
      pointSize,
      selectedLabel,
      editingLabel,
      rects,
      cameraBigboxs,
      relations,
      relationsLabel,
      disabledFlag,
      isFlipped,
      hasFill,
      helpersShowing,
      fromURL
    } = this.state
    const { user, frames, location, t, getSome } = this.props

    const recordData = {
      ...getSome,
      firstFrameIndex: fromURL.firstFrameIndex,
      id: fromURL.id
    }

    const frame = frames[currentFrame - 1]
    let dataURL = ''
    let openState = false
    if (frame) {
      const timestamp = frame.timestamp
      const { bagName, startTimestamp, status } = (this.state.fromURL.isUrl) ? this.state.fromURL : location.state.record
      const time = bagName.split('-')[1]

      const dataPath = `/${time.substring(0, 4)}/${time.substring(
        4,
        6
      )}/${time.substring(
        6,
        8
      )}/${bagName}/Object/${startTimestamp}/${timestamp}.txt`

      dataURL = OPEN_BASE_URL + dataPath
      openState = status === 'objects_ready'
    }
    return (
      <div className="views">
        <div className="views-top-panel">
          <div className="views-left-panel">
            <div className="views-top-canvas">
              <div className="views-main-2d" ref={el => (cameraBigboxs.canvasRoot = el)}>
                <canvas ref={canvas => (cameraBigboxs.canvas = canvas)} />
                <div className="views-statis-counter">{`${relations.length}/${rects.length}`}</div>
              </div>
              <div
                className="views-main-3d"
                ref={container => (this.views[MAIN].container = container)}
              >
                <div className="views-statis-counter views-counter-right">{`${relations.length}/${labels.length}`}</div>
              </div>
            </div>
            <div className="views-bottom-list">
              <div className="views-list-2d">
                <LeftRect
                  rects={rects}
                  selectedLabel={selectedLabel}
                  handleSelectRect={this.handleSelectRect}
                  handleToggleLabelVisibility={this.handleToggleLabelVisibility}
                />
              </div>
              <div className="views-list-relation">
                <MiddleList
                  relations={relations}
                  relationsLabel={relationsLabel}
                  disabledFlag={disabledFlag}
                  handleSelectRelation={this.handleSelectRelation}
                  handleRemoveLabel={this.handleRemoveLabel}
                  handleMiddleLabelVisibility={this.handleMiddleLabelVisibility}
                  handleAddRelation={this.handleAddRelation}
                />
              </div>
              <div className="views-list-3d">
                <RightLabel
                  labels={labels}
                  editingLabel={editingLabel}
                  handleSelectLabel={this.handleSelectLabel}
                />
              </div>
            </div>
          </div>
          <div className="views-right-panel" style={{ width: '290px' }}>
            <div className="views-panel-inner">
              <Tabs type="card">
                <TabPane tab={t('settingsTab')} key="1">
                  <div className="views-shortcuts-row">
                    <div className="views-shortcuts-command">{t('sc.fit2d')}</div>
                    <div className="views-shortcuts-keybinding">
                      <IconButton onClick={this.scaleToFit2d}>
                        <i className="viewer2-icon-fit" />
                      </IconButton>
                    </div>
                  </div>
                  <div className="views-shortcuts-row">
                    <div className="views-shortcuts-command">{t('sc.fit3d')}</div>
                    <div className="views-shortcuts-keybinding">
                      <IconButton onClick={this.scaleToFit3d}>
                        <i className="viewer2-icon-fit" />
                      </IconButton>
                    </div>
                  </div>
                  <div className="views-shortcuts-row">
                    <div className="views-shortcuts-command">{t('sc.flip')}</div>
                    <div className="views-shortcuts-keybinding">
                      <IconButton
                        type="sync"
                        active={isFlipped}
                        iconStyle={toolIconStyle}
                        onClick={this.handleFlipImage}
                      />
                    </div>
                  </div>
                  <div className="views-shortcuts-row">
                    <div className="views-shortcuts-command">{t('sc.fill')}</div>
                    <div className="views-shortcuts-keybinding">
                      <IconButton
                        type="border-outer"
                        active={hasFill}
                        iconStyle={toolIconStyle}
                        onClick={this.handleFillLabel}
                      />
                    </div>
                  </div>
                  <div className="views-shortcuts-row">
                    <div className="views-shortcuts-command">{t('sc.helpers')}</div>
                    <div className="views-shortcuts-keybinding">
                      <IconButton
                        type="table"
                        active={helpersShowing}
                        iconStyle={toolIconStyle}
                        onClick={this.handleToggleHelpers}
                      />
                    </div>
                  </div>
                  <div className="ant-tabpane-wrapper">
                    <div className="views-settings">
                      <div className="views-setting-row">
                        <div className="views-setting-title">
                          {t('settings.pcd')}
                        </div>
                      </div>
                      <div className="views-setting-row">
                        <div className="views-setting-head">
                          {t('settings.pointSize')}
                        </div>
                        <div className="views-setting-control">
                          <Slider
                            value={pointSize}
                            step={0.01}
                            min={0.5}
                            max={2}
                            marks={{
                              0.5: {
                                style: sliderLabelStyle,
                                label: '0.5'
                              },
                              2: {
                                style: sliderLabelStyle,
                                label: '2'
                              }
                            }}
                            onChange={this.handleChangePointSize}
                          />
                        </div>
                      </div>
                      <Divider style={{ margin: '1.2rem 0' }} />
                      <div className="views-setting-row">
                        <div className="views-setting-title">
                          {t('settings.frameState')}
                        </div>
                      </div>
                      <div className="views-setting-row">
                        <FrameInfo
                          frame={frame}
                          // record={location.state.record}
                          record={recordData}
                        />
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab={t('shortcutsTab')} key="2">
                  <div className="ant-tabpane-wrapper">
                    <Shortcuts user={user} />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="views-bottom-panel">
          <div className="views-frames-slider">
            <div className="views-slider-bar">
              <Slider
                value={typeof currentFrame === 'number' ? currentFrame : 1}
                min={1}
                max={totalFrames}
                onChange={this.handleInputFrame}
              />
            </div>
            <div className="views-slider-input">
              <InputNumber
                value={currentFrame}
                min={1}
                max={totalFrames}
                size="small"
                style={{ marginLeft: '1rem' }}
                onChange={this.handleInputFrame}
              />
              <span className="views-slider-input-frames">/ {totalFrames}</span>
            </div>
          </div>
          <Divider type="vertical" />
          <div className="views-frames-actions">
            <div className="views-frames-action">
              <Checkbox
                checked={noLabels && !relations.length}
                disabled={!!relations.length}
                onChange={this.handleChangeNoLabels}
              >
                {t('noLabels')}
              </Checkbox>
            </div>
            <div className="views-frames-action">
              <Button
                type="primary"
                size="small"
                onClick={this.handleSave}
                disabled={!isEdited}
              >
                {t('btns.save')}
              </Button>
            </div>
          </div>
          {user.role === role.admin ? (
            <>
              <Divider type="vertical" />
              <div style={{ pointerEvents: openState ? 'auto' : 'none' }}>
                <div className="views-frames-actions">
                  <div className="views-frames-action">
                    <Tooltip title={dataURL}>
                      <CopyToClipboard text={dataURL}>
                        <Button
                          type="primary"
                          size="small"
                          disabled={!openState}
                          onClick={this.handleCopyPath}
                        >
                          .txt
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <LoadingCover loading={firstLoading} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    bag: state.bag.curBag,
    frames: state.annotation.frames.sort(
      (frameA, frameB) => frameA.timestamp - frameB.timestamp
    ),
    objects: state.annotation.objects.sort(
      (objectA, objectB) => objectA.id - objectB.id
    ),
    objId: state.annotation.objId,
    message: state.alert.message,
    getSome: state.getSome,
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation('views')(Viewer2D3Drelation))
)
