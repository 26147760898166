import React, { Component } from 'react'
import './DevHome.scss'

class DevHome extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div className="dev">Dev</div>
  }
}

export default DevHome
