import React from 'react'
import './LabelType.scss'

const LabelType = ({ color }) => (
  <span className="label-type" style={{ backgroundColor: color }}>
    x
  </span>
)

export default LabelType
