import React from 'react'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Input, InputNumber, Button } from 'antd'

const FrameForm = ({ form, onSubmit, formItemLayout, tailFormItemLayout }) => {
  const { getFieldDecorator, validateFields } = form

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((error, values) => {
      if (!error) {
        onSubmit(values)
      }
    })
  }

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label="Bag ID">
        {getFieldDecorator('bag_id', {
          rules: [{ required: true, message: 'Bag ID can not be empty' }]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="Timestamp">
        {getFieldDecorator('timestamp', {
          rules: [{ required: true, message: 'Timestamp can not be empty' }]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item label="Object ID">
        {getFieldDecorator('annotationobjects_id', {
          rules: [
            { required: true, message: 'Annotation object ID can not be empty' }
          ]
        })(<InputNumber />)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Create frame
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'frame form' })(FrameForm)
