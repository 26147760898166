import React, { Component } from 'react'
import './CameraCanvas.scss'
import { fabric } from 'fabric'
import { withTranslation } from 'react-i18next'
import {createRect,scaledBoundingRect} from './rect'

function getUVFromVector3(vec, projectionMatrix) {
  const result = vec.applyMatrix4(projectionMatrix)
  return {
    x: result.x / result.z,
    y: result.y / result.z,
    z: result.z
  }
}
function getBoxMiddlePoint(mesh) {
  const position = mesh.position
  const rotation = mesh.rotation._z
  const dimension = mesh.userData.dimension
  const carx = position.x+0.5*dimension.x*Math.cos(rotation)
  const cary = position.y+0.5*dimension.x*Math.sin(rotation)
  const carz = position.z+0.5*dimension.z
  return [new THREE.Vector3(carx,cary,carz)]
}
function getCornerPosFromBox(mesh) {
  mesh.geometry.computeBoundingBox()
  const boundingBox = mesh.geometry.boundingBox.clone()
  mesh.updateMatrixWorld(true)
  const { min, max } = boundingBox.applyMatrix4(mesh.matrixWorld)
  return [
    new THREE.Vector3(min.x, min.y, min.z),
    new THREE.Vector3(min.x, min.y, max.z),
    new THREE.Vector3(min.x, max.y, min.z),
    new THREE.Vector3(min.x, max.y, max.z),
    new THREE.Vector3(max.x, min.y, min.z),
    new THREE.Vector3(max.x, min.y, max.z),
    new THREE.Vector3(max.x, max.y, min.z),
    new THREE.Vector3(max.x, max.y, max.z),
    ...getBoxMiddlePoint(mesh)
  ]
}
class CameraCanvas extends Component{
  constructor(props){
    super(props)
    this.state = {
      tabTitle:[],
      itemIndex:0,
      cameraBigboxs:{
        name: 'camera_A',
        canvas: null,
        fcanvas: null,
        currentImage: null,
      },
      imagesCamera:[],
      cameraboxs:[],
      imageTopheight:0,
      bigRects:[]
    }
  }
  componentDidMount(){
    this.props.onRef && this.props.onRef(this)
  }
  componentWillUnmount(){
    this.setState = (state,callback)=>{
      return
    }
  }

  createFcanvases = (cameraBox,camScale) => {
    cameraBox.fcanvas = new fabric.Canvas(cameraBox.canvas, {
      preserveObjectStacking: true,
      selection: false,
      selectionColor: 'transparent',
      selectionBorderColor: 'transparent',
      backgroundColor: 'transparent',
      defaultCursor: 'default',
      stopContextMenu: true,
    })
    cameraBox.fcanvas.setZoom(camScale)
    cameraBox.fcanvas.on({
      'mouse:wheel': e => this.handleMouseWheel(cameraBox,e,camScale),
    })
  }
  handleMouseWheel = (cameraBox,opt,camScale) => {
    opt.e.preventDefault()
    opt.e.stopPropagation()

    const fcanvas = cameraBox.fcanvas

    const delta = opt.e.deltaY
    let zoomRatio = fcanvas.getZoom()

    zoomRatio = delta > 0 ? zoomRatio - 0.05 : zoomRatio + 0.05
    if (zoomRatio >= camScale && zoomRatio <= 1) {
      fcanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoomRatio)
    }

    const vpt = fcanvas.viewportTransform

    if (delta > 0) {
      // left
      if (vpt[4] >= 0) {
        fcanvas.viewportTransform[4] = 0
      }

      // right
      const rightBoundaryOffset =
        fcanvas.getWidth() - this.imageWidth * fcanvas.getZoom()
      if (vpt[4] <= rightBoundaryOffset) {
        fcanvas.viewportTransform[4] = rightBoundaryOffset
      }

      // top
      if (vpt[5] >= 0) {
        fcanvas.viewportTransform[5] = 0
      }

      // bottom
      const bottomBoundaryOffset =
        fcanvas.getHeight() - this.imageHeight * fcanvas.getZoom()
      if (vpt[5] <= bottomBoundaryOffset) {
        fcanvas.viewportTransform[5] = bottomBoundaryOffset
      }
    }
  }

  initCanvas = (cameraProp) => {
    console.log(cameraProp)
    const tabTitle = []
    cameraProp.forEach((item,index)=>{
      tabTitle.push({
        title:item,
        exitFlag:false
      })
    })
    this.setState({
      tabTitle:tabTitle
    })
  }
  initAddImg = (imagesCanvas) => {
    const { cameraBigboxs,itemIndex} = this.state
    if(imagesCanvas&&imagesCanvas.length>0){
      this.addCameraImg(cameraBigboxs,imagesCanvas[itemIndex])
      this.setState({
        imagesCamera:imagesCanvas
      })
    }
  }
  addCameraImg = (cameraBox,imagesCanvas) => {
    const imgWidth = 490
    const image = new Image()
    image.src = imagesCanvas
    image.onload = () => {
      if(cameraBox.canvas){
        const scale = imgWidth / image.width
        const camHeight = image.height * scale

        this.imageWidth = image.width
        this.imageHeight = image.height
        cameraBox.canvas.width = imgWidth
        cameraBox.canvas.height = camHeight
        if(cameraBox.fcanvas === null){
          this.createFcanvases(cameraBox,scale)
        }
        const canvasImg = new fabric.Image(image, {
          hasBorders: false,
          hasControls: false,
          selectable: false,
          angle: 0,
          opacity: 1,
          hoverCursor: 'default',
        })
        cameraBox.currentImage = canvasImg
        cameraBox.fcanvas.insertAt(canvasImg, 0, true)
      }
    }
  }

  createRectFromLabel = (label) => {
    const {projectionMatrixs} = this.props.viewData
    const {cameraBigboxs,itemIndex,bigRects } = this.state
    const points3D = getCornerPosFromBox(label)
    const cube = []
    projectionMatrixs.forEach((item,index)=>{
      let points2D = points3D.map(point =>{
        return getUVFromVector3(point.clone(), item)
      })
      let positiveNum = points2D.every((item)=>{
         return item.z>0
      })
      let pictureRange = points2D.every((item)=>{
        return item.x<0&&item.x>this.imageWidth&&item.y<0&&item.y>this.imageHeight
      })
      if(positiveNum&&!pictureRange){
        cube.push(createRect(scaledBoundingRect(points2D), label.userData.type))
      }else{
        let poin2DtNo = points2D.map((item) => {
          return {x:0,y:0}
        })
        points2D = poin2DtNo
        cube.push(createRect(scaledBoundingRect(points2D), label.userData.type))
      }
    })
    cameraBigboxs.fcanvas.remove(bigRects[itemIndex])
    this.setState({
      bigRects:cube
    },()=>{
      if(this.state.bigRects.length>0){
        this.addRect()
      }
      this.tabBorderFun()
    })
  }
  tabBorderFun = () => {
    const {bigRects,tabTitle } = this.state
    let taborder = tabTitle
    if(bigRects.length>0){
      bigRects.forEach((item,index) => {
        let itemRight = item.left+item.width
        let itemBottom = item.top+item.height
        if(itemRight < 0 || item.left > this.imageWidth || itemBottom < 0 || item.top > this.imageHeight||item.width===0){
          taborder[index].exitFlag = false
        }else{
          taborder[index].exitFlag = true
        }
      })
    }
    this.setState({
      tabTitle:taborder
    })
  }
  // 去掉边框
  deActiveBorder = () =>{
    const {tabTitle } = this.state
    let taborder = tabTitle
    if(taborder.length>0){
      taborder.map((item) => {
        return item.exitFlag = false
      })
    }
    console.log(taborder)
    this.setState({
      tabTitle:taborder
    })
  }
  updateRect = label => {
    this.createRectFromLabel(label)
    this.handleNoSelectable()
  }
  handleNoSelectable = () => {
    const { cameraBigboxs } = this.state
    cameraBigboxs.fcanvas.getObjects().forEach((klass)=>{
      klass.selectable = false
    })
  }
  addRect = (label) => {
    const { cameraBigboxs,itemIndex,bigRects } = this.state
    cameraBigboxs.fcanvas.add(bigRects[itemIndex])
    this.handleNoSelectable()
  }

  handleClickImg=(index)=>{
    const {cameraBigboxs,bigRects,imagesCamera} = this.state
    this.setState({
      itemIndex:index,
    })
    console.log(bigRects)
    const bigFcanvas = cameraBigboxs.fcanvas
    cameraBigboxs.fcanvas.clear()
    const image = new Image()
    image.src = imagesCamera[index]
    image.onload = () => {
      const canvasImg = new fabric.Image(image, {
        hasBorders: false,
        hasControls: false,
        selectable: false,
        angle: 0,
        opacity: 1,
        hoverCursor: 'default',
      })
      cameraBigboxs.currentImage = canvasImg
      bigFcanvas.insertAt(canvasImg, 0, true)
      if(bigRects.length>0){
        bigFcanvas.add(bigRects[index])
        this.handleNoSelectable()
      }
    }
  }

  deleteRect = () => {
    const { cameraBigboxs,bigRects } = this.state
    this.deActiveBorder()
    bigRects.forEach((item,index )=> {
      cameraBigboxs.fcanvas.remove(bigRects[index])
    })
    cameraBigboxs.fcanvas.renderAll()
    this.setState({
      bigRects:[]
    })
  }
  render() {
    const {itemIndex,cameraBigboxs,tabTitle} = this.state
    const {t} = this.props
    const {cameraProp,cameraShowing} = this.props.viewData
    return(
      <div className="views-images-box"  style={{ visibility: cameraShowing ? 'visible' : 'hidden' }}>
        {
          cameraProp.length>0 ? (
          <div className="views-camerabox">
            <div className="views-camerabox-top">
              <canvas ref={canvas => (cameraBigboxs.canvas = canvas)}/>
            </div>
            <div className="views-camerabox-tabs">
              {
                tabTitle.map((item,index)=>{
                  return (
                    <span key={index}  className={[item.exitFlag?'exitBorder':'',itemIndex===index?'tab-active':''].join(' ')} onClick={this.handleClickImg.bind(this,index)}>{item.title}</span>
                  )
                })
              }
            </div>
          </div>
          ) : (
            <div className="views-image-missing">{t('missingImage')}</div>
          )
        }
      </div>
    )
  }
}

export default withTranslation('views')(CameraCanvas)

