import React from 'react'
import './NotAuth.scss'
import { withTranslation } from 'react-i18next'

import { StopOutlined } from '@ant-design/icons'

const Unauth = ({ t }) => {
  return (
    <div className="na">
      <StopOutlined />
      <h1 className="na-content">{t('content')}</h1>
    </div>
  )
}

export default withTranslation('notAuth')(Unauth)
