import React from 'react'
import './LangButton.scss'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { localeActions } from '../../_actions'

const LangButton = ({ i18n, dispatch }) => {
  const handleChangeLang = lang => {
    i18n.changeLanguage(lang)
    dispatch(localeActions.changeLang(lang))
  }

  return (
    <div className="lang-row">
      <button className="lang-btn" onClick={() => handleChangeLang('en')}>
        En
      </button>
      /
      <button className="lang-btn" onClick={() => handleChangeLang('zh')}>
        中
      </button>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    // lang: state.locale.lang
  }
}

export default connect(mapStateToProps)(withTranslation()(LangButton))
