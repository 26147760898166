import React, { Component } from 'react'
import './ExamHome.scss'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { Button } from 'antd'

class AnnotHome extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="examhome">
        <div className="examhome-annotation">
          <Link to="/annotations">
            <Button type="primary" size="small" ghost>
              {t('btns.allAnnotations')}
            </Button>
          </Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    annotations: state.annotation.annotations
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation('annotHome')(AnnotHome))
)
