import React, { Component } from 'react'
import './Statistics.scss'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import { userActions, annotationActions, groupActions, alertActions } from '../_actions'
import { getFilteredUsers } from '../_utils'

import { FolderOutlined, SearchOutlined } from '@ant-design/icons'
import { WorkChart } from './components'
import {
  Form,
  Row,
  Col,
  Table,
  DatePicker,
  Button,
  Tag,
  Statistic,
  Input,
  ConfigProvider,
  Select,
  Spin,
  message
} from 'antd'

const { Column } = Table
const { RangePicker } = DatePicker
const { Option } = Select

const TYPE = {
  // '3D': '3d',
  // '2D': '2d',
  // SIGNAL: 'signal',
  // TRACKING_3D: '3d_tracking',
  // TRACKING_2D: '2d_tracking',
  '2D': '2d',
  '3D': '3d',
  TRACKING_3D: '3d_tracking',
  TRACKING_2D: '2d_tracking',
}
const PageSize = 10

const InitPagination = {
  current: 1,
  pageSize: PageSize,
  total: 0,
  showQuickJumper: true
}

class Statistics extends Component {
  state = {
    user: null,
    dates: [moment().subtract(7, 'days').startOf('day'), moment()],
    filteredAnnotations: [],
    statDays: [],
    allannotators: [],
    allexaminers: [],
    annotators: [],
    examiners: [],
    searchText: '',
    selectedTypes: '',
    iNum: 0,
    tNum: 0,
    oNum: 0,
    fNum: 0,
    correctRate: 0,
    annotatorAddCount: 0,
    examinerAddCount: 0,
    examinerExamCount: 0,
    annotatorWorkload: 0,
    examinerWorkload: 0,
    pagination:InitPagination,
  }

  componentDidMount() {
    const { dispatch } = this.props
    const { user, dates, selectedAnnotators, selectedExaminers, selectedAnnotatorsGroups, selectedExaminersGroups, selectedTypes, selectedTags ,pagination} = this.state

    const data = {
      annotator_id: selectedAnnotators,
      examiner_id: selectedExaminers,
      startdate: dates[0] ? dates[0].startOf('day').unix() : null,
      finishdate: dates[1] ? dates[1].endOf('day').unix() : null,
      page: pagination.current,
      pagesize: pagination.pageSize,
      annotator_group_id: selectedAnnotatorsGroups,
      examiner_group_id: selectedExaminersGroups,
      type: selectedTypes ? selectedTypes : null,
      tag: selectedTags ? selectedTags : null,
      // annotator_id: user === null ? 0 : user.id,
      // finishdate_start: dates[0].startOf('month').unix(),
      // finishdate_end: dates[1].startOf('month').unix()
    }

    dispatch(userActions.getAll(1, Number.MAX_SAFE_INTEGER)).then(() => {
      this.setState({
        allannotators: getFilteredUsers(this.props.users, 'annotator', true),
        annotators: getFilteredUsers(this.props.users, 'annotator', true),
        allexaminers: getFilteredUsers(this.props.users, 'examiner', true),
        examiners: getFilteredUsers(this.props.users, 'examiner', true)
      })
    })
    dispatch(annotationActions.getAllTags(1, Number.MAX_SAFE_INTEGER))
    dispatch(groupActions.getAll(1, Number.MAX_SAFE_INTEGER))
    dispatch(annotationActions.getStat(
      data
      // {annotator_id: "1837",
      // examiner_id: "1838",
      // finishdate_start: "1",
      // finishdate_end: "1632131310401",
      // page: "1",
      // pagesize: "10",
      // annotator_group_id: "0",
      // examiner_group_id: "0",
      // type: ["3d_tracking"],
      // tag: ["白天"]}
    )).then(() => {
      const { statSum, statDays ,totalCount } = this.props
      this.setState({
        iNum: statSum.i||0,
        tNum: statSum.t||0,
        oNum: statSum.o||0,
        fNum: statSum.frames||0,
        count:statSum.count||0,
        correctRate: this.toPercent(statSum.annotatorAccuracy)||0,
        annotatorAddCount: statSum.annotatorBuildCount||0,
        examinerAddCount: statSum.examinerBuildCount||0,
        examinerExamCount: statSum.examinerExamCount||0,
        annotatorWorkload: statSum.annotatorWork||0,
        examinerWorkload: statSum.examinerWork||0,
        pagination:{...pagination,total:totalCount}
      })
      pagination.total =totalCount
      this.setState({ filteredAnnotations: this.props.stats })
      this.setState({
        statDays: statDays.map(item => {
          return {
            date: moment.unix(parseInt(item.finishdate)).format('MM-DD'),
            annotatorWork: item.annotatorWork || 0,
            examinerWork: item.examinerWork || 0
          }
        })
      })
    })
    // dispatch(annotationActions.getStatSum(
    //   data
    //   //{ annotator_id: "1837",
    //   // examiner_id: "1838",
    //   // finishdate_start: "1",
    //   // finishdate_end: "1632131310401",
    //   // page: "1",
    //   // pagesize: "10",
    //   // annotator_group_id: "0",
    //   // examiner_group_id: "0",
    //   // type: ["3d_tracking"],
    //   // tag: ["白天"]}
    // )).then(() => {
    //   const { statSum } = this.props
    //   this.setState({
    //     iNum: statSum.i,
    //     tNum: statSum.t,
    //     oNum: statSum.o,
    //     fNum: statSum.frame,
    //     correctRate: this.toPercent(statSum.accuracy),
    //     annotatorAddCount: statSum.annotatorAddCount,
    //     examinerAddCount: statSum.examinerAddCount,
    //     examinerExamCount: statSum.examinerExamCount,
    //     annotatorWorkload: statSum.annotatorWorkload,
    //     examinerWorkload: statSum.annotatorWorkload,
    //   })
    // })

  }

  componentDidUpdate(prevProps) {
    const { annotations } = this.props
    if (annotations !== prevProps.annotations) {
      this.setFilteredAnnotations()
    }
  }


  toPercent = (point) => {
    if (!point)
      return null
    let str = Number(point * 100).toFixed(2)
    str += "%"
    return str
  }

  handleInquery = (type) => {
    const { user, dates, selectedAnnotators, selectedExaminers, selectedAnnotatorsGroups, selectedExaminersGroups, selectedTypes, selectedTags ,pagination} = this.state
    const { dispatch, t } = this.props

    const data = {
      annotator_id: selectedAnnotators,
      examiner_id: selectedExaminers,
      startdate: dates[0].startOf('day').unix(),
      finishdate: dates[1].endOf('day').unix(),
      page: 1,
      pagesize: pagination.pageSize,
      annotator_group_id: selectedAnnotatorsGroups,
      examiner_group_id: selectedExaminersGroups,
      type: selectedTypes ? selectedTypes : null,
      tag: selectedTags ? selectedTags : null,
      // annotator_id: user === null ? 0 : user.id,
      // finishdate_start: dates[0].startOf('month').unix(),
      // finishdate_end: dates[1].startOf('month').unix()
    }

    if (type == 'query') {
      pagination.current=1
      dispatch(annotationActions.getStat(data)).then(() => {
        const { statSum, statDays ,totalCount} = this.props
        this.setState({
          iNum: statSum.i||0,
          tNum: statSum.t||0,
          oNum: statSum.o||0,
          fNum: statSum.frames||0,
          count:statSum.count||0,
          correctRate: this.toPercent(statSum.annotatorAccuracy)||0,
          annotatorAddCount: statSum.annotatorBuildCount||0,
          examinerAddCount: statSum.examinerBuildCount||0,
          examinerExamCount: statSum.examinerExamCount||0,
          annotatorWorkload: statSum.annotatorWork||0,
          examinerWorkload: statSum.examinerWork||0,
        })
        this.setState({ filteredAnnotations: this.props.stats })
        pagination.total =totalCount
        // let newStatDays = statDays.map(item => {
        //   return {
        //     date: moment.unix(parseInt(item.finishdate)).format('MM-DD'),
        //     annotatorWork: item.annotatorWork,
        //     examinerWork: item.examinerWork
        //   }
        // })
        this.setState({
          statDays: statDays.map(item => {
            return {
              date: moment.unix(parseInt(item.finishdate)).format('MM-DD'),
              annotatorWork: item.annotatorWork || 0,
              examinerWork: item.examinerWork || 0
            }
          })
        })
      })
    } else if (type == 'calculation') {
      dispatch(annotationActions.calcuStats(data)).then(() => {
        // dispatch(
        //   alertActions.success({
        //     type: 'success',
        //     detail: t(`msg.calculateSuccessful`)
        //   })
        // )
        message.success('最新标注数据已统计')
      })
      .catch(()=>{
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t(`msg.calculateSuccessful`)
          })
        )
      })
    }

  }

  handleSelectRow = (selectedRowKeys, selectedRows) => {
    this.setState({
      user: selectedRows[0]
    })
  }

  handleChangeDates = value => {
    this.setState({ dates: value })
  }

  handleChangeTypes = value => {
    this.setState(
      {
        selectedTypes: value
      }
      // ,
      // () => {
      //   this.setFilteredAnnotations()
      // }
    )
  }

  handleChangeTags = value => {
    this.setState({ selectedTags: value })
  }

  handleChangeAnnotatorGroups = value => {
    if (!value) {
      this.setState({ selectedAnnotatorsGroups: value, annotators: getFilteredUsers(this.props.users, 'annotator', true), })
    } else {
      this.setState({
        selectedAnnotatorsGroups: value,
        annotators: this.state.allannotators.filter(annotator => annotator.groupId == value)
      })
    }
  }

  handleChangeAnnotators = value => {
    this.setState({ selectedAnnotators: value })
  }

  handleChangeExaminerGroups = value => {
    if (!value) {
      this.setState({ selectedExaminersGroups: value, examiners: getFilteredUsers(this.props.users, 'examiner', true), })
    } else {
      this.setState({
        selectedExaminersGroups: value,
        examiners: this.state.allexaminers.filter(examiner => examiner.groupId == value)
      })
    }
  }

  handleChangeExaminers = value => {
    this.setState({ selectedExaminers: value })
  }

  setFilteredAnnotations = () => {
    const { selectedTypes } = this.state
    const { annotations } = this.props

    this.setState({
      filteredAnnotations: annotations.filter(
        annotation =>
          selectedTypes.includes(annotation.type)
      )
    })
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { dispatch, t } = this.props
    const { user, dates, selectedAnnotators, selectedExaminers, selectedAnnotatorsGroups, selectedExaminersGroups, selectedTypes, selectedTags } = this.state

    const data = {
      annotator_id: selectedAnnotators,
      examiner_id: selectedExaminers,
      startdate: dates[0].startOf('day').unix(),
      finishdate: dates[1].endOf('day').unix(),
      page: pagination.current,
      pagesize: pagination.pageSize,
      annotator_group_id: selectedAnnotatorsGroups,
      examiner_group_id: selectedExaminersGroups,
      type: selectedTypes ? selectedTypes : null,
      tag: selectedTags ? selectedTags : null,
      // annotator_id: user === null ? 0 : user.id,
      // finishdate_start: dates[0].startOf('month').unix(),
      // finishdate_end: dates[1].startOf('month').unix()
    }

      dispatch(annotationActions.getStat(data)).then(() => {
        const { statSum, statDays ,totalCount} = this.props
        this.setState({
          iNum: statSum.i||0,
          tNum: statSum.t||0,
          oNum: statSum.o||0,
          fNum: statSum.frames||0,
          count:statSum.count||0,
          correctRate: this.toPercent(statSum.annotatorAccuracy)||0,
          annotatorAddCount: statSum.annotatorBuildCount||0,
          examinerAddCount: statSum.examinerBuildCount||0,
          examinerExamCount: statSum.examinerExamCount||0,
          annotatorWorkload: statSum.annotatorWork||0,
          examinerWorkload: statSum.examinerWork||0,
        })
        this.setState({ filteredAnnotations: this.props.stats })
        pagination.total =totalCount
        // let newStatDays = statDays.map(item => {
        //   return {
        //     date: moment.unix(parseInt(item.finishdate)).format('MM-DD'),
        //     annotatorWork: item.annotatorWork,
        //     examinerWork: item.examinerWork
        //   }
        // })
        this.setState({
          statDays: statDays.map(item => {
            return {
              date: moment.unix(parseInt(item.finishdate)).format('MM-DD'),
              annotatorWork: item.annotatorWork || 0,
              examinerWork: item.examinerWork || 0
            }
          })
        })
      })
    this.setState({ pagination })
  }

  getColumnSearchProps = dataIndex => {
    const { t } = this.props

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div style={{ padding: '0.5rem' }}>
          <Input
            ref={node => {
              this.searchInput = node
            }}
            placeholder={t('searchPlhd')}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: '0.5rem', display: 'block' }}
          />
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {t('btns.reset')}
          </Button>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {t('btns.search')}
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <SearchOutlined
          style={{
            width: '2rem',
            fontSize: '0.9rem',
            color: filtered ? '#138D75' : undefined
          }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select())
        }
      },
      render: text => text.toString()
    }
  }

  render() {
    const {
      user,
      dates,
      filteredAnnotations,
      selectedTypes,
      iNum,
      tNum,
      oNum,
      fNum,
      correctRate,
      annotatorAddCount,
      examinerAddCount,
      examinerExamCount,
      annotatorWorkload,
      examinerWorkload,
      annotators,
      examiners,
      statDays,
      count,
      pagination
    } = this.state
    const { users, gettingAnnotations, t, stats, tags, groups } = this.props
    const realTags = () => {
      var newArr = []
      var arrId = []
      for (var item of tags) {
        if (arrId.indexOf(item.tag) == -1) {
          arrId.push(item.tag)
          newArr.push(item)
        }
      }
      return newArr
    }
    const rowSelection = {
      type: 'radio',
      columnWidth: '30px',
      onChange: this.handleSelectRow
    }

    const customizeRenderUsersEmpty = () => (
      <div className="users-empty">
        <FolderOutlined style={{ fontSize: '2rem', color: '#797979' }} />
        <p>{t('noAnnotators')}</p>
      </div>
    )

    const customizeRenderAnnotationsEmpty = () => (
      <div className="annotations-empty">
        <FolderOutlined style={{ fontSize: '2rem', color: '#797979' }} />
        <p>{t('noRecords')}</p>
      </div>
    )
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: t('type'),
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: t('time'),
        dataIndex: 'time',
        key: 'time',
      },
      {
        title: t('taskDetails'),
        key: 'taskDetails',
        render: (text, record) => {
          return <div className="taskDetails">
            <span>{t('framesNum')}：{record.fNum}</span>
            <span>{t('boxesNum')}：{record.oNum}</span>
          </div>
        },
      },
      {
        title: t('annotator'),
        key: 'annotator',
        render: (text, record) => {
          return <div className="taskDetails">
            <span>{record.annotatorName}({record.annotatorGroup})</span>
            <span>{t('sumitBoxes')}：{record.iNum}</span>
            <span>{t('validBoxes')}：{record.annotatorBuild}</span>
            <span>{t('workload')}:{record.annotatorworkload}</span>
          </div>
        },
      },
      {
        title: t('examiner'),
        key: 'examiner',
        render: (text, record) => {
          return <div className="taskDetails">
            <span>{record.examinerName}({record.examinerGroup})</span>
            <span>{t('fillBoxes')}：{record.examinerBuild}</span>
            <span>{t('adjustBoxes')}：{record.examinerReviewFrame}</span>
            <span>{t('workload')}:{record.examinerworkload}</span>
          </div>
        },
      },
    ]

    return (
      <>
        <div className="statistics">
          <div className="statistics-left-panel">
            <Form labelCol={{ span: 4 }}>
              <Form.Item label={t('type')}>
                <Select
                  onChange={this.handleChangeTypes}
                  style={{ minWidth: '5rem' }}
                  placeholder={`${t('all')}${t('type')}`}
                  allowClear
                >
                  {Object.values(TYPE).map(type => (
                    <Option key={type}>{t(`annotTypeText.${type}`)}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='tag'>
                <Select
                  onChange={this.handleChangeTags}
                  style={{ minWidth: '5rem' }}
                  placeholder={`${t('all')}${t('tag')}`}
                  allowClear
                >
                  {Object.values(realTags()).map(tag => (
                    <Option key={tag.tag}>{t(`${tag.tag}`)}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('annotator')}>
                <Input.Group compact>
                  <Form.Item style={{ margin: '0px', marginRight: '2px' }}>
                    <Select
                      style={{ width: '8rem' }}
                      onChange={this.handleChangeAnnotatorGroups}
                      placeholder={`${t('all')}${t('group')}`}
                      allowClear
                    >
                      {Object.values(groups).map(group => (
                        <Option key={group.id}>{group.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ margin: '0px' }}>
                    <Select
                      showSearch
                      style={{ width: '8rem' }}
                      onChange={this.handleChangeAnnotators}
                      placeholder={`${t('all')}${t('executor')}`}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                    >
                      {Object.values(annotators).map(annotator => (
                        <Option key={annotator.id}>{annotator.username}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item label={t('examiner')}>
                <Input.Group compact>
                  <Form.Item style={{ margin: '0px', marginRight: '2px' }}>
                    <Select
                      style={{ width: '8rem' }}
                      onChange={this.handleChangeExaminerGroups}
                      placeholder={`${t('all')}${t('group')}`}
                      allowClear
                    >
                      {Object.values(groups).map(group => (
                        <Option key={group.id}>{group.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{ margin: '0px' }}>
                    <Select
                      showSearch
                      style={{ width: '8rem' }}
                      onChange={this.handleChangeExaminers}
                      placeholder={`${t('all')}${t('executor')}`}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                    >
                      {Object.values(examiners).map(examiner => (
                        <Option key={examiner.id}>{examiner.username}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item label={t('time')}>
                <RangePicker
                  placeholder={[t('startTime'), t('endTime')]}
                  value={dates}
                  mode={[]}
                  onChange={this.handleChangeDates}
                />
              </Form.Item>
            </Form>
            <div className="statistics-filter-row dates">
              <Button
                onClick={() => this.handleInquery('calculation')}
              >
                {t('btn.calculation')}
              </Button>
              <Button
                type="primary"
                onClick={() => this.handleInquery('query')}
              >
                {t('btn.query')}
              </Button>
            </div>
            {/* <div className="statistics-filter-row">
              <ConfigProvider renderEmpty={customizeRenderUsersEmpty}>
                <Table
                  dataSource={getFilteredUsers(users, 'annotator')}
                  rowSelection={rowSelection}
                  size="middle"
                  rowKey="id"
                >
                  <Column title="ID" dataIndex="id" key="id" />
                  <Column
                    title={t('username')}
                    dataIndex="username"
                    key="username"
                    {...this.getColumnSearchProps('username')}
                  />
                </Table>
              </ConfigProvider>
            </div> */}
          </div>
          <div className="statistics-right-panel">
            <Spin spinning={!!gettingAnnotations}>
              <div className="statistics-stats">
                <Row>
                  <Col span={10} className="statistics-box">
                    <h2 style={{ textAlign: 'center' }}>{t('total')}</h2>
                    <Row>
                      <Col span={8}>
                        <div>
                          <h3>{t('taskDetails')}</h3>
                          <p>{t('tasksNum')}：{count}</p>
                          <p>{t('framesNum')}：{fNum}</p>
                          <p>{t('boxesNum')}：{oNum}</p>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div>
                          <h3>{t('annotator')}</h3>
                          <p>{t('sumitBoxes')}：{iNum}</p>
                          <p>{t('validBoxes')}：{annotatorAddCount}</p>
                          <p>{t('workload')}：{annotatorWorkload}</p>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div>
                          <h3>{t('examiner')}</h3>
                          <p>{t('fillBoxes')}：{examinerAddCount}</p>
                          <p>{t('adjustBoxes')}：{examinerExamCount}</p>
                          <p>{t('workload')}：{examinerWorkload}</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={14}>
                    <WorkChart statDays={statDays} />
                  </Col>
                </Row>
                {/* <div className='statistics-note'>
                  <p>I:标注员提交的Box数量</p>
                  <p>T:任务涉及到的Box总数量</p>
                  <p>O:任务Box数</p>
                </div> */}
              </div>
            </Spin>
          </div>
          <div className="statistics-table">
            <ConfigProvider renderEmpty={customizeRenderAnnotationsEmpty}>
              <Table
                dataSource={filteredAnnotations}
                pagination={pagination}
                // dataSource={stats}
                size="small"
                columns={columns}
                onChange={this.handleTableChange}
                style={{ width: '100%' }}
                rowKey="id"
              />
            </ConfigProvider>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    users: state.authentication.users,
    annotations: state.annotation.annotations,
    tags: state.tag.tags,
    groups: state.group.groups,
    stats: state.annotation.stats,
    statSum: state.annotation.statSum,
    statDays: state.annotation.statDays,
    totalCount:state.annotation.totalCount,
    gettingAnnotations: state.annotation.gettingAnnotations,
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(
  withTranslation(['statistics'])(Statistics)
)
