import React from 'react'
import { withTranslation } from 'react-i18next'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Input, Button, Select } from 'antd'

const TaskTypeForm = ({
  form,
  onSubmit,
  formItemLayout,
  tailFormItemLayout,
  loading,
  t,
  objectTypes
}) => {
  const { getFieldDecorator, validateFields } = form

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((error, values) => {
      if (!error) {
        onSubmit(values)
      }
    })
  }
  const children = []
  for (let i in objectTypes) {
    children.push(
      <Select.Option key={objectTypes[i].id}>
        {objectTypes[i].name}
      </Select.Option>
    )
  }
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={t('name')}>
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Name can not be empty' }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('objecttypes')}>
        {getFieldDecorator('objecttypes', {
          rules: [{ required: true, message: 'objecttypes can not be empty' }]
        })(
          <Select mode="multiple" placeholder="Please select">
            {children}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('description')}>
        {getFieldDecorator('description')(<Input />)}
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('btns.create')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default withTranslation(['taskTypes', 'common'])(
  Form.create({ name: 'taskType form' })(TaskTypeForm)
)
