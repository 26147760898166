import React, { Component } from 'react'
import './Views.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { fabric } from 'fabric'
import { createPointsKDTree, changePointsColorInBox, dyePCD } from '../_utils'
// No cache for fabric
fabric.Object.prototype.noScaleCache = false

import { DeleteOutlined, InboxOutlined, SyncOutlined } from '@ant-design/icons'

import { Button, Tabs, Slider, InputNumber, Divider, message, Tooltip, Checkbox } from 'antd'
import { IconButton, HoverPanel, LabelType, LoadingCover } from '../common'
import {
  AttributesTable,
  Shortcuts,
  FrameInfo,
  LabelsStats,
} from './components'
import { bagActions, annotationActions, alertActions } from '../_actions'
import { dataServices } from '../_services'
import { db } from '../_db'
import DataWorker from '../_workers/data.worker'

import {
  MATERIAL_COLORS,
  PLANE_DISTANCE,
  FIGURE_PRECISION,
  DEFAULT_POINT_SIZE,
  HALF_WIDTH,
  DEFAULT_DIMENSION,
  OPEN_BASE_URL,
  PCD_COLOR,
  PCD_INBOX_COLOR,
} from './constants'
import { MAIN, TOP, SIDE, CAM_WIDTH } from './constants'
import {
  toPrecision,
  extractObjectFusion,
  base64ToArrayBuffer,
  recreateDB,
} from '../_utils'
import { history, role } from '../_helpers'

// Three libs
import 'three/examples/js/loaders/PCDLoader'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls'
// import 'three/examples/js/controls/DragControls'
import '../_lib/DragControls'
import 'three/examples/js/renderers/CSS2DRenderer'

const TabPane = Tabs.TabPane

const sliderLabelStyle = {
  color: '#fff',
  fontSize: '0.8rem',
  userSelect: 'none',
}

function applyPrecisionToLabel(object) {
  const props = ['position', 'scale', 'rotation']
  const subProps = ['x', 'y', 'z']

  props.forEach(prop => {
    subProps.forEach(subProp => {
      object[prop][subProp] = toPrecision(
        object[prop][subProp],
        FIGURE_PRECISION,
      )
    })
  })

  return object
}

const defaultProjectionMatrix = new THREE.Matrix4().set(
  -1.4508e3,
  -9.0111e2,
  -3.2518e1,
  -2.6377e2,
  -9.236,
  -5.8289e2,
  -1.4231e3,
  -4.6927e2,
  -3.7988e-2,
  -9.9907e-1,
  -2.0459e-2,
  -3.1505e-1,
  0,
  0,
  0,
  1,
)

function getUVFromVector3(vec, projectionMatrix) {
  // vec = new THREE.Vector3(-vec.y, vec.x, vec.z)
  const result = vec.applyMatrix4(projectionMatrix)

  return {
    x: result.x / result.z,
    y: result.y / result.z,
  }
}

function getCornerPosFromBox(mesh) {
  mesh.geometry.computeBoundingBox()
  const boundingBox = mesh.geometry.boundingBox.clone()

  mesh.updateMatrixWorld(true)
  const { min, max } = boundingBox.applyMatrix4(mesh.matrixWorld)

  // Important, coordinate system in this scene and altimate data are different:
  // (x -> y, y -> -x, z -> z)
  // NO NEED !!!
  // return [
  //   new THREE.Vector3(min.y, -min.x, min.z),
  //   new THREE.Vector3(min.y, -min.x, max.z),
  //   new THREE.Vector3(min.y, -max.x, min.z),
  //   new THREE.Vector3(min.y, -max.x, max.z),
  //   new THREE.Vector3(max.y, -min.x, min.z),
  //   new THREE.Vector3(max.y, -min.x, max.z),
  //   new THREE.Vector3(max.y, -max.x, min.z),
  //   new THREE.Vector3(max.y, -max.x, max.z),
  // ]
  return [
    new THREE.Vector3(min.x, min.y, min.z),
    new THREE.Vector3(min.x, min.y, max.z),
    new THREE.Vector3(min.x, max.y, min.z),
    new THREE.Vector3(min.x, max.y, max.z),
    new THREE.Vector3(max.x, min.y, min.z),
    new THREE.Vector3(max.x, min.y, max.z),
    new THREE.Vector3(max.x, max.y, min.z),
    new THREE.Vector3(max.x, max.y, max.z),
  ]
}

// function getLabelRotateZAngle(label) {
//   return THREE.Math.radToDeg(label.rotation.z) % 360
// }

class Views extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentFrame: 1,
      totalFrames: 1,
      labels: [],
      labelsCounter: {},
      cameraImg: null,
      editingLabel: null,
      labelAdding: false,
      labelRemoving: false,
      helpersShowing: true,
      auxShowing: true,
      cameraShowing: true,
      isInfoVisible: true,
      isStatsVisible: true,
      firstLoading: true,
      idEdited: false,
      noLabels: false,
      pointSize: DEFAULT_POINT_SIZE,
      flipCamera: false,
      pointHue: MATERIAL_COLORS.PCD.H,
      pointSaturation: MATERIAL_COLORS.PCD.S,
      pointLightness: MATERIAL_COLORS.PCD.L,
    }

    this.views = [
      {
        name: 'main',
        container: null,
        canvas: null,
        camera: null,
        context: null,
        dimension: {},
        eye: [-40, 0, 40],
        up: [0, 0, 1],
        fov: 45,
        mapControl: null,
        render: () => {},
      },
      {
        name: 'top',
        container: null,
        canvas: null,
        camera: null,
        context: null,
        dimension: {},
        eye: [0, 0, 10],
        up: [0, 0, 1],
        mapControl: null,
        dragControl: null,
        render: () => {},
      },
      {
        name: 'side',
        container: null,
        canvas: null,
        camera: null,
        context: null,
        dimension: {},
        eye: [0, -200, 0],
        up: [0, -1, 0],
        mapControl: null,
        dragControl: null,
        render: () => {},
      },
    ]

    this.cams = [
      {
        name: 'camera_1',
        canvas: null,
        fcanvas: null,
        currentImage: null,
      },
    ]

    this.scene, this.rendererMain, this.rendererAux
    this.plane, this.raycaster, this.mouse, this.loader
    this.helpers = []
    this.defaultBoxGeometry, this.defaultBoxMaterial
    this.defaultEdgesGeometry, this.defaultLineMaterial
    this.defaultConeGeometry
    this.dataWorker
    this.currentPCD

    this.camScale
    this.imageWidth
    this.imageHeight
    this.projections = []

    this.buffer = {}
    this.bufferIndex = 0

    this.unlabeledIndices = []
    this.projectionMatrix = defaultProjectionMatrix
    this.pointsKDTree
  }

  componentDidMount() {
    const { dispatch, location } = this.props

    if (location.state) {
      const { bagId, startTimestamp, endTimestamp } = location.state.record

      // Get frames when component did mount
      if (bagId && startTimestamp && endTimestamp) {
        dispatch(
          bagActions.get(bagId)
        )

        dispatch(
          annotationActions.getAllFrames(bagId, startTimestamp, endTimestamp, [
            'ready',
            'finish',
          ]),
        )

        // Initialize views
        setTimeout(this.init, 0)
      }
    } else {
      history.replace('/annotations')
    }

    // Initialize data worker
    this.dataWorker = new DataWorker()
  }

  componentDidUpdate(prevProps) {
    const { bag, dispatch, frames, objects, message: alertMessage } = this.props

    // alert if any
    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear()),
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear()),
        )
      }
    }

    // setup matrix
    if (bag && bag.matrix) {
      const projectionMatrix = JSON.parse(bag.matrix).CAMERA_1
        .split(',')
        .map(float => parseFloat(float)) || null
      this.projectionMatrix = projectionMatrix && projectionMatrix.length === 16 && new THREE.Matrix4().set(...projectionMatrix)
    }

    // Get objects when: do have frames && not other props changes
    if (frames.length > 0 && frames !== prevProps.frames) {
      const lastFrameIndex = frames.length - 1
      let firstFrameIndex = lastFrameIndex

      frames.forEach((frame, index) => {
        if (!frame.annotationobjectsId || frame.annotationobjectsId < 0) {
          this.unlabeledIndices.push(index)

          firstFrameIndex === lastFrameIndex && (firstFrameIndex = index)
        }
      })

      this.setState(
        {
          currentFrame: firstFrameIndex + 1,
          totalFrames: frames.length,
        },
        () => {
          this.loadData(() => {
            if (frames[firstFrameIndex].annotationobjectsId > 0) {
              dispatch(
                annotationActions.getObject(
                  frames[firstFrameIndex].annotationobjectsId
                )
              )
            }
          })
          // if (frames[firstFrameIndex].annotationobjectsId > 0) {
          //   dispatch(
          //     annotationActions.getObject(
          //       frames[firstFrameIndex].annotationobjectsId,
          //     ),
          //   )
          // }
          // this.loadData()
        },
      )
    }

    // Put objects into the scene
    // if (objects.length > 0 && objects !== prevProps.objects) {
    if (objects !== prevProps.objects) {
      // When no objects, reuse objects in the previous frame
      this.updateLabels(objects)

      // When no objects, set noLabels
      this.setState({ noLabels: !objects.length })
    }
  }

  componentWillUnmount() {
    // Remove global listeners before leaving
    window.removeEventListener('resize', this.onWindowResize)
    window.removeEventListener('keydown', this.onKeyDown)

    // Kill worker before leaving
    this.dataWorker.terminate()

    // Clear db before leaving
    recreateDB(db)
  }

  /** THREE loading trigger */
  init = () => {
    this.createCanvases()
    this.createScene()
    this.createAccessories()
    this.createDefaultBox()
    this.createHelpers()
    this.createRenderers()
    this.createViews()

    this.animate()
  }

  createCanvases = () => {
    this.views.forEach(view => {
      const canvas = document.createElement('canvas')
      view.canvas = canvas
      view.container.append(view.canvas)
    })

    this.bindEventListeners()
  }

  bindEventListeners = () => {
    const main = this.views[MAIN]
    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('keydown', this.onKeyDown, false)
    main.canvas.addEventListener('mousedown', this.onMouseDown)
  }

  /** Listeners */
  onWindowResize = () => {
    const main = this.views[MAIN]
    const top = this.views[TOP]

    this.views.forEach(({ name, canvas, camera, dimension }) => {
      dimension.width = canvas.clientWidth
      dimension.height = canvas.clientHeight
      dimension.aspect = dimension.width / dimension.height

      canvas.width = dimension.width * window.devicePixelRatio
      canvas.height = dimension.height * window.devicePixelRatio

      if (camera) {
        switch (name) {
          case 'main':
            camera.aspect = dimension.aspect
            break
          case 'top':
            camera.left = -HALF_WIDTH
            camera.right = HALF_WIDTH
            camera.top = HALF_WIDTH / dimension.aspect
            camera.bottom = -HALF_WIDTH / dimension.aspect
            break
          case 'side':
            camera.left = HALF_WIDTH
            camera.right = -HALF_WIDTH
            camera.top = -HALF_WIDTH / dimension.aspect + PLANE_DISTANCE
            camera.bottom = HALF_WIDTH / dimension.aspect + PLANE_DISTANCE
            break
        }

        camera.updateProjectionMatrix()
      }
    })

    this.rendererMain.setSize(main.canvas.clientWidth, main.canvas.clientHeight)
    this.rendererAux.setSize(top.canvas.clientWidth, top.canvas.clientHeight)
    this.labelRenderer.setSize(
      main.canvas.clientWidth,
      main.canvas.clientHeight,
    )
  }

  onKeyDown = e => {
    const { currentFrame, editingLabel, firstLoading } = this.state
    const { user } = this.props

    // Disable all keyboard events when first loading
    if (firstLoading) return

    if (e.altKey) {
      switch (e.code) {
        case 'Equal':
          this.handleAddLabel()
          break
        case 'Minus':
          this.handleRemoveLabel()
          break
        case 'ArrowRight':
          this.handleInputFrame(currentFrame + 1)
          break
        case 'ArrowLeft':
          this.handleInputFrame(currentFrame - 1)
          break
        case 'KeyA':
          this.handleToggleAuxViews()
          break
        case 'KeyC':
          this.handleToggleCamera()
          break
        case 'KeyH':
          this.handleToggleHelpers()
          break
        case 'KeyI':
          this.handleInspect()
          break
        case 'KeyS':
          this.handleSave()
          break
      }
    } else if (editingLabel) {
      switch (e.code) {
        case 'KeyW':
        case 'ArrowUp':
          this.handleInputLabel(
            editingLabel.position.y + (e.shiftKey ? 0.01 : 0.1),
            'position',
            'y',
          )
          break
        case 'KeyS':
        case 'ArrowDown':
          this.handleInputLabel(
            editingLabel.position.y - (e.shiftKey ? 0.01 : 0.1),
            'position',
            'y',
          )
          break
        case 'KeyA':
        case 'ArrowLeft':
          this.handleInputLabel(
            editingLabel.position.x - (e.shiftKey ? 0.01 : 0.1),
            'position',
            'x',
          )
          break
        case 'KeyD':
        case 'ArrowRight':
          this.handleInputLabel(
            editingLabel.position.x + (e.shiftKey ? 0.01 : 0.1),
            'position',
            'x',
          )
          break
        case 'KeyE':
          this.handleInputLabel(
            editingLabel.position.z + (e.shiftKey ? 0.01 : 0.1),
            'position',
            'z',
          )
          break
        case 'KeyR':
          this.handleInputLabel(
            editingLabel.position.z - (e.shiftKey ? 0.01 : 0.1),
            'position',
            'z',
          )
          break
        case 'KeyC':
          this.handleInputLabel(
            editingLabel.rotation.z - (e.shiftKey ? 0.01 : 0.1),
            'rotation',
            'z',
          )
          break
        case 'KeyV':
          this.handleInputLabel(
            editingLabel.rotation.z + (e.shiftKey ? 0.01 : 0.1),
            'rotation',
            'z',
          )
          break
        case 'KeyF':
          this.handleInputLabel(
            editingLabel.rotation.z + 3.14 < 6.28
              ? editingLabel.rotation.z + 3.14
              : editingLabel.rotation.z - 3.14,
            'rotation',
            'z',
          )
          break
        // case 'KeyN':
        //   this.handleInputLabel(
        //       editingLabel.rotation.y - (e.shiftKey ? 0.01 : 0.1),
        //     'rotation',
        //     'y'
        //   )
        //   break
        // case 'KeyM':
        //   this.handleInputLabel(
        //       editingLabel.rotation.y + (e.shiftKey ? 0.01 : 0.1),
        //     'rotation',
        //     'y'
        //   )
        //   break
        case 'KeyO':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.x
              : editingLabel.userData.dimension.x) + (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'x',
          )
          break
        case 'KeyL':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.x
              : editingLabel.userData.dimension.x) - (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'x',
          )
          break
        case 'Semicolon':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.y
              : editingLabel.userData.dimension.y) + (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'y',
          )
          break
        case 'KeyK':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.y
              : editingLabel.userData.dimension.y) - (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'y',
          )
          break
        case 'KeyU':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.z
              : editingLabel.userData.dimension.z) + (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'z',
          )
          break
        case 'KeyI':
          this.handleInputLabel(
            (e.ctrlKey
              ? editingLabel.scale.z
              : editingLabel.userData.dimension.z) - (e.shiftKey ? 0.01 : 0.1),
            e.ctrlKey ? 'scale' : 'dimension',
            'z',
          )
          break
        case 'KeyQ':
          ;[role.globalExaminer, role.groupExaminer].includes(user.role) &&
            this.handleInputLabel(!editingLabel.userData.fail, 'fail')
          break
      }
    }
  }

  onMouseDown = e => {
    const main = this.views[MAIN]
    const {
      labels,
      labelAdding,
      labelRemoving,
      editingLabel,
      isEdited,
    } = this.state

    e.preventDefault()

    this.mouse.x = (e.offsetX / e.target.clientWidth) * 2 - 1
    this.mouse.y = -(e.offsetY / e.target.clientHeight) * 2 + 1

    this.raycaster.setFromCamera(this.mouse, main.camera)
    const intersects = this.raycaster.intersectObjects(labels)

    if (labelAdding) {
      isEdited || this.setState({ isEdited: true })

      const intersect = new THREE.Vector3()
      this.raycaster.ray.intersectPlane(this.plane, intersect) // 'target'(para 2) is required
      this.addLabel(intersect)
      return
    }

    if (labelRemoving && intersects.length > 0) {
      isEdited || this.setState({ isEdited: true })

      this.removeLabel(intersects[0].object)
      return
    }

    if (intersects.length > 0) {
      const label = intersects[0].object

      this.selectRect(label)
      this.highlightLabel(label)
      this.setState({
        editingLabel: label,
      })

      this.pointCameraAtLabel(label, this.views[TOP])
      this.pointCameraAtLabel(label, this.views[SIDE])
    }

    if (intersects.length === 0 && editingLabel) {
      this.deselectRect()
      this.unhighlightLabel()
      this.setState({
        editingLabel: null,
      })
    }
  }

  createRenderers = () => {
    this.rendererMain = new THREE.WebGLRenderer({ antialias: true })
    this.rendererMain.setPixelRatio(window.devicePixelRatio)

    this.rendererAux = new THREE.WebGLRenderer({ antialias: true })
    this.rendererAux.setPixelRatio(window.devicePixelRatio)

    this.labelRenderer = new THREE.CSS2DRenderer()
    this.labelRenderer.domElement.style.position = 'absolute'
    this.labelRenderer.domElement.style.top = 0
    this.labelRenderer.domElement.style.pointerEvents = 'none'
    this.labelRenderer.domElement.style.zIndex = 0
    this.views[0].container.appendChild(this.labelRenderer.domElement)

    this.onWindowResize() // Get canvases' sizes and set renderers' sizes
  }

  createScene = () => {
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0x151515)
  }

  createAccessories = () => {
    this.plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), PLANE_DISTANCE)
    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()
    this.loader = new THREE.PCDLoader()
  }

  createViews = () => {
    this.views.forEach(view => {
      let camera
      const HALF_WIDTH = 8

      switch (view.name) {
        case 'main':
          camera = new THREE.PerspectiveCamera(
            view.fov,
            view.canvas.width / view.canvas.height,
            0.1,
            400,
          )
          break
        case 'top':
          camera = new THREE.OrthographicCamera(
            -HALF_WIDTH,
            HALF_WIDTH,
            HALF_WIDTH / view.dimension.aspect,
            -HALF_WIDTH / view.dimension.aspect,
            0,
            400,
          )
          break
        case 'side':
          camera = new THREE.OrthographicCamera(
            HALF_WIDTH,
            -HALF_WIDTH,
            -HALF_WIDTH / view.dimension.aspect + PLANE_DISTANCE, // Add an vertical offset equal to PLANE_DISTANCE
            HALF_WIDTH / view.dimension.aspect + PLANE_DISTANCE,
            0,
            400,
          )
          break
      }

      camera.position.fromArray(view.eye)
      camera.up.fromArray(view.up)

      view.camera = camera
      view.mapControl = this.createMapControls(view.camera, view.canvas)

      if (view.name !== 'main') {
        // No dragging in the main view
        view.dragControl = this.createDragControls(
          view.camera,
          view.canvas,
          view.mapControl,
        )

        // No rotate in other views
        view.mapControl.enableRotate = false
      }

      // Main render for main view, auxiliary render for top/side/rear views
      view.context = view.canvas.getContext('2d')
      view.render =
        view.name === 'main'
          ? () => {
              this.rendererMain.render(this.scene, view.camera)
              view.context.drawImage(this.rendererMain.domElement, 0, 0)

              this.labelRenderer.render(this.scene, view.camera)
            }
          : () => {
              this.rendererAux.render(this.scene, view.camera)
              view.context.drawImage(this.rendererAux.domElement, 0, 0)
            }
    })
  }

  createHelpers = () => {
    const defaultBoxMaterial = this.defaultBoxMaterial.clone()
    // Car helper
    const mesh = new THREE.Mesh(this.defaultBoxGeometry, defaultBoxMaterial)
    mesh.material.color.set(MATERIAL_COLORS.HELPER)

    const line = new THREE.LineSegments(
      this.defaultEdgesGeometry,
      this.defaultLineMaterial.clone(),
    )
    line.material.color.set(MATERIAL_COLORS.HELPER)
    mesh.add(line)

    const arrow = new THREE.Mesh(this.defaultConeGeometry, defaultBoxMaterial)
    arrow.position.fromArray([2.5, 0, 0])
    arrow.rotation.fromArray([0, 0, -1.57])

    mesh.add(arrow)

    mesh.position.set(0, 0, -PLANE_DISTANCE)
    mesh.rotateZ(-Math.PI / 2)
    this.helpers.push(mesh)
    this.scene.add(mesh)

    // Axes helper
    const axesHelper = new THREE.AxesHelper(60)
    this.helpers.push(axesHelper)
    this.scene.add(axesHelper)

    const gridHelper = new THREE.GridHelper(200, 20)
    gridHelper.rotateX(Math.PI / 2)
    this.helpers.push(gridHelper)
    this.scene.add(gridHelper)
  }

  createDefaultBox = () => {
    // Create default Box
    this.defaultBoxGeometry = new THREE.BoxBufferGeometry(
      ...Object.values(DEFAULT_DIMENSION),
    ) // Deafult box geometry
    this.defaultBoxMaterial = new THREE.MeshBasicMaterial({
      color: MATERIAL_COLORS.CAR,
      opacity: 0.4,
      transparent: true,
    })

    // Create default Edges
    this.defaultEdgesGeometry = new THREE.EdgesGeometry(this.defaultBoxGeometry)
    this.defaultLineMaterial = new THREE.LineBasicMaterial({
      color: MATERIAL_COLORS.CAR,
    })

    // Create default arrow cone
    this.defaultConeGeometry = new THREE.ConeBufferGeometry(
      0.5,
      0.5,
      50,
      1,
      true,
    )
  }

  createMapControls = (camera, canvas) => {
    const mapControl = new MapControls(camera, canvas)
    mapControl.enableDamping = true
    mapControl.dampingFactor = 0.3
    mapControl.enableKeys = false
    mapControl.screenSpacePanning = false
    mapControl.minDistance = 1
    mapControl.maxDistance = 300
    mapControl.maxPolarAngle = Math.PI / 2

    return mapControl
  }

  createDragControls = (camera, canvas, mapControl) => {
    const { labels, isEdited } = this.state
    const { user } = this.props
    const dragControl = new THREE.DragControls(labels, camera, canvas)

    dragControl.addEventListener('dragstart', e => {
      mapControl.enabled = false

      if (e.object.type === 'LineSegments') return

      this.selectRect(e.object)
      this.highlightLabel(e.object)
      this.setState({
        editingLabel: applyPrecisionToLabel(e.object),
      })
    })

    dragControl.addEventListener('drag', e => {
      if (e.object.type === 'LineSegments') return

      const count = changePointsColorInBox(
        e.object,
        this.currentPCD,
        this.pointsKDTree,
        PCD_INBOX_COLOR
      )

      e.object.userData.pointsCount = count

      // Update rects
      this.updateRect(e.object)

      //Update labels
      this.setState({
        editingLabel: applyPrecisionToLabel(e.object),
      })

      if ([role.globalExaminer, role.groupExaminer].includes(user.role)) {
        this.setFail(e.object, true)
      }
      isEdited || this.setState({ isEdited: true })
    })

    dragControl.addEventListener('dragend', e => {
      mapControl.enabled = true

      this.pointCameraAtLabel(e.object, this.views[TOP])
      this.pointCameraAtLabel(e.object, this.views[SIDE])
    })

    dragControl.addEventListener('dragBefore', e => {
      changePointsColorInBox(
        this.state.editingLabel,
        this.currentPCD,
        this.pointsKDTree,
        PCD_COLOR
      )
    })

    return dragControl
  }

  createLabel = ({
    id,
    name,
    type,
    position,
    scale,
    dimension,
    rotation,
    fail,
  }) => {
    // Create box
    const defaultBoxMaterial = this.defaultBoxMaterial.clone()
    let mesh = new THREE.Mesh(this.defaultBoxGeometry, defaultBoxMaterial)

    // Create line
    const defaultLineMaterial = this.defaultLineMaterial.clone()
    const line = new THREE.LineSegments(
      this.defaultEdgesGeometry,
      defaultLineMaterial,
    )
    mesh.add(line)

    // Create arrow, using the same material with box to change color synchronized
    const arrow = new THREE.Mesh(this.defaultConeGeometry, defaultBoxMaterial)

    arrow.position.fromArray([2.5, 0, 0])
    arrow.rotation.fromArray([0, 0, -1.57])
    mesh.add(arrow)

    // Label customization
    // ID
    id ? (mesh.userData.id = id) : this.assignID(mesh)

    // Name
    if (name) {
      mesh.name = name
    } else {
      mesh.name = 'Label' + (mesh.userData.id ? mesh.userData.id : '')
    }

    // Type
    if (type && type !== 'car') {
      const color = MATERIAL_COLORS[type.toUpperCase()]

      mesh.userData.type = type
      mesh.material.color.set(color)
      mesh.children[0].material.color.set(color)
    } else {
      mesh.userData.type = 'car'
    }

    // Position
    if (position) {
      position instanceof THREE.Vector3
        ? mesh.position.copy(position)
        : mesh.position.fromArray(position)
    }

    // Scale
    scale && mesh.scale.fromArray(scale)

    // Dimension
    mesh.userData.dimension = new THREE.Vector3(
      ...Object.values(dimension ? dimension : DEFAULT_DIMENSION),
    )

    // Rotation
    rotation && mesh.rotation.fromArray(rotation)

    // Add label tag
    const labelTagDiv = document.createElement('div')
    labelTagDiv.className = 'views-label'
    labelTagDiv.textContent = mesh.name
    // labelTagDiv.style.pointerEvents = 'auto'
    const labelTag = new THREE.CSS2DObject(labelTagDiv)
    labelTag.position.set(0, 0, 3)

    mesh.add(labelTag)

    if (fail) {
      mesh.children[0].material.color.set(MATERIAL_COLORS.FAIL)
      labelTagDiv.style.color = MATERIAL_COLORS.FAIL

      mesh.userData.fail = fail
    }

    // Create rects
    const rects = []
    const rect = this.createRectFromLabel(mesh)
    rects.push(rect)
    mesh.userData.rects = rects

    // Apply precision
    mesh = applyPrecisionToLabel(mesh)

    const count = changePointsColorInBox(
      mesh,
      this.currentPCD,
      this.pointsKDTree,
      PCD_INBOX_COLOR
    )
    mesh.userData.pointsCount = count

    return mesh
  }

  // Cams
  createFcanvases = () => {
    this.cams.forEach((cam, index) => {
      cam.fcanvas = new fabric.Canvas(cam.canvas, {
        preserveObjectStacking: true,
        selection: false,
        selectionColor: 'transparent',
        selectionBorderColor: 'transparent',
        backgroundColor: 'transparent',
        defaultCursor: 'default',
        stopContextMenu: true,
      })
      cam.fcanvas.setZoom(this.camScale)
      cam.fcanvas.on({
        'mouse:wheel': e => this.handleMouseWheel(e, index),
        'selection:created': e => this.handleObjectSelected(e, index),
        'selection:updated': e => this.handleObjectUpdated(e, index),
        'selection:cleared': e => this.handleObjectCleared(e, index),
      })
    })
  }

  pointCameraAtLabel = (label, view) => {
    view.mapControl.reset()

    switch (view.name) {
      case 'top':
        view.camera.left = -HALF_WIDTH + label.position.x
        view.camera.right = HALF_WIDTH + label.position.x
        view.camera.top = HALF_WIDTH / view.dimension.aspect + label.position.y
        view.camera.bottom =
          -HALF_WIDTH / view.dimension.aspect + label.position.y
        break
      case 'side':
        view.camera.left = HALF_WIDTH - label.position.x
        view.camera.right = -HALF_WIDTH - label.position.x
        view.camera.top = -HALF_WIDTH / view.dimension.aspect + PLANE_DISTANCE
        view.camera.bottom = HALF_WIDTH / view.dimension.aspect + PLANE_DISTANCE
        break
    }

    view.camera.updateProjectionMatrix()
  }

  animate = () => {
    this.views.forEach(view => {
      view.render()
    })

    requestAnimationFrame(this.animate)
  }

  /** Event handlers */
  handleAddLabel = () => {
    const main = this.views[MAIN]
    const { labelAdding } = this.state

    main.mapControl.enabled = labelAdding
    main.mapControl.enableRotate = labelAdding

    this.setState({
      labelAdding: !labelAdding,
      labelRemoving: false,
    })
  }

  handleRemoveLabel = () => {
    const main = this.views[MAIN]
    const { labelRemoving } = this.state

    main.mapControl.enabled = labelRemoving
    main.mapControl.enableRotate = labelRemoving

    this.deselectRect()
    this.unhighlightLabel()
    this.setState({
      labelRemoving: !labelRemoving,
      labelAdding: false,
      editingLabel: null,
    })
  }

  handleToggleHelpers = () => {
    const { helpersShowing } = this.state

    this.helpers.forEach(helper =>
      helpersShowing ? this.scene.remove(helper) : this.scene.add(helper),
    )

    this.setState({ helpersShowing: !helpersShowing })
  }

  handleResetCamera = viewName => {
    this.views[viewName].mapControl.reset()
  }

  handleInputLabel = (value, prop, subProp) => {
    const { editingLabel, isEdited } = this.state
    const { user } = this.props

    value === +value && (value = toPrecision(value, FIGURE_PRECISION))

    // This is verbose, the better way is setting Proxy or getter/setter to labels
    if (editingLabel) {
      const count = changePointsColorInBox(
        editingLabel,
        this.currentPCD,
        this.pointsKDTree,
        PCD_COLOR
      )
      editingLabel.userData.pointsCount = count

      switch (prop) {
        case 'name':
        case 'fail':
          editingLabel.userData[prop] = value
          break
        case 'type':
          // Update counter
          this.updateLabelsCounter({
            type: 'update',
            payload: { old: editingLabel.userData.type, new: value },
          })

          editingLabel.userData[prop] = value
          break
        case 'position':
        case 'rotation':
          editingLabel[prop][subProp] = value
          break
        case 'scale':
          editingLabel[prop][subProp] = value
          editingLabel.userData.dimension[subProp] =
            DEFAULT_DIMENSION[subProp] * value
          break
        case 'dimension':
          const dis = value - editingLabel.userData[prop][subProp]

          editingLabel.userData[prop][subProp] = value
          editingLabel.scale[subProp] = toPrecision(
            value / DEFAULT_DIMENSION[subProp],
            FIGURE_PRECISION,
          )

          switch (subProp) {
            case 'x':
              editingLabel.position.x +=
                (dis * Math.cos(editingLabel.rotation.z)) / 2
              editingLabel.position.y +=
                (dis * Math.sin(editingLabel.rotation.z)) / 2
              break
            case 'y':
              editingLabel.position.x +=
                (dis * Math.cos(editingLabel.rotation.z - Math.PI / 2)) / 2
              editingLabel.position.y +=
                (dis * Math.sin(editingLabel.rotation.z - Math.PI / 2)) / 2
              break
            case 'z':
              editingLabel.position.z += dis / 2
              break
          }

          editingLabel.position.x = toPrecision(
            editingLabel.position.x,
            FIGURE_PRECISION,
          )
          editingLabel.position.y = toPrecision(
            editingLabel.position.y,
            FIGURE_PRECISION,
          )
          editingLabel.position.z = toPrecision(
            editingLabel.position.z,
            FIGURE_PRECISION,
          )
          break
      }

      // Change color
      if (prop === 'type') {
        const color = MATERIAL_COLORS[editingLabel.userData.type.toUpperCase()]

        editingLabel.material.color.set(color)
        editingLabel.children[2].element.style.backgroundColor = color

        // Update rects
        editingLabel.userData.rects.forEach((rect, index) => {
          rect.set({
            stroke: color,
          })
          this.cams[index].fcanvas.requestRenderAll()
        })
      }

      // Change label tag content
      if (prop === 'name') {
        editingLabel.children[2].element.textContent = value
      }

      // Mark fail
      if (prop === 'fail') {
        this.setFail(editingLabel, value)
      } else if (
        [role.globalExaminer, role.groupExaminer].includes(user.role)
      ) {
        this.setFail(editingLabel, true)
      }

      changePointsColorInBox(
        editingLabel,
        this.currentPCD,
        this.pointsKDTree,
        PCD_INBOX_COLOR
      )

      this.setState({
        editingLabel,
      })

      if (['position', 'rotation', 'dimension', 'scale'].includes(prop)) {
        this.updateRect(editingLabel)
      }

      isEdited || this.setState({ isEdited: true })
    }
  }

  handleInputFrame = value => {
    const { totalFrames } = this.state
    const { dispatch, frames } = this.props

    if (value > 0 && value <= totalFrames) {
      const objectId = frames[value - 1].annotationobjectsId
      this.handleSave()

      this.setState(
        {
          currentFrame: value,
          editingLabel: null,
          noLabels: false,
        },
        () => {
          this.setState({ isEdited: false, noLabels: false })

          if (objectId > 0) {
            dispatch(annotationActions.getObject(objectId))
          }

          this.loadData()
        },
      )
    }
  }

  handleSelectLabel = (label, e) => {
    const { editingLabel } = this.state

    if (label !== editingLabel) {
      this.selectRect(label)
      this.highlightLabel(label)
      this.setState({
        editingLabel: label,
      })

      this.pointCameraAtLabel(label, this.views[TOP])
      this.pointCameraAtLabel(label, this.views[SIDE])
    } else {
      this.deselectRect()
      this.unhighlightLabel(label)
      this.setState({
        editingLabel: null,
      })
    }
  }

  handleRemoveEditingLabel = e => {
    const { editingLabel } = this.state

    e.stopPropagation()
    this.handleSelectLabel(editingLabel)
    this.removeLabel(editingLabel)
  }

  handleInspect = () => {
    // console.log(this.state.labels)
    console.log(`${this.imageWidth} x ${this.imageHeight}`)
  }

  handleSave = () => {
    const { labels, currentFrame, isEdited } = this.state
    const { dispatch, frames, location, t } = this.props
    const { id } = location.state.record

    if (!isEdited) return

    const objectId = frames[currentFrame - 1].annotationobjectsId
    const data = { pcd: [] }

    labels.forEach(label => {
      const [pcdObject, ...camObjects] = extractObjectFusion(
        label,
        this.imageWidth,
        this.imageHeight,
      )

      if (pcdObject) {
        data.pcd.push(pcdObject)
      }

      camObjects.forEach((object, index) => {
        const camName = this.cams[index].name

        if (!data[camName]) {
          data[camName] = []
        }

        data[camName].push(object)
      })
    })

    dispatch(
      objectId > 0
        ? annotationActions.updateObject(data, objectId, labels.length)
        : annotationActions.createObject(
            data,
            frames[currentFrame - 1].id,
            labels.length,
          ),
    )
      .then(object => {
        // When created objects, remove that frame from indices
        if (!(objectId > 0)) {
          frames[currentFrame - 1].annotationobjectsId = object.id

          this.unlabeledIndices.splice(
            this.unlabeledIndices.findIndex(
              value => value === currentFrame - 1,
            ),
            1,
          )
        }

        // Update this annotation detail and progress
        dispatch(
          annotationActions.updateAnnotation({
            id,
            progress: `${frames.length - this.unlabeledIndices.length} / ${
              frames.length
            }`,
          }),
        )

        dispatch(
          alertActions.success({
            type: 'success',
            detail: t('alerts.saveSuccess'),
          }),
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: t('alerts.saveFailure'),
          }),
        )
      })
  }

  handleToggleCamera = () => {
    const { cameraShowing } = this.state
    this.setState({ cameraShowing: !cameraShowing })
  }

  handleToggleAuxViews = () => {
    const { auxShowing } = this.state
    this.setState({ auxShowing: !auxShowing })
  }

  handleToggleFrameInfo = () => {
    const { isInfoVisible } = this.state

    this.setState({ isInfoVisible: !isInfoVisible })
  }

  handleToggleStatsInfo = () => {
    const { isStatsVisible } = this.state

    this.setState({ isStatsVisible: !isStatsVisible })
  }

  handleChangePointSize = value => {
    this.setState({ pointSize: value })

    this.currentPCD.material.size = value
  }

  handleChangePointColor = (value, prop) => {
    this.setState({ [prop]: value }, () => {
      const { pointHue: h, pointSaturation: s, pointLightness: l } = this.state
      this.currentPCD.material.color.setHSL(h, s, l)
    })
  }

  handleFlipCamera = () => {
    const { flipCamera } = this.state

    this.cams.forEach(cam => {
      cam.currentImage.rotate(flipCamera ? 0 : 180)
      cam.currentImage.setCoords()
      cam.fcanvas.requestRenderAll()
    })

    this.setState({ flipCamera: !flipCamera })
  }

  handleCopyPath = () => {
    const { dispatch, t } = this.props

    dispatch(
      alertActions.success({
        type: 'success',
        detail: t('alerts.copySuccess'),
      }),
    )
  }

  handleChangeNoLabels = e => {
    this.setState({
      noLabels: e.target.checked,
      isEdited: e.target.checked,
    })
  }

  handleMouseWheel = (opt, index) => {
    opt.e.preventDefault()
    opt.e.stopPropagation()

    const fcanvas = this.cams[index].fcanvas

    const delta = opt.e.deltaY
    let zoomRatio = fcanvas.getZoom()

    zoomRatio = delta > 0 ? zoomRatio - 0.05 : zoomRatio + 0.05
    if (zoomRatio >= this.camScale && zoomRatio <= 1) {
      fcanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoomRatio)
    }

    const vpt = fcanvas.viewportTransform

    if (delta > 0) {
      // left
      if (vpt[4] >= 0) {
        fcanvas.viewportTransform[4] = 0
      }

      // right
      const rightBoundaryOffset =
        fcanvas.getWidth() - this.imageWidth * fcanvas.getZoom()
      if (vpt[4] <= rightBoundaryOffset) {
        fcanvas.viewportTransform[4] = rightBoundaryOffset
      }

      // top
      if (vpt[5] >= 0) {
        fcanvas.viewportTransform[5] = 0
      }

      // bottom
      const bottomBoundaryOffset =
        fcanvas.getHeight() - this.imageHeight * fcanvas.getZoom()
      if (vpt[5] <= bottomBoundaryOffset) {
        fcanvas.viewportTransform[5] = bottomBoundaryOffset
      }
    }
  }

  handleObjectSelected = (opt, index) => {
    const { labels } = this.state
    const label = labels.find(
      label => label.userData.rects[index] === opt.target,
    )

    if (label) {
      this.handleSelectLabel(label)
    }
  }

  handleObjectUpdated = (opt, index) => {
    const { labels } = this.state
    const label = labels.find(
      label => label.userData.rects[index] === opt.target,
    )

    if (label) {
      this.handleSelectLabel(label)
    }
  }

  handleObjectCleared = (opt, index) => {
    const { editingLabel } = this.state

    if (opt.e) {
      this.handleSelectLabel(editingLabel)
    }
  }

  /** load Data */
  loadData = () => {
    const { currentFrame } = this.state
    const { frames, location } = this.props
    let { bagName } = location.state.record

    this.bufferIndex = currentFrame
    const index = currentFrame - 1
    const timestamp = frames[index].timestamp

    if (this.buffer[timestamp] && this.buffer[timestamp].pcd) {
      this.addPCD(this.buffer[timestamp].pcd)
      this.addCameraImg(this.buffer[timestamp].camera)
      this.pointsKDTree = this.buffer[timestamp].kdTree
    } else {
      this.buffer[timestamp] = {}

      const getPCD = db['PCD'].get(frames[index].timestamp)
      const getCAMERA = db['CAMERA_1'].get(frames[index].timestamp)

      Promise.all([getPCD, getCAMERA])
        .then(result => {
          // Make sure there must be pcd in db
          if (result[0]) {
            // Add PCD to buffer
            const [pcd, kdTree] = this.loadPCD(result[0].data)
            this.buffer[timestamp].pcd = pcd
            this.addPCD(this.buffer[timestamp].pcd)
            this.buffer[timestamp].kdTree = kdTree
            this.pointsKDTree = this.buffer[timestamp].kdTree

            if (result[1]) {
              // Add CAMERA to buffer
              this.buffer[timestamp].camera =
                'data:image/jpeg;base64,' + result[1].data
              this.addCameraImg(this.buffer[timestamp].camera)
            } else {
              this.addCameraImg(null)
            }
          } else {
            const data = {
              bagName: bagName,
              sensorsList: ['PCD', 'CAMERA_1'],
              startFrametime: timestamp,
              endFrametime: timestamp,
              framesList: [timestamp],
            }

            dataServices.getData(data, onMessage)
          }
        })
        .catch(e => {
          console.log(e)
        })
    }

    // Test and preload next 5 frames
    this.japStep()

    const onMessage = data => {
      // For buffer
      if (data.sensorname === 'PCD') {
        const [pcd, kdTree] = this.loadPCD(data.data)
        this.buffer[timestamp].pcd = pcd
        this.buffer[timestamp].kdTree = kdTree
        this.addPCD(this.buffer[timestamp].pcd)
        this.addCameraImg(null)
        this.pointsKDTree = this.buffer[timestamp].kdTree
      } else {
        this.buffer[timestamp].camera = 'data:image/jpeg;base64,' + data.data
        this.addCameraImg(this.buffer[timestamp].camera)
      }

      // For cache
      this.dataWorker.postMessage({ action: 'PUT_DATA', data })
    }
  }

  preloadData = () => {
    const { frames, location } = this.props
    let { bagName } = location.state.record

    const startIndex = this.bufferIndex
    const endIndex =
      this.bufferIndex + 5 > frames.length
        ? frames.length
        : this.bufferIndex + 5
    const promiseQueue = []

    // startIndex includes, endIndex excludes
    for (let index = startIndex; index < endIndex; ++index) {
      const timestamp = frames[index].timestamp

      // If not in buffer
      if (!this.buffer[timestamp]) {
        this.buffer[timestamp] = {}

        promiseQueue.push(
          new Promise((resolve, reject) => {
            const getPCD = db['PCD'].get(timestamp)
            const getCAMERA = db['CAMERA_1'].get(timestamp)
            Promise.all([getPCD, getCAMERA])
              .then(result => {
                if (result[0] && result[1]) {
                  const [pcd, kdTree] = this.loadPCD(result[0].data)
                  this.buffer[timestamp].pcd = pcd
                  this.buffer[timestamp].kdTree = kdTree
                  this.buffer[timestamp].camera =
                    'data:image/jpeg;base64,' + result[1].data

                  return resolve()
                } else {
                  this.buffer[timestamp] = undefined
                  return resolve(timestamp)
                }
              })
              .catch(e => {
                console.log(e)
                this.buffer[timestamp] = undefined
                return reject()
              })
          }),
        )
      }
    }

    Promise.all(promiseQueue).then(result => {
      // Filter frames which need to request data
      const framesList = result.filter(item => !!item)

      if (framesList.length > 0) {
        const data = {
          bagName: bagName,
          sensorsList: ['PCD', 'CAMERA_1'],
          startFrametime: frames[startIndex].timestamp,
          endFrametime: frames[endIndex - 1].timestamp,
          framesList,
        }

        dataServices.getData(data, onMessage, onEnd)
      }
    })

    const onMessage = data => {
      this.buffer[data.frametime] || (this.buffer[data.frametime] = {})

      // For buffer
      if (data.sensorname === 'PCD') {
        const [pcd, kdTree] = this.loadPCD(data.data)
        this.buffer[data.frametime].pcd = pcd
        this.buffer[data.frametime].kdTree = kdTree
      } else {
        this.buffer[data.frametime].camera =
          'data:image/jpeg;base64,' + data.data
      }

      // For cache
      this.dataWorker.postMessage({ action: 'PUT_DATA', data })
    }

    const onEnd = () => {
      console.log('preload ends')
    }
  }

  japStep = () => {
    const { frames } = this.props

    if (this.bufferIndex === frames.length) return

    const index = this.bufferIndex
    const timestamp = frames[index].timestamp

    const getPCD = db['PCD'].get(timestamp)
    const getCAMERA = db['CAMERA_1'].get(timestamp)

    Promise.all([getPCD, getCAMERA])
      .then(result => {
        if (!result[0] && !result[1]) {
          this.preloadData()
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  /** PCD */
  loadPCD = data => {
    const mesh = this.loader.parse(base64ToArrayBuffer(data), 'name')
    dyePCD(mesh)
    this.updatePCD(mesh.material)
    // mesh.rotateZ(Math.PI / 2)
    const kdTree = createPointsKDTree(mesh)
    return [mesh, kdTree]
  }

  addPCD = pcd => {
    const { firstLoading } = this.state

    if (this.currentPCD) {
      this.scene.remove(this.currentPCD)
    }

    // Set point size
    this.updatePCD(pcd.material)

    this.scene.add(pcd)
    this.currentPCD = pcd

    firstLoading && this.setState({ firstLoading: false })
  }

  updatePCD = material => {
    const {
      pointSize,
      pointHue: h,
      pointSaturation: s,
      pointLightness: l,
    } = this.state

    material.size = pointSize // Points size
    material.sizeAttenuation = false
    material.color.setHSL(h, s, l)
  }

  addCameraImg = img => {
    this.setState({
      cameraImg: img
    })

    const { flipCamera, labels } = this.state
    const cam = this.cams[0]

    if (!img) return

    const image = new Image()
    image.src = img

    image.onload = () => {
      if (!this.camScale) {
        const scale = CAM_WIDTH / image.width
        const camHeight = image.height * scale
        this.camScale = scale
        this.imageWidth = image.width
        this.imageHeight = image.height

        cam.canvas.width = CAM_WIDTH
        cam.canvas.height = camHeight
        this.createFcanvases()

        labels.forEach((label, labelIndex) => {
          this.cams.forEach((cam, index) =>
            cam.fcanvas.insertAt(
              label.userData.rects[index],
              labelIndex + 1,
              true,
            ),
          )
        })
      }

      const canvasImg = new fabric.Image(image, {
        hasBorders: false,
        hasControls: false,
        selectable: false,
        angle: flipCamera ? 180 : 0,
        opacity: 1,
        hoverCursor: 'default',
      })
      cam.currentImage = canvasImg

      cam.fcanvas.insertAt(canvasImg, 0, true)
    }
  }

  createRect = ({ left, top, width, height }, type) => {
    const color = MATERIAL_COLORS[type.toUpperCase()]

    const rect = new fabric.Rect({
      left,
      top,
      originX: 'left',
      originY: 'top',
      width,
      height,
      hoverCursor: 'move',
      fill: 'rgba(0, 0, 0, 0)',
      stroke: color,
      strokeWidth: 5,
      strokeUniform: true,
      hasBorders: false,
      cornerColor: '#fff',
      cornerSize: 5,
      borderOpacityWhenMoving: 0.3,
      hasRotatingPoint: false,
    })

    return rect
  }

  getBoundingRect = points => {
    const polygon = new fabric.Polygon(points, {
      fill: 'transparent',
      stroke: 'transparent',
      strokeWidth: 0,
      strokeUniform: true,
      hasRotatingPoint: false,
      cornerColor: '#fff',
      cornerSize: 8,
    })

    return polygon.getBoundingRect()
  }

  createRectFromLabel = label => {
    const points3D = getCornerPosFromBox(label)
    const points2D = points3D.map(point =>
      getUVFromVector3(point, this.projectionMatrix)
    )
    const boundingRect = this.getBoundingRect(points2D)
    const scaledBoundingRect = {
      left: boundingRect.left,
      top: boundingRect.top,
      width: boundingRect.width,
      height: boundingRect.height,
    }

    return this.createRect(scaledBoundingRect, label.userData.type)
  }

  updateRect = label => {
    const { labels } = this.state

    const labelIndex = labels.indexOf(label)

    label.userData.rects.forEach((rect, index) => {
      const newRect = this.createRectFromLabel(label)
      const fcanvas = this.cams[index].fcanvas

      fcanvas.discardActiveObject()
      fcanvas.insertAt(newRect, labelIndex + 1, true)
      fcanvas.setActiveObject(newRect)

      label.userData.rects[index] = newRect
    })
  }

  selectRect = label => {
    this.cams.forEach((cam, index) => {
      cam.fcanvas.setActiveObject(label.userData.rects[index])
      cam.fcanvas.requestRenderAll()
    })
  }

  deselectRect = () => {
    this.cams.forEach(cam => {
      cam.fcanvas.discardActiveObject()
      cam.fcanvas.requestRenderAll()
    })
  }

  /** Add & remove label */
  addLabel = position => {
    const { labels } = this.state

    // 3D Box
    const mesh = this.createLabel({ position })
    this.scene.add(mesh)
    labels.push(mesh)
    this.highlightLabel(mesh)

    // 2D Rect
    this.cams.forEach((cam, index) => {
      cam.fcanvas.add(mesh.userData.rects[index])
    })

    this.selectRect(mesh)
    this.updateLabelsCounter({ type: 'create', payload: mesh.userData.type })
    this.setState({
      labels,
      editingLabel: mesh,
      noLabels: false,
    })
  }

  removeLabel = targetLabel => {
    const { labels } = this.state

    changePointsColorInBox(
      targetLabel,
      this.currentPCD,
      this.pointsKDTree,
      PCD_COLOR
    )

    // Remove label tag
    targetLabel.children[2].element.remove()
    // Remove label mesh
    this.scene.remove(targetLabel)
    // Remove rects
    this.cams.forEach((cam, index) =>
      cam.fcanvas.remove(targetLabel.userData.rects[index]),
    )

    labels.splice(labels.indexOf(targetLabel), 1)
    this.setState({ labels })
    this.updateLabelsCounter({
      type: 'remove',
      payload: targetLabel.userData.type,
    })

    this.setState({ isEdited: labels.length ? true : false })
  }

  updateLabels = objects => {
    const { labels } = this.state

    // Clean labels
    labels.forEach(label => {
      label.children[2].element.remove()
      this.scene.remove(label)
      this.cams.forEach((cam, index) => {
        cam.fcanvas.remove(label.userData.rects[index])
      })
    })
    labels.length = 0

    this.setState(
      {
        labelsCounter: {},
      },
      () => {
        // Push new labels
        objects.forEach(object => {
          let mesh = this.createLabel(object)

          this.scene.add(mesh)
          labels.push(mesh)
          if (this.camScale) {
            this.cams.forEach((cam, index) => {
              cam.fcanvas.add(mesh.userData.rects[index])
            })
          }

          this.updateLabelsCounter({
            type: 'create',
            payload: mesh.userData.type,
          })
        })
      },
    )

    this.setState({
      labels,
    })
  }

  updateLabelsCounter = ({ type, payload }) => {
    let { labelsCounter } = this.state

    const create = data => {
      labelsCounter[data]
        ? (labelsCounter[data] += 1)
        : (labelsCounter[data] = 1)
    }

    const remove = data => {
      this.state.labelsCounter[data] -= 1

      if (!labelsCounter[data]) {
        delete labelsCounter[data]
      }
    }

    const update = data => {
      remove(data.old)
      create(data.new)
    }

    const handler = {
      create,
      remove,
      update,
    }

    handler[type](payload)

    this.setState({ labelsCounter })
  }

  highlightLabel = label => {
    this.unhighlightLabel()
    label.children[0].material.color.set(MATERIAL_COLORS.HIGHLIGHT)
    label.children[2].element.style.backgroundColor =
      MATERIAL_COLORS[label.userData.type.toUpperCase()]
  }

  unhighlightLabel = () => {
    const { editingLabel } = this.state

    if (editingLabel) {
      editingLabel.children[0].material.color.set(
        editingLabel.userData.fail
          ? MATERIAL_COLORS.FAIL
          : MATERIAL_COLORS[editingLabel.userData.type.toUpperCase()],
      )
      editingLabel.children[2].element.style.backgroundColor = ''
    }
  }

  assignID = label => {
    const { labels } = this.state

    label.userData.id =
      labels.length === 0 ? 0 : labels[labels.length - 1].userData.id + 1
  }

  setFail = (label, value) => {
    label.userData.fail = value
    // Change tag color
    label.children[2].element.style.color = value ? MATERIAL_COLORS.FAIL : ''
  }

  // Fabric utils

  render() {
    const {
      currentFrame,
      totalFrames,
      labels,
      labelsCounter,
      cameraImg,
      editingLabel,
      labelAdding,
      labelRemoving,
      helpersShowing,
      cameraShowing,
      auxShowing,
      isInfoVisible,
      isStatsVisible,
      firstLoading,
      isEdited,
      noLabels,
      pointSize,
      pointHue,
      pointSaturation,
      pointLightness,
    } = this.state
    const { user, frames, location, t } = this.props

    const labelsCollection = labels.map(label => (
      <div
        className={`views-collection-row${
          editingLabel && label.uuid === editingLabel.uuid ? ' highlight' : ''
        }`}
        key={label.uuid}
        onClick={e => this.handleSelectLabel(label, e)}
      >
        <div className="views-collection-left">
          <LabelType
            color={MATERIAL_COLORS[label.userData.type.toUpperCase()]}
          />
          <span
            style={{ color: label.userData.fail ? MATERIAL_COLORS.FAIL : '' }}
          >
            {label.name}
          </span>
        </div>
        {editingLabel && label.uuid === editingLabel.uuid ? (
          <div className="views-collection-right">
            <Tooltip title={t('tooltip.remove')} mouseEnterDelay={0.3}>
              <DeleteOutlined onClick={this.handleRemoveEditingLabel} />
            </Tooltip>
          </div>
        ) : null}
      </div>
    ))

    const emptyCollection = (
      <div className="views-collection-empty">
        <div>
          <InboxOutlined style={{ fontSize: '30px' }} />
        </div>
        {t('noLabels')}
      </div>
    )

    const frame = frames[currentFrame - 1]
    let dataURL = ''
    let openState = false

    if (frame) {
      const timestamp = frame.timestamp
      const { bagName, startTimestamp, status } = location.state.record
      const time = bagName.split('-')[1]

      const dataPath = `/${time.substring(0, 4)}/${time.substring(
        4,
        6,
      )}/${time.substring(
        6,
        8,
      )}/${bagName}/Object/${startTimestamp}/${timestamp}.txt`

      dataURL = OPEN_BASE_URL + dataPath
      openState = status === 'objects_ready'
    }

    return (
      <div className="views">
        <div className="views-top-panel">
          <div className="views-left-panel">
            <div
              className="views-main"
              ref={container => (this.views[MAIN].container = container)}
            >
              <div
                className="views-image"
                style={{ visibility: cameraShowing ? 'visible' : 'hidden' }}
              >
                {cameraImg ? (
                  <div>
                    <div className="views-box-bar">
                      <div className="views-name">{t('camerasName.front')}</div>
                    </div>
                    <canvas ref={canvas => (this.cams[0].canvas = canvas)} />
                  </div>
                  /* <div className="views-image-box">
                    <img
                      src={cameraImg}
                      style={
                        flipCamera ? { transform: 'rotate(180deg)' } : null
                      }
                    />
                  </div> */
                ) : (
                  <div className="views-image-missing">{t('missingImage')}</div>
                )}
                {/* <div className="views-box-bar">
                  <div className="views-name">{t('camerasName.front')}</div>
                </div>
                <canvas ref={canvas => (this.cams[0].canvas = canvas)} /> */}
              </div>
              <div
                className="views-aux"
                style={{ visibility: auxShowing ? 'visible' : 'hidden' }}
              >
                <div className="views-box">
                  <div className="views-box-bar">
                    <div className="views-name">{t('viewsName.top')}</div>
                  </div>
                  <div
                    className="views-top"
                    ref={container => (this.views[TOP].container = container)}
                  />
                </div>
                <div className="views-box">
                  <div className="views-box-bar">
                    <div className="views-name">{t('viewsName.side')}</div>
                  </div>
                  <div
                    className="views-side"
                    ref={container => (this.views[SIDE].container = container)}
                  />
                </div>
              </div>

              <HoverPanel
                active={labelAdding || labelRemoving}
                style={{
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1,
                }}
              >
                <IconButton
                  active={labelAdding}
                  type="plus"
                  onClick={this.handleAddLabel}
                />
                <IconButton
                  active={labelRemoving}
                  type="minus"
                  onClick={this.handleRemoveLabel}
                />
                <IconButton
                  active={helpersShowing}
                  type="table"
                  onClick={this.handleToggleHelpers}
                />
                <Divider type="vertical" style={{ margin: '0 4px' }} />
                <IconButton
                  type="reload"
                  onClick={() => this.handleResetCamera(MAIN)}
                />
                <Divider type="vertical" style={{ margin: '0 4px' }} />
                <IconButton
                  active={cameraShowing}
                  type="camera"
                  onClick={this.handleToggleCamera}
                />
                <IconButton
                  active={auxShowing}
                  type="block"
                  onClick={this.handleToggleAuxViews}
                />
                <IconButton
                  active={isInfoVisible}
                  type="info-circle"
                  onClick={this.handleToggleFrameInfo}
                />
                <IconButton
                  active={isStatsVisible}
                  type="bar-chart"
                  onClick={this.handleToggleStatsInfo}
                />
              </HoverPanel>
              <FrameInfo
                frame={frame}
                record={location.state.record}
                visible={isInfoVisible}
              />
              <LabelsStats
                counter={labelsCounter}
                style={{ right: auxShowing ? '26%' : '' }}
                visible={isStatsVisible}
              />
            </div>
          </div>
          <div className="views-right-panel">
            <div className="views-panel-inner">
              <Tabs type="card">
                <TabPane tab={t('labelsTab')} key="0">
                  <div className="ant-tabpane-wrapper">
                    <div className="views-labels-collection">
                      <div className="views-collection-scroll">
                        {labelsCollection.length
                          ? labelsCollection
                          : emptyCollection}
                      </div>
                    </div>
                    {editingLabel ? (
                      <AttributesTable
                        label={editingLabel}
                        user={user}
                        onChange={this.handleInputLabel}
                      />
                    ) : null}
                  </div>
                </TabPane>
                <TabPane tab={t('settingsTab')} key="1">
                  <div className="ant-tabpane-wrapper">
                    <div className="views-settings">
                      <div className="views-setting-row">
                        <div className="views-setting-title">
                          {t('settings.pcd')}
                        </div>
                      </div>
                      <div className="views-setting-row">
                        <div className="views-setting-head">
                          {t('settings.pointSize')}
                        </div>
                        <div className="views-setting-control">
                          <Slider
                            value={pointSize}
                            step={0.01}
                            min={0.5}
                            max={2}
                            marks={{
                              0.5: {
                                style: sliderLabelStyle,
                                label: '0.5',
                              },
                              2: {
                                style: sliderLabelStyle,
                                label: '2',
                              },
                            }}
                            onChange={this.handleChangePointSize}
                          />
                        </div>
                      </div>
                      {/*<div className="views-setting-row">
                        <div className="views-setting-head">
                          {t('settings.pointHue')}
                        </div>
                        <div className="views-setting-control">
                          <Slider
                            value={pointHue}
                            step={0.01}
                            min={0}
                            max={1}
                            marks={{
                              0: {
                                style: sliderLabelStyle,
                                label: '0',
                              },
                              1: {
                                style: sliderLabelStyle,
                                label: '1',
                              },
                            }}
                            onChange={value =>
                              this.handleChangePointColor(value, 'pointHue')
                            }
                          />
                        </div>
                      </div>
                      <div className="views-setting-row">
                        <div className="views-setting-head">
                          {t('settings.pointSaturation')}
                        </div>
                        <div className="views-setting-control">
                          <Slider
                            value={pointSaturation}
                            step={0.01}
                            min={0}
                            max={1}
                            marks={{
                              0: {
                                style: sliderLabelStyle,
                                label: '0',
                              },
                              1: {
                                style: sliderLabelStyle,
                                label: '1',
                              },
                            }}
                            onChange={value =>
                              this.handleChangePointColor(
                                value,
                                'pointSaturation',
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="views-setting-row">
                        <div className="views-setting-head">
                          {t('settings.pointLightness')}
                        </div>
                        <div className="views-setting-control">
                          <Slider
                            value={pointLightness}
                            step={0.01}
                            min={0}
                            max={1}
                            marks={{
                              0: {
                                style: sliderLabelStyle,
                                label: '0',
                              },
                              1: {
                                style: sliderLabelStyle,
                                label: '1',
                              },
                            }}
                            onChange={value =>
                              this.handleChangePointColor(
                                value,
                                'pointLightness',
                              )
                            }
                          />
                        </div>
                      </div> */}
                      <Divider style={{ margin: '1.2rem 0' }} />
                      <div className="views-setting-row">
                        <div className="views-setting-title">
                          {t('settings.camera')}
                        </div>
                      </div>
                      <div className="views-setting-row">
                        <div className="views-setting-head">
                          {t('settings.imageFlip')}
                        </div>
                        <div className="views-setting-control">
                          <Button size="small" onClick={this.handleFlipCamera}>
                            <SyncOutlined />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab={t('shortcutsTab')} key="2">
                  <div className="ant-tabpane-wrapper">
                    <Shortcuts user={user} />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="views-bottom-panel">
          <div className="views-frames-slider">
            <div className="views-slider-bar">
              <Slider
                value={typeof currentFrame === 'number' ? currentFrame : 1}
                min={1}
                max={totalFrames}
                onChange={this.handleInputFrame}
              />
            </div>
            <div className="views-slider-input">
              <InputNumber
                value={currentFrame}
                min={1}
                max={totalFrames}
                size="small"
                style={{ marginLeft: '1rem' }}
                onChange={this.handleInputFrame}
              />
              <span className="views-slider-input-frames">/ {totalFrames}</span>
            </div>
          </div>
          <Divider type="vertical" />
          <div className="views-frames-actions">
            <div className="views-frames-action">
              <Checkbox
                checked={noLabels}
                disabled={!!labels.length}
                onChange={this.handleChangeNoLabels}
              >
                {t('noLabels')}
              </Checkbox>
            </div>
            <div className="views-frames-action">
              <Button
                type="primary"
                size="small"
                onClick={this.handleSave}
                disabled={!isEdited}
              >
                {t('btns.save')}
              </Button>
            </div>
          </div>
          {user.role === role.admin ? (
            <>
              <Divider type="vertical" />
              <div style={{ pointerEvents: openState ? 'auto' : 'none' }}>
                <div className="views-frames-actions">
                  <div className="views-frames-action">
                    <Tooltip title={dataURL}>
                      <CopyToClipboard text={dataURL}>
                        <Button
                          type="primary"
                          size="small"
                          disabled={!openState}
                          onClick={this.handleCopyPath}
                        >
                          .txt
                        </Button>
                      </CopyToClipboard>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <LoadingCover loading={firstLoading} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    bag: state.bag.curBag,
    frames: state.annotation.frames.sort(
      (frameA, frameB) => frameA.timestamp - frameB.timestamp,
    ),
    objects: state.annotation.objects.sort(
      (objectA, objectB) => objectA.id - objectB.id,
    ),
    message: state.alert.message,
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation('views')(Views)),
)
