export function validateNumber(value) {
  // '-' and numbers pass the validation
  return value === '-' || !isNaN(Number(value))
}

export function toPrecision(value, precision) {
  return precision
    ? Math.floor(value * 10 ** precision) / 10 ** precision
    : Math.round(value)
}

export function capitalize(string) {
  return string[0].toUpperCase() + string.substring(1)
}
