import React from 'react'
import './IconButton.scss'

import { Icon as LegacyIcon } from '@ant-design/compatible'

const IconButton = ({
  type,
  active,
  style: customStyle,
  iconStyle = { fontSize: '16px' },
  onClick,
  children
}) => {
  return (
    <button
      className={`icon-button${active ? ' active' : ''}`}
      style={customStyle || null}
      onClick={onClick}
    >
      {children ? (
        children
      ) : (
        <LegacyIcon type={type || 'question'} style={iconStyle} />
      )}
    </button>
  )
}

export default IconButton
