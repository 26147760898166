import React, { Component, Suspense } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { CookiesProvider } from 'react-cookie'

import { history, routes } from './_helpers'
import { localeActions } from './_actions'
import zhCN from 'antd/es/locale/zh_CN'

// Components
import { Layout, AuthRoute } from './common'
import { ConfigProvider } from 'antd'
import 'moment/locale/zh-cn'
import Item from 'antd/lib/list/Item'

class App extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { i18n, dispatch } = this.props

    dispatch(localeActions.changeLang(i18n.language))
  }

  render() {
    const { lang } = this.props

    const locale = lang === 'zh' ? zhCN : null

    return (
      <Suspense fallback="loading">
        <div className="App">
          <ConfigProvider locale={locale}>
            <CookiesProvider>
              <Router history={history}>
                <Switch>
                  {routes
                    .filter(route => !route.auth)
                    .map(route => (
                      <Route
                        key={route.path}
                        path={route.path}
                        // component={route.component}
                        // component={()=>{
                        //   document.title = route.title
                        //   return <route.component></route.component>}}
                        exact={route.exact}
                        // title={route.title}
                        render={props => {
                          document.title = route.title
                          return <route.component {...props} routes={route.routes} title={route.title||'All-Label'}></route.component>
                      }}
                      />
                    ))}
                  <Layout>
                    {routes
                      .filter(route => route.auth)
                      .map(route => (
                        <AuthRoute
                          key={route.path}
                          path={route.path}
                          component={ route.component}
                          auth={route.auth}
                          exact={route.exact}
                          title={route.title||'All-Label'}
                          // title={route.title}
                        //   render={props => {
                        //     document.title = route.title
                        //     return <route.component {...props} routes={route.routes} auth={route.auth}></route.component>
                        // }}
                        />
                      ))}
                  </Layout>
                </Switch>
              </Router>
            </CookiesProvider>
          </ConfigProvider>
        </div>
      </Suspense>
    )
  }
}

function mapStateToProps(state) {
  return {
    lang: state.locale.lang
  }
}

export default connect(mapStateToProps)(withTranslation()(App))
