import React, { Component } from 'react'
import './Profile.scss'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { userActions ,alertActions} from '../../_actions'
import { fieldsToData } from '../../_utils'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Input, Button, Divider } from 'antd'

const formItemLayout = {
  labelAlign: 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const tailFormItemLayout = {
  wrapperCol: { span: 18, offset: 6 }
}

const ProfileForm = Form.create({
  //
  name: 'profile',
  //
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields)
  },
  //
  mapPropsToFields(props) {
    return {
      username: Form.createFormField({
        ...props.username,
        value: props.username.value
      }),
      password: Form.createFormField({
        ...props.password,
        value: props.password.value
      }),
      email: Form.createFormField({
        ...props.email,
        value: props.email.value
      }),
      phonenumber: Form.createFormField({
        ...props.phonenumber,
        value: props.phonenumber.value
      }),
      description: Form.createFormField({
        ...props.description,
        value: props.description.value
      })
    }
  },
  //
  onValuesChange(_, values) {}
})(({ form, onSubmit, loading, t }) => {
  const { getFieldDecorator } = form

  return (
    <Form {...formItemLayout}>
      <Form.Item label={t('username')}>
        {getFieldDecorator('username')(<Input />)}
      </Form.Item>
      <Form.Item label={t('email')}>
        {getFieldDecorator('email', {
          rules: [{ type: 'email', message: 'Invalid email address' }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('phone')}>
        {getFieldDecorator('phonenumber')(<Input />)}
      </Form.Item>
      <Form.Item label={t('description')}>
        {getFieldDecorator('description')(
          <Input.TextArea autosize={{ minRows: 1, maxRows: 4 }} />
        )}
      </Form.Item>
      <Divider />
      <Form.Item label={t('password')}>
        {getFieldDecorator('password')(<Input.Password />)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" onClick={onSubmit} loading={loading}>
          {t('btns.update')}
        </Button>
      </Form.Item>
    </Form>
  )
})

class Profile extends Component {
  constructor(props) {
    super(props)

    const user = props.user

    this.state = {
      fields: {
        username: {
          value: user.username
        },
        email: {
          value: user.email
        },
        phonenumber: {
          value: user.phonenumber
        },
        description: {
          value: user.description
        },
        password: {
          value: ''
        }
      }
    }
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }))
  }

  handleSubmit = () => {
    const { fields } = this.state
    const { dispatch, user ,t} = this.props

    const data = fieldsToData(fields, {
      id: user.id,
      role: user.role,
      isActive: user.is_active||user.isActive,
      groupId:user.group_id||user.groupId
    })

    dispatch(userActions.update(data, 'self')).then(() => {
      dispatch(
        alertActions.success({
          type: 'success',
          detail: t('msg.updateSuccessful')
        })
      )
      dispatch(userActions.get(user.id))
      dispatch(userActions.getAll())
    })
  }

  render() {
    const { fields } = this.state
    const { updatingUser, t } = this.props

    return (
      <div className="profile">
        <div className="profile-panel">
          <div className="profile-form-container">
            <ProfileForm
              {...fields}
              onChange={this.handleFormChange}
              onSubmit={this.handleSubmit}
              loading={updatingUser}
              t={t}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    updatingUser: state.authentication.updatingUser
  }
}

export default connect(mapStateToProps)(withTranslation('users')(Profile))
