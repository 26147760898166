import React, { Component } from 'react'
import './GAdminHome.scss'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { userActions, alertActions } from '../_actions'
import { getFilteredUsers } from '../_utils'

import { Card, Divider, Button, Statistic, message } from 'antd'

const bodyStyle = {
  backgroundColor: '#202020',
  padding: '1.5rem 1.5rem 1rem'
}

const defaultDividerStyle = {
  margin: '1rem 0'
}

class GAdminHome extends Component {
  componentDidMount() {
    const { dispatch } = this.props

    dispatch(userActions.getAll())
  }

  componentDidUpdate(prevProps) {
    const { dispatch, message: alertMessage } = this.props

    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }
  }

  render() {
    const { annotations, users, t } = this.props

    return (
      <div className="gadmin">
        <div className="gadmin-panel">
          <div className="gadmin-card-container">
            <Card
              title={t('usersTitle')}
              bordered={false}
              bodyStyle={bodyStyle}
            >
              <div className="gadmin-card-body">
                <div className="gadmin-statistic-container">
                  <Statistic
                    title={t('common:role.groupAnnotator')}
                    value={getFilteredUsers(users, 'annotator').length}
                  />
                </div>
                <div className="gadmin-statistic-container">
                  <Statistic
                    title={t('common:role.groupExaminer')}
                    value={getFilteredUsers(users, 'examiner').length}
                  />
                </div>
              </div>
              <Divider style={defaultDividerStyle} />
              <div className="gadmin-card-actions">
                <Link to="/users">
                  <Button type="primary" size="small" ghost>
                    {t('btns.allUsers')}
                  </Button>
                </Link>
              </div>
            </Card>
          </div>
          <div className="gadmin-card-container">
            <Card
              title={t('annotationsTitle')}
              bordered={false}
              bodyStyle={bodyStyle}
            >
              <div className="gadmin-card-body">
                <div className="gadmin-statistic-container">
                  <Statistic
                    title={t('stats.total')}
                    value={annotations ? annotations.length : 0}
                  />
                </div>
              </div>
              <Divider style={defaultDividerStyle} />
              <div className="gadmin-card-actions">
                <Link to="/annotations">
                  <Button type="primary" size="small" ghost>
                    {t('btns.allAnnotations')}
                  </Button>
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    users: state.authentication.users,
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(
  withTranslation(['gAdminHome'])(GAdminHome)
)
