import React from 'react'
import './RightLabel.scss'
import { withTranslation } from 'react-i18next'

import { MATERIAL_COLORS } from '../../constants'
import { LabelType } from '../../../common'
import { InboxOutlined } from '@ant-design/icons'

const RightLabel = ({ labels,editingLabel,handleSelectLabel,t }) => {
  const LabelsCollection = labels.map(label => (
    <li
    className={`viewer2-labels-collection-item${
      editingLabel && label.uuid === editingLabel.uuid ? ' selected' : ''
    }`}
      onClick={() => handleSelectLabel(label)}
      key={label.uuid}
      style={{ display:label.visibleList?'none':'block' }}
      id={label.uuid}
    >
      <div className="viewer2-label-row">
        <div className="viewer2-label-type">
          <LabelType color={MATERIAL_COLORS[label.userData.type.toUpperCase()]} />
          {label.name}
        </div>
      </div>
    </li>
  ))

  const emptyCollection = (
    <div className="viewer2-labels-collection-empty">
      <div>
        <InboxOutlined style={{ fontSize: '30px' }} />
      </div>
      {t('noLabels')}
    </div>
  )

  return (
    <ul className="viewer2-labels-collection viewer2-labels-right">
      {LabelsCollection.length
        ? LabelsCollection
        : emptyCollection}
    </ul>
  )
}

export default withTranslation('views')(RightLabel)
