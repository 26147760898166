import React, { Component } from 'react'
import './Test.scss'
import { connect } from 'react-redux'
import {
  annotationActions,
  userActions,
  alertActions,
  bagActions,
  groupActions
} from '../_actions'

import {
  AnnotationForm,
  FrameForm,
  UserForm,
  GroupForm,
  BagForm
} from '../common'
import { Card, message } from 'antd'

const bodyStyle = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#202020',
  padding: '2.5rem 2rem'
}

const formItemLayout = {
  labelAlign: 'left',
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const tailFormItemLayout = {
  wrapperCol: {
    span: 18,
    offset: 6
  }
}

const tabList = [
  {
    key: 'bag',
    tab: 'Bag'
  },
  {
    key: 'annotation',
    tab: 'Annotation'
  },
  {
    key: 'frame',
    tab: 'Frame'
  },
  {
    key: 'user',
    tab: 'User'
  },
  {
    key: 'group',
    tab: 'Group'
  }
]

class Test extends Component {
  state = {
    key: 'annotation'
  }

  componentDidUpdate(prevProps) {
    const { dispatch, message: alertMessage } = this.props

    if (
      alertMessage &&
      alertMessage.detail !== (prevProps.message && prevProps.message.detail)
    ) {
      if (alertMessage.type === 'success') {
        message.success(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      } else {
        message.error(alertMessage.detail, 2, () =>
          dispatch(alertActions.clear())
        )
      }
    }
  }

  handleCreateBag = values => {
    const { dispatch } = this.props

    console.log(values)

    dispatch(bagActions.create(values))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: 'Create success'
          })
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: error.detail
          })
        )
      })
  }

  handleCreateAnnotation = values => {
    const { dispatch } = this.props

    dispatch(annotationActions.createAnnotation(values))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: 'Create success'
          })
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: error.detail
          })
        )
      })
  }

  handleCreateFrame = values => {
    const { dispatch } = this.props

    dispatch(annotationActions.createFrame(values))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: 'Create success'
          })
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: error.detail
          })
        )
      })
  }

  handleCreateUser = values => {
    const { dispatch } = this.props

    dispatch(userActions.create(values))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: 'Create success'
          })
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: error.detail
          })
        )
      })
  }

  handleCreateGroup = values => {
    const { dispatch } = this.props

    dispatch(groupActions.create(values))
      .then(() => {
        dispatch(
          alertActions.success({
            type: 'success',
            detail: 'Create success'
          })
        )
      })
      .catch(error => {
        dispatch(
          alertActions.error({
            type: 'error',
            detail: error.detail
          })
        )
      })
  }

  onTabChange = (key, type) => {
    this.setState({ [type]: key })
  }

  render() {
    const contentList = {
      bag: (
        <BagForm
          onSubmit={this.handleCreateBag}
          formItemLayout={formItemLayout}
          tailFormItemLayout={tailFormItemLayout}
        />
      ),
      annotation: (
        <AnnotationForm
          onSubmit={this.handleCreateAnnotation}
          formItemLayout={formItemLayout}
          tailFormItemLayout={tailFormItemLayout}
        />
      ),
      frame: (
        <FrameForm
          onSubmit={this.handleCreateFrame}
          formItemLayout={formItemLayout}
          tailFormItemLayout={tailFormItemLayout}
        />
      ),
      user: (
        <UserForm
          onSubmit={this.handleCreateUser}
          formItemLayout={formItemLayout}
          tailFormItemLayout={tailFormItemLayout}
        />
      ),
      group: (
        <GroupForm
          onSubmit={this.handleCreateGroup}
          formItemLayout={formItemLayout}
          tailFormItemLayout={tailFormItemLayout}
        />
      )
    }

    return (
      <div className="test">
        <Card
          bordered={false}
          bodyStyle={bodyStyle}
          tabList={tabList}
          activeTabKey={this.state.key}
          onTabChange={key => {
            this.onTabChange(key, 'key')
          }}
        >
          <div className="test-form-container">
            {contentList[this.state.key]}
          </div>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(Test)
