import React from 'react'
import { withTranslation } from 'react-i18next'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Input, Button } from 'antd'

const GroupForm = ({
  form,
  onSubmit,
  formItemLayout,
  tailFormItemLayout,
  loading,
  t
}) => {
  const { getFieldDecorator, validateFields } = form

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((error, values) => {
      if (!error) {
        onSubmit(values)
      }
    })
  }

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={t('name')}>
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Group name can not be empty' }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('email')}>
        {getFieldDecorator('email', {
          rules: [
            { type: 'email', required: false, message: 'Invalid email address' }
          ]
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('phone')}>
        {getFieldDecorator('phonenumber')(<Input />)}
      </Form.Item>
      <Form.Item label={t('description')}>
        {getFieldDecorator('description')(
          <Input.TextArea autosize={{ minRows: 1, maxRows: 4 }} />
        )}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={loading}>
        {t('btns.create')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default withTranslation(['groups', 'common'])(Form.create({ name: 'group form' })(GroupForm))
